import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 0,

  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1.5),
}));

export const SelectionRetainerWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "row",
  padding: "10px",
  alignItems: "center",

  backgroundColor: theme.colors.secondary.main,
}));

export const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.neutral.black[100],
  fontSize: 14,
  fontWeight: 700,
}));
