import { FC } from "react";
import { TextFieldProps } from "@mui/material";

import { InputWrapper, InputLabel } from "./Inputs.styles";
import CustomInput from "./custom-input/CustomInput";
import LockedIcon from "./components/LockedIcon";

type BaseInputProps = { inputLabel: string } & TextFieldProps;

const BaseInput: FC<BaseInputProps> = ({ inputLabel, disabled, ...props }) => {
  return (
    <InputWrapper>
      <InputLabel>{inputLabel}</InputLabel>
      <CustomInput
        disabled={disabled}
        {...props}
        InputProps={{
          endAdornment: <>{disabled ? <LockedIcon /> : null}</>,
        }}
      />
    </InputWrapper>
  );
};

export default BaseInput;
