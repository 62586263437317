import { Typography, TypographyProps, styled } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";

export const StyledNavIcon = styled(NavigateNext)(({ theme }) => ({
  color: theme.colors.neutral.white[100],
}));

export const StyledTypography = styled(Typography)<TypographyProps>(() => ({
  fontSize: 10,
  fontWeight: 700,
  textTransform: "uppercase",
}));
