export type Error = {
  type: string;
  value: number | string;
};

type ErrorBetLeg = {
  errors: Error[];
  requestOrder: number;
};

export type ErrorBet = {
  errors: Error[];
  legs: ErrorBetLeg[];
  requestOrder: number;
};

export type ErrorData = {
  errors: Error[];
  bets: ErrorBet[];
};

export function isErrorData(data: any): data is ErrorData {
  return (
    (data as ErrorData).errors !== undefined &&
    (data as ErrorData).bets !== undefined
  );
}
