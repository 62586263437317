import { Dialog, useTheme } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";
import { FC, useState } from "react";

import {
  DesktopWrapper,
  MobileWrapper,
} from "src/components/component-wrappers";
import MobileTable from "src/components/MobileTable/MobileTable";
import Table from "src/components/Table/Table";

import { modalColumns } from "../data-table/columns";

import { MyBetsDataRow, MyBetsMobileDataSubRow } from "../../models/DataRow";

import appConfig from "src/config/appConfig";

type SubRowsModalProps = {
  open: boolean;
  closeModal: () => void;
  rows: MyBetsDataRow[];
  mobileRows: MyBetsMobileDataSubRow;
};

const SubRowsModal: FC<SubRowsModalProps> = ({
  open,
  closeModal,
  rows,
  mobileRows,
}) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 5,
    page: 0,
  });

  const theme = useTheme();

  function paginationModelHandler(newPaginationModel: GridPaginationModel) {
    setPaginationModel(newPaginationModel);
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "10px",
          backgroundColor: theme.colors.neutral.black[100],

          margin: theme.spacing(1),
          maxHeight: "calc(100% - 60px)",
          width: theme.breakpoints.values[appConfig.breakMobileView],
          padding: theme.spacing(2),
        },
      }}
    >
      <MobileWrapper>
        <MobileTable columns={modalColumns} rows={mobileRows} />
      </MobileWrapper>
      <DesktopWrapper>
        <Table
          columns={modalColumns}
          rows={rows}
          pageSizeOptions={[5, 10, 15, 20]}
          loading={false}
          refetch={() => {}}
          isFetching={false}
          paginationModel={paginationModel}
          setPaginationModel={paginationModelHandler}
          totalRows={rows.length}
        />
      </DesktopWrapper>
    </Dialog>
  );
};

export default SubRowsModal;
