import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "src/store/store";
import { ITransactionsState } from "./transactionSlice.contracts";
import { TransactionType, TransactionTypes } from "src/models/users";
import calculatePeriodValue from "../utils/calculatePeriodValue";

const initialState: ITransactionsState = {
  periodFilter: { value: calculatePeriodValue("today"), label: "today" },
  typefilter: { value: TransactionTypes, label: "all" },
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setPeriodFilter(state, action: PayloadAction<{ value: any }>) {
      const { value } = action.payload;
      state.periodFilter.label = value;
      state.periodFilter.value = calculatePeriodValue(value);
    },
    setTypeFilter(state, action: PayloadAction<{ value: string }>) {
      const { value } = action.payload;
      state.typefilter.label = value;
      switch (value) {
        case "all":
          state.typefilter.value = TransactionTypes;
          break;
        case "deposit":
          state.typefilter.value = [TransactionType.DEPOSIT];
          break;
        case "withdrawal":
          state.typefilter.value = [TransactionType.WITHDRAWAL];
          break;
        default:
          state.typefilter.value = TransactionTypes;
      }
    },
  },
});

export default transactionsSlice.reducer;
export const { setPeriodFilter, setTypeFilter } = transactionsSlice.actions;

export const selectPeriodFilter = (state: RootState) =>
  state.transactions.periodFilter;
export const selectTypeFilter = (state: RootState) =>
  state.transactions.typefilter;
