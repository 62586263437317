import { useCallback } from "react";

import { useCreateMultiBet } from "./createMultiBet";

import { SportBet } from "../models/sport";

type mode = "doubles" | "trebles" | "quads";

const useCreateDoubleBets = () => {
  const createMultiBet = useCreateMultiBet();

  const createDoubleBets = useCallback(
    (sportBets: SportBet[], stake: number) => {
      let count = 1;
      const doubleBets: SportBet[] = [];

      for (let i = 0; i < sportBets.length; i++) {
        for (let j = i + 1; j < sportBets.length; j++) {
          const betsToCombine: SportBet[] = [sportBets[i], sportBets[j]];
          const combinedBet = createMultiBet(
            betsToCombine,
            `double${count}`,
            stake
          );
          doubleBets.push(combinedBet);
          count++;
        }
      }

      return doubleBets;
    },
    [createMultiBet]
  );

  return createDoubleBets;
};

const useCreateTrebleBets = () => {
  const createMultiBet = useCreateMultiBet();

  const createTrebleBets = useCallback(
    (sportBets: SportBet[], stake: number) => {
      let count = 1;
      const trebleBets: SportBet[] = [];

      for (let i = 0; i < sportBets.length; i++) {
        for (let j = i + 1; j < sportBets.length; j++) {
          for (let k = j + 1; k < sportBets.length; k++) {
            const betsToCombine: SportBet[] = [
              sportBets[i],
              sportBets[j],
              sportBets[k],
            ];
            const combinedBet = createMultiBet(
              betsToCombine,
              `treble${count}`,
              stake
            );
            trebleBets.push(combinedBet);
            count++;
          }
        }
      }

      return trebleBets;
    },
    [createMultiBet]
  );

  return createTrebleBets;
};

const useCreateQuadBets = () => {
  const createMultiBet = useCreateMultiBet();

  const createQuadBets = useCallback(
    (sportBets: SportBet[], stake: number) => {
      let count = 1;
      const quadBets: SportBet[] = [];

      for (let i = 0; i < sportBets.length; i++) {
        for (let j = i + 1; j < sportBets.length; j++) {
          for (let k = j + 1; k < sportBets.length; k++) {
            for (let l = k + 1; l < sportBets.length; l++) {
              const betsToCombine: SportBet[] = [
                sportBets[i],
                sportBets[j],
                sportBets[k],
                sportBets[l],
              ];
              const combinedBet = createMultiBet(
                betsToCombine,
                `quad${count}`,
                stake
              );
              quadBets.push(combinedBet);
              count++;
            }
          }
        }
      }

      return quadBets;
    },
    [createMultiBet]
  );

  return createQuadBets;
};

export const useCreateCombinedBets = () => {
  const createDoubleBets = useCreateDoubleBets();
  const createTrebleBets = useCreateTrebleBets();
  const createQuadBets = useCreateQuadBets();

  const createCombinedBets = useCallback(
    (sportBets: SportBet[], mode: mode, stake: number) => {
      const combinedBets: SportBet[] = [];

      if (mode === "doubles") {
        const doubleBets = createDoubleBets(sportBets, stake);
        combinedBets.push(...doubleBets);
      }

      if (mode === "trebles") {
        const trebleBets = createTrebleBets(sportBets, stake);
        combinedBets.push(...trebleBets);
      }

      if (mode === "quads") {
        const quadBets = createQuadBets(sportBets, stake);
        combinedBets.push(...quadBets);
      }

      return combinedBets;
    },
    [createDoubleBets, createTrebleBets, createQuadBets]
  );

  return createCombinedBets;
};
