import { Box, BoxProps, styled } from "@mui/material";

export const ModalWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  height: "100%",
  width: "100%",
  overflow: "auto",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(1),
  padding: theme.spacing(2, 3.5),
}));

export const ButtonWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),

  paddingTop: theme.spacing(2),
}));
