import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
  styled,
} from "@mui/material";

export const FilterWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  height: 50,
  width: `calc(100% + ${theme.spacing(8)})`,
  marginLeft: theme.spacing(-4),

  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(1.5),
  padding: theme.spacing(0, 2.5),

  backgroundColor: "#383838",
}));

export const ButtonsWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  height: "100%",
  width: "100%",

  backgroundColor: "inherit",

  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
}));

interface StyledButtonProps extends ButtonProps {
  isActive?: boolean;
}

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<StyledButtonProps>(({ theme, isActive }) => ({
  height: 38,
  minHeight: 38,

  backgroundColor: isActive ? theme.colors.neutral.black[100] : "inherit",
  borderRadius: 6,
  boxShadow: isActive ? "2px 0px 6px " + theme.colors.secondary.light : "none",

  color: theme.colors.neutral.white[100],
  fontSize: 10,
  fontWeight: 600,
  textTransform: "none",

  transition: "color .2s",

  "&:hover": {
    backgroundColor: isActive
      ? theme.colors.neutral.black[100]
      : theme.colors.neutral.black[25],
    fontWeight: isActive ? 600 : 700,
  },
}));

export const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  height: 50,
  width: "100%",

  backgroundColor: "inherit",

  justifyContent: "center",
  alignItems: "stretch",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),

  "& .MuiTabs-flexContainer": {
    gap: theme.spacing(1),
  },

  //indicator
  "& .MuiTabs-indicator": {
    bottom: theme.spacing(1),
    height: 38,
    minHeight: 38,
    borderRadius: 6,
    border: "2px solid " + theme.colors.neutral.black[100],
    boxShadow: "0px 2px 2px " + theme.colors.secondary.light,
    backgroundColor: theme.colors.neutral.black[100],
  },

  "& .MuiTabs-scrollButtons": {
    display: "none",
  },
}));

export const StyledTab = styled(Tab)<TabProps>(({ theme }) => ({
  height: 38,
  minHeight: 38,
  borderRadius: 6,
  transition: "color .2s",

  paddingLeft: "10px",
  paddingRight: "10px",

  fontSize: "10px",
  fontWeight: 600,
  color: theme.colors.text.light,

  "&.Mui-selected, &.Mui-selected:hover": {
    color: theme.colors.secondary.main,
    zIndex: 5,
  },

  "&:hover": {
    color: theme.colors.neutral.white[80],
  },
}));
