import { FC } from "react";
import { ExpandMore } from "@mui/icons-material";

import { StyledGridPagination } from "./CustomPagination.styles";

const CustomPagination: FC = () => {
  return (
    <StyledGridPagination
      labelRowsPerPage="Items per page"
      slotProps={{
        select: {
          IconComponent: ExpandMore,
        },
      }}
    />
  );
};

export default CustomPagination;
