import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { FC, useState } from "react";

import RepeatBetButton from "./RepeatBetButton";
import SubRowsModal from "../modal";

import { MyBetsItemLeg } from "../../models/MyBetsItem";

import { MyBetsMobileDataRow, MyBetsDataRow } from "../../models/DataRow";

import { isAfter, formatDate } from "src/utils/dateHelper";

const RenderSelectionCell: FC<GridRenderCellParams<any, MyBetsDataRow>> = ({
  row,
  value,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  if (row.subRows && row.subRows.length > 0) {
    return (
      <>
        <div onClick={openModal}>Multiple Selections</div>
        <SubRowsModal
          open={showModal}
          closeModal={closeModal}
          rows={row.subRows}
          mobileRows={{} as MyBetsMobileDataRow}
        />
      </>
    );
  }

  return <>{value}</>;
};

const RenderRepeatBetCell: FC<GridRenderCellParams<any, MyBetsItemLeg>> = ({
  value,
}) => {
  const fixtureDate = value ? new Date(value.fixtureDate) : undefined;
  const isExpired = fixtureDate ? isAfter(new Date(), fixtureDate) : true;

  JSON.stringify(value, null, 4);

  return <RepeatBetButton disabled={isExpired} leg={value} />;
};

export const columns: GridColDef[] = [
  {
    field: "betDate",
    headerName: "Bet Date",
    renderCell: (params) => <>{formatDate(params.value, "DD/MM/YYYY HH:mm")}</>,
  },
  {
    field: "ticketRef",
    headerName: "Ticket Ref",
  },
  {
    field: "fixtureDate",
    headerName: "Fixture Date",
    renderCell: (params) => <>{formatDate(params.value, "DD/MM/YYYY HH:mm")}</>,
  },
  {
    field: "fixture",
    headerName: "Fixture",
  },
  {
    field: "market",
    headerName: "Market",
  },
  {
    field: "selection",
    headerName: "Selection",
    renderCell: RenderSelectionCell,
  },
  {
    field: "price",
    headerName: "Price",
  },
  {
    field: "stake",
    headerName: "Stake",
  },
  {
    field: "tax",
    headerName: "Tax",
  },
  {
    field: "potentialPayout",
    headerName: "Potential Payout",
  },
  {
    field: "status",
    headerName: "Status",
  },
  {
    field: "repeatBet",
    headerName: "Repeat Bet",
    renderCell: RenderRepeatBetCell,
  },
];

export const modalColumns = columns.filter(
  (column) => column.field !== "repeatBet"
);
