import { FC, useEffect } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";

import { Wrapper, SubWrapper, SmallText, Text } from "./BetItem.styles";
import BetCount from "../../betCount";
import LottoBalls from "./lottoBalls/LottoBalls";
import BetInput from "./input/BetInput";
import { Icon } from "src/components/icons";

import { useLottoBetItem } from "./LottoBetItem.hooks";

import { LottoBet } from "src/features/betslip/models/lotto";

import { formatDate, isAfter } from "src/utils/dateHelper";

type LottoBetItemProps = {
  bet: LottoBet;
  index: number;
};

const BetItem: FC<LottoBetItemProps> = ({ bet, index }) => {
  const { removeFromBetSlip, updateLottoStake } = useLottoBetItem();

  const betLeg = bet.legs[0];

  const grids = betLeg.selection
    ? betLeg.selection
        .map((selection) => selection.gridNumber)
        .filter(
          (gridNumber, index, array) => array.indexOf(gridNumber) === index
        )
    : undefined;

  useEffect(() => {
    const closeDate = betLeg.wagerCloseDate;
    if (isAfter(new Date(), closeDate)) {
      toast.info(`A bet in your bet slip is expired and has been removed.`);
      removeFromBetSlip(bet.id);
    }
  }, [bet, betLeg, removeFromBetSlip]);

  return (
    <Wrapper>
      <SubWrapper textAlign={"left"}>
        <Box alignSelf={"end"} paddingBottom={"10px"}>
          <BetCount value={index + 1} size={24} fontSize={11} />
        </Box>
        <div>
          <Text>{formatDate(betLeg.wagerCloseDate, "custom")}</Text>
          <Text>{betLeg.marketName}</Text>
          <Stack
            direction="row"
            divider={
              <Typography color="secondary" fontSize={20}>
                /
              </Typography>
            }
            spacing={1}
          >
            {grids ? (
              grids.map((gridNumber) => {
                if (betLeg.selection) {
                  const gridSelections = betLeg.selection
                    ? betLeg.selection.filter(
                        (selection) => selection.gridNumber === gridNumber
                      )
                    : [];
                  return (
                    <LottoBalls
                      key={gridNumber}
                      lottoBalls={gridSelections ?? []}
                    />
                  );
                }

                if (betLeg.pickSetSelections) {
                  return (
                    <>
                      {betLeg.pickSetSelections.map((selection) => (
                        <LottoBalls
                          key={selection.pickSetId}
                          lottoBalls={selection.balls.map((ball) => ({
                            ...ball,
                            gridNumber: selection.gridNumber,
                          }))}
                        />
                      ))}
                    </>
                  );
                }

                return null;
              })
            ) : (
              <SmallText>{betLeg.selectionName}</SmallText>
            )}
          </Stack>
        </div>
      </SubWrapper>
      <SubWrapper alignItems="center">
        <BetInput
          id={bet.id}
          price={bet.legs[0].price}
          stake={bet.stake}
          updateStake={updateLottoStake}
        />
        <IconButton
          onClick={() => removeFromBetSlip(bet.id, index)}
          sx={{ alignSelf: "end", paddingBottom: "10px" }}
        >
          <Icon name="TrashLight" />
        </IconButton>
      </SubWrapper>
    </Wrapper>
  );
};

export default BetItem;
