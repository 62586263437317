import { toast } from "react-toastify";

import { NumbersExoticMarketType } from "./types";

export const getBallsToPick = (market: NumbersExoticMarketType) => {
  const tier = market.def.data.tiers[0];
  const pickSet = market.def.data.pickSets.find(
    (ps) => ps.id === tier.tierCriteria[0].pickSetId
  );

  if (!pickSet) {
    toast.error("Could not determine selection criteria");
    return undefined;
  }

  return {
    ballsToPick: pickSet.ballsToPick,
    gridNumber: pickSet.gridNumber,
    pickSetId: pickSet.id,
  };
};
