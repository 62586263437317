import { toast } from "react-toastify";

import { useLottoBetSlipSlice } from "src/features/betslip";
import { LottoBet } from "src/features/betslip/models/lotto";
import { isLabelSelection, MyBetsItemLeg } from "../models/MyBetsItem";

import { useLazyGetFixtureQuery } from "src/services/restApi/queries/lotto.service";

import { customExoticMapper } from "src/features/luckyNumbers/Markets/exotic-bets/custom/mapper";

import logger from "src/utils/logger";
import {
  isCustomExoticMarket,
  isNumbersExoticMarket,
  isQuickPicksMarket,
} from "src/features/luckyNumbers/Fixture/utils/markets";
import { advancedNumbersMapper } from "src/features/luckyNumbers/Markets/exotic-bets/numbers/mapper";
import { getBallsToPick } from "src/features/luckyNumbers/Markets/exotic-bets/numbers/getBallsToPick";
import { myNumbersMapper } from "src/features/luckyNumbers/Markets/my-numbers/mapper";
import { LottoFixtureBallType } from "src/features/luckyNumbers";

/**
 * Custom hook which adds repeat lotto bet functionality.
 * @returns A function which adds the bet to repeat to your bet slip and the loading state.
 */
export const useRepeatLottoBet = (): [
  (lottoBetLeg: MyBetsItemLeg) => Promise<void>,
  { isLoading: boolean },
] => {
  const { addBet } = useLottoBetSlipSlice();
  const [getFixture, { isFetching, isLoading }] = useLazyGetFixtureQuery();

  const repeatLottoBet = async (lottoBetLeg: MyBetsItemLeg) => {
    console.log("bet to repeat", JSON.stringify(lottoBetLeg, null, 4));
    try {
      const { data: fixture } = await getFixture(lottoBetLeg.fixtureId);
      if (!fixture) {
        throw new Error("Unable to find bet to repeat.");
      }

      const market = fixture.markets.find(
        (market) => market.id === lottoBetLeg.marketId
      );
      if (!market) {
        throw new Error("Unable to find bet to repeat.");
      }

      let lottoBet: LottoBet;
      if (
        lottoBetLeg.selectionType === "NWAY" &&
        isCustomExoticMarket(market)
      ) {
        const selection = market.selections.find(
          (selection) => selection.id === lottoBetLeg.selectionId
        );
        if (!selection) {
          throw new Error("Unable to find bet to repeat.");
        }
        lottoBet = customExoticMapper(
          lottoBetLeg.projectedStake,
          fixture.def.id,
          market,
          selection
        );
      } else if (
        lottoBetLeg.selectionType === "ADV_NUMBERS" &&
        isNumbersExoticMarket(market)
      ) {
        const selectionCriteria = getBallsToPick(market);
        if (!selectionCriteria) {
          throw new Error("Unable to find bet to repeat.");
        }

        const grid = fixture.def.data.grids.find(
          (grid) => grid.gridNumber === selectionCriteria.gridNumber
        );
        if (!grid) {
          throw new Error("Unable to find bet to repeat.");
        }

        const numbers = grid.balls.filter((ball) => {
          if (lottoBetLeg.selection && "balls" in lottoBetLeg.selection[0]) {
            return lottoBetLeg.selection[0].balls.includes(ball.id);
          }

          return false;
        });

        lottoBet = advancedNumbersMapper(
          lottoBetLeg.projectedStake,
          fixture.def.id,
          selectionCriteria.gridNumber,
          market,
          numbers,
          selectionCriteria.pickSetId
        );
      } else {
        if (lottoBetLeg.selection && isQuickPicksMarket(market)) {
          const selectedNumbers: LottoFixtureBallType[] = [];
          lottoBetLeg.selection.forEach((selection) => {
            if (isLabelSelection(selection)) {
              selectedNumbers.push({
                ...selection,
                id: parseInt(selection.label),
              });
            }
          });
          lottoBet = myNumbersMapper(
            lottoBetLeg.projectedStake,
            fixture.def.id,
            market,
            selectedNumbers
          );
        } else {
          throw new Error("Unable to find bet to repeat.");
        }
      }

      addBet(lottoBet);
      toast.success("Repeat bet added to bet slip.");
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        logger.error(error);
      }
    }
  };

  return [repeatLottoBet, { isLoading: isFetching || isLoading }];
};
