/**
 * Regular expression to text if character is a digit
 */
export const RE_DIGIT = new RegExp(/^\d+$/);

/**
 * Splits an OTP string into an array of characters
 * @param otp - OTP string
 * @param otpLength - Maximum length of OTP string
 * @returns String array
 */
export const splitOtp = (otp: string, otpLength: number) => {
  const splitArray = otp.split("");
  const otpArray: string[] = [];

  for (let i = 0; i < otpLength; i++) {
    const char = splitArray[i];

    if (RE_DIGIT.test(char)) {
      otpArray.push(char);
    } else {
      otpArray.push("");
    }
  }

  return otpArray;
};
