import { FC, useState } from "react";

import { DateText, ExpandedText, SubtitleText, TitleText } from "./BetInfo.styles";

import { SportBetInfo } from "src/features/betslip/models/sport";

import { Close, Info } from "@mui/icons-material";
import { IconButton, Menu } from "@mui/material";
import Stack from "@mui/material/Stack";
import { formatDate } from "src/utils/dateHelper";

type BetInfoProps = {
  info: SportBetInfo;
};

const BetInfo: FC<BetInfoProps> = ({ info }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  console.log(info);

  return (
    <div>
      <DateText>{formatDate(info.fixtureStartTime, "custom")}</DateText>
      <TitleText>{info.name}</TitleText>
      <Stack direction='row' spacing={0.5}>
        <IconButton
          size='small'
          onClick={handleClick}
          sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
        >
          <Info sx={{ color: 'white', fontSize: 14 }} />
        </IconButton>
        <SubtitleText>{info.marketName}</SubtitleText>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <IconButton sx={{ position: 'absolute', top: 2, right: 2 }} onClick={handleClose}>
          <Close fontSize="small" />
        </IconButton>
        <Stack direction={'column'}>
          <ExpandedText><strong>Bet Information</strong></ExpandedText>
          {info.competitors && info.competitors.length > 1 &&
            <ExpandedText>
              {info.competitors.join(' vs ')}
            </ExpandedText>
          }
          <ExpandedText>{info.sport}</ExpandedText>
          <ExpandedText>{info.region}</ExpandedText>
          <ExpandedText>{info.tournament}</ExpandedText>
          <ExpandedText>{info.marketName}</ExpandedText>
          <ExpandedText>{formatDate(info.fixtureStartTime, "custom")}</ExpandedText>
        </Stack>
      </Menu>
    </div>
  );
};

export default BetInfo;
