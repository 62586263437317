import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CustomDialog from "src/components/custom-dialog/CustomDialog";

import { Typography } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { AuthDialogType, useAuthDailogStore } from "src/store/authentication/authStore";

const DisabledModal: FC = () => {
  const navigate = useNavigate();
  const setAuthDisplayType = useAuthDailogStore((state) => state.setDisplayType);
  const authDisplayType = useAuthDailogStore((state) => state.displayType);
  const {
    isGuarded,
    setIsGuardedHandler,
    logout,
  } = useAuth();

  function onCloseHandler() {
    if (isGuarded) {
      navigate("/home");
      setIsGuardedHandler(false);
    }
    setAuthDisplayType(AuthDialogType.None);
    localStorage.removeItem('accountDisabled');
  }

  const disabled = localStorage.getItem('accountDisabled') === 'true';

  useEffect(() => {
    if (disabled) {
      setAuthDisplayType(AuthDialogType.Disabled);
      logout();
    }
  }, [disabled, setAuthDisplayType, logout]);

  return (
    <CustomDialog
      open={authDisplayType === AuthDialogType.Disabled}
      onClose={onCloseHandler}
      onCloseHandler={onCloseHandler}
    >
      <Typography
        variant="h6"
        component="h6"
        align="center"
        sx={{ p: 4, zIndex: 100000 }}
      >
        Your account has been disabled. Please contact customer care.
      </Typography>
    </CustomDialog>
  );
};

export default DisabledModal;
