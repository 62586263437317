import { FC } from "react";

import { StyledBox, StyledText } from "./Declaration.styles";

const Declaration: FC = () => {
  return (
    <StyledBox>
      <StyledText>
        By creating my account below I certify that I am over 18 years of age, I
        have read and accepted Terms & Conditions and Privacy Policy, and I
        agree to receiving promotional information.
      </StyledText>
    </StyledBox>
  );
};

export default Declaration;
