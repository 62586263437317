import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
  selectSportBets,
  selectSportBetSlipMode,
  toggleBetSlipMode,
  toggleValidate,
} from "../../store/sport/sportBetSlipSlice";

import { BetSlipMode } from "../../store/betSlipSlice.contracts";

export const useBetModeToggle = () => {
  const dispatch = useAppDispatch();
  const activeMode = useAppSelector(selectSportBetSlipMode);
  const sportBets = useAppSelector(selectSportBets);

  function setBetSlipMode(mode: BetSlipMode) {
    dispatch(toggleBetSlipMode({ mode }));
    dispatch(toggleValidate({ validate: true }));
  }

  function multipleButtonClick() {
    if (activeMode === "singles" && sportBets.length > 1) {
      setBetSlipMode("multiples");
    }
  }

  function singleButtonClick() {
    if (activeMode === "multiples") {
      setBetSlipMode("singles");
    }
  }

  return { activeMode, multipleButtonClick, singleButtonClick };
};
