import {
  StepIcon,
  StepIconProps,
  StepConnector,
  StepConnectorProps,
  styled,
} from "@mui/material";

export const StyledStepIcon = styled(StepIcon)<StepIconProps>(({ theme }) => ({
  height: "38px",
  width: "38px",

  border: "2px solid " + theme.colors.secondary.main,
  borderRadius: "50%",
  color: "inherit",

  "& .MuiStepIcon-text": {
    fill: theme.colors.neutral.white[100],
    fontSize: 14,
    fontWeight: 700,
  },

  "&.Mui-active": {
    backgroundColor: theme.colors.secondary.main,
    color: theme.colors.secondary.main,
  },

  "&.Mui-completed": {
    color: theme.colors.secondary.main,
  },
}));

export const StyledConnector = styled(StepConnector)<StepConnectorProps>(
  ({ theme }) => ({
    height: "2px",
    backgroundColor: theme.colors.secondary.main,

    top: "18px",
  })
);
