import { FC, useEffect } from "react";
import { SelectChangeEvent } from "@mui/material";

import MarketFilterLoader from "./MarketFilterLoader";
import { MarketFilterBox } from "./MarketFilter.styles";
import CustomSelect from "src/components/customSelect";

import { useMarketFilter } from "../../../hooks/MarketFilter.hooks";

type MarketFilterProps = {
  sportFilter: string;
  marketFilter: string;
  setMarketFilter: (market: string) => void;
};

const MarketFilter: FC<MarketFilterProps> = ({
  sportFilter,
  marketFilter,
  setMarketFilter,
}) => {
  const { marketFilters, isFetching, isLoading } = useMarketFilter(sportFilter);

  function onChangeHandler(event: SelectChangeEvent<string>) {
    setMarketFilter(event.target.value);
  }

  useEffect(() => {
    if (marketFilters.length === 0) {
      return;
    }

    setMarketFilter(marketFilters[0].value);
  }, [marketFilters, setMarketFilter]);

  if (
    isFetching ||
    isLoading ||
    marketFilters.length === 0 ||
    marketFilter === ""
  ) {
    return <MarketFilterLoader />;
  }

  return (
    <MarketFilterBox>
      <CustomSelect
        id="sport-market-filter"
        items={marketFilters}
        onChange={onChangeHandler}
        value={marketFilter}
        variant="outlined"
      />
    </MarketFilterBox>
  );
};

export default MarketFilter;
