import { Typography, TypographyProps, styled } from "@mui/material";

export const EmptyText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.neutral.white[100],
  fontSize: 14,
  fontWeight: 400,
  textAlign: "center",

  padding: theme.spacing(1.25),
}));
