import { createContext, FC, ReactNode, useContext, useState } from "react";

type SidebarContextType = {
  sidebarToggle: boolean;
  walletDrawerToggle: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  toggleWalletDrawer: () => void;
  closeWalletDrawer: () => void;
};

export const SidebarContext = createContext<SidebarContextType>(
  {} as SidebarContextType
);

export const useSidebarContext = () =>
  useContext<SidebarContextType>(SidebarContext);

interface ISidebarProvider {
  children: ReactNode;
}

export const SidebarProvider: FC<ISidebarProvider> = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState<boolean>(false);
  const [walletDrawerToggle, setWalletDrawerToggle] = useState<boolean>(false);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const toggleWalletDrawer = () => {
    setWalletDrawerToggle(!walletDrawerToggle);
  };

  const closeWalletDrawer = () => {
    setWalletDrawerToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        walletDrawerToggle,
        toggleSidebar,
        closeSidebar,
        toggleWalletDrawer,
        closeWalletDrawer,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
