import { useLocation } from "react-router-dom";

import pages from "src/router/routes";

import { getCurrentTabs } from "../mappers/getCurrentTabs";
import { splitPath } from "../utils/pathSplitter";

export const useNavBar = () => {
  const { pathname } = useLocation();
  const pathVars = splitPath(pathname);

  const currentPath = pathVars[0];
  const currentPage = pages[currentPath];

  const currentTabs = getCurrentTabs(currentPage, currentPath);

  return { currentPath: pathname, currentTabs };
};
