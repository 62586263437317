import { FC } from "react";
import { Box } from "@mui/material";

import MultiBetItem from "./item/MultiBetItem";

import { useAppSelector } from "src/hooks/hooks";
import {
  selectMultiModeStakes,
  selectSportBetSlipMode,
} from "../../store/sport/sportBetSlipSlice";

import { useSportMultiBet } from "./SportMultiBet.hooks";

const SportMultiBet: FC = () => {
  const betSlipMode = useAppSelector(selectSportBetSlipMode);
  const multiModeStakes = useAppSelector(selectMultiModeStakes);

  const { multiBet, doubleBets, trebleBets, quadBets, singleBets } =
    useSportMultiBet();

  if (betSlipMode === "singles") {
    return null;
  }

  return (
    <Box>
      <MultiBetItem
        label="Multiple"
        stake="multi"
        initialValue={multiModeStakes.multipleStake}
        numberOfBets={1}
        price={multiBet?.price}
      />
      <MultiBetItem
        label="All Singles"
        stake="single"
        initialValue={multiModeStakes.singlesStake}
        numberOfBets={singleBets.length}
      />
      {doubleBets.length > 0 ? (
        <MultiBetItem
          label="All Doubles"
          stake="double"
          initialValue={multiModeStakes.doublesStake}
          numberOfBets={doubleBets.length}
        />
      ) : null}
      {trebleBets.length > 0 ? (
        <MultiBetItem
          label="All Trebles"
          stake="treble"
          initialValue={multiModeStakes.treblesStake}
          numberOfBets={trebleBets.length}
        />
      ) : null}
      {quadBets.length > 0 ? (
        <MultiBetItem
          label="All Quads"
          stake="quad"
          initialValue={multiModeStakes.quadsStake}
          numberOfBets={quadBets.length}
        />
      ) : null}
    </Box>
  );
};

export default SportMultiBet;
