import { FC } from "react";
import { Grid } from "@mui/material";

import {
  StyledTypography,
  FixtureWrapper,
  TextWrapper,
  TeamNamesWrapper,
} from "./Fixture.styles";
import FixtureSelection from "../selection/FixtureSelection";

import { FixtureItem } from "../../../models/FixtureItem";

import { formatFixtureDate } from "../../../utils/fixtureUtils";

type FixtureProps = {
  fixture: FixtureItem;
};

const Fixture: FC<FixtureProps> = ({ fixture }) => {
  const competitorsMap = fixture.attr.competitors.value.map(
    (competitor, index) => ({
      name: competitor.name,
      title: String(index + 1),
    })
  );
  const market = fixture.markets[0];
  const selections = [...market.selections].sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });

  return (
    <FixtureWrapper container>
      <Grid item maxWidth="40%">
        <TextWrapper>
          <StyledTypography>
            {formatFixtureDate(market.wagerCloseDate)}
          </StyledTypography>
          <TeamNamesWrapper>
            <StyledTypography>{competitorsMap[0].name}</StyledTypography>
            <StyledTypography>{competitorsMap[1].name}</StyledTypography>
          </TeamNamesWrapper>
        </TextWrapper>
      </Grid>
      <Grid
        item
        display="flex"
        flexDirection="row"
        gap={(theme) => theme.spacing(1)}
      >
        {selections.map((selection) => (
          <FixtureSelection
            key={selection.id}
            fixture={fixture}
            market={market}
            competitors={competitorsMap}
            selection={selection}
          />
        ))}
      </Grid>
    </FixtureWrapper>
  );
};

export default Fixture;
