import { FC } from "react";
import { TextFieldProps } from "@mui/material";

import { InputWrapper, InputLabel } from "./Inputs.styles";
import CustomInput from "./custom-input/CustomInput";
import CountryCode from "./components/CountryCode";

type PhoneInputProps = {
  inputLabel: string;
  countryChange: (value: string) => void;
  countryDialCode: string;
  countryError: boolean;
  countryHelperText?: string;
} & TextFieldProps;

const PhoneNumberInput: FC<PhoneInputProps> = ({
  inputLabel,
  countryChange,
  countryDialCode,
  countryError,
  countryHelperText,
  ...props
}) => {
  return (
    <InputWrapper>
      <InputLabel>{inputLabel}</InputLabel>
      <CustomInput
        {...props}
        inputProps={{ inputMode: 'numeric' }}
        InputProps={{
          startAdornment: (
            <CountryCode
              onChange={countryChange}
              error={countryError}
              helperText={countryHelperText}
              initialDialCode={countryDialCode}
            />
          ),
        }}
      />
    </InputWrapper>
  );
};

export default PhoneNumberInput;
