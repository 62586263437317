import { useState } from "react";

type Filter = {
  label: string;
  value: string;
};

const filters: Filter[] = [
  // { label: "Daily Matches", value: "daily" },
  { label: "All Leagues", value: "all" },
  // { label: "Top League", value: "top" },
];

export const useFilter = () => {
  const [currentFilter, setCurrentFilter] = useState<string>(filters[0].value);

  return { currentFilter, setCurrentFilter, filters };
};
