import { Button, ButtonProps, styled } from "@mui/material";

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  "&.MuiButton-contained": {
    color: theme.colors.text.dark,
    fontSize: 16,
    fontWeight: 700,
    textTransform: "capitalize",

    backgroundColor: theme.colors.secondary.main,
    border: "2px solid " + theme.colors.secondary.main,
    borderRadius: 10,

    padding: theme.spacing(1),

    "&.Mui-disabled": {
      opacity: 0.6,
    },

    "&:hover": {
      color: theme.colors.text.dark,
      backgroundColor: theme.colors.secondary.main,
    },
  },

  "&.MuiButton-outlined": {
    color: theme.colors.text.light,
    fontSize: 16,
    fontWeight: 700,
    textTransform: "capitalize",

    backgroundColor: "inherit",
    border: "2px solid " + theme.colors.secondary.main,
    borderRadius: 10,

    padding: theme.spacing(1),

    "&.Mui-disabled": {
      opacity: 0.6,
    },

    "&:hover": {
      color: theme.colors.text.light,
      backgroundColor: "inherit",
    },
  },
}));
