import { useState } from 'react';
import { RefreshRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { baseApi, prodApi } from 'src/services/restApi/queries/baseApi.service';
import { Endpoint } from 'src/services/restApi/queries/config/apiTags';
import store from 'src/store/store';

interface Props {
    dataType: Endpoint; // API TAG YOU WANT TO INVALIDATE
}

export default function RefreshData({ dataType }: Props) {
    const [isRotating, setIsRotating] = useState(false);

    const handleRefresh = () => {
        setIsRotating(true);

        // Invalidate the tags
        store.dispatch(baseApi.util.invalidateTags([dataType]));
        store.dispatch(prodApi.util.invalidateTags([dataType]));

        setTimeout(() => {
            setIsRotating(false);
        }, 2000);
    };

    return (
        <IconButton onClick={handleRefresh} color="secondary">
            <RefreshRounded
                sx={{
                    transition: 'transform 2s linear',
                    transform: isRotating ? 'rotate(360deg)' : 'rotate(0deg)',
                }}
            />
        </IconButton>
    );
}
