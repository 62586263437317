import { FC } from "react";
import { CheckboxProps } from "@mui/material";

import { StyledCheckbox } from "./CheckBoxComponent.styles";

const CheckboxComponent: FC<CheckboxProps> = (props) => {
  return <StyledCheckbox {...props} />;
};

export default CheckboxComponent;
