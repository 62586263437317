import { useAppSelector } from "src/hooks/hooks";
import {
  selectExpandedSportBets,
  selectSportBets,
} from "../../store/sport/sportBetSlipSlice";

export const useSportMultiBet = () => {
  const expandedSportBets = useAppSelector(selectExpandedSportBets);
  const singleBets = useAppSelector(selectSportBets);

  const doubleBets = expandedSportBets.filter((bet) =>
    bet.id.includes("double")
  );
  const trebleBets = expandedSportBets.filter((bet) =>
    bet.id.includes("treble")
  );
  const quadBets = expandedSportBets.filter((bet) => bet.id.includes("quad"));
  const multiBet = expandedSportBets.find((bet) => bet.id === "multi");

  return { multiBet, doubleBets, trebleBets, quadBets, singleBets };
};
