export const isAdult = (date: string) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) age--;
    return age >= 18;
}

export const validateIdentificationNumber = (signupIDNumber: string): { valid: boolean; type?: string } => {
    let checksum = 0;
    let shouldDouble = false;

    for (let i = signupIDNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(signupIDNumber.charAt(i));
      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      checksum += digit;
      shouldDouble = !shouldDouble;
    }

    if (checksum % 10 !== 0) {
      return { valid: false, type: 'invalid' };
    }

    const currentDate = new Date();

    const DOBYear = parseInt(signupIDNumber.slice(0, 2));
    const DOBMonth = parseInt(signupIDNumber.slice(2, 4));
    const DOBDay = parseInt(signupIDNumber.slice(4, 6));

    const cutoff = currentDate.getFullYear() - 2000;
    const DOBFullYear = DOBYear + (DOBYear > cutoff ? 1900 : 2000);

    const dateOfBirth = new Date(DOBFullYear, DOBMonth, DOBDay);

    const diff = (currentDate.getTime() - dateOfBirth.getTime()) / 1000;
    const diffYears = diff / (60 * 60 * 24);
    const diffYearsRounded = Math.abs(Math.round(diffYears / 365.25));

    if (diffYearsRounded < 18) {
      return { valid: false, type: 'underage' };
    }

    return { valid: true };
  };

  export const getidentityNumberStart = (birthDate: string) => {
    const date = new Date(birthDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year.toString().substring(2,4)}${month < 10 ? `0${month}` : month}${day < 10 ? `0${day}` : day}`;
  }