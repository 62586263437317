import { useLocalStorage } from "src/hooks/useStorage";

/**
 * Custom hook that stores display state in local storage.
 * @returns The current display state, with functions to set and remove the state.
 */
const useDisplayState = () => {
  const [value, setValue, remove] = useLocalStorage("display", "decimal");

  const setDisplay = (display: string) => {
    setValue(display);
  };

  const removeDisplay = () => {
    remove();
  };

  return {
    value,
    setDisplay,
    removeDisplay,
  };
};

export default useDisplayState;
