import { RegexPatterns } from "../../../utils/constants/regex";
import { isValidPhoneNumber } from "react-phone-number-input";

import { FieldTypes } from "../models/fieldTypes";

export interface IInitialState {
  cellphone: FieldTypes;
  countryCode: FieldTypes;
  newPassword: FieldTypes;
  confirmPassword: FieldTypes;
  otp: FieldTypes;
}

export const initialState: IInitialState = {
  cellphone: {
    value: "",
    valid: true,
    error: "",
    name: "Phone Number",
    regex: RegexPatterns.cellphone,
  },
  newPassword: {
    value: "",
    valid: true,
    error: "",
    name: "New Password",
    regex: RegexPatterns.password,
  },
  confirmPassword: {
    value: "",
    valid: true,
    error: "",
    name: "Confirm Password",
    regex: RegexPatterns.password,
  },
  countryCode: { value: "+27", valid: true, error: "", name: "Country Code" },
  otp: {
    value: "",
    valid: true,
    error: "",
    name: "OTP",
    regex: RegexPatterns.otp,
  },
};

export enum actionTypes {
  CELLPHONE = "CELLPHONE",
  NEW_PASSWORD = "NEW_PASSWORD",
  CONFIRM_PASSWORD = "CONFIRM_PASSWORD",
  COUNTRY_CODE = "COUNTRY_CODE",
  OTP = "OTP",
}

export interface IAction {
  type: actionTypes;
  payload: string;
}

export const forgotFormReducer = (
  state: typeof initialState,
  action: IAction
) => {
  const payload = action.payload;
  let errorObj = { valid: true, error: "" };
  if (!payload) {
    errorObj = { valid: false, error: "Field is required" };
  }
  let regex;
  switch (action.type) {
    case actionTypes.CELLPHONE:
      regex = state.cellphone.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (
          !regexp.test(payload) ||
          !isValidPhoneNumber(payload, {
            defaultCallingCode: state.countryCode.value.substring(1),
          })
        ) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
      }
      return {
        ...state,
        cellphone: { ...state.cellphone, ...errorObj, value: payload },
      };
    case actionTypes.NEW_PASSWORD:
      regex = state.newPassword.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
        // length between 8-12 characters
        if (payload.length < 8 || payload.length > 12) {
          errorObj = {
            valid: false,
            error: "Password must be between 8 and 12 characters",
          };
        }
      }
      return {
        ...state,
        newPassword: { ...state.newPassword, ...errorObj, value: payload },
      };
    case actionTypes.CONFIRM_PASSWORD:
      regex = state.confirmPassword.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
        if (payload !== state.newPassword.value) {
          errorObj = { valid: false, error: "Passwords do not match" };
        }
      }
      return {
        ...state,
        confirmPassword: {
          ...state.confirmPassword,
          ...errorObj,
          value: payload,
        },
      };
    case actionTypes.OTP:
      regex = state.otp.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
      }
      return { ...state, otp: { ...state.otp, ...errorObj, value: payload } };
    case actionTypes.COUNTRY_CODE:
      return {
        ...state,
        countryCode: { ...state.countryCode, value: payload },
      };
    default:
      return state;
  }
};
