import { useEffect, useState } from "react";

import { useGetAllDisplayOrdersQuery } from "src/services/restApi/queries/displayOrders.service";

export const useSportFilters = () => {
  const [sportFilters, setSportFilters] = useState<string[]>([]);

  const { data, isFetching, isLoading } = useGetAllDisplayOrdersQuery();

  useEffect(() => {
    const filters = data ? Object.keys(data) : [];
    setSportFilters(filters);
  }, [data]);

  return {
    sportFilters,
    isLoading: isFetching || isLoading,
  };
};
