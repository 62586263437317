import { v4 } from "uuid";

import {
  LottoBet,
  LottoBetLeg,
  LottoBetSlip,
  LottoBetSlipIM,
} from "../models/lotto";
import {
  LottoBetSlipIMBet,
  LottoBetSlipIMBetLeg,
  LottoBetSlipIMBetLegSelection,
} from "../models/lotto/lottoBetSlip";

/**
 * Adds a key to the provided bet slip for api calls
 * @param betSlip - betslip to be mapped
 * @returns The bet slip with the added key property
 */
export const lottoBetSlipMapper = (betSlip: LottoBetSlip) => {
  const { bets, ...rest } = betSlip;

  const betSlipInsert: LottoBetSlipIM = {
    bets: lottoBetSlipBetMapper(bets),
    ...rest,
    key: v4(),
  };

  return betSlipInsert;
};

export const lottoBetSlipBetMapper = (
  bets: LottoBet[]
): LottoBetSlipIMBet[] => {
  return bets.map((bet) => {
    return {
      legs: lottoBetSlipBetLegMapper(bet.legs),
      stake: bet.stake,
      payout: bet.payout,
      key: bet.key,
      isFixedStake: bet.isFixedStake,
    };
  });
};

export const lottoBetSlipBetLegMapper = (
  legs: LottoBetLeg[]
): LottoBetSlipIMBetLeg[] => {
  return legs.map((leg) => {
    let selection: LottoBetSlipIMBetLegSelection[] | undefined = undefined;
    if (leg.selection) {
      selection = leg.selection;
    }
    if (leg.pickSetSelections) {
      selection = leg.pickSetSelections.map((s) => ({
        pickSetId: s.pickSetId,
        gridNumber: s.gridNumber,
        balls: s.balls.map((ball) => ball.id),
      }));
    }

    return {
      price: leg.price,
      fixtureId: leg.fixtureId,
      marketId: leg.marketId,
      game: leg.game,
      gameMode: leg.gameMode,
      marketDefId: leg.marketDefId,
      marketName: leg.marketName,
      ...(selection ? { selection } : undefined),
      selectionId: leg.selectionId ?? "",
    };
  });
};
