import { ChangeEvent, Dispatch, FC } from "react";

import {
  EnterCellphoneWrapper,
  AuthTextWrapper,
} from "./EnterCellphone.styles";
import { ForgotTitle, ForgotText } from "../ForgotPassword.styles";
import { PhoneNumberInput, CustomInputElement } from "src/components/Inputs";
import { PrimaryButton } from "src/components/buttons";
import AuthTextButton from "../../auth-text-button/AuthTextButton";

import {
  IInitialState,
  IAction,
  actionTypes,
} from "../../../reducers/forgotFormReducer";

type EnterCellphoneProps = {
  loading: boolean;
  state: IInitialState;
  dispatch: Dispatch<IAction>;
  enterContinueClick: () => void;
};

const EnterCellphone: FC<EnterCellphoneProps> = ({
  loading,
  state,
  dispatch,
  enterContinueClick,
}) => {
  const phoneChangeHandler = (event: ChangeEvent<CustomInputElement>) => {
    event.preventDefault();

    dispatch({
      type: actionTypes.CELLPHONE,
      payload: event.target.value,
    });
  };

  const countryChangeHandler = (value: string) => {
    dispatch({
      type: actionTypes.COUNTRY_CODE,
      payload: value,
    });
  };

  const formInvalid = !state.cellphone.valid || state.cellphone.value.trim() === "";

  const continueClickHandler = () => {
    if (formInvalid) return;

    enterContinueClick();
  };

  return (
    <EnterCellphoneWrapper>
      <ForgotTitle>Forgot password</ForgotTitle>
      <ForgotText>Enter your phone number to reset your password</ForgotText>
      <PhoneNumberInput
        id="forgot-phone-input"
        autoComplete="cellphone"
        helperText={state.cellphone.error}
        inputLabel="Phone Number"
        value={state.cellphone.value}
        error={!state.cellphone.valid}
        onChange={phoneChangeHandler}
        countryChange={countryChangeHandler}
        countryDialCode={state.countryCode.value}
        countryError={!state.countryCode.valid}
        countryHelperText={state.countryCode.error}
      />
      <PrimaryButton
        loading={loading}
        fullWidth
        fontSize={16}
        onClick={continueClickHandler}
        sx={{ marginTop: "10px" }}
        disabled={formInvalid}
      >
        Continue
      </PrimaryButton>
      <AuthTextWrapper>
        <AuthTextButton variant="signup" />
        <AuthTextButton variant="login" />
      </AuthTextWrapper>
    </EnterCellphoneWrapper>
  );
};

export default EnterCellphone;
