import {
  FC,
  PropsWithChildren,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, keyframes } from "@mui/material";

const FooterImageWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [checkSize, setCheckSize] = useState<boolean>(true);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
  const [animateDistance, setAnimateDistance] = useState<number>(0);

  const parentRef = useRef<HTMLDivElement>();
  const childRef = useRef<HTMLDivElement>();

  const animateKeyFrames = useMemo(() => {
    return keyframes`
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(-${animateDistance}px);
    }
    100% {
      transform: translateX(0px);
    }
    `;
  }, [animateDistance]);

  useLayoutEffect(() => {
    function windowResized() {
      setCheckSize(true);
    }

    window.addEventListener("resize", windowResized);

    return () => window.removeEventListener("resize", windowResized);
  }, []);

  useLayoutEffect(() => {
    if (parentRef.current && childRef.current && checkSize) {
      const parentWidth = parentRef.current.offsetWidth;
      const childWidth = childRef.current.offsetWidth;

      if (childWidth > parentWidth) {
        setShouldAnimate(true);
        setAnimateDistance(childWidth - parentWidth);
      } else {
        setShouldAnimate(false);
        setAnimateDistance(0);
      }

      setCheckSize(false);
    }
  }, [animateDistance, checkSize, shouldAnimate]);

  return (
    <Box
      ref={parentRef}
      sx={{
        height: 46,
        width: "100%",
        overflow: "hidden",

        display: "flex",
        flexDirection: "row",
        justifyContent: shouldAnimate ? "flex-start" : "center",
        alignItems: "center",
      }}
    >
      <Box
        ref={childRef}
        sx={{
          height: 46,
          width: "fit-content",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: (theme) => theme.spacing(2),

          animation: shouldAnimate
            ? `${animateKeyFrames} 10s infinite ease`
            : "none",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FooterImageWrapper;
