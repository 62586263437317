import { Box, useTheme } from "@mui/material";
import { FC } from "react";

import { PrimaryButton, SecondaryButton } from "src/components/buttons";
import {
  AuthDialogType,
  useAuthDailogStore,
} from "src/store/authentication/authStore";

const AuthButtons: FC = () => {
  const theme = useTheme();
  const setAuthDisplayType = useAuthDailogStore(
    (state) => state.setDisplayType
  );
  const modalShown = useAuthDailogStore((state) => state.displayType);
  const toggleLogin = () => {
    setAuthDisplayType(
      modalShown === AuthDialogType.Login
        ? AuthDialogType.None
        : AuthDialogType.Login
    );
  };

  const toggleRegister = () => {
    setAuthDisplayType(
      modalShown === AuthDialogType.Register
        ? AuthDialogType.None
        : AuthDialogType.Register
    );
  };

  return (
    <Box component="span" display="flex" gap={theme.spacing(1)}>
      <SecondaryButton onClick={toggleLogin}>Login</SecondaryButton>
      <PrimaryButton onClick={toggleRegister}>Join Now</PrimaryButton>
    </Box>
  );
};

export default AuthButtons;
