import { FC } from "react";
import { IconButton } from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";

import { StyledBox } from "../../carousel/components/GlideActions.styles";

type GlideActionProps = {
  onClick: () => void;
};

type ButtonType = FC<GlideActionProps>;

export const BackButton: ButtonType = ({ onClick }: GlideActionProps) => {
  return (
    <StyledBox
      sx={(theme) => ({
        left: -2,
        background: theme.colors.gradients.homeLeft,
        justifyContent: "flex-start",
      })}
    >
      <IconButton
        aria-label="back"
        onClick={onClick}
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <NavigateBefore color="secondary" fontSize="large" />
      </IconButton>
    </StyledBox>
  );
};

export const ForwardButton: ButtonType = ({ onClick }: GlideActionProps) => {
  return (
    <StyledBox
      sx={(theme) => ({
        right: -2,
        background: theme.colors.gradients.homeRight,
        justifyContent: "flex-end",
      })}
    >
      <IconButton
        aria-label="forward"
        onClick={onClick}
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <NavigateNext color="secondary" fontSize="large" />
      </IconButton>
    </StyledBox>
  );
};
