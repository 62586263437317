import { Box, alpha, lighten, useTheme } from "@mui/material";
import { FC } from "react";
import { MobileWrapper } from "src/components/component-wrappers";
import { useAuth } from "src/features/authentication";
import TopbetLogo from "../Logo";
import { AuthButtons, MenuButton, ProfileButtons } from "./Buttons";
import { HeaderWrapper } from "./Header.styles";

const Header: FC = () => {
  const theme = useTheme();
  const { isAuthenticated } = useAuth();

  return (
    <>
      <HeaderWrapper
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: theme.header.alignItems,
          paddingX: theme.spacing(1.875),
          paddingBottom: theme.spacing(3.5),
          paddingTop: theme.header.paddingTop,
          boxShadow:
            theme.palette.mode === "dark"
              ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
              : `0px 2px 8px -3px ${alpha(
                theme.colors.neutral.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.neutral.black[100],
                0.1
              )}`,
        }}
      >
        <Box sx={{ display: "inline-flex", alignItems: "center" }}>
          <MenuButton />
          <MobileWrapper sx={{ ml: 2 }}>
            <TopbetLogo visible height={38} />
          </MobileWrapper>
        </Box>
        {isAuthenticated ? <ProfileButtons /> : <AuthButtons />}
      </HeaderWrapper>
    </>
  );
};

export default Header;
