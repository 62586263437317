import { Tab, TabProps, Tabs, TabsProps, styled } from "@mui/material";

export const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  height: 50,
  width: "100%",

  background: theme.colors.gradients.gold,

  alignItems: "center",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),

  "& .MuiTabs-flexContainer": {
    gap: theme.spacing(1),
  },

  //indicator
  "& .MuiTabs-indicator": {
    height: 38,
    minHeight: 38,
    borderRadius: 6,
    border: "2px solid " + theme.colors.neutral.black[100],
    backgroundColor: theme.colors.neutral.black[100],
  },

  "& .MuiTabs-scrollButtons": {
    display: "none",
  },
}));

export const StyledTab = styled(Tab)<TabProps>(({ theme }) => ({
  height: 38,
  minHeight: 38,
  borderRadius: 6,
  transition: "color .2s",

  paddingLeft: "10px",
  paddingRight: "10px",

  fontSize: "10px",
  fontWeight: 600,
  color: theme.colors.neutral.black[100],

  "&.Mui-selected, &.Mui-selected:hover": {
    color: theme.colors.secondary.main,
    zIndex: 5,
    height: 38,
    minHeight: 38,
    borderRadius: 6,
    border: "2px solid " + theme.colors.neutral.black[100],
    boxShadow: "0px 2px 10px " + theme.colors.primary.light,
    backgroundColor: theme.colors.neutral.black[100],
  },

  "&:hover": {
    backgroundColor: theme.colors.neutral.black[25],
    fontWeight: 700,
  },

  ".new-icon-wrapper, .new-icon-text": {
    backgroundColor: "black",
    color: "white",
    zIndex: 5,
  },
}));
