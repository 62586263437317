import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
  selectCurrentWallet,
  updateWalletSelection,
} from "src/features/wallet";

import { useGetWalletsQuery } from "src/services/restApi/queries/wallets.service";

export const useProfileWalletSelector = () => {
  const dispatch = useAppDispatch();
  const currentWallet = useAppSelector(selectCurrentWallet);

  const { data: wallets, isFetching, isLoading } = useGetWalletsQuery();

  useEffect(() => {
    if (!wallets) {
      return;
    }

    const newWallet = wallets.items.find(
      (wallet) => wallet.id === currentWallet.id
    );

    if (newWallet) {
      dispatch(updateWalletSelection({ wallet: newWallet }));
    }
  }, [currentWallet, dispatch, wallets]);

  return {
    currentWallet,
    isLoading: isFetching || isLoading,
  };
};
