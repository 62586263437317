import { Endpoint } from "src/services/restApi/queries/config/apiTags";

type IAppConfig = {
  breakMobileView: "sm" | "md" | "lg";
  excludeRefreshTokenApis: string[];
  liveInPlayPolling: number;
  notShowBetslip: string[];
  showMutationToast: string[];
  hideGenericErrorToast: string[];
  showNew: {
    pages: { [key: string]: boolean };
    tabs: { [key: string]: boolean };
  };
  hideNavigationBar: string[];
};
//any miscellaneous config that is used across the whole app
const appConfig: IAppConfig = {
  breakMobileView: "sm",
  excludeRefreshTokenApis: [Endpoint.POST_USER], //These endpoints will not check for 401 errors or try to refresh the token
  liveInPlayPolling: 120000,
  notShowBetslip: [
    //These routes will not show the betslip
    //We might need to fine tune this later
    "/home",
    "/aviator",
    "/account/myBets",
    "/account/transactions",
    "/account/deposit",
    "/account/withdraw",
    "/account/accountDetails",
    "/account/changePassword",
    "/account/notification",
    "/account/fica",
    "/account/responsibleGambling",
    "/betGames",
    "/liveCasino/evolution",
    "/liveCasino/ezugi",
    "/promotions/special",
    "/promotions/recurring",
    "/slots/habanero",
    "/slots/habanero/play",
    "/slots/spinmatic",
    "/slots/spinmatic/play",
    "/slots/pragmatic",
    "/slots/pragmatic/play",
    "/splitThePot",
    "/splitThePot/play",
    "/slots/evolution",
    "/slots/evolution/play",
  ],
  showMutationToast: [
    "updateNotifications",
    //If you want any specific toast messages for mutations add them here - otherwise it will not show at all
    //Add the endpointName here i.e: 'validateBets'
  ],
  hideGenericErrorToast: [
    //If you want to hide the generic toast message for mutations/queries add them here - otherwise it will not show at all
    //Add the endpointName here i.e: 'validateBets'
    "validateBets",
  ],
  showNew: {
    //these items will show a new button next to item name
    pages: {
      Aviator: true,
      Sports: false,
      "Lucky Numbers": false,
      Slots: true,
      "Live Casino": false,
      "Split The Pot": true,
      Promotions: false,
    },
    tabs: {
      habanero: false,
      spinmatic: false,
      pragmatic: true,
    },
  },
  hideNavigationBar: [
    "/home",
    "/slots/habanero/play",
    "/slots/spinmatic/play",
    "/slots/pragmatic/play",
    "/slots/evolution/play",
    "/splitThePot/play",
  ],
};

export default appConfig;
