import {
  Box,
  BoxProps,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";

export const EmptyWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2.5),
}));

export const Wrapper = styled(RadioGroup)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(2.5),
}));

export const Selector = styled(FormControlLabel)(({ theme }) => ({
  color: theme.colors.secondary.main,
  fontSize: 16,
  fontWeight: 400,

  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
}));

export const SelectorButton = styled(Radio)(({ theme }) => ({
  "&.MuiRadio-root": {
    color: theme.colors.secondary.main,
  },
}));

export const BalanceText = styled(Typography)(({ theme }) => ({
  color: theme.colors.secondary.main,
  fontSize: 13,
  fontWeight: 400,

  marginLeft: theme.spacing(5.625),
}));
