import { FC } from "react";

import FixturesLoader from "./FixturesLoader";
import FixturesEmpty from "./FixturesEmpty";
import { FixturesWrapper } from "./Fixtures.styles";
import FixtureGroup from "./group/FixtureGroup";

import { useTournaments } from "./Fixtures.hooks";

import { FixtureItem } from "../../models/FixtureItem";

type FixturesProps = {
  fixtures: FixtureItem[];
  isLoading: boolean;
};

const Fixtures: FC<FixturesProps> = ({ fixtures, isLoading }) => {
  const tournaments = useTournaments(fixtures);

  if (isLoading) {
    return <FixturesLoader />;
  }

  if (fixtures.length === 0) {
    return <FixturesEmpty />;
  }

  return (
    <FixturesWrapper>
      {tournaments.map((tournament) => {
        const groupTitle = `${tournament.region} - ${tournament.tournament}`;
        const fixturesGroup = fixtures.filter(
          (fixture) =>
            fixture.attr.region?.value === tournament.region &&
            fixture.attr.tournamentName.value === tournament.tournament
        );
        return (
          <FixtureGroup
            key={groupTitle}
            title={groupTitle}
            fixtures={fixturesGroup}
          />
        );
      })}
    </FixturesWrapper>
  );
};

export default Fixtures;
