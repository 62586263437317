import { useCallback } from "react";

import { useSettings } from "src/hooks/useSettings";

import { SportBet } from "../models/sport";
import { SportBetInfoBonusStretch } from "../models/sport/sportBet";

import { calculateBetPayout } from "../utils/payoutAndStakeCalc";

export const useCreateMultiBet = () => {
  const settings = useSettings();

  const createMultiBet = useCallback(
    (bets: SportBet[], id: string, stake: number) => {
      const soccerBonusStretches = settings?.bonusStretches.find(
        (bonusStretch) => bonusStretch.sportName === "Soccer"
      );

      const legs = bets.map((bet) => bet.legs[0]);
      const betLegPrices = legs.map((leg) => leg.price);

      let info = { ...bets[0].info };
      let promos = { ...bets[0].promos };
      let percent = 1;

      if (bets.every((bet) => bet.info.sport === "Soccer") && settings) {
        const betsLength = bets.length;
        const currentBonus = soccerBonusStretches?.entries.find(
          (entry) => entry.numberOfLegs === betsLength
        );
        const nextBonus = soccerBonusStretches?.entries.find(
          (entry) => entry.numberOfLegs === betsLength + 1
        );

        const bonusStretch: SportBetInfoBonusStretch = {
          current: {
            exclMinPrice: soccerBonusStretches?.exclMinPrice ?? 0,
            numberOfLegs: currentBonus?.numberOfLegs ?? 0,
            percentage: currentBonus?.percentage ?? 0,
          },
          next: {
            exclMinPrice: soccerBonusStretches?.exclMinPrice ?? 0,
            numberOfLegs: nextBonus?.numberOfLegs ?? 0,
            percentage: nextBonus?.percentage ?? 0,
          },
        };

        info.bonusStretch = bonusStretch;
        promos.bonusStretch = soccerBonusStretches?.ref ?? 0;
        percent += (currentBonus?.percentage ?? 0) / 100;
      }

      const { payout, price } = calculateBetPayout(
        stake,
        betLegPrices,
        percent
      );

      const multiBet: SportBet = {
        id,
        errors: bets[0].errors,
        info,
        legs,
        live: bets[0].live,
        payout,
        price,
        promos,
        provider: bets[0].provider,
        stake,
      };

      return multiBet;
    },
    [settings]
  );

  return createMultiBet;
};
