/**
 * Utility functions used in the fixture(s) components
 */
import Fraction from "fraction.js";

import { formatDate, isToday, isTomorrow } from "src/utils/dateHelper";

export function determineMarketType(quickSearchCode: string) {
  switch (quickSearchCode) {
    case "1":
      return "1";
    case "2":
      return "x";
    case "3":
      return "2";
  }
}

export function determinePrice(price: number, decimal: boolean) {
  if (decimal) {
    return price.toFixed(2);
  } else {
    return new Fraction(price).toFraction(true);
  }
}

export function formatFixtureDate(date: string) {
  if (isToday(date)) {
    return "Today - " + formatDate(date, "HH:mm");
  }

  if (isTomorrow(date)) {
    return "Tomorrow - " + formatDate(date, "HH:mm");
  }

  return formatDate(date, "DD-MM-YYYY - HH:mm");
}
