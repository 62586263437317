import { Box, Divider, styled } from "@mui/material";

export const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.colors.secondary.main,
  width: "1.5px",
}));

export const NavButtonsWrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",

  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "stretch",
}));
