import { FC, useReducer, useState } from "react";

import { ForgotWrapper } from "./ForgotPassword.styles";
import EnterCellphone from "./steps/EnterCellphone";
import VerifyOtp from "./steps/VerifyOtp";
import ConfirmPassword from "./steps/ConfirmPassword";

import { useAuth } from "../../hooks/useAuth";

import { getFullNumber } from "../../utils/getFullNumber";

import {
  forgotFormReducer,
  initialState,
} from "../../reducers/forgotFormReducer";
import {
  AuthDialogType,
  useAuthDailogStore,
} from "src/store/authentication/authStore";
import { toast } from "react-toastify";
import logger from "src/utils/logger";

type ForgotPasswordProps = {
  step: number;
  incrementStep: () => void;
};

const ForgotPassword: FC<ForgotPasswordProps> = ({ step, incrementStep }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const setAuthDisplayType = useAuthDailogStore(
    (state) => state.setDisplayType
  );

  const [state, dispatch] = useReducer(forgotFormReducer, initialState);

  const { forgotPassword, confirmPassword } = useAuth();

  const fullNumber = getFullNumber(
    state.countryCode.value,
    state.cellphone.value
  );

  const enterContinueClick = async () => {
    setLoading(true);
    try {
      await forgotPassword(fullNumber);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      logger.error("Error occurred while trying to reset password", error);
      setLoading(false);
      return;
    }

    incrementStep();
    setLoading(false);
  };

  const verifyContinueClick = () => {
    incrementStep();
  };

  const updateContinueClick = async () => {
    setLoading(true);
    try {
      await confirmPassword(
        fullNumber,
        state.otp.value,
        state.confirmPassword.value
      );
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred while updating your password.");
      }
      logger.error("Error occurred while trying to reset password", error);
      setLoading(false);
      return;
    }
    setAuthDisplayType(AuthDialogType.Login);
    setLoading(false);
  };

  return (
    <ForgotWrapper>
      {step === 0 ? (
        <EnterCellphone
          loading={loading}
          state={state}
          dispatch={dispatch}
          enterContinueClick={enterContinueClick}
        />
      ) : null}
      {step === 1 ? (
        <VerifyOtp
          state={state}
          dispatch={dispatch}
          fullNumber={fullNumber}
          verifyClickHandler={verifyContinueClick}
        />
      ) : null}
      {step === 2 ? (
        <ConfirmPassword
          loading={loading}
          state={state}
          dispatch={dispatch}
          updateContinueClick={updateContinueClick}
        />
      ) : null}
    </ForgotWrapper>
  );
};

export default ForgotPassword;
