import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

const useMediaSize = () => {
    const [size, setSize] = useState<'sm' | 'md' | 'lg'>('sm');

    const theme = useTheme();
    const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
    const smallToMid = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (greaterThanMid) {
            setSize('lg')
        } else if (smallToMid) {
            setSize('md')
        } else if (lessThanSmall) {
            setSize('sm')
        }
    }, [greaterThanMid, smallToMid, lessThanSmall]);



    return size;
};

export default useMediaSize;
