import { useGetAccountDetailsQuery } from "src/services/restApi/queries/users.service";

export const useAccountInfo = () => {
  const { data, isFetching, isLoading } = useGetAccountDetailsQuery();
  const accountName = data ? data.personalDetails.firstName : "";
  const username = data ? data.personalDetails.username : "";
  const accountNumber = data ? data.accountNumber : "";

  return { accountName, isLoading: isFetching || isLoading, username, accountNumber };
};
