import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "src/store/store";
import { IMyBetsState } from "./myBetsSlice.contracts";

const initialState: IMyBetsState = {
  periodFilter: "today",
  statusFilter: "pending",
  walletFilter: 1,
  itemsPerPage: 10,
  pageNumber: 1,
};

const myBetsSlice = createSlice({
  name: "myBets",
  initialState,
  reducers: {
    setPeriodFilter(state, action: PayloadAction<{ value: string }>) {
      const { value } = action.payload;
      state.periodFilter = value;
    },
    setStatusFilter(state, action: PayloadAction<{ value: string }>) {
      const { value } = action.payload;
      state.statusFilter = value;
    },
    setWalletFilter(state, action: PayloadAction<{ value: number }>) {
      const { value } = action.payload;
      state.walletFilter = value;
    },
  },
});

export default myBetsSlice.reducer;
export const { setPeriodFilter, setStatusFilter, setWalletFilter } =
  myBetsSlice.actions;

export const selectPeriodFilter = (state: RootState) =>
  state.myBets.periodFilter;
export const selectStatusFilter = (state: RootState) =>
  state.myBets.statusFilter;
export const selectWalletFilter = (state: RootState) =>
  state.myBets.walletFilter;
