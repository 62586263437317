import { FC, useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";

import { RegisterWrapper, RegisterTitle } from "./Register.styles";
import RegisterStepper from "./components/RegisterStepper";
import CreateAccount from "./steps/CreateAccount";
import AccountDetails from "./steps/AccountDetails";
import VerifyOtp from "./steps/VerifyOtp";

import {
  actionTypes,
  authFormReducer,
  initialState,
} from "../../reducers/authFormReducer";

import { useAuth } from "../../hooks/useAuth";

import ApiClient from "src/lib/axiosWrapper";
import { EPunterNotificationType, EUsernameType } from "src/models/users";
import { Endpoint } from "src/services/restApi/queries/config/apiTags";
import { env } from "src/env";

import { getFullNumber } from "../../utils/getFullNumber";
import logger from "src/utils/logger";
import useAffiliate from "src/hooks/useAffiliate";
import { Alert } from "@mui/material";
import {
  AuthDialogType,
  useAuthDailogStore,
} from "src/store/authentication/authStore";

const API = new ApiClient(env.REACT_APP_BASE_API_URL);

type RegisterProps = {
  step: number;
  incrementStep: () => void;
};

const Register: FC<RegisterProps> = ({ step, incrementStep }) => {
  const [state, dispatch] = useReducer(authFormReducer, initialState);
  const { bTag, bTag2 } = useAffiliate();
  const [conflict, setConflict] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const setAuthDisplayType = useAuthDailogStore(
    (state) => state.setDisplayType
  );
  const { verifyOtp } = useAuth();

  const registerUser = async () => {
    try {
      await API.post(
        Endpoint.POST_USER,
        {
          personalDetails: {
            username: fullNumber,
            firstName: state.firstName.value,
            surname: state.lastName.value,
            dateOfBirth: new Date(state.birthDate.value),
            language: window?.navigator?.language.slice(0, 2) ?? "en",
            email: state.email.value,
            phoneNumber: fullNumber,
            identificationNumber: state.identityNumber.value,
          },
          notificationSettings: [
            {
              type: EPunterNotificationType.PROMOTIONAL, //WHAT ARE THE OTHER NOTIFICATION TYPES
              contactMethod: EUsernameType.PHONE_NUMBER,
            },
          ],
          password: state.password.value,
          ...(bTag2 && { promoCode: bTag2 }),
          ...(bTag && { metadata: { affiliateCode: bTag } }),
          metadata: null,
        },
        {
          headers: {
            "G-Recaptcha-Response": state.recaptcha,
            "X-Api-Key": env.REACT_APP_REGISTRATION_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err: any) {
      if (err.response?.status === 409) {
        setConflict(true);
      }
      setError(true);
      throw err;
    }
  };

  const createContinueClick = () => {
    if (state.confirmPassword.valid && state.cellphone.valid && state.cellphone.value && state.confirmPassword.value) {
      incrementStep();
    } else {
      toast.error("Please fill in all fields correctly.");
    }
  };

  const accountContinueClick = async () => {
    setLoading(true);
    try {
      await registerUser();
    } catch (err) {
      toast.error("There was an error registering your account.");
      logger.error(err);
      setLoading(false);
      setError(true);
    }
    if (!conflict && !error) {
      incrementStep();
    }
    setLoading(false);
  };

  const otpVerifyClick = async () => {
    setLoading(true);
    setError(false);
    try {
      await verifyOtp(state.otp.value, fullNumber);
    } catch (err) {
      toast.error("Something went wrong while verifying your account. Please make sure the OTP is correct.");
      logger.error(err);
      setError(true);
      dispatch({type: actionTypes.OTP, payload: ""})
    } finally {
      setLoading(false);
    }

    if (!error && !conflict) setAuthDisplayType(AuthDialogType.None);
  };

  const fullNumber = getFullNumber(
    state.countryCode.value,
    state.cellphone.value
  );

  useEffect(() => {
    if (step === 1) {
      dispatch({ type: actionTypes.RECAPTCHA, payload: "" });
    }
  }, [step]);

  const title =
    step === 0
      ? "Create account"
      : step === 1
        ? "Account details"
        : "Verification";

  return (
    <RegisterWrapper component="form">
      {conflict && (
        <Alert
          sx={{ backgroundColor: "gray", color: "white" }}
          severity="warning"
        >
          An account with some of these details already exists. Please login or
          contact support.
        </Alert>
      )}
      <RegisterTitle>
        Step {step + 1} - {title}
      </RegisterTitle>
      <RegisterStepper activeStep={step} steps={[0, 1, 2]} />
      {step === 0 ? (
        <CreateAccount
          state={state}
          dispatch={dispatch}
          createContinueClick={createContinueClick}
        />
      ) : null}
      {step === 1 ? (
        <AccountDetails
          loading={loading}
          state={state}
          dispatch={dispatch}
          accountContinueClick={accountContinueClick}
        />
      ) : null}
      {step === 2 ? (
        <VerifyOtp
          loading={loading}
          state={state}
          dispatch={dispatch}
          fullNumber={fullNumber}
          otpVerifyClick={otpVerifyClick}
        />
      ) : null}
    </RegisterWrapper>
  );
};

export default Register;
