import { FC } from "react";
import { List } from "@mui/material";

import { SubMenuWrapper } from "./SidebarMenu.styles";
import MenuItem from "./MenuItem";
import appConfig from "src/config/appConfig";

export interface ISidebarMenuItem {
  label: string;
  path: string;
  onClick?: () => void;
}
interface ISidebarMenu {
  items: ISidebarMenuItem[];
  onClick: () => void;
}

const SidebarMenu: FC<ISidebarMenu> = ({ items, onClick }) => {
  return (
    <SubMenuWrapper>
      <List>
        {items.map((item, index) => (
          <MenuItem
            key={index}
            label={item.label}
            path={item.path}
            onClick={item.onClick ?? onClick}
            showNewIcon={appConfig.showNew.pages[item.label]}
          />
        ))}
      </List>
    </SubMenuWrapper>
  );
};

export default SidebarMenu;
