import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "src/store/store";
import { IFicaState } from "./ficaSlice.contracts";

const initialState: IFicaState = {
  idDoc: undefined,
  poADoc: undefined,
  fundsSource: 0,
  declarationAccepted: false,
};

const ficaSlice = createSlice({
  name: "fica",
  initialState,
  reducers: {
    setFundsSource(state, action: PayloadAction<{ source: number }>) {
      const { source } = action.payload;
      state.fundsSource = source;
    },
    setDeclarationAccepted(
      state,
      action: PayloadAction<{ accepted: boolean }>
    ) {
      const { accepted } = action.payload;
      state.declarationAccepted = accepted;
    },
    setIdDoc(state, action: PayloadAction<{ doc: File }>) {
      const { doc } = action.payload;
      state.idDoc = doc;
    },
    clearIdDoc(state) {
      state.idDoc = undefined;
    },
    setPoADoc(state, action: PayloadAction<{ doc: File }>) {
      const { doc } = action.payload;
      state.poADoc = doc;
    },
    clearPoADoc(state) {
      state.poADoc = undefined;
    },
  },
});

export default ficaSlice.reducer;

export const {
  setFundsSource,
  setDeclarationAccepted,
  setIdDoc,
  clearIdDoc,
  setPoADoc,
  clearPoADoc,
} = ficaSlice.actions;

export const selectIdDoc = (state: RootState) => state.fica.idDoc;
export const selectPoADoc = (state: RootState) => state.fica.poADoc;
export const selectDeclarationAccepted = (state: RootState) =>
  state.fica.declarationAccepted;
export const selectFundsSource = (state: RootState) => state.fica.fundsSource;
