import { FC, PropsWithChildren, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { StylesProvider } from "@mui/styles";
import { SafeArea as SA } from "capacitor-plugin-safe-area";
import { TopbetTheme } from "../schemes/TopbetTheme";
import { Theme } from "@mui/material/styles";
import logger from "src/utils/logger";

/**
 * Functional component that wraps its children with a theme provider.
 * Sets up state for the active theme, inset top, and loading status.
 * Includes an async function to retrieve safe area insets and update the state.
 * Sets up two useEffect hooks, one for initiating the insets retrieval and one for updating the theme based on the inset top state.
 * Renders loading message if the component is loading, otherwise renders the children wrapped in a StylesProvider and ThemeProvider with the active theme.
 *
 * @param {PropsWithChildren} children - The children to be wrapped by the theme provider.
 * @return {JSX.Element} The wrapped children with the active theme.
 */
const ThemeProviderWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [activeTheme, setActiveTheme] = useState<Theme>(TopbetTheme);
  const [insetTop, setInsetTop] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Asynchronously retrieves safe area insets and updates the state with the top inset value.
   * Sets loading state to false after the insets are retrieved.
   *
   * @return {Promise<void>} A promise that resolves when the insets are retrieved and state is updated.
   */
  const getInsetsPrep = async (): Promise<void> => {
    await SA.getSafeAreaInsets().then(({ insets }) => {
      setInsetTop(insets.top); // State is updated here to re-render when height changes.
    });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getInsetsPrep();
  }, []);

  /**
   * useEffect hook that updates the theme based on the inset top state.
   * If the insetTop value is truthy, it updates the active theme with modified styles to accommodate the safe area insets.
   * It clones the original theme object to avoid mutating it directly.
   * 
   * @param {number} insetTop - The inset top value retrieved from safe area insets.
   */
  useEffect(() => {
    const safeAreaTheme = { ...TopbetTheme }; // Clone the theme object to avoid mutating the original.
    if (insetTop) {
      logger.log('Updating theme because of inset safeArea');
      safeAreaTheme.header.height = '80px'
      safeAreaTheme.header.paddingTop = `${(insetTop) + 12}px`;
      safeAreaTheme.header.alignItems = 'center';
      safeAreaTheme.sidebar.paddingTop = `${insetTop + 12}px`;
    } else {
      safeAreaTheme.header.paddingTop = `30px`;
    }
    setActiveTheme(safeAreaTheme);
  }, [insetTop]);

  if (loading) {
    return <></>; // Renders nothing if loading is true.
  }

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={activeTheme}>{children}</ThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
