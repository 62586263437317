import { LoadingButtonProps } from "@mui/lab";
import { TypographyProps } from "@mui/material";
import { FC } from "react";

import {
  StyledCircularProgress,
  StyledLoadingButton,
  StyledTypography,
} from "./PrimaryButton.styles";

interface PrimaryButtonProps extends LoadingButtonProps {
  fontSize?: TypographyProps["fontSize"];
}

const PrimaryButton: FC<PrimaryButtonProps> = ({
  children,
  fontSize = 11,
  loading,
  ...props
}) => {
  return (
    <StyledLoadingButton
      loading={loading}
      loadingIndicator={<StyledCircularProgress size={16} />}
      {...props}
    >
      <StyledTypography fontSize={fontSize}>
        {loading ? null : children}
      </StyledTypography>
    </StyledLoadingButton>
  );
};

export default PrimaryButton;
