import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "src/store/store";
import { IWalletState } from "./walletSlice.contracts";
import { Wallet } from "../models/Wallet";

const initialState: IWalletState = {
  currentWallet: {
    id: 1,
    type: "CASH_WALLET",
    description: "Cash",
    currencies: [
      {
        symbol: "R",
        code: "ZAR",
        reserved: 0,
        available: 0,
        total: 0,
        creditLimit: 0,
      },
    ],
  },
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    updateWalletSelection(state, action: PayloadAction<{ wallet: Wallet }>) {
      const { wallet } = action.payload;
      state.currentWallet = wallet;
    },
  },
});

export default walletSlice.reducer;

export const { updateWalletSelection } = walletSlice.actions;

export const selectCurrentWallet = (state: RootState) =>
  state.wallet.currentWallet;
