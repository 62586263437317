import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
  selectDateOption,
  selectOddsOption,
  selectShowOptionsModal,
  selectTimeOption,
  setDateOption,
  setOddsOption,
  setTimeOption,
  toggleOptionsModal,
} from "src/features/sports/store/sportsSlice";

export const useShowOptionsModal = () => {
  const dispatch = useAppDispatch();
  const showOptionsModal = useAppSelector(selectShowOptionsModal);

  function toggleOptionsModalHandler(show: boolean) {
    dispatch(toggleOptionsModal({ show }));
  }

  return { showOptionsModal, toggleOptionsModalHandler };
};

type UpdateOddsOptionType = {
  option: "odds";
  newValue: number[];
};

type UpdateOtherOptionsType = {
  option: "date" | "time";
  newValue: string;
};

type UpdateLocalOptionsType = UpdateOddsOptionType | UpdateOtherOptionsType;

export const useOptions = () => {
  const [localTimeOption, setLocalTimeOption] = useState<string>("all");
  const [localDateOption, setLocalDateOption] = useState<string>("all");
  const [localOddsOption, setLocalOddsOption] = useState<number[]>([0, 2]);

  const dispatch = useAppDispatch();
  const timeOption = useAppSelector(selectTimeOption);
  const dateOption = useAppSelector(selectDateOption);
  const oddsOption = useAppSelector(selectOddsOption);

  function clearOptions() {
    setLocalDateOption("all");
    setLocalOddsOption([0, 10]);
    setLocalTimeOption("all");
  }

  function updateLocalOptions({ option, newValue }: UpdateLocalOptionsType) {
    if (option === "date") {
      setLocalDateOption(newValue);
      if (newValue !== "all") {
        setLocalTimeOption("all");
      }
    } else if (option === "odds") {
      setLocalOddsOption(newValue);
    } else {
      setLocalTimeOption(newValue);
      if (newValue !== "all") {
        setLocalDateOption("today");
      }
    }
  }

  function updateRedux() {
    dispatch(setTimeOption({ option: localTimeOption }));
    dispatch(setDateOption({ option: localDateOption }));
    dispatch(setOddsOption({ option: localOddsOption }));
  }

  useEffect(() => {
    setLocalTimeOption(timeOption);
  }, [timeOption]);

  useEffect(() => {
    setLocalDateOption(dateOption);
  }, [dateOption]);

  useEffect(() => {
    setLocalOddsOption(oddsOption);
  }, [oddsOption]);

  return {
    timeOption: localTimeOption,
    dateOption: localDateOption,
    oddsOption: localOddsOption,
    clearOptions,
    updateLocalOptions,
    updateRedux,
  };
};
