import { FC } from "react";

const Promotions: FC = () => {
  return (
    <>
      <img
        src="https://new.topbet.co.za/assets/content/signupbonus-7c500ad2.jpg"
        alt="Nothing to show here."
        width={"100%"}
      />
      <h1>
        <a
          href=" https://new.topbet.co.za/assets/content/Hatsy (Pty) Ltd Welcome Bonus Terms and Conditions_31012022-4a832397.pdf"
          style={{ color: "#f66e22" }}
          target="_blank"
          rel="noreferrer"
        >
          Click Here to view Terms & Conditions
        </a>
      </h1>
      <br />
      <img
        src="https://new.topbet.co.za/assets/content/predictorweb(1)-107fac18.png"
        alt="Nothing to show here."
        width={"100%"}
      />
      <h1>
        <a
          href="https://new.topbet.co.za/assets/content/Topbet_Predictor_TermsandConditions-82a59f46.pdf"
          style={{ color: "#f66e22" }}
          target="_blank"
          rel="noreferrer"
        >
          Click Here to view Terms & Conditions
        </a>
      </h1>
    </>
  );
};

export default Promotions;
