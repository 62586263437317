import { styled } from "@mui/material/styles";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)<DataGridProps>(({ theme }) => ({
  borderRadius: 0,
  border: 0,
  borderTop: `2px solid ${theme.colors.secondary.main}`,

  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.colors.neutral.white[10],
  },

  "& .MuiDataGrid-row.Mui-hovered": {
    backgroundColor: theme.colors.neutral.white[10],
  },

  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    color: theme.colors.secondary.main,
    fontSize: 12,
    fontWeight: 700,

    border: "none",
    alignItems: "center",

    "&:hover, &.Mui-hovered": {
      border: "none",
    },

    "&:focus-within": {
      outline: "none",
    },
  },

  "& .MuiDataGrid-cell": {
    color: theme.colors.neutral.white[100],
  },
}));
