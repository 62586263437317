import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";

import PreferencesModal from "./preferences/Preferences";
import BetSlipInLine from "./inLine/BetSlipInLine";
import BetSlipDrawer from "./drawer/BetSlipDrawer";
import LottoBetSlip from "./LottoBetSlip";
import SportBetSlip from "./SportBetSlip";

import {
  useBetSlipStorage,
  useBetsStorage,
} from "../hooks/betSlipStorage.hooks";

const BetSlip: FC = () => {
  useBetSlipStorage();
  useBetsStorage();

  const { pathname } = useLocation();

  const BetSlip = useMemo(() => {
    const isLotto = pathname.includes("luckyNumbers");
    return isLotto ? <LottoBetSlip /> : <SportBetSlip />;
  }, [pathname]);

  return (
    <>
      <PreferencesModal />
      <BetSlipInLine>{BetSlip}</BetSlipInLine>
      <BetSlipDrawer>{BetSlip}</BetSlipDrawer>
    </>
  );
};

export default BetSlip;
