import { useCallback } from "react";
import { toast } from "react-toastify";

import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { selectAcceptOdds } from "../store/betSlipSlice";
import {
  selectSportBets,
  selectSportBetSlip,
  setSportBets,
  toggleBetSlipMode,
  updateMultiModeStake,
} from "../store/sport/sportBetSlipSlice";

import { ErrorData } from "../models/ErrorObject";

import logger from "src/utils/logger";
import { SportBet, SportBetLeg } from "../models/sport";
import { calculateBetPayout } from "../utils/payoutAndStakeCalc";

const expiredErrors = [
  "NOT_WAGERABLE_CLOSED",
  "NOT_BETTABLE_FIXTURE_STATUS",
  "NOT_BETTABLE_MARKET_STATUS",
  "NOT_BETTABLE_SELECTION_STATUS",
];

function updateBetPrice(
  bet: SportBet,
  currentIndex: number,
  index: number,
  newValue: number
) {
  if (index === currentIndex) {
    const currentLeg = bet.legs[0];
    const newLeg: SportBetLeg = {
      ...currentLeg,
      price: newValue,
    };
    const { payout, price } = calculateBetPayout(bet.stake, [newValue]);

    const newBet: SportBet = {
      ...bet,
      legs: [newLeg],
      payout,
      price,
    };
    return newBet;
  } else {
    return bet;
  }
}

export const useHandleErrors = () => {
  const dispatch = useAppDispatch();
  const acceptOdds = useAppSelector(selectAcceptOdds);
  const sportBets = useAppSelector(selectSportBets);
  const sportBetSlipBets = useAppSelector(selectSportBetSlip).bets;

  const handleErrors = useCallback(
    (errorData: ErrorData) => {
      // create local version of sport bets
      // errors fixed on local version - redux updated only once
      let updatedSportBets = [...sportBets];

      for (const betError of errorData.bets) {
        const betIndex = betError.requestOrder;
        const currentBet = sportBetSlipBets[betIndex];

        if (betError.errors.length > 0) {
          for (const error of betError.errors) {
            if (error.type === "PAYOUT_LIMIT") {
              toast.warning(
                "You have exceeded the payout limit. The stake has been adjusted to stay within the payout limit."
              );
              if (currentBet.id === "multi") {
                dispatch(
                  updateMultiModeStake({
                    stake: "multi",
                    amount: Number(error.value),
                  })
                );
              } else if (currentBet.id.includes("double")) {
                dispatch(
                  updateMultiModeStake({
                    stake: "double",
                    amount: Number(error.value),
                  })
                );
              } else if (currentBet.id.includes("treble")) {
                dispatch(
                  updateMultiModeStake({
                    stake: "treble",
                    amount: Number(error.value),
                  })
                );
              } else if (currentBet.id.includes("quad")) {
                dispatch(
                  updateMultiModeStake({
                    stake: "quad",
                    amount: Number(error.value),
                  })
                );
              } else {
                updatedSportBets = updatedSportBets.map((bet) => {
                  if (bet.id === currentBet.id) {
                    const betLegPrices = bet.legs.map((leg) => leg.price);
                    const { payout, price } = calculateBetPayout(
                      Number(error.value),
                      betLegPrices
                    );

                    const newBet: SportBet = {
                      ...bet,
                      payout,
                      price,
                      stake: Number(error.value),
                    };
                    return newBet;
                  } else {
                    return bet;
                  }
                });
              }
            } else if (error.type === "INCORRECT_BET_PAYOUT") {
              logger.error(
                "The payout calculated for the current bet is incorrect."
              );
              if (currentBet.id === "multi") {
                dispatch(
                  updateMultiModeStake({
                    stake: "multi",
                    amount: 0,
                  })
                );
              } else if (currentBet.id.includes("double")) {
                dispatch(
                  updateMultiModeStake({
                    stake: "double",
                    amount: 0,
                  })
                );
              } else if (currentBet.id.includes("treble")) {
                dispatch(
                  updateMultiModeStake({
                    stake: "treble",
                    amount: 0,
                  })
                );
              } else if (currentBet.id.includes("quad")) {
                dispatch(
                  updateMultiModeStake({
                    stake: "quad",
                    amount: 0,
                  })
                );
              } else {
                updatedSportBets = updatedSportBets.filter(
                  (_, index) => index !== betIndex
                );
              }
              break;
            } else {
              logger.log(error);
            }
          }
        }

        for (const legError of betError.legs) {
          // get current leg in betslip bets
          const currentLegIndex = legError.requestOrder;
          const currentLeg = sportBetSlipBets[betIndex].legs[currentLegIndex];

          // find index of bet in sport bets
          const currentBetIndex = sportBets.findIndex(
            (bet) => bet.legs[0].selectionId === currentLeg.selectionId
          );
          if (currentBetIndex < 0) {
            continue;
          }

          for (const error of legError.errors) {
            // handle each leg error
            if (expiredErrors.includes(error.type)) {
              updatedSportBets = updatedSportBets.filter(
                (_, index) => index !== currentBetIndex
              );
              break;
            }

            if (error.type === "RELATED_BET") {
              toast.warning("Bets from the same fixture cannot be combined");
              dispatch(toggleBetSlipMode({ mode: "singles" }));
              break;
            }

            if (error.type === "HEARTBEAT_FAILED") {
              toast.info(
                "The selection has temporarily become unavailable and has been removed from your bet slip."
              );
              updatedSportBets = updatedSportBets.filter(
                (_, index) => index !== currentBetIndex
              );
              break;
            }

            if (error.type === "INCORRECT_PRICE") {
              // handle incorrect price
              if (acceptOdds === "any") {
                updatedSportBets = updatedSportBets.map((bet, index) =>
                  updateBetPrice(
                    bet,
                    currentBetIndex,
                    index,
                    Number(error.value)
                  )
                );
              }

              if (acceptOdds === "higher") {
                if (Number(error.value) > currentLeg.price) {
                  updatedSportBets = updatedSportBets.map((bet, index) =>
                    updateBetPrice(
                      bet,
                      currentBetIndex,
                      index,
                      Number(error.value)
                    )
                  );
                } else {
                  toast.warning(
                    "The odds have decreased on one of your bets. Please review your bet slip."
                  );
                }
              }

              if (acceptOdds === "none") {
                toast.warning(
                  "The odds have changed on one of your bets. Please review your bet slip."
                );
              }
            }
          }
        }
      }

      dispatch(setSportBets({ bets: updatedSportBets }));
    },
    [acceptOdds, dispatch, sportBets, sportBetSlipBets]
  );

  return handleErrors;
};
