import { FC } from "react";

import { BalanceText, Selector, SelectorButton } from "./WalletSelector.styles";

import { Wallet } from "../../models/Wallet";

import { currencyFormat } from "src/utils/formatters";

interface IWalletItem {
  wallet: Wallet;
}

const WalletItem: FC<IWalletItem> = ({ wallet }) => {
  return (
    <>
      <Selector
        control={<SelectorButton />}
        label={wallet.description + " Wallet"}
        value={wallet.type}
      />
      <BalanceText>
        Available Balance{" "}
        {currencyFormat(
          wallet.currencies[0].available,
          wallet.currencies[0].symbol
        )}
      </BalanceText>
      <BalanceText>
        Current Balance{" "}
        {currencyFormat(
          wallet.currencies[0].total,
          wallet.currencies[0].symbol
        )}
      </BalanceText>
    </>
  );
};

export default WalletItem;
