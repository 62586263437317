import { Box, BoxProps, styled } from "@mui/material";

export const SubheaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "start",
  // gap: theme.spacing(1.75),
  position: 'sticky',
  top: theme.header.height,
  zIndex: 999,
  backgroundColor: theme.palette.background.default,
}));
