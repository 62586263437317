import { Box, BoxProps, styled } from "@mui/material";

export const Header = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const TitleWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  height: "30px",

  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  padding: theme.spacing(0, 1.25),

  backgroundColor: theme.colors.secondary.main,
  borderRadius: "5px",

  fontSize: 10,
  fontWeight: 600,
  color: theme.colors.text.dark,
  textTransform: "uppercase",
}));
