import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";

import Loader from "src/components/loader/Loader";

import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";

import AuthGuard from "src/Guards/authGuard/AuthGuard";
import pages from "./routes";

//#region Auth
const Login = Loader(lazy(() => import("src/pages/Login/Login")));
const Register = Loader(lazy(() => import("src/pages/Register/Register")));
const Forgot = Loader(lazy(() => import("src/pages/Forgot/Forgot")));
//#endregion Auth

const Home = Loader(lazy(() => import("src/pages/Home/Home")));
const Aviator = Loader(lazy(() => import("src/pages/Aviator/Aviator")));
const BetGames = Loader(lazy(() => import("src/pages/BetGames/BetGames")));

//#region Account
const Withdraw = Loader(lazy(() => import("src/pages/Withdraw/Withdraw")));
const Account = Loader(lazy(() => import("src/pages/Account/Account")));
const Fica = Loader(lazy(() => import("src/pages/Fica/Fica")));
const ChangePassword = Loader(
  lazy(() => import("src/pages/ChangePassword/ChangePassword"))
);
const Transactions = Loader(
  lazy(() => import("src/pages/Transactions/Transactions"))
);
const Notifications = Loader(
  lazy(() => import("src/pages/Notifications/Notifications"))
);
const ResponsibleGambling = Loader(
  lazy(() => import("src/pages/ResponsibleGambling/ResponsibleGambling"))
);
const MyBets = Loader(lazy(() => import("src/pages/MyBets/MyBets")));
const Deposits = Loader(lazy(() => import("src/pages/Deposits/Deposits")));
//#endregion Account

//#region Live Casino
const EvolutionPage = Loader(
  lazy(() => import("src/pages/LiveCasino/Evolution"))
);
const EzugiPage = Loader(lazy(() => import("src/pages/LiveCasino/Ezugi")));
//#endregion Live Casino

//#region Lucky Numbers
const LuckyNumbers = Loader(
  lazy(() => import("src/pages/LuckyNumbers/LuckyNumbers"))
);
const LuckyNumbersResults = Loader(
  lazy(() => import("src/pages/LuckyNumbers/Results"))
);
const LuckyNumbersFavourites = Loader(
  lazy(() => import("src/pages/LuckyNumbers/Favourites"))
);
const SearchLotto = Loader(lazy(() => import("src/pages/LuckyNumbers/Search")));
//#endregion Lucky Numbers

//#region Promotions
const Promotions = Loader(
  lazy(() => import("src/pages/Promotions/Promotions"))
);
//#endregion Promotions

//#region Slots
const Habanero = Loader(lazy(() => import("src/pages/Slots/Habanero")));
const PlayHabenero = Loader(lazy(() => import("src/pages/Slots/playHabanero")));
const Spinmatic = Loader(lazy(() => import("src/pages/Slots/Spinmatic")));
const PlaySpinmatic = Loader(
  lazy(() => import("src/pages/Slots/playSpinmatic"))
);
//#endregion Slots

//#region Split The Pot
const SplitThePot = Loader(
  lazy(() => import("src/pages/SplitThePot/SplitThePot"))
);
const PlaySplitThePot = Loader(
  lazy(() => import("src/pages/SplitThePot/PlaySplitThePot"))
);
//#endregion Split The Pot

//#region Sports
const AllSports = Loader(lazy(() => import("src/pages/Sports/All/AllSports")));
const SearchSports = Loader(
  lazy(() => import("src/pages/Sports/Search/SearchSports"))
);
const LiveSports = Loader(
  lazy(() => import("src/pages/Sports/Live/LiveSports"))
);
//#endregion Sports

//#region Status
const Unverified = Loader(
  lazy(() => import("src/pages/Fallbacks/Status/Unverified/Unverified"))
);
const StatusComingSoon = Loader(
  lazy(() => import("src/pages/Fallbacks/Status/ComingSoon/ComingSoon"))
);
const StatusMaintenance = Loader(
  lazy(() => import("src/pages/Fallbacks/Status/Maintenance/Maintenance"))
);
const Status404 = Loader(
  lazy(() => import("src/pages/Fallbacks/Status/Status404/Status404"))
);
const Status500 = Loader(
  lazy(() => import("src/pages/Fallbacks/Status/Status500/Status500"))
);
const StatusSuccess = Loader(
  lazy(() => import("src/pages/Fallbacks/Status/Success/Success"))
);
const StatusFailure = Loader(
  lazy(() => import("src/pages/Fallbacks/Status/Failure/Failure"))
);
const StatusCancel = Loader(
  lazy(() => import("src/pages/Fallbacks/Status/Cancel/Cancel"))
);
//#endregion Status
const PlayPragmaticPage = Loader(
  lazy(() => import("src/pages/Slots/playPragmatic"))
);
const PragmaticPage = Loader(lazy(() => import("src/pages/Slots/Pragmatic")));

const EvolutionSlotsPage = Loader(
  lazy(() => import("src/pages/Slots/Evolution"))
);

const PlayEvolution = Loader(
  lazy(() => import("src/pages/Slots/playEvolution"))
);

const routes: RouteObject[] = [
  {
    path: "",
    element: <BaseLayout />,
    /**
     * All children within this element will NOT have a Sidebar and top Navbar
     * All children within this element does not need to be authenticated to access
     */
    children: [
      //#region Base
      {
        //Navigate to home when base routed to base path
        path: "/",
        element: <Navigate to={pages.home.path} replace />,
      },
      //#endregion Base
      //#region Auth
      {
        //All authentication routes
        //No navbars are shown as the user is not logged in
        path: pages.auth.root,
        children: [
          {
            path: "",
            element: <Login />,
          },
          {
            path: pages.auth.login.name,
            element: <Login />,
          },
          {
            path: pages.auth.register.name,
            element: <Register />,
          },
          {
            path: pages.auth.forgot.name,
            element: <Forgot />,
          },
        ],
      },
      //#endregion Auth
      //#region Status
      {
        //All status routes
        path: pages.status.root,
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />,
          },
          {
            path: pages.status.unverified.name,
            element: <Unverified />,
          },
          {
            path: pages.status.status404.name,
            element: <Status404 />,
          },
          {
            path: pages.status.status500.name,
            element: <Status500 />,
          },
          {
            path: pages.status.statusMaintenance.name,
            element: <StatusMaintenance />,
          },
          {
            path: pages.status.statusComingSoon.name,
            element: <StatusComingSoon />,
          },
          //TODO: Maybe make paths that shows status within the sidebars as well
          {
            path: pages.status.statusSuccess.name,
            element: <StatusSuccess />,
          },
          {
            path: pages.status.statusFailure.name,
            element: <StatusFailure />,
          },
          {
            path: pages.status.statusCancel.name,
            element: <StatusCancel />,
          },
        ],
      },
      //#endregion Status
      //#region NotFound
      {
        path: "*",
        element: <Status404 />,
      },
      //#endregion NotFound
    ],
  },
  {
    path: "",
    element: (
      /**
       * All children with this element will have a Sidebar and top Navbar
       * AuthGuard checks that the user is logged in before granting access to its children pages
       */
      <SidebarLayout />
    ),
    children: [
      //#region Base
      {
        path: "",
        element: <Navigate to={pages.home.name} replace />,
      },
      //#endregion Base
      //#region Home
      {
        path: pages.home.name,
        element: <Home />,
      },
      //#endregion Home
      //#region Account
      {
        path: pages.account.root,
        children: [
          {
            path: "",
            element: <Navigate to={pages.account.myBets.name} replace />,
          },
          {
            path: pages.account.myBets.name,
            element: (
              <AuthGuard>
                <MyBets />
              </AuthGuard>
            ),
          },
          {
            path: pages.account.transaction.name,
            element: (
              <AuthGuard>
                <Transactions />
              </AuthGuard>
            ),
          },
          {
            path: pages.account.deposit.name,
            element: (
              <AuthGuard>
                <Deposits />
              </AuthGuard>
            ),
          },
          {
            path: pages.account.withdraw.name,
            element: (
              <AuthGuard>
                <Withdraw />
              </AuthGuard>
            ),
          },
          {
            path: pages.account.accountDetails.name,
            element: (
              <AuthGuard>
                <Account />
              </AuthGuard>
            ),
          },
          {
            path: pages.account.changePassword.name,
            element: (
              <AuthGuard>
                <ChangePassword />
              </AuthGuard>
            ),
          },
          {
            path: pages.account.notification.name,
            element: (
              <AuthGuard>
                <Notifications />
              </AuthGuard>
            ),
          },
          {
            path: pages.account.fica.name,
            element: (
              <AuthGuard>
                <Fica />
              </AuthGuard>
            ),
          },
          {
            path: pages.account.responsibleGambling.name,
            element: (
              <AuthGuard>
                <ResponsibleGambling />
              </AuthGuard>
            ),
          },
        ],
      },
      //#endregion Account
      //#region Aviator
      {
        path: pages.aviator.name,
        element: (
          <AuthGuard>
            <Aviator />
          </AuthGuard>
        ),
      },
      //#endregion Aviator
      //#region BetGames
      {
        path: pages.betGames.name,
        element: <BetGames />,
      },
      //#endregion BetGames
      //#region liveCasino
      {
        path: pages.liveCasino.root,
        children: [
          {
            path: "",
            element: <Navigate to={pages.liveCasino.evolution.name} replace />,
          },
          {
            path: pages.liveCasino.evolution.name,
            element: (
              <AuthGuard>
                <EvolutionPage />
              </AuthGuard>
            ),
          },
          {
            path: pages.liveCasino.ezugi.name,
            element: (
              <AuthGuard>
                <EzugiPage />
              </AuthGuard>
            ),
          },
        ],
      },
      //#endregion liveCasino
      //#region luckyNumbers
      {
        path: pages.luckyNumbers.root,
        children: [
          {
            path: "",
            element: <Navigate to={pages.luckyNumbers.all.name} replace />,
          },
          {
            path: pages.luckyNumbers.all.name,
            element: <LuckyNumbers />,
          },
          {
            path: pages.luckyNumbers.results.name,
            element: <LuckyNumbersResults />,
          },
          // {
          //   path: pages.luckyNumbers.draws.name,
          //   element: <StatusComingSoon />,
          // },
          {
            path: pages.luckyNumbers.favourites.name,
            element: <LuckyNumbersFavourites />,
          },
          {
            path: pages.luckyNumbers.search.name,
            element: <SearchLotto />,
          },
        ],
      },
      //#endregion luckyNumbers
      //#region promotions
      {
        path: pages.promotions.root,
        children: [
          {
            path: "",
            element: <Navigate to={pages.promotions.special.name} replace />,
          },
          {
            path: pages.promotions.special.name,
            element: <Promotions />,
          },
          // {
          //   path: pages.promotions.recurring.name,
          //   element: <StatusComingSoon />,
          // },
        ],
      },
      //#endregion Promotions
      //#region slots
      {
        path: pages.slots.root,
        children: [
          {
            path: "",
            element: <Navigate to={pages.slots.habanero.name} replace />,
          },
          {
            path: pages.slots.habanero.name,
            element: <Habanero />,
          },
          {
            path: pages.slots.playHabanero.path,
            element: <PlayHabenero />,
          },
          {
            path: pages.slots.spinmatic.name,
            element: <Spinmatic />,
          },
          {
            path: pages.slots.playSpinmatic.path,
            element: <PlaySpinmatic />,
          },
          {
            path: pages.slots.evolution.name,
            element: <EvolutionSlotsPage />,
          },
          {
            path: pages.slots.playEvolution.path,
            element: (
              <AuthGuard>
                <PlayEvolution />
              </AuthGuard>
            ),
          },
          {
            path: pages.slots.pragmatic.name,
            element: <PragmaticPage />,
          },
          {
            path: pages.slots.playPragmatic.path,
            element: (
              <AuthGuard>
                <PlayPragmaticPage />
              </AuthGuard>
            ),
          },
        ],
      },
      //#endregion Slots
      //#region splitThePot
      {
        path: pages.splitThePot.root,
        children: [
          {
            path: "",
            element: <SplitThePot />,
          },
          {
            path: pages.splitThePot.play.name,
            element: (
              <AuthGuard>
                <PlaySplitThePot />
              </AuthGuard>
            ),
          },
        ],
      },
      //#endregion splitThePot
      //#region sports
      {
        path: pages.sports.root,
        children: [
          {
            path: "",
            element: <Navigate to={pages.sports.all.name} replace />,
          },
          {
            path: pages.sports.all.name,
            element: <AllSports />,
          },
          {
            path: pages.sports.live.name,
            element: <LiveSports />,
          },
          {
            path: pages.sports.search.name,
            element: <SearchSports />,
          },
          // {
          //   path: pages.sports.virtual.name,
          //   element: <StatusComingSoon />,
          // },
        ],
      },
      //#endregion sports
    ],
  },
];

export default routes;
