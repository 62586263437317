import { FC } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import TopbetLogoImg from "src/assets/topbet-logo/Topbet_Logo.svg";

interface ITopbetLogo {
  visible: boolean;
  height?: number | string;
}

const TopbetLogo: FC<ITopbetLogo> = ({ visible, height = "100%" }) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate("/home");
  };

  if (!visible) {
    return null;
  }

  return (
    <Box
      component="img"
      src={TopbetLogoImg}
      alt={TopbetLogoImg}
      height={height}
      onClick={onClickHandler}
    />
  );
};

export default TopbetLogo;
