import { create } from 'zustand';

export enum AuthDialogType {
  Login = 'login',
  Register = 'register',
  ForgotPassword = 'forgotPassword',
  Disabled = 'disabled',
  Verify = 'verify',
  None = 'none',
}

// Define the state for the Dialog
export interface DialogState {
  displayType: AuthDialogType
  setDisplayType: (type: AuthDialogType) => void;
  temporaryNumber?: string;
  setTemporaryNumber: (number: string) => void;
}


// Create a store for managing the dialog state
/**
 * Represents the state and actions for managing a dialog.
 */
export const useAuthDailogStore = create<DialogState>((set) => ({
  /**
   * Indicates whether the dialog is currently open or not.
   */
  isDialogOpen: false,
  /**
   * Indicates whether the the content is gaurded or not.
   */
  isGaurded: false,
  /**
   * The content of the dialog.
   */
  displayType: AuthDialogType.None,

  /**
   * Sets the display type of the dialog.
   * @param type - The type of the dialog.
   */
  setDisplayType: (type: AuthDialogType) => set({ displayType: type }),


  /**
   * Sets the temporary number.
   */
  
  setTemporaryNumber: (number: string) => set({ temporaryNumber: number }),
}));