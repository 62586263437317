import { FC } from "react";
import { useLocation } from "react-router-dom";

import { FooterWrapper, ListWrapper } from "./Footer.styles";
import FooterImage from "./components/FooterImage";
import FooterImageWrapper from "./components/FooterImageWrapper";
import FooterListItem, {
  FooterListItemProps,
} from "./components/FooterListItem";
import FooterText from "./components/FooterText";

import appConfig from "src/config/appConfig";

import { FooterImg, footerImgArray } from "src/assets/footer";

const items: FooterListItemProps[] = [
  {
    title: "TopBet",
    content: [
      // {label: 'About Us', name: 'about-us' }, // not in the response
      { label: "Contact Us", name: "Contact Us" },
      { label: "FAQ's", name: "FAQ's Market Types" },
      { label: "Branches", name: "Branches" },
      // {label: 'News', name: 'News' }, // not in the response
      { label: "Promotions", name: "Promotions" },
    ],
  },
  {
    title: "Regulations",
    content: [
      // {label: 'Bookmarker License', name: 'Bookmarker License' }, // not in the response
      { label: "Dispute Resolution", name: "DISPUTE RESOLUTION" },
      { label: "Gambling Board Conduct", name: "Gambling Board Contact" },
      { label: "FICA", name: "FICA" },
      { label: "Privacy Policy", name: "Privacy Policy" },
      { label: "Responsible Gambling", name: "Responsible Gambling" },
      { label: "Terms and Conditions", name: "Legal" },
    ],
  },
  {
    title: "Rules",
    content: [
      { label: "Limits", name: "LIMITS" },
      // 'General Terms & Conditions', // not in the response
      // 'Bonus Terms & Conditions', // not in the response
      { label: "Bookmaker Operation Rules", name: "Bookmaker License " },
      // 'Racing and Betting Operational Rules', // not in the response
      // 'Totalisator Rules' // not in the response
    ],
  },
  {
    title: "How to play",
    content: [
      { label: "Habanero", name: "HABANERO PAY-TABLE" },
      { label: "Magic Millions", name: "MAGIC MILLIONS - How To Play" },
      { label: "Numbers and Jackpot", name: "Numbers and Jackpot Betting" },
      { label: "Sport Betting", name: "Sports Betting" },
    ],
  },
];

const Footer: FC = () => {
  const { pathname } = useLocation();
  const addPadding = !appConfig.notShowBetslip.includes(pathname);

  return (
    <FooterWrapper addPadding={addPadding}>
      <ListWrapper maxWidth="lg">
        {items.map((item, index) => (
          <FooterListItem key={index + ": " + item.title} {...item} />
        ))}
      </ListWrapper>
      <FooterImageWrapper>
        <FooterImage img={FooterImg} />
      </FooterImageWrapper>
      <FooterImageWrapper>
        {footerImgArray.map((img, index) => (
          <FooterImage key={"footer image " + index} img={img} />
        ))}
      </FooterImageWrapper>
      <FooterText />
    </FooterWrapper>
  );
};

export default Footer;
