import { CircularProgress } from "@mui/material";
import { FC } from "react";
import { toast } from "react-toastify";

import { useMyBets } from "../../hooks/useMyBets";
import { useRepeatLottoBet } from "../../hooks/useRepeatLottoBet";
import { useRepeatSportBet } from "../../hooks/useRepeatSportBet";

import { StyledButton } from "./RepeatBetButton.styles";

import { MyBetsItemLeg } from "../../models/MyBetsItem";

interface IRepeatBet {
  disabled: boolean;
  leg: MyBetsItemLeg | undefined;
}

const RepeatBetButton: FC<IRepeatBet> = ({ disabled, leg }) => {
  const { currentStatusFilter } = useMyBets();
  const [repeatLottoBet, { isLoading: isLoadingLotto }] = useRepeatLottoBet();
  const [repeatSportBet, { isLoading: isLoadingSport }] = useRepeatSportBet();

  const onClickHandler = () => {
    if (leg) {
      if (leg.sportId === "LOTTO") {
        repeatLottoBet(leg);
      } else {
        repeatSportBet(
          leg.fixtureId,
          leg.marketId,
          leg.selectionId,
          leg.marketDefinitionId
        );
      }
    } else {
      toast.error("Unable to repeat bet");
    }
  };

  return (
    <StyledButton
      disabled={currentStatusFilter !== "pending" || disabled}
      onClick={onClickHandler}
      variant="contained"
    >
      {isLoadingLotto || isLoadingSport ? (
        <CircularProgress
          size={20}
          sx={(theme) => ({
            color: theme.colors.neutral.black[100],
          })}
        />
      ) : (
        "Repeat bet"
      )}
    </StyledButton>
  );
};

export default RepeatBetButton;
