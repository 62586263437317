import { alpha, createTheme, darken, lighten } from "@mui/material";
import "@mui/lab/themeAugmentation";

const themeColors = {
  primary: "#FF2727",
  secondary: "#CCB76C",
  info: "#EEEFF1",
  success: "#65CD9C",
  warning: "#FFA217",
  error: "#F92C2C",
  primaryAlt: "#000000",
  trueWhite: "#ffffff",
  background: "#161616",
  textLight: "#EEEFF1",
  textDark: "#383838",
};

const colors = {
  gradients: {
    black1:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)",
    black2: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
    gold: "linear-gradient(270deg, #CCB76C 0%, #F4E5AE 19.06%, #CCB76C 49.48%, #FFEEB2 80%, #CCB76C 100%);",
    homeLeft:
      "linear-gradient(90deg, #161616 13.36%, rgba(22, 22, 22, 0.00) 98.55%);",
    homeRight:
      "linear-gradient(270deg, #161616 13.36%, rgba(22, 22, 22, 0.00) 98.55%);",
  },
  shadows: {
    success:
      "0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)",
    error:
      "0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)",
    info: "0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)",
    primary:
      "0px 1px 4px rgba(112, 99, 192, 0.25), 0px 3px 12px 2px rgba(112, 99, 192, 0.35)",
    warning:
      "0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)",
    card: "0px 0px 2px #6A7199",
    cardSm: "0px 0px 2px #6A7199",
    cardLg:
      "0 0rem 14rem 0 rgb(255 255 255 / 20%), 0 0.8rem 2.3rem rgb(111 130 156 / 3%), 0 0.2rem 0.7rem rgb(17 29 57 / 15%)",
  },
  primary: {
    lighter: lighten(themeColors.primary, 0.5),
    light: lighten(themeColors.primary, 0.3),
    main: themeColors.primary,
    dark: darken(themeColors.primary, 0.2),
  },
  primaryAlt: {
    lighter: alpha(themeColors.primaryAlt, 0.85),
    light: alpha(themeColors.primaryAlt, 0.3),
    main: themeColors.primaryAlt,
    dark: darken(themeColors.primaryAlt, 0.2),
  },
  secondary: {
    lighter: alpha(themeColors.secondary, 0.85),
    light: alpha(themeColors.secondary, 0.6),
    main: themeColors.secondary,
    dark: darken(themeColors.secondary, 0.2),
  },
  info: {
    lighter: alpha(themeColors.info, 0.85),
    light: alpha(themeColors.info, 0.3),
    main: themeColors.info,
    dark: darken(themeColors.info, 0.2),
  },
  success: {
    lighter: alpha(themeColors.success, 0.85),
    light: alpha(themeColors.success, 0.3),
    main: themeColors.success,
    dark: darken(themeColors.success, 0.2),
  },
  warning: {
    lighter: alpha(themeColors.warning, 0.85),
    light: alpha(themeColors.warning, 0.3),
    main: themeColors.warning,
    dark: darken(themeColors.warning, 0.2),
  },
  error: {
    lighter: alpha(themeColors.error, 0.85),
    light: alpha(themeColors.error, 0.3),
    main: themeColors.error,
    dark: darken(themeColors.error, 0.2),
  },
  neutral: {
    white: {
      10: "#FFFFFF1A",
      25: "#FFFFFF40",
      60: "#FFFFFF99",
      80: "#FFFFFFCC",
      100: "#FFFFFF",
    },
    black: {
      10: "#0000001A",
      25: "#00000040",
      60: "#00000099",
      80: "#000000CC",
      100: "#000000",
    },
    grey: {
      1: "#F9F9FB",
      2: "#CFCFCF",
      3: "#9B9B9B",
      4: "#727272",
      5: "#343434",
    },
  },
  text: {
    light: themeColors.textLight,
    dark: themeColors.textDark,
  },
  layout: {
    general: {
      background: themeColors.background,
    },
    sidebar: {
      background: themeColors.primaryAlt,
      textColor: themeColors.secondary,
      dividerBg: "#272C48",
      menuItemColor: "#9EA4C1",
      menuItemColorActive: "#ffffff",
      menuItemBg: themeColors.primaryAlt,
      menuItemBgActive: "rgba(43, 48, 77, .6)",
      menuItemIconColor: "#444A6B",
      menuItemIconColorActive: "#ffffff",
      menuItemHeadingColor: darken(themeColors.secondary, 0.3),
      paddingTop: "0px",
    },
  },
};

export const TopbetTheme = createTheme({
  colors: {
    gradients: colors.gradients,
    shadows: colors.shadows,
    primary: colors.primary,
    primaryAlt: colors.primaryAlt,
    secondary: colors.secondary,
    info: colors.info,
    success: colors.success,
    warning: colors.warning,
    error: colors.error,
    neutral: colors.neutral,
    text: colors.text,
  },
  general: {
    background: colors.layout.general.background,
    reactFrameworkColor: "#00D8FF",
    borderRadiusSm: "6px",
    borderRadius: "10px",
    borderRadiusLg: "12px",
    borderRadiusXl: "16px",
  },
  sidebar: {
    background: colors.layout.sidebar.background,
    textColor: colors.layout.sidebar.textColor,
    dividerBg: colors.layout.sidebar.dividerBg,
    menuItemColor: colors.layout.sidebar.menuItemColor,
    menuItemColorActive: colors.layout.sidebar.menuItemColorActive,
    menuItemBg: colors.layout.sidebar.menuItemBg,
    menuItemBgActive: colors.layout.sidebar.menuItemBgActive,
    menuItemIconColor: colors.layout.sidebar.menuItemIconColor,
    menuItemIconColorActive: colors.layout.sidebar.menuItemIconColorActive,
    menuItemHeadingColor: colors.layout.sidebar.menuItemHeadingColor,
    boxShadow: "1px 0 0 #272C48",
    width: "240px",
    paddingTop: "0px",
  },
  header: {
    height: "60px",
    background: themeColors.primaryAlt,
    boxShadow: "0px 1px 0px #272C48",
    textColor: colors.secondary.main,
    alignItems: "center",
    paddingTop: "0px",
  },
  betSlip: {
    height: "72px",
    width: "400px",
  },
  spacing: 8,
  palette: {
    common: {
      black: colors.neutral.black[100],
      white: colors.neutral.white[100],
    },
    mode: "dark",
    primary: {
      light: colors.primary.light,
      main: colors.primary.main,
      dark: colors.primary.dark,
    },
    secondary: {
      light: colors.secondary.light,
      main: colors.secondary.main,
      dark: colors.secondary.dark,
    },
    info: {
      light: colors.info.light,
      main: colors.info.main,
      dark: colors.info.dark,
      contrastText: themeColors.trueWhite,
    },
    success: {
      light: colors.success.light,
      main: colors.success.main,
      dark: colors.success.dark,
      contrastText: themeColors.trueWhite,
    },
    warning: {
      light: colors.warning.light,
      main: colors.warning.main,
      dark: colors.warning.dark,
      contrastText: themeColors.trueWhite,
    },
    error: {
      light: colors.error.light,
      main: colors.error.main,
      dark: colors.error.dark,
      contrastText: themeColors.trueWhite,
    },
    text: {
      primary: colors.neutral.black[100],
      secondary: colors.neutral.black[80],
      disabled: colors.neutral.black[60],
    },
    background: {
      paper: colors.neutral.white[100],
      default: colors.layout.general.background,
    },
    action: {
      active: colors.neutral.black[100],
      hover: "pink",
      hoverOpacity: 0.1,
      selected: colors.neutral.black[25],
      selectedOpacity: 0.1,
      disabled: colors.neutral.black[60],
      disabledBackground: colors.neutral.black[10],
      disabledOpacity: 0.38,
      focus: colors.neutral.black[10],
      focusOpacity: 0.05,
      activatedOpacity: 0.12,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1840,
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(colors.layout.general.background, 0.7),
          backdropFilter: "blur(2px)",

          "&.MuiBackdrop-invisible": {
            backgroundColor: "transparent",
            backdropFilter: "blur(2px)",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: "none",
          marginLeft: 8,
          marginRight: 8,
          fontWeight: "bold",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "html, body": {
          width: "100%",
          height: "100%",
        },
        body: {
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
          flex: 1,
        },
        "#root": {
          width: "100%",
          height: "100%",
          display: "flex",
          flex: 1,
          flexDirection: "column",
        },
        html: {
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
        },
        ".child-popover .MuiPaper-root .MuiList-root": {
          flexDirection: "column",
        },
        "#nprogress": {
          pointerEvents: "none",
        },
        "#nprogress .bar": {
          background: colors.primary.lighter,
        },
        "#nprogress .spinner-icon": {
          borderTopColor: colors.primary.lighter,
          borderLeftColor: colors.primary.lighter,
        },
        "#nprogress .peg": {
          boxShadow:
            "0 0 15px " +
            colors.primary.lighter +
            ", 0 0 8px" +
            colors.primary.light,
        },
        ":root": {
          "--swiper-theme-color": colors.primary.main,
          // colorScheme: "dark", //this field did something weird the the callpay modals
        },
        code: {
          background: colors.info.lighter,
          color: colors.neutral.black[100],
          borderRadius: 4,
          padding: 4,
        },
        "@keyframes ripple": {
          "0%": {
            transform: "scale(.8)",
            opacity: 1,
          },
          "100%": {
            transform: "scale(2.8)",
            opacity: 0,
          },
        },
        "@keyframes float": {
          "0%": {
            transform: "translate(0%, 0%)",
          },
          "100%": {
            transform: "translate(3%, 3%)",
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        colorPrimary: {
          fontWeight: "bold",
          lineHeight: "40px",
          fontSize: 13,
          background: colors.neutral.black[10],
          color: colors.neutral.black[80],
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: -5,
          marginBottom: -5,
        },
        title: {
          fontSize: 15,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSecondary: {
          background: colors.neutral.black[10],
          color: colors.neutral.black[100],

          "&:hover": {
            background: colors.neutral.black[10],
          },
        },
        deleteIcon: {
          color: colors.neutral.black[60],

          "&:hover": {
            color: colors.neutral.black[80],
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",

          "&.Mui-expanded": {
            margin: 0,
          },
          "&::before": {
            display: "none",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: "bold",
        },
        colorDefault: {
          background: colors.neutral.black[60],
          color: colors.neutral.white[100],
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
        avatar: {
          background: colors.neutral.black[10],
          fontSize: 13,
          color: colors.neutral.black[80],
          fontWeight: "bold",

          "&:first-of-type": {
            border: 0,
            background: "transparent",
          },
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        alignItemsFlexStart: {
          marginTop: 0,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          fontSize: 13,
          fontWeight: "bold",
          transition: "all .2s",
        },
        textPrimary: {
          "&.Mui-selected": {
            boxShadow: colors.shadows.primary,
          },
          "&.MuiButtonBase-root:hover": {
            background: colors.neutral.black[10],
          },
          "&.Mui-selected.MuiButtonBase-root:hover": {
            background: colors.primary.main,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
          padding: 0,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          height: 6,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel": {
            transform: "none",
          },
          "& .MuiSlider-valueLabel": {
            borderRadius: 6,
            background: colors.neutral.black[100],
            color: colors.neutral.white[100],
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,

          "& .MuiListItem-button": {
            transition: "all .2s",

            "& > .MuiSvgIcon-root": {
              minWidth: 34,
            },

            "& .MuiTouchRipple-root": {
              opacity: 0.2,
            },
          },
          "& .MuiListItem-root.MuiButtonBase-root.Mui-selected": {
            backgroundColor: colors.neutral.black[10],
          },
          "& .MuiMenuItem-root.MuiButtonBase-root:active": {
            backgroundColor: alpha(colors.primary.lighter, 0.2),
          },
          "& .MuiMenuItem-root.MuiButtonBase-root .MuiTouchRipple-root": {
            opacity: 0.2,
          },
        },
        padding: {
          padding: "12px",

          "& .MuiListItem-button": {
            borderRadius: 6,
            margin: "1px 0",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: 12,
        },
        list: {
          padding: 12,

          "& .MuiMenuItem-root.MuiButtonBase-root": {
            fontSize: 14,
            marginTop: 1,
            marginBottom: 1,
            transition: "all .2s",
            color: colors.neutral.black[80],

            "& .MuiTouchRipple-root": {
              opacity: 0.2,
            },

            "&:hover, &:active, &.active, &.Mui-selected": {
              color: colors.neutral.black[100],
              background: alpha(colors.primary.lighter, 0.2),
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: "transparent",
          transition: "all .2s",

          "&:hover, &:active, &.active, &.Mui-selected": {
            color: colors.neutral.black[100],
            background: alpha(colors.primary.lighter, 0.2),
          },
          "&.Mui-selected:hover": {
            background: alpha(colors.primary.lighter, 0.2),
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.MuiButtonBase-root": {
            color: colors.secondary.main,

            "&:hover, &:active, &.active, &.Mui-selected": {
              color: colors.neutral.black[100],
              background: alpha(colors.primary.lighter, 0.2),
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: 1,
        },
        root: {
          ".MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment":
          {
            right: 14,
          },
        },
        clearIndicator: {
          background: alpha(colors.error.lighter, 0.2),
          color: colors.error.main,
          marginRight: 8,

          "&:hover": {
            background: alpha(colors.error.lighter, 0.3),
          },
        },
        popupIndicator: {
          color: colors.neutral.black[80],

          "&:hover": {
            background: alpha(colors.primary.lighter, 0.2),
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          lineHeight: 1.5,
          fontSize: 14,
        },
        standardInfo: {
          color: colors.info.main,
        },
        action: {
          color: colors.neutral.black[80],
        },
      },
    },
    // @ts-ignore
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          margin: 0,
          zIndex: 5,
          position: "absolute",
          top: "50%",
          marginTop: -6,
          left: -6,
        },
        outlined: {
          backgroundColor: colors.neutral.white[100],
          boxShadow: "0 0 0 6px " + colors.neutral.white[100],
        },
        outlinedPrimary: {
          backgroundColor: colors.neutral.white[100],
          boxShadow: "0 0 0 6px " + colors.neutral.white[100],
        },
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          position: "absolute",
          height: "100%",
          top: 0,
          borderRadius: 50,
          backgroundColor: colors.neutral.black[10],
        },
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          minHeight: 0,
          padding: "8px 0",

          "&:before": {
            display: "none",
          },
        },
        missingOppositeContent: {
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: alpha(colors.neutral.black["100"], 0.95),
          padding: "8px 16px",
          fontSize: 13,
        },
        arrow: {
          color: alpha(colors.neutral.black["100"], 0.95),
        },
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily:
      '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: "bold",
      fontSize: 64,
      letterSpacing: "-1%",
      color: "white",
    },
    h2: {
      fontWeight: "bold",
      fontSize: 50,
      letterSpacing: "-1%",
      color: "white",
    },
    h3: {
      fontWeight: "bold",
      fontSize: 40,
      letterSpacing: "-2%",
      color: "white",
    },
    h4: {
      fontWeight: "bold",
      fontSize: 32,
      letterSpacing: "-2%",
      color: "white",
    },
    h5: {
      fontWeight: "bold",
      fontSize: 24,
      letterSpacing: "-2%",
      color: "white",
    },
    h6: {
      fontWeight: "bold",
      fontSize: 20,
      letterSpacing: "-2%",
      color: "white",
    },
    body1: {
      fontWeight: "normal",
      fontSize: 16,
      letterSpacing: "1%",
    },
    body2: {
      fontWeight: "normal",
      fontSize: 14,
      letterSpacing: "1%",
    },
    button: {
      fontWeight: "bold",
      fontSize: 16,
      letterSpacing: "3%",
    },
    caption: {
      fontSize: 13,
      textTransform: "uppercase",
      color: colors.neutral.black[60],
    },
    subtitle1: {
      fontSize: 14,
      color: colors.neutral.black[80],
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 15,
      color: colors.neutral.black[60],
    },
    overline: {
      fontSize: 13,
      fontWeight: 700,
      textTransform: "uppercase",
    },
  },
  shadows: [
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],
});
