import {
  darken,
  ListItemButton,
  ListItemButtonProps,
  styled,
} from "@mui/material";

export const LeagueItemButton = styled(ListItemButton)<ListItemButtonProps>(
  ({ theme }) => ({
    width: "100%",

    fontSize: 14,
    fontWeight: 700,
    color: theme.colors.text.light,

    "&.Mui-selected": {
      backgroundColor: theme.colors.secondary.main,
      color: theme.colors.text.dark,

      "&:hover": {
        backgroundColor: theme.colors.secondary.dark,
        color: theme.colors.text.dark,
      },
    },

    "&:hover": {
      backgroundColor: darken(theme.colors.secondary.dark, 0.8),
    },
  })
);
