import { FC } from "react";

import { Icons } from "src/assets/icons";

type IconProps = {
  name: keyof typeof Icons;
};

const Icon: FC<IconProps> = ({ name }) => {
  const Svg = Icons[name];

  return <Svg />;
};

export default Icon;
