import { FC } from "react";

import { StyledText } from "./BetInput.styles";
import BetAmountInput from "../../input/BetAmountInput";

import { useAppSelector } from "src/hooks/hooks";
import { selectSportBetSlipMode } from "src/features/betslip/store/sport/sportBetSlipSlice";

type BetInputProps = {
  id: string;
  price: number;
  stake: number;
  updateStake: (betId: string, amount: number) => void;
};

const BetInput: FC<BetInputProps> = ({ id, price, stake, updateStake }) => {
  const betSlipMode = useAppSelector(selectSportBetSlipMode);

  return (
    <div>
      <StyledText>{(price - 1).toFixed(2)}</StyledText>
      {betSlipMode === "singles" ? (
        <BetAmountInput betId={id} value={stake} updateStake={updateStake} />
      ) : null}
    </div>
  );
};

export default BetInput;
