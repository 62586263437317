import { MyBetsItemLeg } from "./MyBetsItem";

export type SubRowsType = Omit<MyBetsDataRow, "repeatBet" | "subRows">;

export interface MyBetsDataRow {
  id: string;
  betDate: string;
  ticketRef: string;
  fixtureDate: string;
  fixture: string;
  market: string;
  selection: string;
  price: string;
  stake: string;
  tax: string;
  potentialPayout: string;
  status: string;
  repeatBet: MyBetsItemLeg;
  subRows: SubRowsType[];
}

export type MyBetsMobileDataSubRow = Omit<
  MyBetsMobileDataRow,
  "repeatBet" | "subRows"
>;

export interface MyBetsMobileDataRow {
  id: string[];
  betDate: string[];
  ticketRef: string[];
  fixtureDate: string[];
  fixture: string[];
  market: string[];
  selection: string[];
  price: string[];
  stake: string[];
  tax: string[];
  potentialPayout: string[];
  status: string[];
  repeatBet: MyBetsItemLeg[];
  subRows: MyBetsMobileDataSubRow[];
}

export function hasKey<O extends object>(
  obj: O,
  key: string | number | symbol
): key is keyof O {
  return key in obj;
}

export function isMyBets(obj: any): obj is MyBetsMobileDataRow {
  return "repeatBet" in obj;
}
