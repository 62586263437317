import { Input, InputProps, styled } from "@mui/material";

export const StyledInput = styled(Input)<InputProps>(({ theme }) => ({
  border: "1px solid " + theme.colors.neutral.white[100],
  borderRadius: "5px",

  padding: "5px",

  color: theme.colors.neutral.white[100],
  fontSize: "14px",
  fontWeight: 700,
  textAlign: "center",

  maxWidth: "100px",
}));
