import { FC } from "react";
import { ToggleButtonGroup } from "@mui/material";

import { StyledButton, StyledText } from "./BetModeToggle.styles";

import { useBetModeToggle } from "./BetModeToggle.hooks";

const BetModeToggle: FC = () => {
  const { activeMode, multipleButtonClick, singleButtonClick } =
    useBetModeToggle();

  return (
    <ToggleButtonGroup
      aria-label="text alignment"
      exclusive
      fullWidth
      size="medium"
      value={activeMode}
      sx={{
        flexGrow: 0,
      }}
    >
      <StyledButton disableRipple onClick={singleButtonClick} value={"singles"}>
        <StyledText>Single</StyledText>
      </StyledButton>
      <StyledButton
        disableRipple
        onClick={multipleButtonClick}
        value={"multiples"}
      >
        <StyledText>Multiple</StyledText>
      </StyledButton>
    </ToggleButtonGroup>
  );
};

export default BetModeToggle;
