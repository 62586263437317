import { isValidPhoneNumber } from "react-phone-number-input";

import { RegexPatterns } from "src/utils/constants/regex";

import { FieldTypes } from "../models/fieldTypes";

interface IInitialState {
  cellphone: FieldTypes;
  countryCode: FieldTypes;
  password: FieldTypes;
}

export const initialState: IInitialState = {
  cellphone: {
    value: "",
    valid: true,
    error: "",
    name: "Phone Number",
    regex: RegexPatterns.cellphone,
  },
  password: {
    value: "",
    valid: true,
    error: "",
    name: "Password",
    regex: RegexPatterns.password,
  },
  countryCode: { value: "+27", valid: true, error: "", name: "Country Code" },
};

export enum actionTypes {
  CELLPHONE = "CELLPHONE",
  PASSWORD = "PASSWORD",
  COUNTRY_CODE = "COUNTRY_CODE",
}

interface IAction {
  type: actionTypes;
  payload: string;
}

export const loginFormReducer = (
  state: typeof initialState,
  action: IAction
) => {
  let regex;
  let errorObj = { valid: true, error: "" };
  const payload = action.payload;

  if (!payload) {
    errorObj = { valid: false, error: "Field is required" };
  }

  switch (action.type) {
    case actionTypes.CELLPHONE:
      regex = state.cellphone.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (
          !regexp.test(payload) ||
          !isValidPhoneNumber(payload, {
            defaultCallingCode: state.countryCode.value.substring(1),
          })
        ) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
      }
      return {
        ...state,
        cellphone: { ...state.cellphone, ...errorObj, value: payload },
      };
    case actionTypes.PASSWORD:
      regex = state.password.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
      }
      return {
        ...state,
        password: { ...state.password, ...errorObj, value: payload },
      };
    case actionTypes.COUNTRY_CODE:
      return {
        ...state,
        countryCode: { ...state.countryCode, value: payload },
      };
    default:
      return state;
  }
};
