import { useLocation } from "react-router-dom";

/**
 * When bTag or bTag2 is present in the URL, store it in local storage otherwise retrive it from local storage if it has been set.
 * Also set an expiry date for the local storage.
 * This is used to track affiliate links.
 */

export default function useAffiliate() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const bTag = params.get("btag");
  const bTag2 = params.get("btag2");

  // Set the expiry date to 30 days from the current date.
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 30);

  // If bTag or bTag2 is present in the URL, store it in local storage.
  if (bTag) {
    localStorage.setItem("bTag", bTag);
    localStorage.setItem("expiryDate", expiryDate.toISOString());
  }

  if (bTag2) {
    localStorage.setItem("bTag2", bTag2);
    localStorage.setItem("expiryDate", expiryDate.toISOString());
  }

  // If bTag or bTag2 is not present in the URL, retrieve it from local storage.
  const storedBTag = localStorage.getItem("bTag");
  const storedBTag2 = localStorage.getItem("bTag2");
  const storedExpiryDate = localStorage.getItem("expiryDate");

  // If the expiry date has passed, remove the stored bTag and bTag2.
  if (storedExpiryDate && new Date(storedExpiryDate) < new Date()) {
    localStorage.removeItem("bTag");
    localStorage.removeItem("bTag2");
  }

  return { bTag: storedBTag, bTag2: storedBTag2 };
}
