import { CognitoUser } from 'amazon-cognito-identity-js'
import { _getCurrentUser } from './_getCurrentUser'
import logger from 'src/utils/logger'

const asyncGetUserSession = (
  cognitoUser: CognitoUser,
) => {
  return new Promise(function (resolve, reject) {
    cognitoUser.getSession(function (err: any, session: any) {
      if (err) {
        reject(err)
      } else {
        resolve(session)
      }
    })
  }).catch((err) => {
    logger.log(err)
    throw err
  })
}

export const _getCognitoUserSession = async () => {
  const currentUser = _getCurrentUser()
  if (currentUser) {
    const session: any = await asyncGetUserSession(currentUser)
    return session;
  } else {
    throw new Error('No Session')
  }
}