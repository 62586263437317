import { LottoData, LottoResponse } from "src/models/lotto";
import { LottoFixtureType } from "src/features/luckyNumbers/Fixture/models/lottoFixture";
import { baseApi } from "./baseApi.service";
import { Endpoint, TagId } from "./config/apiTags";

const dateMinimum = new Date();
const dateMaximum = new Date(dateMinimum.getTime() + 15 * 24 * 60 * 60 * 1000);
const threeMonthsAgo = new Date(
  dateMinimum.getTime() - 90 * 24 * 60 * 60 * 1000
);

const lottoApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // ############################## GET ALL LOTTO ###################################
    getAllLotto: builder.query<
      LottoResponse,
      {
        "f-limit": number;
        "f-start": number;
        "f-name"?: string;
        "f-def-id"?: number;
      }
    >({
      query: (query) => {
        return {
          url: `${
            Endpoint.LOTTO
          }&f-date-min=${dateMinimum.toISOString()}&f-date-max=${dateMaximum.toISOString()}`,
          method: "GET",
          params: query,
        };
      },
      providesTags: (result, error, arg) => [
        { type: Endpoint.LOTTO, id: TagId.LIST },
      ],
    }),
    getFixture: builder.query<LottoFixtureType, string>({
      query(arg) {
        return {
          url: `${
            Endpoint.SEARCH_LOTTO
          }/${arg}?f-field=id&f-field=name&f-field=status&f-field=date&f-field=wagerOpenDate&f-field=wagerCloseDate&f-field=def&m-field-group=all&m-type=LT003&m-type=NWAY&m-min-end-date=${dateMaximum.toISOString()}`,
          method: "GET",
        };
      },
      providesTags: (result, error, id) => [{ type: Endpoint.LOTTO, id }],
    }),
    getLottoInfo: builder.query<LottoResponse, string>({
      query: (lottoId) => ({
        url: `${Endpoint.LOTTO_INFO}&f-def-id=${lottoId}`,
        method: "GET",
      }),
      providesTags: [{ type: Endpoint.LOTTO_INFO, id: TagId.ITEM }],
    }),
    getLottoResults: builder.query<
      LottoResponse,
      { "f-def-id"?: number; "f-name"?: string }
    >({
      query: (params) => {
        let queryParams = {};
        if (params?.["f-def-id"]) {
          queryParams = {
            ...queryParams,
            "f-def-id": params["f-def-id"],
          };
        }
        if (params?.["f-name"]) {
          queryParams = {
            ...queryParams,
            "f-name": params["f-name"],
          };
        }
        return {
          url: `${
            Endpoint.LOTTO_RESULTS
          }&f-min-date=${threeMonthsAgo.toISOString()}`,
          method: "GET",

          params: queryParams,
        };
      },
      providesTags: (result, error, arg) => [
        { type: Endpoint.LOTTO_RESULTS, id: TagId.LIST },
      ],
    }),
    getLottoFavourites: builder.query<LottoResponse, { "f-def-id": number[] }>({
      query: (arg) => {
        return {
          url: `${
            Endpoint.LOTTO
          }&f-date-min=${dateMinimum.toISOString()}&f-date-max=${dateMaximum.toISOString()}&${arg["f-def-id"].map((id) => `f-def-id=${id}`).join("&")}`,
          method: "GET",
        };
      },
      providesTags: (result, error, id) => [
        { type: Endpoint.LOTTO, id: TagId.LIST },
      ],
    }),
  }),
});

export const {
  useGetAllLottoQuery,
  useLazyGetAllLottoQuery,
  useGetFixtureQuery,
  useLazyGetFixtureQuery,
  useGetLottoInfoQuery,
  useLazyGetLottoInfoQuery,
  useGetLottoResultsQuery,
  useLazyGetLottoResultsQuery,
  useGetLottoFavouritesQuery,
  useLazyGetLottoFavouritesQuery,
} = lottoApi;
