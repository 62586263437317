import { FC, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import Tick from "./tick/Tick";
import { Icon } from "src/components/icons";
import FileComponent from "./file/FileComponent";
import {
  Container,
  TitleText,
  Wrapper,
  UploadContainer,
  UploadButton,
  ErrorText,
} from "./FileUpload.styles";
import logger from "src/utils/logger";

const fileErrorMaps: Record<string, string> = {
  "too-many-files": "Please select only one file.",
  "file-too-large": "Please select a file smaller than 3MB.",
};

interface IFileUpload {
  title: string;
  doc: File | undefined;
  addDoc: (doc: File) => void;
  removeDoc: () => void;
}

const FileUpload: FC<IFileUpload> = ({ title, doc, addDoc, removeDoc }) => {
  const [fileErrors, setFileErrors] = useState<string[]>([]);

  const { acceptedFiles, fileRejections, getInputProps, getRootProps } =
    useDropzone({
      maxFiles: 1,
      minSize: 0,
      maxSize: 3145728,
    });

  const clearError = () => {
    setFileErrors([]);
  };

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      addDoc(acceptedFiles[0]);
    }
  }, [acceptedFiles, addDoc]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.forEach((fileRejection) => {
        fileRejection.errors.forEach((error) =>
          setFileErrors((prevState) => {
            logger.error(error.code);
            if (!prevState.includes(error.code)) {
              return [...prevState, error.code];
            } else {
              return [...prevState];
            }
          })
        );
      });
    }
  }, [fileRejections]);

  return (
    <Container>
      <TitleText>{title}</TitleText>
      <Wrapper>
        <UploadContainer {...getRootProps()}>
          <input {...getInputProps()} />
          {title}
          <UploadButton onClick={clearError}>
            Upload
            <Icon name="Upload" />
          </UploadButton>
        </UploadContainer>
        {doc ? <Tick /> : null}
      </Wrapper>
      {doc ? (
        <FileComponent fileName={doc.name} deleteFile={removeDoc} />
      ) : null}
      {fileErrors.length > 0 ? (
        <>
          {fileErrors.map((fileError, index) => (
            <ErrorText key={fileError + "_" + index}>
              {fileErrorMaps[fileError]}
            </ErrorText>
          ))}
        </>
      ) : null}
    </Container>
  );
};

export default FileUpload;
