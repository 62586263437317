import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  styled,
} from "@mui/material";

export const FileContainer = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: "inherit",
  border: "none",

  color: "#6B6B6B",
  fontSize: 14,
  fontWeight: 400,

  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  paddingTop: theme.spacing(0.625),
  paddingBottom: theme.spacing(0.625),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(1.25),
}));

export const DeleteButton = styled(IconButton)<IconButtonProps>(
  ({ theme }) => ({
    backgroundColor: "inherit",
    padding: theme.spacing(1.25, 2.5),
  })
);
