import { useEffect, useState } from "react";

import { useGetDisplayOrderQuery } from "src/services/restApi/queries/displayOrders.service";

import { SelectItem } from "src/components/customSelect";

export const useMarketFilter = (sportFilter: string) => {
  const [marketFilters, setMarketFilters] = useState<SelectItem[]>([]);

  const { data, isFetching, isLoading } = useGetDisplayOrderQuery({
    "sport-name": sportFilter,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    const displayOrders = Object.values(data)[0].markets.filter(
      (market) => market.displayOrder
    );
    const filters: SelectItem[] = displayOrders.map((displayOrder) => ({
      label: displayOrder.name,
      value: displayOrder.defId,
    }));

    setMarketFilters(filters);
  }, [data]);

  return { marketFilters, isFetching, isLoading };
};
