import {
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(1),
  justifyContent: "center",
  alignItems: "center",
}));

export const StyledTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.colors.neutral.white[100],
    fontSize: 14,
    fontWeight: 500,
  })
);
