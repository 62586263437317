import { Box, styled } from "@mui/material";

import appConfig from "src/config/appConfig";

export const HeaderWrapper = styled(Box)(
  ({ theme }) => `
          height: ${theme.header.height};
          color: ${theme.header.textColor};
          padding: ${theme.spacing(0, 1)};
          right: 0;
          z-index: 1000;
          background-color: ${theme.header.background};
          backdrop-filter: blur(3px);
          position: fixed;
          justify-content: space-between;
          width: 100%;
          
          @media (min-width: ${theme.breakpoints.values[appConfig.breakMobileView]}px) {
              left: ${theme.sidebar.width};
              width: auto;
              justify-content: flex-end;
          }
  `
);

export const LogoWrapper = styled(Box)(
  ({ theme }) => `
          @media (min-width: ${theme.breakpoints.values[appConfig.breakMobileView]}px) {
              display: none;
          } 
  `
);
