import { MyBetsDataRow, SubRowsType } from "../models/DataRow";
import { MyBetsItem } from "../models/MyBetsItem";

import { currencyFormat } from "src/utils/formatters";

const toDataRow = (item: MyBetsItem) => {
  let subRows: SubRowsType[] = [];

  if (item.legs.length > 1) {
    subRows = item.legs.map((leg) => ({
      id: item.ref,
      betDate: item.betDate,
      ticketRef: item.ref,
      fixtureDate: leg.fixtureDate,
      fixture: leg.fixtureName,
      market: leg.marketName,
      selection: leg.selectionName,
      price: currencyFormat(leg.price.dec, "R"),
      stake: currencyFormat(leg.projectedStake, "R"),
      tax: "", // FIXME: not sure what this value is supposed to be
      potentialPayout: currencyFormat(leg.projectedPayout, "R"),
      status: leg.status,
    }));
  }

  const leg = item.legs[0];

  const row: MyBetsDataRow = {
    id: item.ref,
    betDate: item.betDate,
    ticketRef: item.ref,
    fixtureDate: leg.fixtureDate,
    fixture: leg.fixtureName,
    market: leg.marketName,
    selection: leg.selectionName,
    price: currencyFormat(leg.price.dec, "R"),
    stake: currencyFormat(leg.projectedStake, "R"),
    tax: "", // FIXME: not sure what this value is supposed to be
    potentialPayout: currencyFormat(leg.projectedPayout, "R"),
    status: leg.status,
    repeatBet: leg,
    subRows,
  };

  return row;
};

export const toDataTable = (items: MyBetsItem[]) => {
  const rows = items.map((item) => toDataRow(item));
  return rows;
};
