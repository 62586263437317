import { ChangeEvent, FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

import { BaseInput, CustomInputElement } from "src/components/Inputs";
import CustomSwitch from "src/components/switch/CustomSwitch";
import { PrimaryButton } from "src/components/buttons";

import {
  IInitialState,
  IAction,
  actionTypes,
} from "src/features/authentication/reducers/authFormReducer";
import { env } from "src/env";
import { toast } from "react-toastify";

type InputChangeEvent = ChangeEvent<CustomInputElement>;

type AccountDetailsProps = {
  loading: boolean;
  state: IInitialState;
  dispatch: React.Dispatch<IAction>;
  accountContinueClick: () => void;
};

const AccountDetails: FC<AccountDetailsProps> = ({
  loading,
  state,
  dispatch,
  accountContinueClick,
}) => {
  const [doc, setDoc] = useState<"identityNumber" | "passportNumber">(
    "identityNumber"
  );

  const firstNameChangeHandler = (event: InputChangeEvent) => {
    event.preventDefault();

    dispatch({
      type: actionTypes.FIRSTNAME,
      payload: event.target.value,
    });
  };

  const lastNameChangeHandler = (event: InputChangeEvent) => {
    event.preventDefault();

    dispatch({
      type: actionTypes.LASTNAME,
      payload: event.target.value,
    });
  };

  const emailChangeHandler = (event: InputChangeEvent) => {
    event.preventDefault();

    dispatch({
      type: actionTypes.EMAIL,
      payload: event.target.value,
    });
  };

  const birthDateChangeHandler = (event: InputChangeEvent) => {
    event.preventDefault();

    dispatch({
      type: actionTypes.BIRTHDATE,
      payload: event.target.value,
    });
  };

  const docChangeHandler = (event: InputChangeEvent) => {
    event.preventDefault();

    if (doc === "identityNumber") {
      dispatch({
        type: actionTypes.IDENTITYNUMBER,
        payload: event.target.value,
      });
    } else {
      dispatch({
        type: actionTypes.PASSPORTNUMBER,
        payload: event.target.value,
      });
    }
  };

  const formValid =
    state.firstName.valid &&
    state.lastName.valid &&
    state.email.valid &&
    state.birthDate.valid &&
    state[doc].valid &&
    state.recaptcha;

  const continueClickHandler = () => {
    if (formValid) {
      accountContinueClick();
    } else {
      toast.error("Please check that are the fields are filled in correctly.");
    }
  };

  useEffect(() => {
    if (doc === "identityNumber") {
      dispatch({ type: actionTypes.PASSPORTNUMBER, payload: "" });
    } else {
      dispatch({ type: actionTypes.IDENTITYNUMBER, payload: "" });
    }
  }, [doc, dispatch]);

  return (
    <>
      <BaseInput
        required
        id="register-firstName-input"
        inputLabel="First name"
        autoComplete="given-name"
        placeholder="First name"
        value={state.firstName.value}
        error={!state.firstName.valid}
        helperText={state.firstName.error}
        onChange={firstNameChangeHandler}
      />
      <BaseInput
        required
        id="register-lastName-input"
        inputLabel="Last name"
        autoComplete="family-name"
        placeholder="Last name"
        value={state.lastName.value}
        error={!state.lastName.valid}
        helperText={state.lastName.error}
        onChange={lastNameChangeHandler}
      />
      <BaseInput
        required
        id="register-email-input"
        inputLabel="Email"
        autoComplete="email"
        placeholder="Email"
        value={state.email.value}
        error={!state.email.valid}
        helperText={state.email.error}
        onChange={emailChangeHandler}
      />
      <BaseInput
        required
        id="register-birthday-input"
        type="date"
        inputLabel="Date of birth"
        autoComplete="birthday"
        value={state.birthDate.value}
        error={!state.birthDate.valid}
        helperText={state.birthDate.error}
        onChange={birthDateChangeHandler}
      />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        // sx={{ backgroundColor: "blue" }}
      >
        <CustomSwitch
          label="RSA"
          checked={doc === "identityNumber"}
          onChange={() => setDoc("identityNumber")}
        />
        <CustomSwitch
          label="Passport"
          checked={doc === "passportNumber"}
          onChange={() => setDoc("passportNumber")}
        />
      </Box>
      <BaseInput
        required
        id="register-doc-input"
        inputLabel={doc === "identityNumber" ? "ID number" : "Passport number"}
        value={state[doc].value}
        error={!state[doc].valid}
        helperText={state[doc].error}
        onChange={docChangeHandler}
        inputProps={{ inputMode: "numeric" }}
      />
      <ReCAPTCHA
        sitekey={env.REACT_APP_RECAPTCHA_SITE_KEY}
        onChange={(value) =>
          dispatch({ type: actionTypes.RECAPTCHA, payload: value ?? "" })
        }
      />
      <PrimaryButton
        loading={loading}
        fullWidth
        fontSize={16}
        onClick={continueClickHandler}
        disabled={!formValid}
      >
        Continue
      </PrimaryButton>
    </>
  );
};

export default AccountDetails;
