/**
 * Takes a url path and splits it into substrings by '/'.
 *
 * @param path - the url path to split
 * @returns The path variables in an array, with empty values removed
 */
export const splitPath = (path: string) => {
  const pathVars = path.split("/");
  const cleanPathVars = pathVars.filter((pathVar) => pathVar !== "");
  return cleanPathVars;
};
