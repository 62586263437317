import { Box, BoxProps, styled } from "@mui/material";

import appConfig from "src/config/appConfig";

interface FooterWrapperProps extends BoxProps {
  addPadding: boolean;
}

export const FooterWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "addPadding",
})<FooterWrapperProps>(({ theme, addPadding }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(3.125),
  padding: theme.spacing(2.5, 1.25),

  [theme.breakpoints.down(appConfig.breakMobileView)]: {
    paddingBottom: addPadding ? `calc(${theme.betSlip.height} + 8px)` : "none",
  },

  backgroundColor: theme.colors.neutral.black[100],
}));

export const ListWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1.875),

  backgroundColor: "none",
  background: "none",
}));
