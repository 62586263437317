import { FC } from "react";
import { SvgIcon } from "@mui/material";

const MenuIcon: FC = () => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        width="23"
        height="19"
        viewBox="0 0 23 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.13159 0.981506H11.6316H22.1316M1.13159 9.50002H22.1316M1.13159 18.0185H22.1316"
          stroke="#CCB76C"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default MenuIcon;
