import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "src/store/store";
import {
  BetSlipMode,
  MultiModeStake,
  SportBetSlipState,
} from "../betSlipSlice.contracts";
import { SportBet, SportBetLeg } from "../../models/sport";

import { Error, ErrorBet } from "../../models/ErrorObject";

import { getBetSlipsFromStorage, getBetsFromStorage } from "../initialBetSlips";

import {
  calculateBetPayout,
  singleModeCalc,
} from "../../utils/payoutAndStakeCalc";

const { sportBets } = getBetsFromStorage();
const { betSlipMode, sportBetSlip } = getBetSlipsFromStorage();

const initialState: SportBetSlipState = {
  sportBets: {
    bets: sportBets,
    expandedBets: [],
    errors: [],
    multiModeStakes: {
      doublesStake: 0,
      multipleStake: 0,
      quadsStake: 0,
      singlesStake: 0,
      treblesStake: 0,
    },
  },
  betSlip: {
    betSlip: sportBetSlip,
    errors: [],
    includeSingleBets: false,
    includeSingleBetsStake: 10,
    mode: betSlipMode,
    validate: false,
  },
};

const sportBetSlipSlice = createSlice({
  name: "sportBetSlip",
  initialState,
  reducers: {
    //#region Sport bets
    addSportBet(state, action: PayloadAction<{ bet: SportBet }>) {
      const { bet } = action.payload;
      state.sportBets.bets.push(bet);
    },
    clearSportBets(state) {
      state.sportBets.bets = [];
    },
    removeSportBet(state, action: PayloadAction<{ betIds: string[] }>) {
      const { betIds } = action.payload;
      const updatedBets = state.sportBets.bets.filter(
        (bet) => !betIds.includes(bet.id)
      );
      state.sportBets.bets = updatedBets;

      if (updatedBets.length === 1) {
        sportBetSlipSlice.caseReducers.toggleBetSlipMode(state, {
          payload: { mode: "singles" },
          type: "betSlipSlice/toggleBetSlipMode",
        });
      }
    },
    setSportBets(state, action: PayloadAction<{ bets: SportBet[] }>) {
      const { bets } = action.payload;

      state.sportBets.bets = bets;
    },
    updateSportBetStake(
      state,
      action: PayloadAction<{ betId: string[]; stake: number }>
    ) {
      const { betId, stake } = action.payload;

      const updatedBets = state.sportBets.bets.map((bet) => {
        if (betId.includes(bet.id)) {
          const betLegPrices = bet.legs.map((leg) => leg.price);
          const { payout, price } = calculateBetPayout(stake, betLegPrices);

          const newBet: SportBet = {
            ...bet,
            payout,
            price,
            stake,
          };
          return newBet;
        } else {
          return bet;
        }
      });

      state.sportBets.bets = updatedBets;
    },
    updateSportBetPrice(
      state,
      action: PayloadAction<{
        betId: string;
        legIndex: number;
        newPrice: number;
      }>
    ) {
      const { betId, legIndex, newPrice } = action.payload;
      const updatedBets = state.sportBets.bets.map((bet) => {
        if (bet.id === betId) {
          const currentLeg = bet.legs[legIndex];
          const newLeg: SportBetLeg = { ...currentLeg, price: newPrice };
          const { payout, price } = calculateBetPayout(bet.stake, [newPrice]);

          const newBet: SportBet = {
            ...bet,
            legs: [newLeg],
            payout,
            price,
          };
          return newBet;
        } else {
          return bet;
        }
      });

      state.sportBets.bets = updatedBets;
    },
    addSportBetErrors(state, action: PayloadAction<{ errors: ErrorBet[] }>) {
      const { errors } = action.payload;
      state.sportBets.errors = errors;
    },
    clearSportBetErrors(state) {
      state.sportBets.errors = [];
    },
    //#endregion Sport bets
    //#region Expanded Sport Bets
    setExpandedBets(state, action: PayloadAction<{ bets: SportBet[] }>) {
      const { bets } = action.payload;
      state.sportBets.expandedBets = bets;

      // if (bets.length === 1) {
      //   sportBetSlipSlice.caseReducers.toggleBetSlipMode(state, {
      //     payload: { mode: "singles" },
      //     type: "betSlipSlice/toggleBetSlipMode",
      //   });
      // }
    },
    updateExpandedSportBetStake(
      state,
      action: PayloadAction<{ betId: string[]; stake: number }>
    ) {
      const { betId, stake } = action.payload;

      const updatedBets = state.sportBets.expandedBets.map((bet) => {
        if (betId.includes(bet.id)) {
          const betLegPrices = bet.legs.map((leg) => leg.price);
          const { payout, price } = calculateBetPayout(stake, betLegPrices);

          const newBet: SportBet = {
            ...bet,
            payout,
            price,
            stake,
          };
          return newBet;
        } else {
          return bet;
        }
      });

      state.sportBets.expandedBets = updatedBets;
    },
    updateMultiModeStake(
      state,
      action: PayloadAction<{ stake: MultiModeStake; amount: number }>
    ) {
      const { stake, amount } = action.payload;

      switch (stake) {
        case "double": {
          state.sportBets.multiModeStakes.doublesStake = amount;
          break;
        }
        case "multi": {
          state.sportBets.multiModeStakes.multipleStake = amount;
          sportBetSlipSlice.caseReducers.updateExpandedSportBetStake(state, {
            payload: { betId: ["multi"], stake: amount },
            type: "betSlipSlice/updateSportBetStake",
          });
          break;
        }
        case "quad": {
          state.sportBets.multiModeStakes.quadsStake = amount;
          break;
        }
        case "treble": {
          state.sportBets.multiModeStakes.treblesStake = amount;
          break;
        }
        default: {
          state.sportBets.multiModeStakes.singlesStake = amount;
          const sportBetIds = state.sportBets.bets.map((bet) => bet.id);
          sportBetSlipSlice.caseReducers.updateSportBetStake(state, {
            payload: { betId: sportBetIds, stake: amount },
            type: "betSlipSlice/updateSportBetStake",
          });
          break;
        }
      }
    },
    //#endregion Expanded Sport Bets
    //#region Sport betslip
    updateSportBetSlipBets(
      state,
      action: PayloadAction<{ newBets: SportBet[] }>
    ) {
      const { newBets } = action.payload;
      // remove bets which have a stake of 0
      const betSlipBets = newBets.filter((bet) => bet.stake > 0);

      const { totalPayout, totalStake } = singleModeCalc(betSlipBets);

      state.betSlip.betSlip = {
        ...state.betSlip.betSlip,
        bets: betSlipBets,
        totalPayout,
        totalStake,
      };
    },
    addBetSlipErrors(state, action: PayloadAction<{ errors: Error[] }>) {
      const { errors } = action.payload;
      state.betSlip.errors = errors;
    },
    clearBetSlipErrors(state) {
      state.betSlip.errors = [];
    },
    toggleBetSlipMode(state, action: PayloadAction<{ mode: BetSlipMode }>) {
      const { mode } = action.payload;
      state.betSlip.mode = mode;

      const sportBetIds = state.sportBets.bets.map((bet) => bet.id);

      if (mode === "singles") {
        sportBetSlipSlice.caseReducers.updateExpandedSportBetStake(state, {
          payload: {
            betId: ["multi"],
            stake: 0,
          },
          type: "betSlip/updateSportBetStake",
        });

        sportBetSlipSlice.caseReducers.updateSportBetStake(state, {
          payload: {
            betId: sportBetIds,
            stake: state.sportBets.multiModeStakes.singlesStake,
          },
          type: "betSlip/updateSportBetStake",
        });
      } else {
        sportBetSlipSlice.caseReducers.updateExpandedSportBetStake(state, {
          payload: {
            betId: ["multi"],
            stake: state.sportBets.multiModeStakes.multipleStake,
          },
          type: "betSlip/updateSportBetStake",
        });

        sportBetSlipSlice.caseReducers.updateSportBetStake(state, {
          payload: {
            betId: sportBetIds,
            stake: 0,
          },
          type: "betSlip/updateSportBetStake",
        });
      }
    },
    toggleValidate(state, action: PayloadAction<{ validate: boolean }>) {
      const { validate } = action.payload;
      state.betSlip.validate = validate;
    },
    //#endregion Sport betslip
  },
});

export default sportBetSlipSlice.reducer;
export const {
  addBetSlipErrors,
  addSportBet,
  addSportBetErrors,
  clearSportBets,
  clearSportBetErrors,
  removeSportBet,
  setExpandedBets,
  setSportBets,
  toggleBetSlipMode,
  toggleValidate,
  updateMultiModeStake,
  updateSportBetSlipBets,
  updateSportBetStake,
  updateSportBetPrice,
} = sportBetSlipSlice.actions;

/* Sport bets */
export const selectSportBets = (state: RootState) =>
  state.sportBetSlip.sportBets.bets;
export const selectSportBetsErrors = (state: RootState) =>
  state.sportBetSlip.sportBets.errors;
export const selectExpandedSportBets = (state: RootState) =>
  state.sportBetSlip.sportBets.expandedBets;
export const selectMultiModeStakes = (state: RootState) =>
  state.sportBetSlip.sportBets.multiModeStakes;

/* Sport betslip */
export const selectSportBetSlip = (state: RootState) =>
  state.sportBetSlip.betSlip.betSlip;
export const selectIncludeSingleBets = (state: RootState) =>
  state.sportBetSlip.betSlip.includeSingleBets;
export const selectIncludeSingleBetsStake = (state: RootState) =>
  state.sportBetSlip.betSlip.includeSingleBetsStake;
export const selectSportBetSlipMode = (state: RootState) =>
  state.sportBetSlip.betSlip.mode;
export const selectSportBetSlipValidate = (state: RootState) =>
  state.sportBetSlip.betSlip.validate;
