import { FC, useCallback, useMemo } from "react";
import { Box, IconButton, Drawer, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

import { SidebarWrapper } from "./Sidebar.styles";
import Scrollbar from "src/components/Scrollbar";
import VersionNumber from "src/components/version-number/VersionNumber";
import SidebarDivider from "./SidebarDivider";
import SidebarMenu, { ISidebarMenuItem } from "./SidebarMenu";
import TopbetLogo from "../Logo";

import { useAuth } from "src/features/authentication";
import { useSidebarContext } from "src/context/SidebarContext";

import appConfig from "src/config/appConfig";
import {
  AuthDialogType,
  useAuthDailogStore,
} from "src/store/authentication/authStore";

const menuItems1: ISidebarMenuItem[] = [
  {
    label: "Lucky Numbers",
    path: "/luckyNumbers/all",
  },
  {
    label: "Aviator",
    path: "/aviator",
  },
  {
    label: "Bet Games",
    path: "/betGames",
  },
  {
    label: "Sports",
    path: "/sports/all",
  },
  {
    label: "Slots",
    path: "/slots/habanero",
  },
  {
    label: "Live Casino",
    path: "/liveCasino/evolution",
  },
  {
    label: "Split The Pot",
    path: "/splitThePot",
  },
  {
    label: "Promotions",
    path: "/promotions/special",
  },
];

const menuItems2: ISidebarMenuItem[] = [
  {
    label: "Deposit",
    path: "/account/deposit",
  },
  {
    label: "Withdraw",
    path: "/account/withdraw",
  },
];

const MainSidebar: FC = () => {
  const { sidebarToggle, closeSidebar } = useSidebarContext();
  const { isAuthenticated, logout } = useAuth();
  const { pathname } = useLocation();
  const theme = useTheme();
  const setAuthDisplayType = useAuthDailogStore(
    (state) => state.setDisplayType
  );
  const logoutHandler = useCallback(() => {
    logout();
    closeSidebar();
  }, [closeSidebar, logout]);

  const menuItems2Memo = useMemo(() => {
    let newMenuItems = [...menuItems2];

    if (isAuthenticated) {
      newMenuItems.push({
        label: "Logout",
        path: pathname,
        onClick: logoutHandler,
      });
    } else {
      newMenuItems = [
        {
          label: "Login",
          path: pathname,
          onClick: () => setAuthDisplayType(AuthDialogType.Login),
        },
      ];
    }

    return newMenuItems;
  }, [isAuthenticated, pathname, logoutHandler, setAuthDisplayType]);

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: "none",
            [appConfig.breakMobileView]: "inline-block",
          },
          position: "fixed",
          left: 0,
          top: 0,
        }}
      >
        <Scrollbar>
          <Box
            height={theme.header.height}
            sx={{
              backgroundColor: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TopbetLogo visible height="92%" />
          </Box>
          <SidebarDivider />
          <SidebarMenu items={menuItems1} onClick={closeSidebar} />
          <SidebarDivider />
          <SidebarMenu items={menuItems2Memo} onClick={closeSidebar} />
          <VersionNumber />
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: theme.sidebar.boxShadow,
        }}
        anchor="left"
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <IconButton
            sx={{
              position: "absolute",
              top: theme => theme.header.paddingTop,
              right: 4,
              zIndex: 1000,
            }}
            onClick={closeSidebar}
          >
            <Close
              fontSize="medium"
              sx={{
                color: theme.colors.text.light,
              }}
            />
          </IconButton>
          <Scrollbar>
            <SidebarMenu items={menuItems1} onClick={closeSidebar} />
            <SidebarDivider />
            <SidebarMenu items={menuItems2Memo} onClick={closeSidebar} />
            <VersionNumber />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
};

export default MainSidebar;
