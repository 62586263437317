import { CompetitorMap } from "../models/CompetitorMap";

import { DateTimeNow, isAfter } from "src/utils/dateHelper";

export function getSelectionTitle(name: string, competitors: CompetitorMap[]) {
  const competitorTitle = competitors.find(
    (competitor) => competitor.name === name
  );

  if (competitorTitle) {
    return competitorTitle.title;
  }

  if (name === "draw") {
    return "X";
  }

  return name;
}

export function isBetExpired(marketCloseDate: string) {
  const now = DateTimeNow();

  return isAfter(marketCloseDate, now);
}
