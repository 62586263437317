import { CssBaseline } from "@mui/material";
import * as Sentry from "@sentry/react";
import { wrapUseRoutes } from "@sentry/react";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import router from "src/router/router";

import defineAbilityFor from "./config/ability";
import { sentryConfig } from "./config/sentry";

import { AbilityContext } from "./context/canContext";
import Status500 from "./pages/Fallbacks/Status/Status500/Status500";
import { AuthProvider } from "./features/authentication";
import { ThemeProviderWrapper } from "./features/appTheme";
import useAffiliate from "./hooks/useAffiliate";


Sentry.init(sentryConfig);
const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
  const content = useSentryRoutes(router);
  const ability = defineAbilityFor("superAdmin"); //TODO: the user role should be added here
  useAffiliate();

  return (
    <AuthProvider>
      <ThemeProviderWrapper>
        <Sentry.ErrorBoundary
          fallback={
            <Status500 resetErrorBoundary={() => window.location.reload()} />
          }
        >
          <AbilityContext.Provider value={ability}>
            <CssBaseline />
            <ToastContainer style={{ marginTop: '80px' }} />
            {content}
          </AbilityContext.Provider>
        </Sentry.ErrorBoundary>
      </ThemeProviderWrapper>
    </AuthProvider>
  );
}

export default App;
