import { baseApi, prodApi } from "./baseApi.service";
import { Endpoint, TagId } from "./config/apiTags";

import { IncomeSource } from "src/features/fica";

const ficaBaseApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getIncomeSources: builder.query<IncomeSource[], void>({
      query: () => {
        return {
          url: Endpoint.INCOME_SOURCES,
          method: "GET",
          headers: { Wallet: "Turfsport" },
        };
      },
    }),
    setIncomeSource: builder.mutation<void, { id: number }>({
      query: ({ id }) => {
        return {
          url: Endpoint.INCOME_SOURCES,
          method: "PUT",
          body: { id, declaredAsCorrect: true },
          headers: { Wallet: "Turfsport" },
        };
      },
    }),
    setFicaStatus: builder.mutation<void, { status: string }>({
      query: (payload) => {
        return {
          url: Endpoint.FICA,
          method: "PUT",
          body: payload,
          headers: { Wallet: "Turfsport" },
        };
      },
      invalidatesTags: [{ type: Endpoint.MY_ACCOUNT, id: TagId.LIST }],
    }),
  }),
});

export const {
  useGetIncomeSourcesQuery,
  useLazyGetIncomeSourcesQuery,
  useSetIncomeSourceMutation,
  useSetFicaStatusMutation,
} = ficaBaseApi;

type UploadDocResponse = {
  url: string;
  contentType: string;
};

type UploadDocPayload = {
  fileName: string;
};

const ficaProdApi = prodApi.injectEndpoints({
  endpoints: (builder) => ({
    getFicaDocs: builder.query<{ urls: string[] }, void>({
      query: () => {
        return {
          url: "punter-docs/view-url",
          method: "GET",
        };
      },
    }),
    getIdDocURL: builder.query<UploadDocResponse, UploadDocPayload>({
      query: ({ fileName }) => {
        const url =
          "punter-docs/upload-url?doc=Proof-of-ID---Passport&filename=" +
          encodeURI(fileName);
        return {
          url,
          method: "GET",
        };
      },
    }),
    getPoADocURL: builder.query<UploadDocResponse, UploadDocPayload>({
      query: ({ fileName }) => {
        const url =
          "punter-docs/upload-url?doc=Proof-of-Address&filename=" +
          encodeURI(fileName);
        return {
          url,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetFicaDocsQuery,
  useLazyGetFicaDocsQuery,
  useGetIdDocURLQuery,
  useLazyGetIdDocURLQuery,
  useGetPoADocURLQuery,
  useLazyGetPoADocURLQuery,
} = ficaProdApi;
