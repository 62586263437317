import { FC, Suspense } from "react";

import SuspenseLoader from "../SuspenseLoader/SuspenseLoader";

const Loader =
  <T extends JSX.IntrinsicAttributes>(Component: FC<T>) =>
  (props: T) =>
    (
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    );

export default Loader;
