import { Box, BoxProps, styled } from "@mui/material";

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const Tab = styled(Box)<BoxProps>(({ theme }) => ({
  height: "68px",
  minHeight: "68px",
  maxWidth: "68px",
  minWidth: "68px",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(0.625),
  padding: theme.spacing(1),

  opacity: 1,
  backgroundColor: theme.colors.neutral.black[100],
  borderRadius: theme.spacing(1.25),

  color: theme.colors.text.light,
  fontSize: "10px",
  fontWeight: 700,
  textAlign: "center",
}));
