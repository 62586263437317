import { FC } from "react";
import { Box } from "@mui/material";

import { BetSlipWrapper } from "./BetSlip.styles";
import BetModeToggle from "./betModeToggle/BetModeToggle";
import SportBetItems from "./betItems/SportBetItems";
import MultiBetItem from "./multiBet/SportMultiBet";
import SelectionRetainer from "./selectionRetainer/SelectionRetainer";
import BetDetails from "./betDetails/BetDetails";
import { SubmitButton } from "src/components/buttons";

import { useSportBetSlip, useValidateBetSlip } from "./SportBetSlip.hooks";
import { useHandleErrors } from "../hooks/betSlipErrors";
import { usePlaceBetSlip } from "../hooks/placeBetSlip";

import { sportBetSlipMapper } from "../mappers/sportBetSlipMapper";

const SportBetSlip: FC = () => {
  const { sportBetSlip, clearBets } = useSportBetSlip();
  const handleErrors = useHandleErrors();
  const { isLoading, placeBetsHandler } = usePlaceBetSlip({
    clearBets,
    handleErrors,
  });
  useValidateBetSlip();

  function placeBets() {
    placeBetsHandler(sportBetSlipMapper(sportBetSlip));
  }

  return (
    <BetSlipWrapper>
      <BetModeToggle />
      <Box
        sx={{
          flexGrow: 1,
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <SportBetItems />
        <MultiBetItem />
      </Box>
      <SelectionRetainer clearBetSlip={clearBets} retainSelection />
      <BetDetails
        betsLength={sportBetSlip.bets.length}
        totalPayout={sportBetSlip.totalPayout}
        totalStake={sportBetSlip.totalStake}
      />
      <Box
        sx={{
          paddingBottom: (theme) => theme.spacing(1),
          paddingX: (theme) => theme.spacing(1),
        }}
      >
        <SubmitButton
          disabled={sportBetSlip.bets.length === 0}
          fontSize={14}
          fullWidth
          loading={isLoading}
          onClick={placeBets}
        >
          SUBMIT
        </SubmitButton>
      </Box>
    </BetSlipWrapper>
  );
};

export default SportBetSlip;
