import dayjs from "dayjs";

export default function calculatePeriodValue(period: string) {
  const end = dayjs().endOf("date");

  switch (period.toLocaleLowerCase().trim().replace(/\s/g, "")) {
    case "today": {
      const start = dayjs().startOf("date");
      return { start: formatDate(start), end: formatDate(end) };
    }
    case "week": {
      const start = end.add(-7, "days").startOf("date");
      return { start: formatDate(start), end: formatDate(end) };
    }
    case "month": {
      const start = end.add(-1, "month").startOf("date");
      return { start: formatDate(start), end: formatDate(end) };
    }
    case "year": {
      const start = end.add(-1, "year").startOf("date");
      return { start: formatDate(start), end: formatDate(end) };
    }
    default: {
      const start = dayjs().startOf("date");
      return { start: formatDate(start), end: formatDate(end) };
    }
  }
}

const formatDate = (date: dayjs.Dayjs) => {
  return dayjs(date).toISOString();
};
