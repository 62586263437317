import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { FC, useState } from "react";

import HelpModal from "./HelpModal";
import {
  StyledBox,
  StyledListItemButton,
  StyledListItemText,
} from "./FooterListItem.styles";

export type FooterListItemProps = {
  title: string;
  content: {
    label: string;
    name: string;
  }[];
};

const FooterListItem: FC<FooterListItemProps> = ({ title, content }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [helpFileName, setHelpFileName] = useState<string>();

  const toggleCollapse = () => {
    setOpen((prevState) => !prevState);
  };

  const handleOpen = (name: string) => {
    setHelpFileName(name);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setHelpFileName(undefined);
  };

  return (
    <>
      {dialogOpen && helpFileName && (
        <HelpModal
          fileName={helpFileName}
          open={dialogOpen}
          handleClose={handleClose}
        />
      )}
      <StyledBox sx={{ paddingY: "4px" }}>
        <StyledListItemButton disableRipple onClick={toggleCollapse}>
          <StyledListItemText>{title}</StyledListItemText>
          {open ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          )}
        </StyledListItemButton>
        <Collapse
          sx={{ paddingX: "15px" }}
          in={open}
          timeout="auto"
          unmountOnExit
        >
          {content.map((item, index) => (
            <StyledListItemButton
              key={index}
              disableRipple
              onClick={() => handleOpen(item.name)}
            >
              <StyledListItemText>{item.label}</StyledListItemText>
            </StyledListItemButton>
          ))}
        </Collapse>
      </StyledBox>
    </>
  );
};

export default FooterListItem;
