import { Box, BoxProps, alpha, lighten, styled } from "@mui/material";

import appConfig from "src/config/appConfig";

export const LayoutWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  height: "100vh",

  ".MuiPageTitle-wrapper": {
    background:
      theme.palette.mode === "dark"
        ? theme.colors.neutral.white[10]
        : theme.colors.neutral.white[60],
    marginBottom: `${theme.spacing(4)}`,
    boxShadow:
      theme.palette.mode === "dark"
        ? `0 1px 0 ${alpha(
          lighten(theme.colors.primary.main, 0.7),
          0.15
        )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
        : `0px 2px 4px -3px ${alpha(
          theme.colors.neutral.black[100],
          0.1
        )}, 0px 5px 12px -4px ${alpha(
          theme.colors.neutral.black[100],
          0.05
        )}`,
  },
}));

interface ContentWrapperProps extends BoxProps {
  showBetSlip: boolean;
}

export const ContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "showBetSlip",
})<ContentWrapperProps>(({ theme, showBetSlip }) => ({
  zIndex: 5,

  height: "100vh",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
  paddingTop: `${theme.header.height}`,

  [theme.breakpoints.up(appConfig.breakMobileView)]: {
    marginLeft: theme.sidebar.width,
  },

  [theme.breakpoints.up("md")]: {
    marginRight: showBetSlip ? theme.betSlip.width : 0,
  },
}));

export const OutletWrapper = styled(Box)<BoxProps>(() => ({
  flexGrow: 1,
}));
