import { useState } from "react";
import { InputAdornment, Select, SelectChangeEvent } from "@mui/material";
import CountryList from "country-list-with-dial-code-and-flag";
import { MenuItem } from "@mui/material";
import { FormControl } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import { StyledInput } from "./CountryCode.styles";

type CountryCodeProps = {
  onChange: (value: string) => void;
  error?: boolean;
  helperText?: string;
  initialDialCode: string;
};

const CountryCode = ({
  onChange,
  error,
  helperText,
  initialDialCode,
}: CountryCodeProps) => {
  const [country, setCountry] = useState(
    CountryList.findOneByDialCode(initialDialCode)?.dial_code || ""
  );
  const countries = CountryList.getAll();

  const handleChange = (event: SelectChangeEvent<string>) => {
    setCountry(event.target.value);
    onChange(event.target.value);
  };

  return (
    <InputAdornment position="start">
      <FormControl error={error}>
        <Select
          labelId="country-code"
          id="country-code"
          input={<StyledInput />}
          IconComponent={ExpandMore}
          value={country}
          onChange={handleChange}
        >
          {countries.map((country) => {
            const source = `https://flagcdn.com/w20/${country.code.toLowerCase()}.png`;
            const sourceSet = source + " 2x";

            return (
              <MenuItem
                key={`${country.dial_code}${country.name}`}
                value={country.dial_code}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={source}
                  srcSet={sourceSet}
                  alt=""
                />

                {country.dial_code}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </InputAdornment>
  );
};

export default CountryCode;
