import { baseApi } from "./baseApi.service";
import { Endpoint, TagId } from "./config/apiTags";

import { Wallet } from "src/features/wallet";

type Response = {
  items: Wallet[];
};

const walletApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getWallets: builder.query<Response, void>({
      query: () => {
        return {
          url: Endpoint.WALLETS,
          method: "GET",
          headers: { Wallet: "Turfsport" },
        };
      },
      providesTags: [{ type: Endpoint.WALLETS, id: TagId.LIST }],
    }),
  }),
});

export const { useGetWalletsQuery, useLazyGetWalletsQuery } = walletApi;
