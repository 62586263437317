import { FC } from "react";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import { Header, TitleWrapper } from "./Title.styles";
import { Icon } from "src/components/icons";

type TitleProps = {
  title: string;
  onCloseHandler: () => void;
};

const Title: FC<TitleProps> = ({ title, onCloseHandler }) => {
  return (
    <Header>
      <TitleWrapper>
        <Icon name="FilterDark" />
        {title}
      </TitleWrapper>
      <IconButton onClick={onCloseHandler}>
        <Close color="secondary" />
      </IconButton>
    </Header>
  );
};

export default Title;
