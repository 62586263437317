import { FC } from "react";

import BookmakerLicense from "./BookMakerLicense";
import HabaneroPayTable from "./HabaneroPayTable";
import Promotions from "./Promotions";
import TermsAndConditions from "./TermsAndConditions";

export const HTMLFiles: string[] = [
  "Bookmaker License",
  "HABANERO PAY-TABLE",
  "Legal",
  "Promotions",
];

type RenderHTMLProps = {
  fileName: string;
};

const RenderHTML: FC<RenderHTMLProps> = ({ fileName }) => {
  return (
    <div style={{ padding: "15px" }}>
      {fileName === "Bookmaker License" ? <BookmakerLicense /> : null}
      {fileName === "HABANERO PAY-TABLE" ? <HabaneroPayTable /> : null}
      {fileName === "Legal" ? <TermsAndConditions /> : null}
      {fileName === "Promotions" ? <Promotions /> : null}
    </div>
  );
};

export default RenderHTML;
