import {
  ToggleButton,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const StyledButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root": {
    backgroundColor: "#383838",
    borderRadius: 0,
    padding: theme.spacing(0.7),
    textTransform: "capitalize",
    color: theme.colors.secondary.main,
  },

  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.colors.secondary.main,
    color: theme.colors.neutral.black[100],
  },
}));

export const StyledText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 16,
  fontweight: 700,
}));
