import { FC } from "react";

const HabaneroPayTable: FC = () => {
  return (
    <>
      <h1>
        <a
          href="https://new.topbet.co.za/assets/content/Habanero Paytable-d2d0b3d4.pdf"
          style={{ color: "#f66e22" }}
          target="_blank"
          rel="noreferrer"
        >
          Click here for Habanero Pay Table
        </a>
      </h1>
    </>
  );
};

export default HabaneroPayTable;
