import { LottoBet } from "../models/lotto";
import { SportBet } from "../models/sport";

import { BetSlipMode } from "../store/betSlipSlice.contracts";

/**
 * Updates the payout of a lotto bet when the stake changes
 * @param bet - lotto bet to calculate new
 * @returns The provided bet with the updated payout value
 */
export const updateLottoPayout = (bet: LottoBet) => {
  const payout = bet.legs[0].price * bet.stake;

  const updatedBet: LottoBet = {
    ...bet,
    payout,
  };

  return updatedBet;
};

/**
 * Calculates the total payout and total stake of the current bet slip
 * @param bets - bets in lotto bet slip
 * @returns The total payout and total stake of the current bet slip
 */
export const calculateLotto = (bets: LottoBet[]) => {
  let totalPayout = 0;
  let totalStake = 0;

  bets.forEach((bet) => {
    totalPayout += bet.payout;
    totalStake += bet.stake;
  });

  return { totalPayout, totalStake };
};

/**
 * Calculates the total payout and total stake of the current bet slip in single mode
 * @param bets - bets in sport bet slip
 * @returns The total payout and total stake of the current bet slip
 */
export const singleModeCalc = (bets: SportBet[]) => {
  let totalPayout = 0;
  let totalStake = 0;

  bets.forEach((bet) => {
    totalPayout += bet.payout;
    totalStake += bet.stake;
  });

  return { totalPayout, totalStake };
};

/**
 * Calculates the total payout and total stake of the current bet slip in multiple mode
 * @param bet - multi-bet in sport bet slip
 * @returns The total payout and total stake of the current bet slip
 */
export const multiModeCalc = (bet: SportBet) => {
  const totalStake = bet.stake;
  let totalPrice = 0;

  bet.legs.forEach((leg) => {
    if (totalPrice !== 0) {
      totalPrice *= leg.price;
    } else {
      totalPrice = leg.price;
    }
  });

  const totalPayout = Math.ceil(totalPrice * totalStake * 10) / 10;

  return { totalPayout, totalPrice, totalStake };
};

/**
 * Calculates the total payout and total stake of the current bet slip
 * @param bets - bets in sport bet slip
 * @param mode - current mode of the sport bet slip
 * @returns The total payout and total stake of the current bet slip
 */
export const calculateSport = (
  bets: SportBet[],
  mode: BetSlipMode,
  includeSingleBets: boolean
) => {
  if (mode === "singles") {
    return singleModeCalc(bets);
  } else {
    if (includeSingleBets) {
      const { totalPayout: multiPayout, totalStake: multiStake } =
        multiModeCalc(bets[bets.length - 1]);
      const { totalPayout: singlePayout, totalStake: singleStake } =
        singleModeCalc(bets.slice(0, -1));

      return {
        totalPayout: multiPayout + singlePayout,
        totalStake: multiStake + singleStake,
      };
    } else {
      return multiModeCalc(bets[bets.length - 1]);
    }
  }
};

/**
 * Calculates the payout of a bet
 * @param stake - new bet stake
 * @param prices - an array of bet prices
 * @param percent - optional percentage increase on the payout
 * @returns The bet payout
 */
export const calculateBetPayout = (
  stake: number,
  prices: number[],
  percent: number = 1
) => {
  let finalPrice = prices[0];

  if (prices.length > 1) {
    finalPrice = prices.reduce((prevVal, currVal) => {
      return prevVal * currVal;
    }, 1);
  }

  const payout = finalPrice * stake;
  const roundedPayout = Math.ceil(payout * percent * 10) / 10;

  return { payout: roundedPayout, price: finalPrice };
};
