import { FC, PropsWithChildren, Suspense, useEffect } from "react";
import { toast } from "react-toastify";

import SuspenseLoader from "src/components/SuspenseLoader/SuspenseLoader";

import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { IUserSlice } from "src/store/user/userSlice.contracts";

import { useAuth } from "src/features/authentication";
import { persistAuth } from "src/features/authentication/utils/persistAuth";
import logger from "src/utils/logger";
import { AuthDialogType, useAuthDailogStore } from "src/store/authentication/authStore";

const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const userSlice = useAppSelector((state) => state.user);
  const setAuthDisplayType = useAuthDailogStore((state) => state.setDisplayType);
  const {
    loading,
    error,
    user,
    isAuthenticated,
    getTokens,
    setIsGuardedHandler,
  } = useAuth();

  useEffect(() => {
    const prep = async () => {
      try {
        const tokens = await getTokens();
        const userSlice: IUserSlice = {
          user: user,
          status: "authenticated",
          accessToken: tokens?.accessToken ?? null,
          refreshToken: tokens?.refreshToken ?? null,
          error: null,
        };
        dispatch(persistAuth({ userAuth: userSlice }));
      } catch (error) {
        logger.error(error);
        toast.error(
          "There has been an error getting authentication details. Please try logging in"
        );
        setIsGuardedHandler(true);
        setAuthDisplayType(AuthDialogType.Login);
      }
    };

    if (!loading && user) {
      prep();
    }

    if (error) {
      logger.error(error);
      toast.error(
        "There has been an error getting authentication details. Please try logging in"
      );
      setIsGuardedHandler(true);
      setAuthDisplayType(AuthDialogType.Login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading, error]);

  useEffect(() => {
    if (!isAuthenticated && !user && !loading) {
      if (!userSlice || !userSlice?.user) {
        setIsGuardedHandler(true);
        setAuthDisplayType(AuthDialogType.Login);
      }
    }
  }, [
    isAuthenticated,
    loading,
    setIsGuardedHandler,
    setAuthDisplayType,
    user,
    userSlice,
  ]);

  return <Suspense fallback={<SuspenseLoader />}>{children}</Suspense>;
};

export default AuthGuard;
