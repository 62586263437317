import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
  clearSportBets,
  selectSportBetSlip,
  selectSportBetSlipValidate,
  toggleBetSlipMode,
  toggleValidate,
} from "../store/sport/sportBetSlipSlice";
import { selectCurrentWallet } from "src/features/wallet";

import { useAuth } from "src/features/authentication";
import { useHandleErrors } from "../hooks/betSlipErrors";

import {
  usePlaceBetsMutation,
  useValidateBetsMutation,
} from "src/services/restApi/queries/betSlip.service";

import { sportBetSlipMapper } from "../mappers/sportBetSlipMapper";

import { isErrorData } from "../models/ErrorObject";

import logger from "src/utils/logger";
import {
  AuthDialogType,
  useAuthDailogStore,
} from "src/store/authentication/authStore";

export const useSportBetSlip = () => {
  const dispatch = useAppDispatch();
  const sportBetSlip = useAppSelector(selectSportBetSlip);

  function clearBets() {
    dispatch(toggleBetSlipMode({ mode: "singles" }));
    dispatch(clearSportBets());
  }

  return { sportBetSlip, clearBets };
};

export const usePlaceBetSlip = () => {
  const dispatch = useAppDispatch();
  const currentWallet = useAppSelector(selectCurrentWallet);
  const sportBetSlip = useAppSelector(selectSportBetSlip);
  const setAuthDisplayType = useAuthDailogStore(
    (state) => state.setDisplayType
  );
  const [placeBets] = usePlaceBetsMutation();

  const { isAuthenticated } = useAuth();
  const handleErrors = useHandleErrors();

  const placeBetsHandler = async () => {
    if (!isAuthenticated) {
      setAuthDisplayType(AuthDialogType.Login);
      return;
    }

    try {
      const response = await placeBets({
        betSlip: sportBetSlipMapper(sportBetSlip),
        wallet: currentWallet.id.toString(),
      }).unwrap();

      logger.log(response);
      toast.success("Successfully submitted your bet slip.");

      dispatch(clearSportBets());
    } catch (error) {
      if (isErrorData(error)) {
        handleErrors(error);
      } else {
        console.log("error: ", error);
      }
    }
  };

  return placeBetsHandler;
};

export const useValidateBetSlip = () => {
  const dispatch = useAppDispatch();
  const sportBetSlip = useAppSelector(selectSportBetSlip);
  const validate = useAppSelector(selectSportBetSlipValidate);

  const mappedBetSlip = useMemo(() => {
    return sportBetSlipMapper(sportBetSlip);
  }, [sportBetSlip]);

  const [validateBets] = useValidateBetsMutation();
  const handleErrors = useHandleErrors();

  useEffect(() => {
    if (
      validate &&
      mappedBetSlip.bets.length > 0 &&
      mappedBetSlip.totalStake > 0
    ) {
      dispatch(toggleValidate({ validate: false }));
      validateBets(mappedBetSlip)
        .unwrap()
        .catch((error) => {
          const errorData = error.data;
          if (isErrorData(errorData)) {
            handleErrors(errorData);
          } else {
            console.log("errors: ", errorData);
          }
        });
    }
  }, [dispatch, validate, handleErrors, mappedBetSlip, validateBets]);
};
