import { FC } from "react";

import RefreshData from "src/components/refreshData/RefreshData";
import { FilterBox } from "./Filters.styles";
import PeriodFilter from "./PeriodFilter";
import StatusFilter from "./StatusFilter";
import WalletFilter from "./WalletFilter";
import { Endpoint } from "src/services/restApi/queries/config/apiTags";

const Filters: FC = () => {
  return (
    <FilterBox>
      <PeriodFilter />
      <StatusFilter />
      <WalletFilter />
      <RefreshData dataType={Endpoint.MY_BETS} />
    </FilterBox>
  );
};

export default Filters;
