import { FC } from "react";
import { Skeleton } from "@mui/material";

import { MarketFilterBox } from "./MarketFilter.styles";

const MarketFilterLoader: FC = () => {
  return (
    <MarketFilterBox>
      <Skeleton
        animation="pulse"
        variant="rounded"
        height="43px"
        width="142px"
        sx={(theme) => ({
          bgcolor: theme.colors.neutral.grey[5],
        })}
      />
    </MarketFilterBox>
  );
};

export default MarketFilterLoader;
