import { InputBase, InputBaseProps, styled } from "@mui/material";

export const StyledInput = styled(InputBase)<InputBaseProps>(({ theme }) => ({
  "& .MuiSelect-select": {
    backgroundColor: "inherit",
    borderRadius: 0,
    borderRight: "1px solid " + theme.colors.text.light,

    color: theme.colors.text.light,
    fontSize: 14,
    fontWeight: 600,

    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
  },

  "& .MuiSelect-icon": {
    color: theme.colors.neutral.white[100],
  },
}));
