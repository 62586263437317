import FooterImg from "./footer.png";

import Eighteen from "./eighteen.png";
import Gov from "./gov.png";
import Ngb from "./ngb.png";
import Responsible from "./responsible.png";
import Fic from "./fic.webp";

export { FooterImg };

export const footerImgArray = [Responsible, Gov, Ngb, Eighteen, Fic];
