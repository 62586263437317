import { FC } from "react";
import { StyledFormControlLabel, StyledSwitch } from "./CustomSwitch.styles";

interface ICustomSwitch {
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
}

const CustomSwitch: FC<ICustomSwitch> = ({
  label,
  checked,
  disabled = false,
  onChange,
}) => {
  return (
    <StyledFormControlLabel
      control={
        <StyledSwitch
          checked={checked}
          disabled={disabled}
          onClick={onChange}
        />
      }
      label={label}
    />
  );
};

export default CustomSwitch;
