import { ChangeEvent, FC } from "react";

import {
  PasswordInput,
  PhoneNumberInput,
  CustomInputElement,
} from "src/components/Inputs";
import PasswordSpecifications from "../../../../../components/password-specifications/PasswordSpecifications";
import { PrimaryButton } from "src/components/buttons";

import {
  IInitialState,
  IAction,
  actionTypes,
} from "src/features/authentication/reducers/authFormReducer";

type CreateAccountProps = {
  state: IInitialState;
  dispatch: React.Dispatch<IAction>;
  createContinueClick: () => void;
};

const CreateAccount: FC<CreateAccountProps> = ({
  state,
  dispatch,
  createContinueClick,
}) => {
  const phoneChangeHandler = (event: ChangeEvent<CustomInputElement>) => {
    event.preventDefault();

    dispatch({
      type: actionTypes.CELLPHONE,
      payload: event.target.value,
    });
  };

  const countryChangeHandler = (value: string) => {
    dispatch({
      type: actionTypes.COUNTRYCODE,
      payload: value,
    });
  };

  const passwordChangeHandler = (event: ChangeEvent<CustomInputElement>) => {
    event.preventDefault();

    dispatch({
      type: actionTypes.PASSWORD,
      payload: event.target.value,
    });
  };

  const confirmPasswordChangeHandler = (
    event: ChangeEvent<CustomInputElement>
  ) => {
    event.preventDefault();

    dispatch({
      type: actionTypes.CONFIRMPASSWORD,
      payload: event.target.value,
    });
  };

  const createClickHandler = () => {
    if (
      !state.cellphone.valid &&
      !state.countryCode.valid &&
      !state.password.valid &&
      !state.confirmPassword.valid
    ) {
      return;
    }

    createContinueClick();
  };

  return (
    <>
      <PhoneNumberInput
        helperText={state.cellphone.error}
        inputLabel="Phone Number"
        value={state.cellphone.value}
        error={!state.cellphone.valid}
        onChange={phoneChangeHandler}
        countryChange={countryChangeHandler}
        countryDialCode={state.countryCode.value}
        countryError={!state.countryCode.valid}
        countryHelperText={state.countryCode.error}
      />
      <PasswordInput
        inputLabel="Create password"
        id="register-password-input"
        error={!state.password.valid}
        helperText={state.password.error}
        value={state.password.value}
        onChange={passwordChangeHandler}
      />
      <PasswordSpecifications background="gray" />
      <PasswordInput
        inputLabel="Confirm password"
        id="register-confirm-password-input"
        error={!state.confirmPassword.valid}
        helperText={state.confirmPassword.error}
        value={state.confirmPassword.value}
        onChange={confirmPasswordChangeHandler}
      />
      <br />
      <PrimaryButton fullWidth fontSize={16} onClick={createClickHandler}>
        Create
      </PrimaryButton>
    </>
  );
};

export default CreateAccount;
