import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const VerifyWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  gap: theme.spacing(1.25),
  padding: 0,
}));

export const ResendText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.colors.secondary.main,
}));
