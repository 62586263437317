import { FC } from "react";

import { LeagueItemButton } from "./LeagueItem.styles";

type LeagueItemProps = {
  league: string;
  selected: boolean;
  onClickHandler: (selected: boolean, league: string) => void;
};

const LeagueItem: FC<LeagueItemProps> = ({
  league,
  selected,
  onClickHandler,
}) => {
  function onClick() {
    onClickHandler(selected, league);
  }

  return (
    <LeagueItemButton disableRipple onClick={onClick} selected={selected}>
      {league}
    </LeagueItemButton>
  );
};

export default LeagueItem;
