import { Box, BoxProps, Drawer, DrawerProps, styled } from "@mui/material";

import appConfig from "src/config/appConfig";

export const StyledDrawer = styled(Drawer)<DrawerProps>(({ theme }) => ({
  ".MuiDrawer-paper": {
    height: "80%",
    overflow: "visible",

    [theme.breakpoints.up("md")]: {
      display: "none",
    },

    [theme.breakpoints.down("md")]: {
      marginLeft: theme.sidebar.width,
      width: `calc(100% - ${theme.sidebar.width})`,
    },

    [theme.breakpoints.down(appConfig.breakMobileView)]: {
      marginLeft: 0,
      width: "100%",
    },
  },
}));

export const NavButtonsBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.primaryAlt.main,
  borderTop: "2px solid " + theme.colors.secondary.main,
  borderLeft: "2px solid " + theme.colors.secondary.main,
  borderRight: "2px solid " + theme.colors.secondary.main,
  borderRadius: "6px 6px 0px 0px",

  height: "74px",
  width: "100%",
  position: "absolute",
  left: 0,
  right: 0,
  top: "-72px",
  zIndex: 8,
  visibility: "visible",
}));
