import { baseApi } from "src/services/restApi/queries/baseApi.service";
import { Endpoint, TagId } from "src/services/restApi/queries/config/apiTags";

import { LottoBetSlipIM } from "src/features/betslip/models/lotto";
import { SportBetSlipIM } from "../../../features/betslip/models/sport";

type Payload = {
  betSlip: LottoBetSlipIM | SportBetSlipIM;
  wallet: string;
};

const betSlipApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    placeBets: builder.mutation<unknown, Payload>({
      query: (payload) => ({
        url: Endpoint.PLACEBETS,
        method: "POST",
        body: payload.betSlip,
        headers: { Wallet: "Turfsport", "Wallet-Id": payload.wallet },
      }),
      invalidatesTags: [
        { type: Endpoint.MY_BETS, id: TagId.LIST },
        { type: Endpoint.TRANSACTIONS, id: TagId.LIST },
        { type: Endpoint.WALLETS, id: TagId.LIST },
      ],
    }),
    validateBets: builder.mutation<unknown, SportBetSlipIM>({
      query: (payload) => ({
        url: Endpoint.VALIDATEBETS,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { usePlaceBetsMutation, useValidateBetsMutation } = betSlipApi;
