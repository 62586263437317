import { useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import Glide, {
  Breakpoints,
  Controls,
  Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";

// import { BackButton, ForwardButton } from "./components/GlideActions";

import useMediaSize from "src/hooks/useMediaSize";

import "@glidejs/glide/dist/css/glide.core.min.css";

import { CarouselProps } from "./models/CarouselProps";
import { BackButton, ForwardButton } from "./components/GlideActions";

interface ManualCarouselProps<T> extends CarouselProps<T> {
  id: string;
  gap: number;
  perView: number;
  type?: "carousel" | "slider";
}

const ManualCarousel = <T,>({
  Component,
  data,
  id,
  gap,
  perView,
  type = "carousel",
}: ManualCarouselProps<T>) => {
  const size = useMediaSize();

  const startAt = useMemo(() => {
    if (size === "lg") {
      if (data.length < 4) {
        return data.length - 1;
      } else {
        return 3;
      }
    } else if (size === "md") {
      if (data.length < 3) {
        return data.length - 1;
      } else {
        return 2;
      }
    } else {
      if (data.length < 2) {
        return data.length - 1;
      } else {
        return 1;
      }
    }
  }, [data, size]);

  const Slider = useMemo(
    () =>
      new Glide(`.glide-${id}`, {
        cloningRatio: 0,
        type,
        focusAt: "center",
        perView: size === "lg" ? 5 : size === "md" ? 3 : perView,
        startAt,
        gap,
      }),
    [gap, id, perView, size, startAt, type]
  );

  useEffect(() => {
    Slider.mount({ Breakpoints, Controls, Swipe });
    // Slider.go(">");
  }, [Slider]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        alignContent: "center",
      }}
    >
      <BackButton onClick={() => Slider.go("<")} />
      <div className={`glide-${id}`}>
        <div
          className="glide__track"
          data-glide-el="track"
          style={{ padding: "2px" }}
        >
          <ul className="glide__slides">
            {data.map((props, index) => (
              <li
                key={index}
                className="glide__slide"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Component key={index} {...props} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ForwardButton onClick={() => Slider.go(">")} />
    </Box>
  );
};

export default ManualCarousel;
