import { FC } from "react";
import { Box, IconButton } from "@mui/material";

import MenuIcon from "./MenuIcon";

import appConfig from "src/config/appConfig";

import { useSidebarContext } from "src/context/SidebarContext";

const MenuButton: FC = () => {
  const { toggleSidebar } = useSidebarContext();

  return (
    <Box
      component="span"
      sx={{
        display: {
          [appConfig.breakMobileView]: "none",
          xs: "inline-block",
        },
      }}
    >
      <IconButton onClick={toggleSidebar}>
        <MenuIcon />
      </IconButton>
    </Box>
  );
};

export default MenuButton;
