import { FC } from "react";
import { IconButton, InputAdornment, Tooltip, useTheme } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export type PasswordVisibleProps = {
  showPassword: boolean;
  showPasswordHandler: () => void;
};

const PasswordVisibleButton: FC<PasswordVisibleProps> = ({
  showPassword,
  showPasswordHandler,
}) => {
  const theme = useTheme();

  return (
    <InputAdornment position="end">
      <Tooltip title={showPassword ? "Hide Password" : "Show Password"}>
        <IconButton
          aria-label="toggle password visibility"
          onClick={showPasswordHandler}
        >
          {showPassword ? (
            <Visibility sx={{ color: theme.colors.text.light }} />
          ) : (
            <VisibilityOff sx={{ color: theme.colors.text.light }} />
          )}
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
};

export default PasswordVisibleButton;
