import { FC } from "react";
import {
  Link,
  LinkProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";
import { toast } from "react-toastify";

import { openInNewTab } from "src/utils/navigationHelpers";

const StyledTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 8,
  fontWeight: 400,
  textAlign: "center",
  color: theme.colors.text.light,
}));

const StyledLink = styled(Link)<LinkProps>(({ theme }) => ({
  fontSize: 8,
  fontWeight: 400,
  textAlign: "center",
  textDecorationLine: "underline",
  color: theme.colors.text.light,
}));

const FooterText: FC = () => {
  const openWCGRB = () => {
    try {
      openInNewTab("https://www.wcgrb.co.za/");
    } catch (err) {
      toast.error("Unable to open link.");
    }
  };

  return (
    <StyledTypography>
      TopBet @ 202X. All rights reserved. Hatsy (PTY) LTD trading as TOPBET Reg
      No: 2021/566466/07 issued on 21 September 2021, is licensed with license
      number 1019044 and regulated by the{" "}
      <StyledLink onClick={openWCGRB} variant="inherit">
        Western Cape Gambling and Racing Board.
      </StyledLink>
    </StyledTypography>
  );
};

export default FooterText;
