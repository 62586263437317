import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

import appConfig from "src/config/appConfig";

export const FicaContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
  alignItems: "flex-start",
  gap: theme.spacing(2.5),

  [theme.breakpoints.down(appConfig.breakMobileView)]: {
    width: "100%",
  },
  [theme.breakpoints.up(appConfig.breakMobileView)]: {
    width: "60%",
  },
}));

export const FicaText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.neutral.white[100],
  fontSize: 16,
  fontWeight: 500,
}));
