import { FC } from "react";
import { Box, Typography } from "@mui/material";

const FixturesEmpty: FC = () => {
  return (
    <Box height="100%" sx={{ opacity: 0.8 }}>
      <Typography color={(theme) => theme.colors.text.light} textAlign="center">
        No Fixtures available at this time.
      </Typography>
      <Typography color={(theme) => theme.colors.text.light} textAlign="center">
        Please try again later.
      </Typography>
    </Box>
  );
};

export default FixturesEmpty;
