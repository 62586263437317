import { useState } from 'react';
import { toast } from 'react-toastify';

import { useFileUpload } from 'src/hooks/useFileUpload';
import { useFica } from './useFica';

import {
  useSetIncomeSourceMutation,
  useSetFicaStatusMutation,
} from 'src/services/restApi/queries/fica.service';
import {
  useLazyGetIdDocURLQuery,
  useLazyGetPoADocURLQuery,
} from 'src/services/restApi/queries/fica.service';

import { NetworkStatusEnums } from 'src/utils/enums/networkStatus';
import logger from 'src/utils/logger';

/**
 * Custom hook for submitting documents for FICA approval
 */
export const useFicaSubmit = () => {
  const [uploadState, setUploadState] = useState<NetworkStatusEnums>(
    NetworkStatusEnums.IDLE
  );

  const { uploadFile } = useFileUpload('');
  const { idDoc, poADoc, fundsSource, removeDocs } = useFica();

  const [getIdDocUrl] = useLazyGetIdDocURLQuery();
  const [getPoADocUrl] = useLazyGetPoADocURLQuery();

  const [setFicaStatus] = useSetFicaStatusMutation();
  const [setFundsSource] = useSetIncomeSourceMutation();

  const uploadIdDoc = async (idDoc: File) => {
    const idUrlResp = await getIdDocUrl({ fileName: idDoc.name });
    const idUrl = idUrlResp.data?.url;
    if (idUrl) {
      await uploadFile('PUT', idUrl, idDoc);
    } else {
      throw new Error('Unable to upload ID document.');
    }
  };

  const uploadPoADoc = async (poADoc: File) => {
    const poAUrlResp = await getPoADocUrl({ fileName: poADoc.name });
    const poAUrl = poAUrlResp.data?.url;
    if (poAUrl) {
      await uploadFile('PUT', poAUrl, poADoc);
    } else {
      throw new Error('Unable to upload Proof of Address document.');
    }
  };

  const submitFicaDocs = async () => {
    if (!idDoc || !poADoc) {
      toast.warning('Please add documents to upload.');
      return;
    }

    try {
      setUploadState(NetworkStatusEnums.LOADING);
      await uploadIdDoc(idDoc);
      await uploadPoADoc(poADoc);
      await setFicaStatus({ status: 'UPLOADED' });
      await setFundsSource({ id: fundsSource });
      toast.success('Successfully Updated');
      removeDocs();
      setUploadState(NetworkStatusEnums.IDLE);
    } catch (err) {
      setUploadState(NetworkStatusEnums.FAILED);
      toast.error('Something went wrong.');
      logger.error(err);
    }
  };

  const updateFicaDocs = async () => {
    if (!idDoc || !poADoc) {
      toast.warning('Please add documents to upload.');
      return;
    }

    try {
      setUploadState(NetworkStatusEnums.LOADING);
      await uploadIdDoc(idDoc);
      await uploadPoADoc(poADoc);
      await setFicaStatus({ status: 'UPLOADED' });
      toast.success('Successfully Updated');
      removeDocs();
      setUploadState(NetworkStatusEnums.IDLE);
    } catch (err) {
      setUploadState(NetworkStatusEnums.FAILED);
      toast.error('Something went wrong.');
      logger.error(err);
    }
  };

  return { uploadState, submitFicaDocs, updateFicaDocs };
};
