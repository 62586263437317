import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
  selectAllLeagues,
  selectAllSelectedLeagues,
  selectShowLeagesModal,
  setSelectedLeagues,
  toggleLeaguesModal,
} from "src/features/sports/store/sportsSlice";

export const useShowLeaguesModal = () => {
  const dispatch = useAppDispatch();
  const showLeaguesModal = useAppSelector(selectShowLeagesModal);

  function toggleLeaguesModalHandler(show: boolean) {
    dispatch(toggleLeaguesModal({ show }));
  }

  return { showLeaguesModal, toggleLeaguesModalHandler };
};

export const useLeagues = () => {
  const [localSelectedLeagues, setLocalSelectedLeagues] = useState<string[]>(
    []
  );

  const dispatch = useAppDispatch();
  const allLeagues = useAppSelector(selectAllLeagues);
  const selectedLeagues = useAppSelector(selectAllSelectedLeagues);

  function clearOptions() {
    setLocalSelectedLeagues([]);
  }

  function updateRedux() {
    dispatch(setSelectedLeagues({ leagues: localSelectedLeagues }));
  }

  function onClickHandler(selected: boolean, league: string) {
    if (selected) {
      setLocalSelectedLeagues((prevState) =>
        prevState.filter((l) => l !== league)
      );
    } else {
      const leagues = [...localSelectedLeagues];
      leagues.push(league);
      setLocalSelectedLeagues(leagues);
    }
  }

  useEffect(() => {
    setLocalSelectedLeagues(selectedLeagues);
  }, [selectedLeagues]);

  return {
    allLeagues,
    clearOptions,
    onClickHandler,
    selectedLeagues: localSelectedLeagues,
    updateRedux,
  };
};
