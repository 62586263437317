import { FC } from "react";

import { HeaderWrapper, HeaderSubWrapper } from "./WalletHeader.styles";
import { Icon } from "src/components/icons";
import AccountInfo from "./accountInfo/AccountInfo";
import { SecondaryButton } from "src/components/buttons";

import { useWalletHeaderNav } from "./WalletHeader.hooks";

const WalletHeader: FC = () => {
  const navigateToDeposit = useWalletHeaderNav();

  return (
    <HeaderWrapper>
      <HeaderSubWrapper>
        <Icon name="Account" />
        <AccountInfo />
      </HeaderSubWrapper>
      <SecondaryButton onClick={navigateToDeposit}>Deposit</SecondaryButton>
    </HeaderWrapper>
  );
};

export default WalletHeader;
