import { FC } from "react";
import { CircularProgress, Typography } from "@mui/material";

import { EmptyWrapper, Wrapper } from "./WalletSelector.styles";
import WalletItem from "./WalletItem";

import { useWalletSelector } from "./WalletSelector.hooks";

const WalletSelector: FC = () => {
  const { currentWallet, isLoading, wallets, changeWallet } =
    useWalletSelector();

  if (isLoading) {
    return (
      <EmptyWrapper>
        <CircularProgress color="secondary" />
      </EmptyWrapper>
    );
  }

  if (!wallets) {
    return (
      <EmptyWrapper>
        <Typography>Could not get wallets.</Typography>
      </EmptyWrapper>
    );
  }

  return (
    <Wrapper onChange={changeWallet} value={currentWallet.type}>
      {wallets.items.map((wallet) => (
        <WalletItem key={wallet.id} wallet={wallet} />
      ))}
    </Wrapper>
  );
};

export default WalletSelector;
