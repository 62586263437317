import {
  InputBase,
  InputBaseProps,
  MenuItem,
  MenuItemProps,
  styled,
} from "@mui/material";

interface StyledInputProps extends InputBaseProps {
  border: boolean;
}

export const StyledInput = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== "border",
})<StyledInputProps>(({ theme, border }) => ({
  "& .MuiSelect-select": {
    borderRadius: 5,
    border: border
      ? "1px solid " + theme.colors.neutral.white[100]
      : "1px solid " + theme.colors.neutral.black[100],
    background: theme.colors.gradients.black1,
    backgroundColor: theme.colors.neutral.black[100],

    color: theme.colors.neutral.white[100],
    fontSize: 14,
    fontWeight: 600,
    textTransform: "capitalize",

    padding: theme.spacing(1.25, 2.5),

    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(2),

    "&:hover, &:focus": {
      border: "1px solid " + theme.colors.neutral.black[100],
      background: theme.colors.gradients.black1,
      backgroundColor: theme.colors.neutral.black[100],
      color: theme.colors.neutral.white[100],
    },
  },

  "& .MuiSelect-icon": {
    color: theme.colors.neutral.white[100],
  },
}));

export const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  color: theme.colors.neutral.white[100],
}));
