import { Box, BoxProps, styled } from "@mui/material";

export const MarketFilterBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",

  width: "100%",
}));
