import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1, 1.5),

  borderTop: "1px solid " + theme.colors.neutral.white[100],
}));

export const SubWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1.25),
}));

export const StyledText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.text.light,
  fontSize: 13,
  fontWeight: 500,
}));
