import {
  Box,
  BoxProps,
  InputBase,
  InputBaseProps,
  MenuItem,
  MenuItemProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: theme.spacing(1),
}));

export const TitleText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.neutral.white[100],
  fontSize: 16,
  fontWeight: 700,
  lineHeight: "140%",
}));

export const StyledInput = styled(InputBase)<InputBaseProps>(({ theme }) => ({
  "& .MuiSelect-select": {
    borderRadius: 10,
    border: "1px solid " + theme.colors.neutral.white[100],
    backgroundColor: "inherit",

    color: theme.colors.neutral.white[100],
    fontSize: 14,
    fontWeight: 600,

    width: "100%",

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    paddingTop: theme.spacing(2.25),
    paddingBottom: theme.spacing(2.25),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(1.25),

    "&.Mui-disabled": {
      border: "1px solid " + theme.colors.neutral.white[60],
    },
  },

  "& .MuiSelect-icon": {
    color: theme.colors.neutral.white[100],
  },

  "& .Mui-disabled": {
    color: theme.colors.neutral.white[60],
    "-webkit-text-fill-color": theme.colors.neutral.white[60],
  },
}));

export const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  color: theme.colors.neutral.white[100],
}));
