import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "start",
  gap: theme.spacing(0.5),
}));

export const LottoBallWrapper = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
}));

export const LottoBall = styled(Box)<BoxProps>(({ theme }) => ({
  border: "2px solid " + theme.colors.secondary.main,
  borderRadius: "100%",

  height: 30,
  width: 30,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const LottoBallText = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.colors.secondary.main,
    fontWeight: 700,
  })
);

export const Underline = styled(Box)<BoxProps>(({ theme }) => ({
  height: 2,
  width: 30,

  backgroundColor: theme.colors.secondary.main,
}));
