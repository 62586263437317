import {
  IAccountDetails,
  IBankingDetails,
  IPostUser,
  ITransactionQuery,
  ITransactions,
} from "src/models/users";
import { SettingsType } from "src/models/settings";
import { baseApi } from "./baseApi.service";
import { Endpoint, TagId } from "./config/apiTags";
import { env } from "src/env";

// Creating an API for user-related functionalities (TODO: SHOULD WE CALL THIS PUNTERS?)
// THIS IS NOT BEING USED ATM - DUE TO IT CRASHING CYPRESS TESTS
const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Endpoint for user sign-up
    userSignUpPost: builder.mutation<
      any,
      { userdata: IPostUser; captchaResponse: string }
    >({
      // TODO: WHAT IS THE RETURN TYPE OF THIS?
      query: (data) => ({
        url: Endpoint.POST_USER, // Endpoint URL for user sign-up
        method: "POST", // HTTP method used (POST)
        body: data.userdata, // Data to be sent in the request body,
        headers: {
          "G-Recaptcha-Response": data.captchaResponse,
          "X-Api-Key": env.REACT_APP_REGISTRATION_API_KEY,
        }, //g-recapthca response received from sign up
      }),
    }),
    // ######################### GET ACCOUNT DETAILS ##############################
    getAccountDetails: builder.query<IAccountDetails, void>({
      query: () => ({
        url: Endpoint.MY_ACCOUNT, // Endpoint URL for getting account details
        method: "GET", // HTTP method used (GET)
        headers: {
          Wallet: "Turfsport",
        },
      }),
      providesTags: (result, error, arg) => [
        { type: Endpoint.MY_ACCOUNT, id: TagId.LIST },
      ], // Tagging the query with a unique ID
    }),
    // ######################### UPDATE BANKING DETAILS ##############################
    updateBankingDetails: builder.mutation<any, IBankingDetails>({
      query: (data) => ({
        url: `${Endpoint.MY_ACCOUNT}bank-details`, // Endpoint URL for updating banking details
        method: "PUT", // HTTP method used (PUT)
        body: data, // Data to be sent in the request body
        headers: {
          Wallet: "Turfsport",
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: Endpoint.MY_ACCOUNT, id: TagId.LIST },
      ], // Tagging the query with a unique ID
    }),
    // ######################### UPDATE NOTIFICATIONS ##############################
    updateNotifications: builder.mutation<
      any,
      { "promotions-enabled": boolean }
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const accountQuery = await baseQuery({
          url: `${Endpoint.MY_ACCOUNT}`, // Endpoint URL for updating banking details
          method: "GET", // HTTP method used (GET)
          headers: {
            Wallet: "Turfsport",
          },
        });
        if (!accountQuery.data) {
          throw new Error("Account details not found");
        }
        const accountDetails = accountQuery.data as IAccountDetails;
        const accountNumber = accountDetails.accountNumber;
        return baseQuery({
          url: `${Endpoint.POST_USER}/${accountNumber}/notifications`, // Endpoint URL for updating notifications
          method: "PUT",
          params: arg, // HTTP method used (PUT)
          headers: {
            Wallet: "Turfsport",
          },
        });
      },
      invalidatesTags: (result, error, arg) => [
        { type: Endpoint.MY_ACCOUNT, id: TagId.LIST },
      ], // Tagging the query with a unique ID
    }),
    // ######################### GET ACCOUNT TRANSACTIONS ##############################
    getAccountTransactions: builder.query<ITransactions, ITransactionQuery>({
      query: (query) => {
        return {
          url: Endpoint.TRANSACTIONS, // Endpoint URL for getting account transactions
          method: "GET", // HTTP method used (GET)
          headers: {
            Wallet: "Turfsport",
          },
          params: query, // Data to be sent in the request body
        };
      },
      providesTags: (result, error, arg) => [
        { type: Endpoint.TRANSACTIONS, id: TagId.LIST },
      ], // Tagging the query with a unique ID
    }),
    getSettings: builder.query<SettingsType, void>({
      query: () => ({
        url: Endpoint.SETTINGS,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: Endpoint.SETTINGS, id: TagId.ITEM },
      ],
    }),
  }),
});

// Extracting a specific mutation for user sign-up from the API
export const {
  useUserSignUpPostMutation,
  useGetAccountDetailsQuery,
  useLazyGetAccountDetailsQuery,
  useUpdateBankingDetailsMutation,
  useUpdateNotificationsMutation,
  useGetAccountTransactionsQuery,
  useLazyGetAccountTransactionsQuery,
  useGetSettingsQuery,
  useLazyGetSettingsQuery,
} = usersApi;
