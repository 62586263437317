/**
 * This module provides custom functionality for making authenticated API requests using Redux Toolkit Query.
 * It includes token handling and retrying requests in case of token expiration.
 *
 * @module AuthenticatedQuery
 * @preferred
 */

import {
  fetchBaseQuery,
  retry,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { toast } from "react-toastify";
import appConfig from "src/config/appConfig";
import { env } from "src/env";
import { logout, refreshToken } from "src/features/authentication";
import { RootState } from "src/store/store";
import logger from "src/utils/logger";

/**
 * A mutex for ensuring only one token refresh operation occurs at a time.
 */
const mutex = new Mutex();

/**
 * Custom function to serialize query paramters.
 * Can handle query parameters with array values.
 * https://stackoverflow.com/questions/73787483/rtk-query-url-with-parameter-which-is-an-array
 * @param params - Query parameters
 * @returns A serialized string representing the query parameters
 */
const paramsSerializer = (params: Record<string, any>): string => {
  const searchParams = new URLSearchParams();

  for (const key in params) {
    const value = params[key];

    if (Array.isArray(value)) {
      // Serialize arrays
      value.forEach((item) => {
        searchParams.append(key, item);
      });
    } else {
      // Serialize other values
      searchParams.append(key, value);
    }
  }

  return searchParams.toString();
};

/**
 * The base query for making API requests. It includes handling authorization headers.
 */
const baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    prepareHeaders: (headers, { getState }: any) => {
      const token: string | null = (getState() as RootState).user.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      if (!headers.has("X-Api-Key")) {
        //if we already have an x-api-key set, rather use that
        headers.set("X-Api-Key", `${env.REACT_APP_API_KEY}`);
      }

      return headers;
    },
    baseUrl,
    paramsSerializer,
  });

/**
 * A staggered base query that allows for a maximum number of retries in case of network errors.
 * The maximum number of retries can be overridden with `extraOptions: { maxRetries: <N> }` within extra options on the injected endpoint.
 */
const staggeredBaseQuery = (baseUrl: string) =>
  retry(baseQuery(baseUrl), {
    maxRetries: 0,
  });

/**
 * A custom base query function that handles token expiration and refreshes the token when necessary.
 *
 * @param args - The API request arguments.
 * @param api - The API instance.
 * @param extraOptions - Extra options for the query.
 * @returns The result of the API request.
 */
export const baseQueryWithTokenExpirationCheck = (baseUrl: string) => {
  const baseQueryFn: BaseQueryFn<
    FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    // Wait until the mutex is available without locking it.
    await mutex.waitForUnlock();

    let result = await staggeredBaseQuery(baseUrl)(args, api, extraOptions);
    if (result.error && result.error.status === 403) {
      if (baseUrl === env.REACT_APP_BASE_API_URL) {
        const accountDetails = await baseQuery(env.REACT_APP_BASE_API_URL)(
          {
            url: "online/my-account/api/",
            method: "GET",
            headers: {
              Wallet: "Turfsport",
            },
          },
          api,
          extraOptions
        );
        if (accountDetails.error && accountDetails.error.status === 423) {
          toast.dismiss();

          localStorage.setItem("accountDisabled", true.toString());
          const error: FetchBaseQueryError = {
            status: 423,
            data: {
              message: "Your account is disabled. Please contact customer care",
            },
          };
          logout();
          return { error };
        } else {
          localStorage.removeItem("accountDisabled");
        }
      }
    }
    if (!appConfig.excludeRefreshTokenApis.includes(args.url)) {
      //Exlcudung endpoints not needed for 401
      if (result.error && result.error.status === 401) {
        // Checking whether the mutex is locked.
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();

          try {
            // Get a new access token.
            logger.log("Getting New Refresh Token"); // TODO: REMOVE THIS TOAST IF THIS SOLUTION WORKS
            const refreshResult = await refreshToken();
            logger.log("refresh result", refreshResult); // TODO: REMOVE THIS TOAST IF THIS SOLUTION WORKS

            if (
              refreshResult &&
              refreshResult.accessToken &&
              refreshResult.refreshToken
            ) {
              // Retry the initial query.
              logger.log("Running API again"); // TODO: REMOVE THIS TOAST IF THIS SOLUTION WORKS
              result = await staggeredBaseQuery(baseUrl)(
                args,
                api,
                extraOptions
              );
            } else {
              throw new Error("Refresh Token Error");
            }
          } catch {
            toast.dismiss();
            toast.error("Session expired. Please log in again.");
            setTimeout(function () {
              // logout()
            }, 2000);

            // We can bail out of retries if we know it is going to be redundant - not authenticated at all.
            retry.fail(result.error);
          } finally {
            // Release must be called once the mutex should be released again.
            release();
          }
        } else {
          // Wait until the mutex is available without locking it.
          await mutex.waitForUnlock();
          result = await staggeredBaseQuery(baseUrl)(args, api, extraOptions);
        }
      }

      if (result.error && result.error.status === 401) {
        //The result is still 401 after a retry - logging out
        logout();
      }
    }

    return result;
  };

  return baseQueryFn;
};
