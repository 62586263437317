import { Button, ButtonProps, styled } from "@mui/material";

import appConfig from "src/config/appConfig";

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.colors.neutral.black[100],
  fontSize: 16,
  fontWeight: 700,
  textTransform: "capitalize",

  backgroundColor: theme.colors.primary.main,
  border: "2px solid " + theme.colors.primary.main,
  borderRadius: "10px",

  height: 45,
  width: "100%",

  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2, 1.5),

  [theme.breakpoints.up(appConfig.breakMobileView)]: {
    maxWidth: theme.spacing(23),
    alignSelf: "flex-end",
    fontSize: 14,
  },

  "&:hover": {
    backgroundColor: theme.colors.primary.main,
    color: theme.colors.neutral.black[100],
  },

  "&:disabled": {
    opacity: 0.4,
  },
}));
