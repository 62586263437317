import { FC } from 'react';
import { Box } from '@mui/material';

type FooterImageProps = {
  img: string;
};

const FooterImage: FC<FooterImageProps> = ({ img }) => {
  return (
    <Box
      component='div'
      sx={{
        height: '100%',
        objectFit: 'cover',
        width: 'fit-content',
      }}
    >
      <img alt={img} src={img} height='100%' width='fit-content' />
    </Box>
  );
};

export default FooterImage;
