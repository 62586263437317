import { FC } from "react";
import { IconButton } from "@mui/material";

import { ButtonsWrapper, FilterWrapper, StyledButton } from "./Filter.styles";
import { Icon } from "src/components/icons";

import { useAppDispatch } from "src/hooks/hooks";
import {
  toggleLeaguesModal,
  toggleOptionsModal,
} from "src/features/sports/store/sportsSlice";

import { useFilter } from "./Filter.hooks";

const Filter: FC = () => {
  const dispatch = useAppDispatch();

  const { currentFilter, setCurrentFilter, filters } = useFilter();

  function setCurrentFilterHandler(value: string) {
    if (value === "all") {
      dispatch(toggleLeaguesModal({ show: true }));
    }

    setCurrentFilter(value);
  }

  function toggleOptionsModalHandler() {
    dispatch(toggleOptionsModal({ show: true }));
  }

  return (
    <FilterWrapper>
      <IconButton onClick={toggleOptionsModalHandler}>
        <Icon name="FilterLight" />
      </IconButton>
      <ButtonsWrapper>
        {filters.map((filter) => (
          <StyledButton
            isActive={currentFilter === filter.value}
            key={filter.value}
            onClick={() => setCurrentFilterHandler(filter.value)}
          >
            {filter.label}
          </StyledButton>
        ))}
      </ButtonsWrapper>
    </FilterWrapper>
  );
};

export default Filter;
