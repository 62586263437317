import { FC } from "react";
import { Box, Skeleton, Typography } from "@mui/material";

import { useAccountInfo } from "./AccountInfo.hooks";

const AccountInfo: FC = () => {
  const { accountName, isLoading, username, accountNumber } = useAccountInfo();

  if (isLoading) {
    return (
      <Box>
        <Skeleton
          animation="pulse"
          variant="text"
          height="12px"
          width="68px"
          sx={(theme) => ({
            bgcolor: theme.colors.secondary.main,
            m: 0,
            opacity: 0.6,
          })}
        />
        <Skeleton
          animation="pulse"
          variant="text"
          height="12px"
          width="68px"
          sx={(theme) => ({
            bgcolor: theme.colors.secondary.main,
            m: 0,
            mt: "4px",
            opacity: 0.6,
          })}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Typography color="secondary" fontSize={12} fontWeight={700}>
        {accountName} - {accountNumber}
      </Typography>
      <Typography color="secondary" fontSize={12} fontWeight={700}>
        {username}
      </Typography>
    </Box>
  );
};

export default AccountInfo;
