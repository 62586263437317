import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { StyledTabs, StyledTab } from './NavBar.styles';

/* hook imports */
import { useNavBar } from '../hooks/useNavBar';
import NewIcon from 'src/layouts/SidebarLayout/Sidebar/SidebarMenu/NewIcon';
import Box from '@mui/material/Box';
import { TabDataItem } from '../mappers/getCurrentTabs';
import appConfig from 'src/config/appConfig';

const NewIconWrapper = ({ tab }: { tab: TabDataItem }) => {
  if (appConfig.showNew.tabs[tab.label]) {
    return (
      <Box ml={1} className='new-icon'>
        <NewIcon />
      </Box>
    );
  } else {
    return <></>;
  }
};

const NavBar: FC = () => {
  const { currentPath, currentTabs } = useNavBar();
  const navigate = useNavigate();

  if (currentTabs.length === 0) {
    return null;
  }

  return (
    <StyledTabs
      value={currentPath}
      variant='scrollable'
      visibleScrollbar={false}
    >
      {currentTabs.map((tab) => (
        <StyledTab
          key={tab.label}
          label={tab.label}
          value={tab.path}
          onClick={() => navigate(tab.path)}
          icon={<NewIconWrapper tab={tab} />}
          iconPosition='end'
        />
      ))}
      {/* <StyledTab label="Search" /> This neeed to be implemented per route*/}
    </StyledTabs>
  );
};

export default NavBar;
