import { FC } from "react";
import { Box, Skeleton } from "@mui/material";

const SportFiltersLoader: FC = () => {
  const numSkeletons = [1, 2, 3, 4, 5];
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      gap={(theme) => theme.spacing(1)}
    >
      {numSkeletons.map((skeleton) => (
        <Skeleton
          key={"sport-filter-loader-" + skeleton}
          animation="pulse"
          variant="rounded"
          height="68px"
          width="68px"
          sx={(theme) => ({
            bgcolor: theme.colors.neutral.grey[5],
          })}
        />
      ))}
    </Box>
  );
};

export default SportFiltersLoader;
