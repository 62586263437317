import { ReactNode, createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import store from "src/store/store";
import { IUserSlice } from "src/store/user/userSlice.contracts";

import { AuthContextInterface } from "../../context/AuthContextInterface";

import { _getCurrentUser } from "./actions/_getCurrentUser";
import { _getCognitoUserSession } from "./actions/_getSession";
import { confirmPassword as confirm } from "./actions/confirmPassword";
import { forgotPassword as forgot } from "./actions/forgotPassword";
import { loginWithCellphoneAndPasswordCognito } from "./actions/loginWithCellphoneAndPassword";
import refreshAccessToken from "./actions/refreshToken";
import { registerWithCellphoneAndPasswordCognito } from "./actions/registerWithCellphoneAndPassword";
import { resendOtp as resend } from "./actions/resendOtp";
import { verifyOtp as verify } from "./actions/verifyOtp";

import logger from "src/utils/logger";
import { persistAuth } from "../../utils/persistAuth";
import { resetUser } from "../../utils/resetUser";

export const CognitoAuthContext = createContext<AuthContextInterface>({
  user: null,
  loading: false,
  error: undefined,
  isAuthenticated: false,
  isGuarded: false,
  getTokens: () => new Promise((resolve) => null),
  loginWithCellphoneAndPassword: (email: string, password: string) =>
    new Promise((resolve) => {}),
  loginWithGoogle: () => new Promise((resolve) => {}),
  register: (email: string, password: string) => new Promise((resolve) => {}),
  logout: () => new Promise((resolve) => {}),
  refreshToken: () => new Promise((resolve) => {}),
  verifyOtp: (otp: string, cellphone: string) => new Promise((resolve) => {}),
  resendOtp: (cellphone: string) => new Promise((resolve) => {}),
  forgotPassword: (cellphone: string) => new Promise((resolve) => {}),
  confirmPassword: (cellphone: string, otp: string, password: string) =>
    new Promise((resolve) => {}),
  emailVerified: false,
  changePassword: (oldPassword: string, newPassword: string) =>
    new Promise((resolve) => {}),
  setIsGuardedHandler: (guarded: boolean) => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const CognitoAuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error] = useState(null);

  const [isGuarded, setIsGuarded] = useState<boolean>(false);

  const prep = async () => {
    const currentUser = _getCurrentUser();
    if (currentUser) {
      const session = await _getCognitoUserSession();
      if (session) {
        setUser(session.idToken.payload);
        const userSlice: IUserSlice = {
          user: session.idToken.payload,
          status: "authenticated",
          accessToken: session.idToken.jwtToken,
          refreshToken: session.refreshToken.token,
          error: null,
        };
        store.dispatch(persistAuth({ userAuth: userSlice }));
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      prep();
    } catch (error) {
      handleError(error);
    }
  }, []);

  const loginWithCellphoneAndPassword = async (
    cellphone: string,
    password: string
  ) => {
    try {
      const user = await loginWithCellphoneAndPasswordCognito(
        cellphone,
        password
      );
      setUser(user.user);
      prep();
    } catch (error) {
      handleError(error);
    }
  };

  const loginWithGoogle = async () => {
    throw new Error("Method not Implemented");
  };

  const logout = async () => {
    logoutCognito();
    setUser(null);
  };

  const register = async (cellphone: string, password: string) => {
    try {
      //Sign up user to cognito
      const user = await registerWithCellphoneAndPasswordCognito(
        cellphone,
        password
      );
      setUser(user.user);
    } catch (error) {
      handleError(error);
    }
  };

  const resendOtp = async (cellphone: string) => {
    resend(cellphone);
  };

  const refreshToken = async () => {
    throw new Error("Method not Implemented");
  };

  const getTokens = async () => {
    const currentUser = _getCurrentUser();
    if (currentUser) {
      const session = await _getCognitoUserSession();
      return {
        accessToken: session.idToken.jwtToken ?? null,
        refreshToken: session.refreshToken.token ?? null,
      };
    } else {
      setLoading(false);
      handleError(error);
      return null;
    }
  };

  const verifyOtp = async (otp: string, cellphone: string) => {
    const user = await verify(cellphone, otp);

    setUser(user);
  };

  const forgotPassword = async (cellphone: string) => {
    forgot(cellphone);
  };

  const confirmPassword = async (
    cellphone: string,
    otp: string,
    password: string
  ) => {
    await confirm(cellphone, otp, password);
  };

  const handleError = (error: any) => {
    // Handle and display the error
    toast.error("Error Authenticating...");
    logger.error("Error Authenticating:", error);
    throw error;
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    const currentUser = _getCurrentUser();
    if (currentUser) {
      currentUser.getSession(function (err: any, session: any) {
        if (err) {
          toast.error("Error changing password...");
        } else {
          currentUser.changePassword(
            oldPassword,
            newPassword,
            function (err, res) {
              if (err) {
                toast.error("Error changing password...");
                logger.error("Error changing password:", err);
              } else {
                toast.success("Password changed successfully...");
                logger.log("Password changed successfully:", res);
              }
            }
          );
        }
      });
    }
  };

  const setIsGuardedHandler = (guarded: boolean) => {
    setIsGuarded(guarded);
  };

  const isAuthenticated = !!user;

  const emailVerified = true; //TODO

  return (
    <CognitoAuthContext.Provider
      value={{
        isAuthenticated,
        isGuarded,
        user,
        loading,
        error,
        getTokens,
        loginWithCellphoneAndPassword,
        loginWithGoogle,
        register,
        logout,
        refreshToken,
        verifyOtp,
        resendOtp,
        forgotPassword,
        confirmPassword,
        emailVerified,
        changePassword,
        setIsGuardedHandler,
      }}
    >
      {children}
    </CognitoAuthContext.Provider>
  );
};

export const refreshCognitoToken = async () => {
  return await refreshAccessToken();
};

export const logoutCognito = async () => {
  const currentUser = _getCurrentUser();
  if (currentUser) {
    currentUser.signOut();
    store.dispatch(resetUser());
    // Initialize the store
    window.location.reload();
  }

  //window.location.href = "/auth/login";
};
