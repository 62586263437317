import { Breadcrumbs as MuiBreadCrumbs } from '@mui/material';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useNavigate } from 'react-router-dom';

import { StyledNavIcon, StyledTypography } from './BreadCrumbs.styles';

import routes from 'src/router/router';

import { deCamel } from 'src/utils/formatters';

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);
  const navigate = useNavigate();

  return (
    <MuiBreadCrumbs
      aria-label='breadcrumb'
      separator={<StyledNavIcon fontSize='inherit' />}
      sx={(theme) => ({
        marginY: theme.spacing(1),
        marginLeft: theme.spacing(1.5),
      })}
    >
      {breadcrumbs.map(({ key }, index) => {
        if (index === 0) {
          return (
            <StyledTypography
              key={index}
              color={'white'}
              onClick={() => navigate('/home')}
            >
              Home
            </StyledTypography>
          );
        } else {
          if (breadcrumbs.length > 2) {
            if (index === breadcrumbs.length - 1) {
              return null;
            } else {
              return (
                <StyledTypography key={index} color={'secondary'}>
                  {deCamel(key.slice(1))}
                </StyledTypography>
              );
            }
          } else {
            return (
              <StyledTypography key={index} color={'secondary'}>
                {deCamel(key.slice(1))}
              </StyledTypography>
            );
          }
        }
      })}
    </MuiBreadCrumbs>
  );
};

export default Breadcrumbs;
