import { FC } from "react";
import { Drawer, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import { SidebarWrapper } from "./Sidebar.styles";
import Scrollbar from "src/components/Scrollbar";
import SidebarDivider from "./SidebarDivider";
import SidebarMenu, { ISidebarMenuItem } from "./SidebarMenu";

import { WalletHeader, WalletSelector } from "src/features/wallet";

import { useSidebarContext } from "src/context/SidebarContext";

const walletMenu: ISidebarMenuItem[] = [
  {
    label: "My Bets",
    path: "/account/myBets",
  },
  {
    label: "Transactions",
    path: "/account/transactions",
  },
  {
    label: "Deposit",
    path: "/account/deposit",
  },
  {
    label: "Withdraw",
    path: "/account/withdraw",
  },
  {
    label: "Account Details",
    path: "/account/accountDetails",
  },
  {
    label: "Change Password",
    path: "/account/changePassword",
  },
  {
    label: "Notifications",
    path: "/account/notification",
  },
  {
    label: "FICA",
    path: "/account/fica",
  },
  {
    label: "Responsible Gambling",
    path: "/account/responsibleGambling",
  },
];

const WalletSidebar: FC = () => {
  const { walletDrawerToggle, closeWalletDrawer } = useSidebarContext();

  return (
    <Drawer
      anchor="right"
      open={walletDrawerToggle}
      onClose={closeWalletDrawer}
      variant="temporary"
      elevation={9}
    >
      <SidebarWrapper sx={{
        //overriding the base width of the sidebar because the wallet sidebar is temporary so we can use more space
        width: theme => `calc(${theme.sidebar.width} + 100px)`,
      }}>
        <IconButton
          sx={{
            position: "absolute",
            top: 4,
            left: 4,
            zIndex: 1000,
          }}
          onClick={closeWalletDrawer}
        >
          <Close
            fontSize="medium"
            sx={{
              color: (theme) => theme.colors.text.light,
            }}
          />
        </IconButton>
        <Scrollbar>
          <WalletHeader />
          <SidebarDivider />
          <WalletSelector />
          <SidebarDivider />
          <SidebarMenu items={walletMenu} onClick={closeWalletDrawer} />
        </Scrollbar>
      </SidebarWrapper>
    </Drawer>
  );
};

export default WalletSidebar;
