import { FC } from "react";
import { Box } from "@mui/material";

import { BetSlipWrapper } from "./BetSlip.styles";
import LottoBetItems from "./betItems/LottoBetItems";
import SelectionRetainer from "./selectionRetainer/SelectionRetainer";
import BetDetails from "./betDetails/BetDetails";
import { SubmitButton } from "src/components/buttons";

import { useLottoBetSlip } from "./LottoBetSlip.hooks";
import { usePlaceBetSlip } from "../hooks/placeBetSlip";

import { lottoBetSlipMapper } from "../mappers/lottoBetSlipMapper";

const LottoBetSlip: FC = () => {
  const { lottoBetSlip, clearBets } = useLottoBetSlip();
  const { isLoading, placeBetsHandler } = usePlaceBetSlip({ clearBets });

  function placeBets() {
    placeBetsHandler(lottoBetSlipMapper(lottoBetSlip));
  }

  return (
    <BetSlipWrapper>
      <Box
        sx={(theme) => ({
          flexGrow: 1,
          overflow: "scroll",
          borderTop: "2px solid " + theme.colors.secondary.main,
        })}
      >
        <LottoBetItems />
      </Box>
      <SelectionRetainer clearBetSlip={clearBets} />
      <BetDetails
        betsLength={lottoBetSlip.bets.length}
        totalPayout={lottoBetSlip.totalPayout}
        totalStake={lottoBetSlip.totalStake}
      />
      <Box
        sx={{
          paddingBottom: (theme) => theme.spacing(1),
          paddingX: (theme) => theme.spacing(1),
        }}
      >
        <SubmitButton
          disabled={lottoBetSlip.bets.length === 0}
          fontSize={14}
          fullWidth
          loading={isLoading}
          onClick={placeBets}
        >
          SUBMIT
        </SubmitButton>
      </Box>
    </BetSlipWrapper>
  );
};

export default LottoBetSlip;
