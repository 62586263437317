import { FC } from "react";
import { Card, CardProps, styled } from "@mui/material";

import { FicaText } from "../../../pages/Fica/Fica.styles";
import CustomSwitch from "src/components/switch/CustomSwitch";

import { useFica } from "../hooks/useFica";

const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
  background: theme.colors.gradients.black1,
  backgroundColor: theme.colors.neutral.black[100],
  borderRadius: 0,
  border: 0,

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: theme.spacing(2.5),
  gap: theme.spacing(2.5),
}));

interface IDeclaration {
  disabled: boolean;
}

const Declaration: FC<IDeclaration> = ({ disabled }) => {
  const { declarationAccepted, updateDeclarationSelection } = useFica();

  return (
    <StyledCard>
      <FicaText>
        I declare that the funds were not derived from or are being employed in
        any illegal transactions by the person/company for whom/which they were
        obtained
      </FicaText>
      <CustomSwitch
        label="Yes"
        disabled={disabled}
        checked={disabled ? true : declarationAccepted}
        onChange={() => updateDeclarationSelection(!declarationAccepted)}
      />
    </StyledCard>
  );
};

export default Declaration;
