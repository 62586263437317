import { FC } from "react";

import SportFiltersLoader from "./SportFiltersLoader";
import { StyledTab, StyledTabs } from "./SportFilters.styles";
import { SportIcon } from "src/components/icons";

import { useSportFilters } from "../../../hooks/SportFilters.hooks";

import { getSportIconName } from "../../../utils/getSportIconName";

type SportFiltersProps = {
  sportFilter: string;
  onChangeHandler: (filter: string) => void;
};

const SportFilters: FC<SportFiltersProps> = ({
  sportFilter,
  onChangeHandler,
}) => {
  const { sportFilters, isLoading } = useSportFilters();

  if (isLoading) {
    return <SportFiltersLoader />;
  }

  return (
    <StyledTabs
      value={sportFilter}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
    >
      {sportFilters.map((filter, index) => (
        <StyledTab
          key={filter + index}
          label={filter}
          value={filter}
          onClick={() => onChangeHandler(filter)}
          icon={
            <SportIcon
              name={getSportIconName(filter === sportFilter, filter)}
            />
          }
        />
      ))}
    </StyledTabs>
  );
};

export default SportFilters;
