export interface LabelSelection {
  gridNumber: number;
  label: string;
}

export const isLabelSelection = (
  selection: LabelSelection | PickSetSelection
): selection is LabelSelection => {
  return "label" in selection;
};

export interface PickSetSelection {
  balls: number[];
  pickSetId: number;
}

export const isPickSetSelections = (
  selections: (LabelSelection | PickSetSelection)[]
): selections is PickSetSelection[] => {
  return "pickSetId" in selections[0];
};

export interface MyBetsItemLeg {
  comboCount: number;
  currentSelectionPrice: number;
  fixtureDate: string;
  fixtureDefinitionId?: string;
  fixtureId: string;
  fixtureName: string;
  fixtureStatus: string;
  legNumber: number;
  liveInPlay: boolean;
  marketDefinitionId: string;
  marketId: string;
  marketName: string;
  marketSequence: number;
  marketStatus?: string;
  originalPrice: {
    dec: number;
  };
  price: {
    dec: number;
  };
  projectedPayout: number;
  projectedStake: number;
  resultedAfterBet: boolean;
  scheduledOrder: number;
  scratchedComboCount: number;
  selection?: (LabelSelection | PickSetSelection)[];
  selectionDefinitionId: string;
  selectionId: string;
  selectionName: string;
  selectionStatus?: string;
  selectionType: string;
  sp: boolean;
  sportId: string;
  sportName: string;
  status: "ACTIVE" | "WON" | "LOST" | "SCRATCHED" | "CANCELLED" | "CASHED_OUT";
  tournamentId: string;
  tournamentName: string;
  winningComboCount: number;
}

export interface MyBetsItem {
  betDate: string;
  cashOut?: {
    value: number;
  };
  groupRef: string;
  lateBet: boolean;
  legs: MyBetsItemLeg[];
  netPayout: number;
  payout?: number;
  ref: string;
  refundedStake: number;
  stake: number;
  status: "ACTIVE" | "WON" | "LOST" | "SCRATCHED" | "CANCELLED" | "CASHED_OUT";
  statusReason: string;
  takeback: boolean;
  takeOut: number;
  taxAmount: number;
  unroundedPotentialPayout: number;
  walletId: number;
}
