import { baseApi } from "./baseApi.service";
import { Endpoint, TagId } from "./config/apiTags";

import { FixtureItem } from "src/features/sports/models/FixtureItem";

interface Response {
  items: FixtureItem[];
  filters: any[];
}
const fixturesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // ############################## GET ALL FIXTURES ###################################
    getAllFixtures: builder.query<Response, { "m-def-id"?: string }>({
      query: (query) => {
        return {
          url: Endpoint.FIXTURES,
          method: "GET",
          params: query,
        };
      },
      providesTags: (result, error, arg) => [
        { type: Endpoint.FIXTURES, id: TagId.LIST },
      ],
    }),
    getLiveFixtures: builder.query<Response, { "m-def-id"?: string }>({
      query: (params) => {
        return {
          url: Endpoint.LIVE_FIXTURES,
          method: "GET",
          params,
        };
      },
      providesTags: (result, error, arg) => [
        { type: Endpoint.LIVE_FIXTURES, id: TagId.LIST },
      ],
    }),
  }),
});

export const {
  useGetAllFixturesQuery,
  useLazyGetAllFixturesQuery,
  useGetLiveFixturesQuery,
  useLazyGetLiveFixturesQuery,
} = fixturesApi;
