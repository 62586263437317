import { Box, BoxProps, styled } from "@mui/material";

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
  gap: theme.spacing(0.625),

  paddingTop: theme.spacing(0.625),
  paddingBottom: theme.spacing(0.625),

  flexGrow: 1,
}));
