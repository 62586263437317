type PageObj = {
  name: string;
  path: string;
};

type Page = {
  root: string;
  [key: string]: PageObj | string;
};

const pages: Record<string, any> = {
  auth: {
    root: "auth",
    login: {
      name: "login",
      path: "/auth/login",
    },
    register: {
      name: "register",
      path: "/auth/register",
    },
    forgot: {
      name: "forgot",
      path: "/auth/forgot",
    },
  },
  home: {
    name: "home",
    path: "/home",
  },
  account: {
    root: "account",
    myBets: {
      name: "myBets",
      path: "/account/myBets",
    },
    transaction: {
      name: "transactions",
      path: "/account/transactions",
    },
    deposit: {
      name: "deposit",
      path: "/account/deposit",
    },
    withdraw: {
      name: "withdraw",
      path: "/account/withdraw",
    },
    accountDetails: {
      name: "accountDetails",
      path: "/account/accountDetails",
    },
    changePassword: {
      name: "changePassword",
      path: "/account/changePassword",
    },
    notification: {
      name: "notification",
      path: "/account/notification",
    },
    fica: {
      name: "fica",
      path: "/account/fica",
    },
    responsibleGambling: {
      name: "responsibleGambling",
      path: "/account/responsibleGambling",
    },
  },
  aviator: {
    name: "aviator",
    path: "/aviator",
  },
  betGames: {
    name: "betGames",
    path: "/betGames",
  },
  liveCasino: {
    root: "liveCasino",
    evolution: {
      name: "evolution",
      path: "/liveCasino/evolution",
    },
    ezugi: {
      name: "ezugi",
      path: "/liveCasino/ezugi",
    },
  },
  luckyNumbers: {
    root: "luckyNumbers",
    all: {
      name: "all",
      path: "/luckyNumbers/all",
    },
    results: {
      name: "results",
      path: "/luckyNumbers/results",
    },
    // draws: {
    //   name: "draws",
    //   path: "/luckyNumbers/draws",
    // },
    favourites: {
      name: "favourites",
      path: "/luckyNumbers/favourites",
    },
    search: {
      name: "search",
      path: "/luckyNumbers/search",
    },
  },
  promotions: {
    root: "promotions",
    special: {
      name: "special",
      path: "/promotions/special",
    },
    // recurring: {
    //   name: "recurring",
    //   path: "/promotions/recurring",
    // },
  },
  slots: {
    root: "slots",
    habanero: {
      name: "habanero",
      path: "/slots/habanero",
    },
    spinmatic: {
      name: "spinmatic",
      path: "/slots/spinmatic",
    },
    evolution: {
      name: "evolution",
      path: "/slots/evolution",
    },
    pragmatic: {
      name: "pragmatic",
      path: "/slots/pragmatic",
    },
    playHabanero: {
      name: "playHabanero",
      path: "/slots/habanero/play",
    },
    playSpinmatic: {
      name: "playSpinmatic",
      path: "/slots/spinmatic/play",
    },
    playEvolution: {
      name: "playEvolution",
      path: "/slots/evolution/play",
    },
    playPragmatic: {
      name: "playPragmatic",
      path: "/slots/pragmatic/play",
    },
  },
  splitThePot: {
    root: "splitThePot",
    path: "/splitThePot",
    play: {
      name: "play",
      path: "/splitThePot/play",
    },
  },
  sports: {
    root: "sports",
    all: {
      name: "all",
      path: "/sports/all",
    },
    live: {
      name: "live",
      path: "/sports/live",
    },
    search: {
      name: "search",
      path: "/sports/search",
    },
    // virtual: {
    //   name: "virtual",
    //   path: "/sports/virtual",
    // },
  },
  status: {
    root: "status",
    unverified: {
      name: "unverified",
      path: "/status/unverified",
    },
    statusComingSoon: {
      name: "coming-soon",
      path: "/status/coming-soon",
    },
    statusMaintenance: {
      name: "maintenance",
      path: "/status/maintenance",
    },
    status404: {
      name: "404",
      path: "/status/404",
    },
    status500: {
      name: "500",
      path: "/status/500",
    },
    statusSuccess: {
      name: "success",
      path: "/status/success",
    },
    statusFailure: {
      name: "failure",
      path: "/status/failure",
    },
    statusCancel: {
      name: "cancel",
      path: "/status/cancel",
    },
  },
};

export default pages;

// type guards
export const isPage = (obj: Page | PageObj): obj is Page => {
  return "root" in obj;
};

export const isPageObj = (obj: PageObj | string): obj is PageObj => {
  if (typeof obj === "string") {
    return false;
  } else {
    return true;
  }
};
