import { useCallback } from "react";

import { useAppDispatch } from "src/hooks/hooks";
import {
  removeSportBet,
  updateSportBetStake,
} from "src/features/betslip/store/sport/sportBetSlipSlice";

export const useSportBetItem = () => {
  const dispatch = useAppDispatch();

  const removeFromBetSlip = useCallback(
    (betId: string) => {
      dispatch(removeSportBet({ betIds: [betId] }));
    },
    [dispatch]
  );

  const updateSportStake = useCallback(
    (betId: string, amount: number) => {
      dispatch(updateSportBetStake({ betId: [betId], stake: amount }));
    },
    [dispatch]
  );

  return { removeFromBetSlip, updateSportStake };
};
