import { toast } from "react-toastify";

import { useAppSelector } from "src/hooks/hooks";
import { selectCurrentWallet } from "src/features/wallet";

import { useAuth } from "src/features/authentication";
import { usePlaceBetsMutation } from "src/services/restApi/queries/betSlip.service";

import { LottoBetSlipIM } from "../models/lotto";
import { SportBetSlipIM } from "../models/sport";

import { ErrorData, isErrorData } from "../models/ErrorObject";
import logger from "src/utils/logger";
import {
  AuthDialogType,
  useAuthDailogStore,
} from "src/store/authentication/authStore";

type BetSlipIM = LottoBetSlipIM | SportBetSlipIM;

type UsePlaceBetsArgs = {
  clearBets: () => void;
  handleErrors?: (errorData: ErrorData) => void;
};

export const usePlaceBetSlip = ({
  clearBets,
  handleErrors,
}: UsePlaceBetsArgs) => {
  const currentWallet = useAppSelector(selectCurrentWallet);

  const [placeBets, { isLoading }] = usePlaceBetsMutation();
  const setAuthDisplayType = useAuthDailogStore(
    (state) => state.setDisplayType
  );
  const { isAuthenticated } = useAuth();

  const placeBetsHandler = async (betSlip: BetSlipIM) => {
    if (!isAuthenticated) {
      setAuthDisplayType(AuthDialogType.Login);
      return;
    }

    try {
      const response = await placeBets({
        betSlip,
        wallet: currentWallet.id.toString(),
      }).unwrap();

      logger.log(response);
      toast.success("Successfully submitted your bet slip.");

      clearBets();
    } catch (error) {
      if (isErrorData(error)) {
        if (handleErrors) {
          handleErrors(error);
        }
      } else {
        console.log("error: ", error);
      }
    }
  };

  return { isLoading, placeBetsHandler };
};
