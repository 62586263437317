import { FC } from "react";
import { FormControl, Select, SelectProps } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import { StyledInput, StyledMenuItem } from "./CustomSelect.styles";

export type SelectItem = {
  label: string;
  value: string;
};

interface ICustomSelect extends SelectProps<string> {
  items: SelectItem[];
  border?: boolean;
}

const CustomSelect: FC<ICustomSelect> = ({
  items,
  border = false,
  ...rest
}) => {
  return (
    <FormControl variant="standard">
      <Select
        input={<StyledInput border={border} />}
        IconComponent={ExpandMore}
        {...rest}
        variant="filled"
      >
        {items.map((item, index) => {
          return (
            <StyledMenuItem key={index + "_" + item} value={item.value}>
              {item.label}
            </StyledMenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
