import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const SelectionWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const SelectionText = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.colors.text.light,
    fontSize: 12,
    fontWeight: 600,
  })
);

interface SelectionButtonProps extends ButtonProps {
  isActive: boolean;
}

export const SelectionButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<SelectionButtonProps>(({ theme, isActive }) => ({
  height: "38px",

  backgroundColor: isActive ? theme.colors.secondary.main : "#7C7C7C",

  color: theme.colors.neutral.black[100],
  fontSize: 12,

  "&:hover": {
    backgroundColor: isActive ? theme.colors.secondary.main : "#7C7C7C",

    color: theme.colors.neutral.black[100],
    fontSize: 12,
  },
}));
