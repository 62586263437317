import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "src/store/store";
import { BetSlipState } from "./betSlipSlice.contracts";

import { getDefaultStakeFromStorage } from "./initialBetSlips";

const defaultSportStake = getDefaultStakeFromStorage();

const initialState: BetSlipState = {
  isBetSlipOpen: false,
  preferences: {
    preferencesOpen: false,
    acceptOdds: "any",
    defaultStake: defaultSportStake,
  },
};

const betSlipSlice = createSlice({
  name: "betSlip",
  initialState,
  reducers: {
    //#region General
    toggleBetSlip(state, action: PayloadAction<{ isOpen: boolean }>) {
      const { isOpen } = action.payload;
      state.isBetSlipOpen = isOpen;
    },
    //#endregion General
    //#region Preferences
    toggleBetSlipPreferences(state, action: PayloadAction<{ open: boolean }>) {
      const { open } = action.payload;
      state.preferences.preferencesOpen = open;
    },
    setAcceptOdds(state, action: PayloadAction<{ acceptOdds: string }>) {
      const { acceptOdds } = action.payload;
      state.preferences.acceptOdds = acceptOdds;
    },
    setDefaultStake(state, action: PayloadAction<{ newStake: number }>) {
      const { newStake } = action.payload;
      state.preferences.defaultStake = newStake;
    },
    //#endregion Preferences
  },
});

export default betSlipSlice.reducer;
export const {
  setAcceptOdds,
  setDefaultStake,
  toggleBetSlip,
  toggleBetSlipPreferences,
} = betSlipSlice.actions;

/* General */
export const selectIsBetSlipOpen = (state: RootState) =>
  state.betSlip.isBetSlipOpen;

/* Preferences */
export const selectPreferencesOpen = (state: RootState) =>
  state.betSlip.preferences.preferencesOpen;
export const selectAcceptOdds = (state: RootState) =>
  state.betSlip.preferences.acceptOdds;
export const selectDefaultStake = (state: RootState) =>
  state.betSlip.preferences.defaultStake;
