import { Card, CardContent, CardMedia, Stack } from "@mui/material";
import { FC } from "react";

import {
  StyledButton,
  StyledTypography
} from "./BetNowCard.styles";

export type BetNowCardProps = {
  name: string;
  onClick?: () => void;
  img?: string;
  imgUrl?: string;
};

const BetNowCard: FC<BetNowCardProps> = ({ name, onClick, img, imgUrl }) => {
  return (
    <Card sx={{
      mx: 3,
      height: 280,
      width: 180,
      border: (theme) => `2px solid ${theme.palette.secondary.main}`,
      backgroundColor: 'black',
    }}>
      <CardMedia
        component="img"
        height="60%"
        image={img}
        alt={name}
        sx={{ objectFit: 'cover' }}
      />
      <CardContent>
        <Stack direction='column' justifyContent='center' alignItems='center' spacing={1}>
          <StyledTypography >
            {name}
          </StyledTypography>
          <StyledButton fullWidth onClick={onClick}>
            Bet Now
          </StyledButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default BetNowCard;
