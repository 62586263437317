export interface IRegexField {
  pattern: RegExp;
  message: string
}

interface IRegex {
  email: IRegexField;
  password: IRegexField;
  name: IRegexField;
  cellphone: IRegexField;
  birthDate: IRegexField;
  idNumber: IRegexField;
  passportNumber: IRegexField;
  otp: IRegexField;
  amount: IRegexField;
}

export const RegexPatterns: IRegex = {
  email: {
    pattern:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Invalid Email Address',
  },
  password: {
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+])(?=.{8,16})/,
    message: 'Must contain at least one number, uppercase letter, special character and be between 8 and 16 characters long',
  },
  name: {
    pattern: /^[a-zA-Z\w\W]{0,32}$/,
    message: 'Name is invalid',
  },
  cellphone: {
    pattern: /^[0-9]{9,10}$/, // 9 or 10 digits to start with or without 0
    message: 'Cellphone number is invalid',
  },
  birthDate: {
    pattern: /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/,
    message: 'Birth date is invalid',
  },
  idNumber: {
    pattern: /^[0-9]{13,13}$/,
    message: 'ID number is invalid',
  },
  passportNumber: {
    pattern: /^[a-zA-Z0-9]{0,32}$/,
    message: 'Passport number is invalid',
  },
  otp: {
    pattern: /^[0-9]{6,6}$/,
    message: 'OTP is invalid',
  },
  amount: {
    pattern: /^[0-9]{1,}$/,
    message: 'Amount is invalid',
  },
}