import { FC, useEffect, useState } from "react";
import { CircularProgress, Dialog } from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import RenderHTML, { HTMLFiles } from "./helpModalHTMLContent";

import { useGetHelpFilesQuery } from "src/services/restApi/queries/helpFiles.service";

import { HelpFile } from "src/models/helpFiles";

type HelpModalProps = {
  fileName: string;
  open: boolean;
  handleClose: () => void;
};

const HelpModal: FC<HelpModalProps> = ({ fileName, open, handleClose }) => {
  const [helpFileContent, setHelpFileContent] = useState<string>("");
  const [loadingHelpFile, setLoadingHelpFile] = useState<boolean>(false);

  const { data: helpFiles, isFetching, isLoading } = useGetHelpFilesQuery();

  useEffect(() => {
    setLoadingHelpFile(true);
    const controller = new AbortController();
    const signal = controller.signal;

    async function getHelpFiles() {
      if (!helpFiles) {
        setHelpFileContent("Nothing to show here.");
        return;
      }

      const currentHelpFile = helpFiles.fileArray.find(
        (file: HelpFile) => file.name === fileName
      );
      if (!currentHelpFile) {
        setHelpFileContent("Nothing to show here.");
        return;
      }

      let fullPath = currentHelpFile.fullPath;
      if (fileName === "FICA") {
        fullPath += "file.md";
      }

      fetch(encodeURI(fullPath), {
        signal,
      })
        .then((response) => response.text())
        .then((res) => {
          setHelpFileContent(res);
          setLoadingHelpFile(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            console.log("Request aborted");
          } else {
            console.error(error);
          }
        });
    }

    getHelpFiles();

    return () => {
      controller.abort();
      setHelpFileContent("");
      setLoadingHelpFile(false);
    };
  }, [fileName, helpFiles]);

  return (
    <Dialog open={open} onClose={handleClose}>
      {isFetching || isLoading || loadingHelpFile ? (
        <div style={{ padding: "15px" }}>
          <CircularProgress
            sx={{ color: (theme) => theme.colors.neutral.black[100] }}
          />
        </div>
      ) : (
        <>
          {HTMLFiles.includes(fileName.trim()) ? (
            <RenderHTML fileName={fileName.trim()} />
          ) : (
            <div style={{ padding: "15px" }}>
              <Markdown remarkPlugins={[remarkGfm]}>{helpFileContent}</Markdown>
            </div>
          )}
        </>
      )}
    </Dialog>
  );
};

export default HelpModal;
