import { FC } from "react";
import { Button, ListItem } from "@mui/material";
import { NavLink } from "react-router-dom";

import NewIcon from "./NewIcon";

interface IMenuItem {
  label: string;
  onClick: () => void;
  path: string;
  showNewIcon?: boolean;
}

const MenuItem: FC<IMenuItem> = ({
  label,
  onClick,
  path,
  showNewIcon = false,
}) => {
  return (
    <ListItem component="div">
      <Button
        disableRipple
        component={NavLink}
        endIcon={showNewIcon ? <NewIcon /> : null}
        onClick={onClick}
        to={path}
      >
        {label}
      </Button>
    </ListItem>
  );
};

export default MenuItem;
