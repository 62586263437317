import { FC } from "react";
import { TextFieldProps } from "@mui/material";

import { StyledTextField } from "./CustomInput.styles";

export type CustomInputElement = HTMLInputElement | HTMLTextAreaElement;

const CustomInput: FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

export default CustomInput;
