import { FC, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

import {
  LayoutWrapper,
  ContentWrapper,
  OutletWrapper,
} from "./SidebarLayout.styles";
import {
  LoginModal,
  ForgotPasswordModal,
  RegisterModal,
} from "src/features/authentication";
import { MainSidebar, WalletSidebar } from "./Sidebar";
import Header from "./Header";
import Subheader from "./Subheader";
import Footer from "src/components/footer/Footer";
import { BetSlip } from "src/features/betslip";
import appConfig from "src/config/appConfig";
import DisabledModal from "src/features/authentication/components/disabled";
import UnverifiedDialog from "src/features/authentication/components/unverified";

const SidebarLayout: FC = () => {
  const { pathname } = useLocation();

  const showBetSlip = useMemo(() => {
    return !appConfig.notShowBetslip.includes(pathname);
  }, [pathname]);

  return (
    <>
      <RegisterModal />
      <DisabledModal />
      <LoginModal />
      <ForgotPasswordModal />
      <UnverifiedDialog />
      <LayoutWrapper>
        <Header />
        <MainSidebar />
        <WalletSidebar />
        <ContentWrapper showBetSlip={showBetSlip}>
          <Subheader />
          <OutletWrapper>
            <Outlet />
          </OutletWrapper>
          <Footer />
        </ContentWrapper>
        {showBetSlip && <BetSlip />}
      </LayoutWrapper>
    </>
  );
};

export default SidebarLayout;
