import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import {
  CircularProgress,
  CircularProgressProps,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";

import appConfig from "src/config/appConfig";

export const StyledCircularProgress = styled(
  CircularProgress
)<CircularProgressProps>(({ theme }) => ({
  color: theme.colors.neutral.black[100],
}));

export const StyledLoadingButton = styled(LoadingButton)<LoadingButtonProps>(
  ({ fullWidth, theme }) => ({
    backgroundColor: theme.colors.secondary.main,
    border: "2px solid " + theme.colors.secondary.main,

    height: 40,
    padding: 14,
    width: "100%",

    [theme.breakpoints.up(appConfig.breakMobileView)]: {
      alignSelf: "flex-end",
      width: fullWidth ? "100%" : "200px",
    },

    "&:disabled": {
      opacity: "40%",
    },

    "&:hover": {
      backgroundColor: theme.colors.secondary.dark,
      border: "2px solid " + theme.colors.secondary.dark,
    },
  })
);

export const StyledTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.colors.neutral.black[100],
    fontWeight: 700,
    textTransform: "capitalize",
  })
);
