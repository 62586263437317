import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(1.25),

  borderRadius: "10px",
  backgroundColor: theme.general.background,
}));

export const StyledText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.text.light,
  fontSize: 12,
  fontWeight: 400,
}));
