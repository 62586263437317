import { styled } from "@mui/material";
import { GridPagination } from "@mui/x-data-grid";

export const StyledGridPagination = styled(GridPagination)(({ theme }) => ({
  borderRadius: 5,
  border: "1px solid " + theme.colors.neutral.white[100],

  marginTop: theme.spacing(2.5),

  "& .MuiTablePagination-toolbar": {
    margin: theme.spacing(1.25, 2.5),
    minHeight: "0 !important",
    padding: "0 !important",
  },

  "& .MuiTablePagination-selectLabel": {
    color: theme.colors.neutral.white[100],
    fontSize: 12,
    fontWeight: 700,
  },

  "& .MuiTablePagination-select": {
    color: theme.colors.neutral.white[100],
    fontSize: 12,
    fontWeight: 700,

    "&:focus": {
      backgroundColor: "inherit",
    },
  },

  "& .MuiTablePagination-selectIcon": {
    color: theme.colors.neutral.white[100],
  },

  "& .MuiTablePagination-displayedRows": {
    color: theme.colors.neutral.white[100],
    fontSize: 12,
    fontWeight: 700,
  },

  "& .MuiTablePagination-actions": {
    color: theme.colors.neutral.white[100],
  },
}));
