import { FC } from "react";

import { Wrapper, SubWrapper, StyledText } from "../SportMultiBet.styles";
import { StyledInput } from "../../betItems/input/BetAmountInput.styles";

import { useMultiBetItem } from "./MultiBetItem.hooks";

import { MultiModeStake } from "src/features/betslip/store/betSlipSlice.contracts";

type MultiBetItemProps = {
  label: string;
  stake: MultiModeStake;
  initialValue: number;
  numberOfBets: number;
  price?: number;
};

const MultiBetItem: FC<MultiBetItemProps> = ({
  label,
  stake,
  initialValue,
  numberOfBets,
  price,
}) => {
  const { inputError, inputChangeHandler, localValue } = useMultiBetItem(
    stake,
    initialValue
  );

  return (
    <Wrapper>
      <StyledText>{label}</StyledText>
      <SubWrapper alignItems="center">
        {price ? (
          <StyledText mr={(theme) => theme.spacing(2)}>
            {(price - 1).toFixed(2)}
          </StyledText>
        ) : null}
        <StyledText>{`${numberOfBets}x`}</StyledText>
        <StyledInput
          error={inputError}
          value={localValue}
          onChange={inputChangeHandler}
        />
      </SubWrapper>
    </Wrapper>
  );
};

export default MultiBetItem;
