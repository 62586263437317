import { useState } from "react";
import { toast } from "react-toastify";
import { AxiosRequestConfig } from "axios";

import ApiClient from "src/lib/axiosWrapper";

import { NetworkStatusEnums } from "src/utils/enums/networkStatus";
import logger from "src/utils/logger";

/**
 * Custom hook for managing file uploads
 * @param baseUrl base URL of API that you want to connect to
 * @returns A function that uploads the provided file to the specified API.
 */
export const useFileUpload = (baseUrl: string) => {
  const [uploadState, setUploadState] = useState<NetworkStatusEnums>(
    NetworkStatusEnums.IDLE
  );

  const api = new ApiClient(baseUrl);

  /**
   * Function that uploads the file to the specified API.
   * @param method API request method to be used
   * @param url Endpoint the file must be uploaded to
   * @param data File that must be uploaded
   * @param config Optional request configuration
   */
  const uploadFile = async (
    method: "POST" | "PUT",
    url: string,
    data: File,
    config?: AxiosRequestConfig
  ) => {
    try {
      setUploadState(NetworkStatusEnums.LOADING);

      const resp =
        method === "POST"
          ? await api.post(url, data, config)
          : await api.put(url, data, config);
      return resp;
    } catch (err) {
      setUploadState(NetworkStatusEnums.FAILED);
      toast.error("There has been an error.");
      logger.error(err);
    } finally {
      setUploadState(NetworkStatusEnums.IDLE);
    }
  };

  return { uploadState, uploadFile };
};
