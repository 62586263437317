import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
  selectMultiModeStakes,
  selectSportBets,
  selectSportBetSlipMode,
  setExpandedBets,
  toggleValidate,
  updateSportBetSlipBets,
} from "../../store/sport/sportBetSlipSlice";

import { useCreateCombinedBets } from "../../hooks/createCombinedBets";
import { useCreateMultiBet } from "../../hooks/createMultiBet";

import { SportBet } from "../../models/sport";

export const useSportBets = () => {
  const dispatch = useAppDispatch();
  const betSlipMode = useAppSelector(selectSportBetSlipMode);
  const sportBets = useAppSelector(selectSportBets);
  const multiModeStakes = useAppSelector(selectMultiModeStakes);

  const createCombinedBets = useCreateCombinedBets();
  const createMultiBet = useCreateMultiBet();

  useEffect(() => {
    const newBets: SportBet[] = [];

    if (betSlipMode === "multiples") {
      if (sportBets.length >= 3) {
        // add doubles
        const doubleBets = createCombinedBets(
          sportBets,
          "doubles",
          multiModeStakes.doublesStake
        );
        newBets.push(...doubleBets);
      }

      if (sportBets.length >= 4) {
        // add trebles
        const trebleBets = createCombinedBets(
          sportBets,
          "trebles",
          multiModeStakes.treblesStake
        );
        newBets.push(...trebleBets);
      }

      if (sportBets.length >= 5) {
        // add quads
        const quadBets = createCombinedBets(
          sportBets,
          "quads",
          multiModeStakes.quadsStake
        );
        newBets.push(...quadBets);
      }

      if (sportBets.length >= 2) {
        const multiBet = createMultiBet(
          sportBets,
          "multi",
          multiModeStakes.multipleStake
        );
        newBets.push(multiBet);
      }
    }

    dispatch(setExpandedBets({ bets: newBets }));

    dispatch(toggleValidate({ validate: true }));
    dispatch(updateSportBetSlipBets({ newBets: [...sportBets, ...newBets] }));
  }, [
    betSlipMode,
    createCombinedBets,
    createMultiBet,
    dispatch,
    multiModeStakes,
    sportBets,
  ]);

  return sportBets;
};
