import { baseQueryWithTokenExpirationCheck } from "./config/query.config";
import { TAG_TYPES } from "./config/apiTags";
import { env } from "src/env";
import { createApi } from '@reduxjs/toolkit/query/react';

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: baseQueryWithTokenExpirationCheck(env.REACT_APP_BASE_API_URL),
  tagTypes: [...TAG_TYPES],
  endpoints: () => ({}),
});

export const prodApi = createApi({
  reducerPath: "prodApi",
  baseQuery: baseQueryWithTokenExpirationCheck(env.REACT_APP_PROD_API_URL),
  tagTypes: [...TAG_TYPES],
  endpoints: () => ({}),
});
