import {
  Box,
  BoxProps,
  Dialog,
  DialogProps,
  IconButton,
  IconButtonProps,
  styled,
} from "@mui/material";

import appConfig from "src/config/appConfig";

export const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  // top: theme.header.height,
  // height: "fit-content",
  margin: 0,
  padding: 0,

  height: `calc(100vh - ${theme.header.height})`,

  "& .MuiDialog-container": {
    alignItems: "start",
    height: "100%",
  },

  "& .MuiDialog-paper": {
    borderRadius: "10px",
    backgroundColor: theme.colors.neutral.black[100],

    margin: theme.spacing(1),
    maxHeight: "calc(100% - 60px)",
    width: theme.breakpoints.values[appConfig.breakMobileView],
  },
}));

export const DialogContainer = styled(Box)<BoxProps>(({ theme }) => ({
  position: "relative",
  height: "100%",
  width: "100%",
}));

interface StyledIconButtonProps extends IconButtonProps {
  pos: "left" | "right";
}

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "pos",
})<StyledIconButtonProps>(({ theme, pos }) => ({
  position: "absolute",
  top: theme.spacing(2),
  left: pos === "left" ? theme.spacing(2) : undefined,
  right: pos === "right" ? theme.spacing(2) : undefined,
}));
