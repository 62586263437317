import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "src/store/store";
import { LottoBetSlipState } from "../betSlipSlice.contracts";
import { LottoBet } from "../../models/lotto";

import { emptyLottoBetSlip, getBetSlipsFromStorage } from "../initialBetSlips";
import {
  calculateLotto,
  updateLottoPayout,
} from "../../utils/payoutAndStakeCalc";

const { lottoBetSlip } = getBetSlipsFromStorage();

const initialState: LottoBetSlipState = {
  betSlip: lottoBetSlip,
};

const lottoBetSlipSlice = createSlice({
  name: "betSlip",
  initialState,
  reducers: {
    //#region Lotto betslip
    addLottoBet(state, action: PayloadAction<{ bet: LottoBet }>) {
      const { bet } = action.payload;
      state.betSlip.bets.push(bet);

      const { totalPayout, totalStake } = calculateLotto(state.betSlip.bets);
      state.betSlip.totalPayout = totalPayout;
      state.betSlip.totalStake = totalStake;
    },
    clearLottoBets(state) {
      state.betSlip = emptyLottoBetSlip;
    },
    removeLottoBet(state, action: PayloadAction<{ betId: string, betIndex?: number }>) {
      //If the betIndex is provided, remove the bet at that index with the ID
      //It could be that an item with the same ID exists in the betslip
      const { betId, betIndex } = action.payload;
      let updatedBets = [];
      if (betIndex || betIndex === 0) {
        const array = state.betSlip.bets;

        //Find the index of the bet with the ID that matches the selected index
        if (betIndex >= 0 && betIndex < array.length && array[betIndex].id === betId) {
          array.splice(betIndex, 1);
        }
        updatedBets = array;
      } else {
        updatedBets = state.betSlip.bets.filter((bet) => bet.id !== betId);
      }

      state.betSlip.bets = updatedBets;

      const { totalPayout, totalStake } = calculateLotto(updatedBets);
      state.betSlip.totalPayout = totalPayout;
      state.betSlip.totalStake = totalStake;
    },
    updateLottoBetAmount(
      state,
      action: PayloadAction<{ betId: string; amount: number }>
    ) {
      const { betId, amount } = action.payload;
      const updatedBets = state.betSlip.bets.map((bet) => {
        if (bet.id === betId) {
          const newBet: LottoBet = { ...bet, stake: amount };
          return updateLottoPayout(newBet);
        } else {
          return bet;
        }
      });

      state.betSlip.bets = updatedBets;

      const { totalPayout, totalStake } = calculateLotto(updatedBets);
      state.betSlip.totalPayout = totalPayout;
      state.betSlip.totalStake = totalStake;
    },
    //#endregion Lotto betslip
  },
});

export default lottoBetSlipSlice.reducer;
export const {
  addLottoBet,
  clearLottoBets,
  removeLottoBet,
  updateLottoBetAmount,
} = lottoBetSlipSlice.actions;

/* Lotto betslip */
export const selectLottoBetSlip = (state: RootState) =>
  state.lottoBetSlip.betSlip;
export const selectLottoBets = (state: RootState) =>
  state.lottoBetSlip.betSlip.bets;
