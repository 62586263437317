import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "start",
  padding: theme.spacing(1.5),

  borderRadius: "10px",
  backgroundColor: theme.general.background,
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  color: theme.colors.text.light,
}));

export const SpecificationWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.colors.text.light,
}));
