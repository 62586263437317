import { Box, BoxProps, styled } from "@mui/material";

export const HeaderBox = styled(Box)<BoxProps>(({ theme }) => ({
  border: "none",
  borderRadius: 0,
  borderBottom: "1px solid " + theme.colors.secondary.main,
  borderRight: "3px solid " + theme.colors.secondary.main,

  color: theme.colors.secondary.main,
  fontSize: 12,
  fontWeight: 700,
  textAlign: "center",

  height: theme.spacing(8),
  width: theme.spacing(11.25),

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const CellBox = styled(Box)<BoxProps>(({ theme }) => ({
  border: "none",
  borderRadius: 0,
  borderTop: "1px solid " + theme.colors.neutral.white[60],
  borderBottom: "1px solid " + theme.colors.neutral.white[60],
  borderRight: "2px solid " + theme.colors.neutral.white[60],

  color: theme.colors.neutral.white[60],
  fontSize: 10,
  fontWeight: 400,
  textAlign: "center",

  height: theme.spacing(8),
  width: theme.spacing(10),

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  padding: theme.spacing(0.25),
}));
