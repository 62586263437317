import { ChangeEvent, useEffect, useState } from "react";

import { useAppDispatch } from "src/hooks/hooks";
import { updateMultiModeStake } from "src/features/betslip/store/sport/sportBetSlipSlice";

import { MultiModeStake } from "src/features/betslip/store/betSlipSlice.contracts";

type InputChangeEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export const useMultiBetItem = (
  stake: MultiModeStake,
  initialValue: number
) => {
  const [localValue, setLocalValue] = useState<string>(String(initialValue));
  const [inputError, setInputError] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  function inputChangeHandler(event: InputChangeEvent) {
    event.preventDefault();

    const newValue = event.target.value;

    try {
      setLocalValue(newValue);
    } catch (err) {
      setInputError(true);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const newStake = parseFloat(localValue);
      if (!isNaN(newStake)) {
        dispatch(updateMultiModeStake({ stake, amount: newStake }));
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [dispatch, localValue, stake]);

  useEffect(() => {
    setLocalValue(String(initialValue));
  }, [initialValue]);

  return { inputError, inputChangeHandler, localValue };
};
