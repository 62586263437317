import { Box, BoxProps, styled } from "@mui/material";

export const TickWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.success.main,
  borderRadius: "50%",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(0.2),
}));
