import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
  selectAllSelectedLeagues,
  setAllLeagues,
} from "../../store/sportsSlice";

import { FixtureItem } from "../../models/FixtureItem";

import {
  getUniqueTournaments,
  TournamentItem,
} from "../../utils/getUniqueTournaments";

export const useTournaments = (fixtures: FixtureItem[]) => {
  const [allTournaments, setAllTournaments] = useState<TournamentItem[]>([]);
  const [filteredTournaments, setFilteredTournaments] = useState<
    TournamentItem[]
  >([]);

  const dispatch = useAppDispatch();
  const selectedLeagues = useAppSelector(selectAllSelectedLeagues);

  useEffect(() => {
    const ts = getUniqueTournaments(fixtures);
    setAllTournaments(ts);
    const tournamentStrings = ts.map((t) => t.tournament);
    const uniqueTournamentStrings = tournamentStrings.filter((t, index) => {
      const tournamentIndex = tournamentStrings.findIndex(
        (tournament) => tournament === t
      );
      return tournamentIndex === index;
    });
    dispatch(setAllLeagues({ leagues: uniqueTournamentStrings }));
  }, [dispatch, fixtures]);

  useEffect(() => {
    if (selectedLeagues.length > 0) {
      const newTournaments = allTournaments.filter((tournament) =>
        selectedLeagues.includes(tournament.tournament)
      );
      setFilteredTournaments(newTournaments);
    } else {
      setFilteredTournaments(allTournaments);
    }
  }, [allTournaments, selectedLeagues]);

  return filteredTournaments;
};
