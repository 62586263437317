import { ChangeEvent, FC } from "react";
import { toast } from "react-toastify";

import { ConfirmWrapper } from "./ConfirmPassword.styles";
import { ForgotTitle } from "../ForgotPassword.styles";
import { PasswordInput, CustomInputElement } from "src/components/Inputs";
import PasswordSpecifications from "../../../../../components/password-specifications/PasswordSpecifications";
import { PrimaryButton } from "src/components/buttons";

import {
  IInitialState,
  IAction,
  actionTypes,
} from "src/features/authentication/reducers/forgotFormReducer";

type ConfirmPasswordProps = {
  loading: boolean;
  state: IInitialState;
  dispatch: React.Dispatch<IAction>;
  updateContinueClick: () => void;
};

const ConfirmPassword: FC<ConfirmPasswordProps> = ({
  loading,
  state,
  dispatch,
  updateContinueClick,
}) => {
  const newPasswordChangeHandler = (event: ChangeEvent<CustomInputElement>) => {
    event.preventDefault();

    dispatch({
      type: actionTypes.NEW_PASSWORD,
      payload: event.target.value,
    });
  };

  const confirmPasswordChangeHandler = (
    event: ChangeEvent<CustomInputElement>
  ) => {
    event.preventDefault();

    dispatch({
      type: actionTypes.CONFIRM_PASSWORD,
      payload: event.target.value,
    });
  };

  const updateClickHandler = () => {
    if (
      state.newPassword.value.trim() === "" ||
      state.confirmPassword.value.trim() === ""
    ) {
      return;
    }

    if (!state.newPassword.valid || !state.confirmPassword.valid) {
      return;
    }

    if (state.newPassword.value !== state.confirmPassword.value) {
      toast.error("Passwords must match");
      return;
    }

    updateContinueClick();
  };

  return (
    <ConfirmWrapper>
      <ForgotTitle>Enter new password</ForgotTitle>
      <PasswordInput
        inputLabel="New password"
        id="forgot-new-password-input"
        error={!state.newPassword.valid}
        helperText={state.newPassword.error}
        value={state.newPassword.value}
        onChange={newPasswordChangeHandler}
      />
      <PasswordSpecifications background="gray" />
      <PasswordInput
        inputLabel="Confirm password"
        id="forgot-confirm-password-input"
        error={!state.confirmPassword.valid}
        helperText={state.confirmPassword.error}
        value={state.confirmPassword.value}
        onChange={confirmPasswordChangeHandler}
      />
      <PrimaryButton
        loading={loading}
        fullWidth
        fontSize={16}
        onClick={updateClickHandler}
      >
        Update
      </PrimaryButton>
    </ConfirmWrapper>
  );
};

export default ConfirmPassword;
