import { v4 } from "uuid";

import { SportBetSlip, SportBetSlipIM } from "../models/sport";

/**
 * Adds a key to the provided bet slip for api calls
 * @param betSlip - betslip to be mapped
 * @returns The bet slip with the added key property
 */
export const sportBetSlipMapper = (betSlip: SportBetSlip) => {
  const betSlipInsert: SportBetSlipIM = {
    ...betSlip,
    key: v4(),
  };

  return betSlipInsert;
};
