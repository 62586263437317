import { SportIconProps } from "src/components/icons/SportIcon";

export function getSportIconName(
  isActive: boolean,
  label: string
): SportIconProps["name"] {
  switch (label) {
    case "Rugby":
      return isActive ? "RugbyDark" : "RugbyLight";
    case "Tennis":
      return isActive ? "TennisDark" : "TennisLight";
    case "Basketball":
      return isActive ? "BasketballDark" : "BasketballLight";
    case "Cricket":
      return isActive ? "CricketDark" : "CricketLight";
    default:
      return isActive ? "SoccerDark" : "SoccerLight";
  }
}
