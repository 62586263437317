import logger from "src/utils/logger";
import { _getCognitoUser } from "./_getCognitoUser";

export const forgotPassword = async (cellphone: string) => {
    try {
        const cognitoUser = _getCognitoUser(cellphone);
        cognitoUser.forgotPassword({
            onSuccess: function (result) {
                logger.log('call result: ' + result);
            },
            onFailure: function (err) {
                // alert(err.message || JSON.stringify(err));
                throw new Error(err.message);
            },
        });
    } catch (error: any) {
        throw new Error('er');
    }
}