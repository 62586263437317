import { useEffect } from "react";

import { useAppSelector } from "src/hooks/hooks";
import {
  selectSportBetSlipMode,
  selectSportBetSlip,
  selectSportBets,
} from "../store/sport/sportBetSlipSlice";
import { selectLottoBetSlip } from "../store/lotto/lottoBetSlipSlice";

import { BetSlipMode } from "../store/betSlipSlice.contracts";
import { LottoBetSlip } from "../models/lotto";
import { SportBet, SportBetSlip } from "../models/sport";

import betSlipConfig from "../config/betSlipConfig";

interface BetSlipStorageItem {
  betSlips: {
    sportBetSlip: SportBetSlip;
    lottoBetSlip: LottoBetSlip;
  };
  betSlipMode: BetSlipMode;
}

interface BetsStorageItem {
  sportBets: SportBet[];
}

/**
 * Custom hook that handles persisting bet slip state in local storage
 */
export const useBetSlipStorage = () => {
  const betSlipMode = useAppSelector(selectSportBetSlipMode);
  const lottoBetSlip = useAppSelector(selectLottoBetSlip);
  const sportBetSlip = useAppSelector(selectSportBetSlip);

  useEffect(() => {
    const storageItem: BetSlipStorageItem = {
      betSlips: {
        lottoBetSlip,
        sportBetSlip,
      },
      betSlipMode,
    };

    localStorage.setItem(
      betSlipConfig.betSlipLocalStorageKey,
      JSON.stringify(storageItem)
    );
  }, [betSlipMode, lottoBetSlip, sportBetSlip]);
};

export const useBetsStorage = () => {
  const sportBets = useAppSelector(selectSportBets);

  useEffect(() => {
    const storageItem: BetsStorageItem = {
      sportBets,
    };
    localStorage.setItem(
      betSlipConfig.betsLocalStorageKey,
      JSON.stringify(storageItem)
    );
  }, [sportBets]);
};
