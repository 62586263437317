import { FC } from "react";

import {
  SelectionWrapper,
  SelectionText,
  SelectionButton,
} from "./FixtureSelection.styles";
import { Icon } from "src/components/icons";

import useDisplayState from "src/hooks/useDisplay";
import { useSportBetSlipSlice } from "src/features/betslip";

import {
  FixtureItem,
  FixtureMarketItem,
  FixtureSelectionItem,
} from "../../../models/FixtureItem";

import { determinePrice } from "../../../utils/fixtureUtils";
import { getSelectionTitle, isBetExpired } from "../../../utils/selectionUtils";

import { CompetitorMap } from "src/features/sports/models/CompetitorMap";

type FixtureSelectionProps = {
  fixture: FixtureItem;
  market: FixtureMarketItem;
  competitors: CompetitorMap[];
  selection: FixtureSelectionItem;
};

const FixtureSelection: FC<FixtureSelectionProps> = ({
  fixture,
  market,
  competitors,
  selection,
}) => {
  const { value } = useDisplayState();
  const { addBet, removeBet, isBetActive } = useSportBetSlipSlice();

  const betId = fixture.id + "-" + market.id + "-" + selection.id;
  const isActive = isBetActive(betId);
  const isExpired = isBetExpired(market.wagerCloseDate);
  const title = getSelectionTitle(selection.name, competitors);
  const price = determinePrice(selection.price.dec - 1, value === "decimal");

  function onClickHandler() {
    if (isActive) {
      removeBet(betId);
    } else {
      addBet(fixture, market, selection);
    }
  }

  if (isExpired) {
    <SelectionWrapper height="38px" width="60px">
      <Icon name="GoldLock" />
    </SelectionWrapper>;
  }

  return (
    <SelectionWrapper>
      <SelectionText>{title}</SelectionText>
      <SelectionButton isActive={isActive} onClick={onClickHandler}>
        {price}
      </SelectionButton>
    </SelectionWrapper>
  );
};

export default FixtureSelection;
