import { Box, BoxProps, Divider, DividerProps, styled } from "@mui/material";

export const StyledDivider = styled(Divider)<DividerProps>(({ theme }) => ({
  height: "2px",
  width: "80%",
  backgroundColor: theme.colors.secondary.main,
}));

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 0,

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2.5),
  padding: theme.spacing(1),
}));

export const RowBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(4.5),
}));
