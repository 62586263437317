import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: 236,
  width: 162,

  backgroundColor: theme.colors.neutral.black[100],

  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  borderRadius: "12px",
  border: "1.5px solid " + theme.colors.secondary.main,
}));

export const ImageBox = styled(Box)<BoxProps>(() => ({
  height: "60%",
  width: "100%",

  overflow: "hidden",
  backgroundPosition: "center",
  backgroundSize: "cover",
}));

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1.25),
  padding: theme.spacing(1.25),
}));

export const StyledTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontSize: 12,
    fontWeight: 700,
    textAlign: "center",
    textTransform: "uppercase",
    color: theme.colors.text.light,

    alignSelf: "center",
  })
);

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  height: "40px",
  // width: "100%",

  backgroundColor: theme.colors.secondary.main,
  border: "2px solid " + theme.colors.secondary.main,

  fontSize: 16,
  fontWeight: 700,
  textTransform: "uppercase",
  color: theme.colors.neutral.black[100],

  "&:hover": {
    backgroundColor: theme.colors.secondary.dark,
    border: "2px solid " + theme.colors.secondary.dark,
  },
}));
