/**
 * Derives the user's full cellphone number.
 * @param countryCode - Cellphone code for specified country
 * @param cellphone - User's cellphone number
 * @returns The user's full cellphone number
 */
export const getFullNumber = (countryCode: string, cellphone: string) => {
  return (
    countryCode + (cellphone.charAt(0) === "0" ? cellphone.slice(1) : cellphone)
  );
};

/**
 * Hides the first 6 digits of the user's full cellphone number
 * @param fullNumber - The user's full cellphone number (output of getFullNumber)
 * @returns The user's full cellphone number with the first 6 digits hidden
 */
export const getMaskedNumber = (fullNumber: string) => {
  return fullNumber.replace(/.(?=.{4})/g, "*");
};
