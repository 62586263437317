import { HelpFileResponse } from "src/models/helpFiles";
import { prodApi } from "./baseApi.service";

const helpFilesService = prodApi.injectEndpoints({
  endpoints: (builder) => ({
    getHelpFiles: builder.query<HelpFileResponse, void>({
      query: () => {
        return {
          url: "help-files",
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetHelpFilesQuery, useLazyGetHelpFilesQuery } =
  helpFilesService;
