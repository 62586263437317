import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import {
  selectIdDoc,
  selectPoADoc,
  selectDeclarationAccepted,
  selectFundsSource,
  setIdDoc,
  clearIdDoc,
  setPoADoc,
  clearPoADoc,
  setDeclarationAccepted,
  setFundsSource,
} from '../store/ficaSlice';
import logger from 'src/utils/logger';

/**
 * Custom hook that interacts with the fica redux slice.
 * Handles selecting values from the store and dispatching actions to the store.
 */
export const useFica = () => {
  const dispatch = useAppDispatch();

  const idDoc = useAppSelector(selectIdDoc);
  const poADoc = useAppSelector(selectPoADoc);
  const declarationAccepted = useAppSelector(selectDeclarationAccepted);
  const fundsSource = useAppSelector(selectFundsSource);

  const addIdDoc = useCallback(
    (doc: File) => {
      logger.log(encodeURI(doc.name));
      dispatch(setIdDoc({ doc }));
    },
    [dispatch]
  );

  const removeIdDoc = () => {
    dispatch(clearIdDoc());
  };

  const addPoADoc = useCallback(
    (doc: File) => {
      logger.log(encodeURI(doc.name));
      dispatch(setPoADoc({ doc }));
    },
    [dispatch]
  );

  const removePoADoc = () => {
    dispatch(clearPoADoc());
  };

  const removeDocs = () => {
    removeIdDoc();
    removePoADoc();
  };

  const updateDeclarationSelection = (accepted: boolean) => {
    dispatch(setDeclarationAccepted({ accepted }));
  };

  const updateFundsSource = (source: number) => {
    dispatch(setFundsSource({ source }));
  };

  return {
    idDoc,
    poADoc,
    declarationAccepted,
    fundsSource,
    addIdDoc,
    removeIdDoc,
    addPoADoc,
    removePoADoc,
    removeDocs,
    updateDeclarationSelection,
    updateFundsSource,
  };
};
