import { useAppDispatch } from "src/hooks/hooks";
import { toggleBetSlipPreferences } from "../../store/betSlipSlice";
import {
  clearSportBets,
  toggleBetSlipMode,
} from "../../store/sport/sportBetSlipSlice";

// TODO: make clear bets function customizable
export const useSelectionRetainer = () => {
  const dispatch = useAppDispatch();

  const trashClickHandler = () => {
    dispatch(toggleBetSlipMode({ mode: "singles" }));
    dispatch(clearSportBets());
  };

  const settingsClickHandler = () => {
    dispatch(toggleBetSlipPreferences({ open: true }));
  };

  return { trashClickHandler, settingsClickHandler };
};
