import { FC } from "react";
import { Stack } from "@mui/material";
import {
  GridColDef,
  GridPaginationModel,
  GridRowsProp,
} from "@mui/x-data-grid";
import { ErrorTwoTone } from "@mui/icons-material";

import { StyledDataGrid } from "./Table.styles";
import CustomPagination from "./components/CustomPagination";

interface TableProps {
  rows: GridRowsProp;
  columns: GridColDef[];
  pageSizeOptions: number[];
  loading: boolean;
  refetch: () => void;
  isFetching: boolean;
  paginationModel: GridPaginationModel;
  setPaginationModel: (paginationModel: GridPaginationModel) => void;
  totalRows: number;
  error?: boolean;
}

const Table: FC<TableProps> = ({
  rows,
  columns,
  pageSizeOptions,
  loading,
  isFetching,
  paginationModel,
  setPaginationModel,
  totalRows,
  error,
}) => {
  return (
    <StyledDataGrid
      density="compact"
      slots={{
        pagination: CustomPagination,
        noRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            {error ? (
              <ErrorTwoTone fontSize="large" color="error" />
            ) : (
              "No rows"
            )}
          </Stack>
        ),
      }}
      rows={rows}
      columns={columns}
      pagination={true}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      paginationMode="client"
      pageSizeOptions={pageSizeOptions}
      disableRowSelectionOnClick
      loading={loading || isFetching}
    />
  );
};

export default Table;
