import { FC } from "react";
import { InputAdornment, Tooltip } from "@mui/material";

import { Icon } from "src/components/icons";

const LockedIcon: FC = () => {
  return (
    <InputAdornment position="end">
      <Tooltip title={"This input is locked"}>
        <Icon name="GoldLock" />
      </Tooltip>
    </InputAdornment>
  );
};

export default LockedIcon;
