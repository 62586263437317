import { useNavigate } from "react-router-dom";

import { useSidebarContext } from "src/context/SidebarContext";

export const useWalletHeaderNav = () => {
  const { closeWalletDrawer } = useSidebarContext();
  const navigate = useNavigate();

  const navigateToDeposit = () => {
    closeWalletDrawer();
    navigate("/account/deposit");
  };

  return navigateToDeposit;
};
