import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const RegisterWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  gap: theme.spacing(1.25),
  padding: theme.spacing(5),
}));

export const RegisterTitle = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontSize: 25,
    fontWeight: 700,
    color: theme.colors.neutral.white[100],
  })
);

export const RegisterText = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontSize: 14,
    fontWeight: 400,
    color: theme.colors.text.light,
  })
);

export const ResendText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.colors.secondary.main,
}));
