import { Box, BoxProps, styled } from "@mui/material";

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: "100%",
  width: "90px",
  position: "absolute",
  top: 0,

  display: "flex",
  justifyContent: "flex-start",
  alignContent: "center",
  padding: theme.spacing(0.75),

  zIndex: 6,
}));
