import { ReactComponent as AccountLarge } from "./other/account-large.svg";
import { ReactComponent as Account } from "./other/account.svg";
import { ReactComponent as FilterDark } from "./other/filter-dark.svg";
import { ReactComponent as FilterLight } from "./other/filter-light.svg";
import { ReactComponent as Gear } from "./other/gear.svg";
import { ReactComponent as GoldLock } from "./other/gold-lock.svg";
import { ReactComponent as Info } from "./other/info.svg";
import { ReactComponent as Menu } from "./other/menu.svg";
import { ReactComponent as Star } from "./other/star.svg";
import { ReactComponent as TrashDark } from "./other/trash-dark.svg";
import { ReactComponent as TrashLight } from "./other/trash-light.svg";
import { ReactComponent as Upload } from "./other/upload.svg";
import { ReactComponent as Wallet } from "./other/wallet.svg";

import { ReactComponent as BasketballDark } from "./sport/basketball-dark.svg";
import { ReactComponent as BasketballLight } from "./sport/basketball-light.svg";
import { ReactComponent as BoxingDark } from "./sport/boxing-dark.svg";
import { ReactComponent as BoxingLight } from "./sport/boxing-light.svg";
import { ReactComponent as ChessDark } from "./sport/chess-dark.svg";
import { ReactComponent as ChessLight } from "./sport/chess-light.svg";
import { ReactComponent as CricketDark } from "./sport/cricket-dark.svg";
import { ReactComponent as CricketLight } from "./sport/cricket-light.svg";
import { ReactComponent as RugbyDark } from "./sport/rugby-dark.svg";
import { ReactComponent as RugbyLight } from "./sport/rugby-light.svg";
import { ReactComponent as SoccerDark } from "./sport/soccer-dark.svg";
import { ReactComponent as SoccerLight } from "./sport/soccer-light.svg";
import { ReactComponent as TennisDark } from "./sport/tennis-dark.svg";
import { ReactComponent as TennisLight } from "./sport/tennis-light.svg";

export const Icons = {
  AccountLarge,
  Account,
  FilterDark,
  FilterLight,
  Gear,
  GoldLock,
  Info,
  Menu,
  Star,
  TrashDark,
  TrashLight,
  Upload,
  Wallet,
};

export const SportIcons = {
  BasketballDark,
  BasketballLight,
  BoxingDark,
  BoxingLight,
  ChessDark,
  ChessLight,
  CricketDark,
  CricketLight,
  RugbyDark,
  RugbyLight,
  SoccerDark,
  SoccerLight,
  TennisDark,
  TennisLight,
};
