import { FC } from "react";

import {
  Wrapper,
  LottoBall,
  LottoBallWrapper,
  LottoBallText,
  Underline,
} from "./LottoBalls.styles";

import { LottoBetLegSelection } from "src/features/betslip/models/lotto";

type LottoBallsProps = {
  lottoBalls: LottoBetLegSelection[];
};

const LottoBalls: FC<LottoBallsProps> = ({ lottoBalls }) => {
  return (
    <Wrapper>
      {lottoBalls.map((lottoBall, index) => (
        <LottoBallWrapper key={lottoBall.label + index}>
          <LottoBall>
            <LottoBallText>{lottoBall.label}</LottoBallText>
          </LottoBall>
          {!!lottoBall.absoluteDrawPosition && <Underline />}
        </LottoBallWrapper>
      ))}
    </Wrapper>
  );
};

export default LottoBalls;
