import { MyBetsDataRow, MyBetsMobileDataRow } from "../models/DataRow";

export const toMobileDataTable = (items: MyBetsDataRow[]) => {
  const rows: MyBetsMobileDataRow = {
    id: items.map((item) => item.id),
    betDate: items.map((item) => item.betDate),
    ticketRef: items.map((item) => item.ticketRef),
    fixtureDate: items.map((item) => item.fixtureDate),
    fixture: items.map((item) => item.fixture),
    market: items.map((item) => item.market),
    selection: items.map((item) => item.selection),
    price: items.map((item) => item.price),
    stake: items.map((item) => item.stake),
    tax: items.map((item) => item.tax),
    potentialPayout: items.map((item) => item.potentialPayout),
    status: items.map((item) => item.status),
    repeatBet: items.map((item) => item.repeatBet),
    subRows: items.map((item) => ({
      id: item.subRows.map((item) => item.id),
      betDate: item.subRows.map((item) => item.betDate),
      ticketRef: item.subRows.map((item) => item.ticketRef),
      fixtureDate: item.subRows.map((item) => item.fixtureDate),
      fixture: item.subRows.map((item) => item.fixture),
      market: item.subRows.map((item) => item.market),
      selection: item.subRows.map((item) => item.selection),
      price: item.subRows.map((item) => item.price),
      stake: item.subRows.map((item) => item.stake),
      tax: item.subRows.map((item) => item.tax),
      potentialPayout: item.subRows.map((item) => item.potentialPayout),
      status: item.subRows.map((item) => item.status),
    })),
  };

  return rows;
};
