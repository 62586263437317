import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import {
  CircularProgress,
  CircularProgressProps,
  lighten,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";

export const StyledCircularProgress = styled(
  CircularProgress
)<CircularProgressProps>(({ theme }) => ({
  color: theme.colors.neutral.white[100],
}));

export const StyledLoadingButton = styled(LoadingButton)<LoadingButtonProps>(
  ({ theme }) => ({
    backgroundColor: theme.header.background,
    border: "2px solid " + theme.colors.secondary.main,
    color: "white",

    height: 40,
    padding: 14,

    "&:disabled": {
      border: "2px solid " + theme.colors.secondary.dark,
      opacity: "60%",
    },

    "&:hover": {
      backgroundColor: lighten(theme.colors.neutral.black[100], 0.1),
      border: "2px solid " + theme.colors.secondary.dark,
      opacity: "80%",
    },
  })
);

export const StyledTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.colors.neutral.white[100],
    fontWeight: 700,
    textTransform: "none",
  })
);
