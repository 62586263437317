import { FC } from "react";
import { IconButton } from "@mui/material";

import {
  Wrapper,
  SelectionRetainerWrapper,
  Text,
} from "./SelectionRetainer.styles";
import CheckboxComponent from "src/components/checkbox/CheckBoxComponent";
import { Icon } from "src/components/icons";

import { useSelectionRetainer } from "./SelectionRetainer.hooks";

type SelectionRetainerProps = {
  clearBetSlip: () => void;
  retainSelection?: boolean;
};

const SelectionRetainer: FC<SelectionRetainerProps> = ({
  clearBetSlip,
  retainSelection = false,
}) => {
  const { settingsClickHandler } = useSelectionRetainer();

  return (
    <SelectionRetainerWrapper
      sx={{ justifyContent: retainSelection ? "space-between" : "flex-end" }}
    >
      {retainSelection ? (
        <Wrapper>
          <CheckboxComponent />
          <Text>Retain Selection</Text>
        </Wrapper>
      ) : null}
      <Wrapper>
        <IconButton onClick={settingsClickHandler}>
          <Icon name="Gear" />
        </IconButton>
        <IconButton onClick={clearBetSlip}>
          <Icon name="TrashDark" />
        </IconButton>
      </Wrapper>
    </SelectionRetainerWrapper>
  );
};

export default SelectionRetainer;
