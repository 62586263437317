import { styled, Tab, TabProps, Tabs, TabsProps } from "@mui/material";

export const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  width: "100%",

  "& .MuiTabs-flexContainer": {
    gap: theme.spacing(1),
    justifyContent: "space-between",
  },

  "& .MuiTabs-indicator": {
    display: "none",
  },

  "& .MuiTabs-scrollButtons": {
    width: 30,
    color: theme.colors.secondary.main,

    "&.Mui-disabled": {
      visibility: "none",
    },
  },
}));

export const StyledTab = styled(Tab)<TabProps>(({ theme }) => ({
  height: "68px",
  minHeight: "68px",
  maxWidth: "68px",
  minWidth: "68px",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(0.625),
  padding: theme.spacing(1),

  opacity: 1,
  backgroundColor: theme.colors.neutral.black[100],
  borderRadius: theme.spacing(1.25),

  color: theme.colors.text.light,
  fontSize: "10px",
  fontWeight: 700,
  textAlign: "center",

  "&.Mui-selected, &.Mui-selected:hover": {
    opacity: 1,
    backgroundColor: theme.colors.secondary.main,
    color: theme.colors.text.dark,
    zIndex: 5,
  },
}));
