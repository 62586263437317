interface BetSlipConfig {
  betsLocalStorageKey: string;
  betSlipLocalStorageKey: string;
  defaultStakeLocalStorageKey: string;
}

/**
 * config values used in bet slip feature
 */
const betSlipConfig: BetSlipConfig = {
  betsLocalStorageKey: "unsubmittedBets",
  betSlipLocalStorageKey: "unsubmittedBetSlips",
  defaultStakeLocalStorageKey: "sportDefaultStake",
};

export default betSlipConfig;
