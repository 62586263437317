import logger from "src/utils/logger";
import { _getCognitoUser } from "./_getCognitoUser";


export const resendOtp = async (cellphone: string) => {
    try {
        const cognitoUser = _getCognitoUser(cellphone);
        cognitoUser.resendConfirmationCode( function (err, result) {
            if (err) {
                // alert(err.message || JSON.stringify(err));
                throw new Error(err.message);
            }
            logger.log('call result: ' + result);
        }
        );

        
    } catch (error: any) {
        throw new Error('er');
    }
}