import { skipToken } from "@reduxjs/toolkit/query";
import { toast } from "react-toastify";

import { useSportBetSlipSlice } from "src/features/betslip";
import { useLazyGetAllFixturesQuery } from "src/services/restApi/queries/fixtures.service";
import logger from "src/utils/logger";

/**
 * Custom hook which adds repeat sport bet functionality.
 * @returns A function which adds the bet to repeat to your bet slip and the loading state.
 */
export const useRepeatSportBet = (): [
  (
    fixtureId: string,
    marketId: string,
    selectionId: string,
    marketDefId: string
  ) => Promise<void>,
  { isLoading: boolean },
] => {
  const { addBet } = useSportBetSlipSlice();
  const [getFixtures, { isFetching, isLoading }] = useLazyGetAllFixturesQuery();

  const repeatSportBet = async (
    fixtureId: string,
    marketId: string,
    selectionId: string,
    marketDefId: string
  ) => {
    const fixtures = await getFixtures({
      "m-def-id": marketDefId ?? skipToken,
    });

    try {
      const fixture = fixtures.data?.items.find(
        (fixture) => fixture.id === fixtureId
      );
      if (!fixture) {
        throw new Error("Unable to find bet to repeat.");
      }

      const market = fixture.markets.find((market) => market.id === marketId);
      if (!market) {
        throw new Error("Unable to find bet to repeat.");
      }

      const selection = market.selections.find(
        (selection) => selection.id === selectionId
      );
      if (!selection) {
        throw new Error("Unable to find bet to repeat.");
      }

      addBet(fixture, market, selection);
      toast.success("Repeat bet added to bet slip.");
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        logger.error(error);
      }
    }
  };

  return [repeatSportBet, { isLoading: isFetching || isLoading }];
};
