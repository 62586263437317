import { FC } from "react";

import { StyledBox } from "./SportBetItems.styles";
import BetItemsEmpty from "./BetItemsEmpty";
import BetItem from "./item/SportBetItem";

import { useSportBets } from "./SportBetItems.hooks";

const SportBetItems: FC = () => {
  const sportBets = useSportBets();

  if (sportBets.length === 0) {
    return (
      <StyledBox>
        <BetItemsEmpty />
      </StyledBox>
    );
  }

  return (
    <StyledBox>
      {sportBets.map((bet, index) => (
        <BetItem key={bet.id} bet={bet} index={index} />
      ))}
    </StyledBox>
  );
};

export default SportBetItems;
