import { SelectChangeEvent } from "@mui/material";
import { FC } from "react";

import { Wrapper } from "./OptionsSelect.styles";
import CustomSelect, { SelectItem } from "src/components/customSelect";

type OptionSelectProps = {
  currentOption: string;
  id: string;
  label: string;
  options: SelectItem[];
  optionChangeHandler: (value: string) => void;
};

const OptionSelect: FC<OptionSelectProps> = ({
  currentOption,
  id,
  label,
  options,
  optionChangeHandler,
}) => {
  function onChangeHandler(event: SelectChangeEvent<string>) {
    optionChangeHandler(event.target.value);
  }

  return (
    <Wrapper>
      {label}
      <CustomSelect
        border
        id={`${id}-option-select`}
        items={options}
        onChange={onChangeHandler}
        value={currentOption}
        variant="outlined"
      />
    </Wrapper>
  );
};

export default OptionSelect;
