import { FC, PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";

import { StyledBox, StyledContainer } from "./PageWrapper.styles";

type PageWrapperProps = PropsWithChildren<{
  helmetTitle: string;
}>;

/**
 * Wrapper component for pages which provides styling and react helmet functionality.
 * @param helmetTitle string to display in the helmet title
 * @param children react jsx
 * @returns React component
 */
const PageWrapper: FC<PageWrapperProps> = ({ helmetTitle, children }) => {
  return (
    <StyledBox>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <StyledContainer maxWidth="lg">{children}</StyledContainer>
    </StyledBox>
  );
};

export default PageWrapper;
