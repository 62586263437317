import { FC } from "react";

import { SignupText } from "./AuthTextButton.styles";

import { AuthDialogType, useAuthDailogStore } from "src/store/authentication/authStore";

type AuthTextButtonProps = {
  variant: "login" | "signup";
};

const AuthTextButton: FC<AuthTextButtonProps> = ({ variant }) => {
  const setDialogType = useAuthDailogStore((state) => state.setDisplayType);

  const onClickHandler = () => {
    if (variant === "login") {
      setDialogType(AuthDialogType.Login);
    } else {
      setDialogType(AuthDialogType.Register);
    }
  };

  return (
    <SignupText onClick={onClickHandler}>
      {variant === "login" ? "Login" : "Signup"}
    </SignupText>
  );
};

export default AuthTextButton;
