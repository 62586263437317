import { ChangeEvent, useCallback } from "react";

import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
  selectAcceptOdds,
  selectDefaultStake,
  selectPreferencesOpen,
  setAcceptOdds,
  setDefaultStake,
  toggleBetSlipPreferences,
} from "../../store/betSlipSlice";

import betSlipConfig from "../../config/betSlipConfig";

type AcceptOddsEvent = ChangeEvent<HTMLInputElement>;
type DefaultStakeEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export const useSportBetSlipPreferences = () => {
  const dispatch = useAppDispatch();
  const preferencesOpen = useAppSelector(selectPreferencesOpen);
  const acceptOdds = useAppSelector(selectAcceptOdds);
  const defaultStake = useAppSelector(selectDefaultStake);

  const changeAcceptOdds = useCallback(
    (event: AcceptOddsEvent) => {
      event.preventDefault();

      const newValue = event.target.value;
      dispatch(setAcceptOdds({ acceptOdds: newValue }));
    },
    [dispatch]
  );

  const changeDefaultStake = useCallback(
    (event: DefaultStakeEvent) => {
      event.preventDefault();

      const newValue = event.target.value;

      try {
        const newStake = parseInt(newValue);
        dispatch(setDefaultStake({ newStake }));
        localStorage.setItem(
          betSlipConfig.defaultStakeLocalStorageKey,
          JSON.stringify(newStake)
        );
      } catch (err) {
        console.error("Please enter a integer value.");
      }
    },
    [dispatch]
  );

  const closePreferences = useCallback(() => {
    dispatch(toggleBetSlipPreferences({ open: false }));
  }, [dispatch]);

  return {
    acceptOdds,
    defaultStake,
    preferencesOpen,
    changeAcceptOdds,
    changeDefaultStake,
    closePreferences,
  };
};
