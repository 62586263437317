import { Box, BoxProps, styled } from "@mui/material";

export const FilterBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(1.25),

  marginLeft: theme.spacing(2),
}));
