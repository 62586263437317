import { Box, BoxProps, styled } from "@mui/material";

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  gap: theme.spacing(0.5),

  color: theme.colors.text.light,
}));
