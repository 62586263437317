import { SportBetSlip } from "../models/sport";
import { LottoBetSlip } from "../models/lotto";

import betSlipConfig from "../config/betSlipConfig";

export const emptySportBetSlip: SportBetSlip = {
  bets: [],
  comboBets: [],
  errors: [],
  totalPayout: 0,
  totalStake: 0,
};

export const emptyLottoBetSlip: LottoBetSlip = {
  bets: [],
  totalPayout: 0,
  totalStake: 0,
};

/**
 * Fetches bet slips from local storage and provides them as initial values to redux
 * @returns Either empty bet slips or bet slips retrieved from local storage
 */
export function getBetSlipsFromStorage() {
  const storedBetSlipsString = localStorage.getItem(
    betSlipConfig.betSlipLocalStorageKey
  );
  if (storedBetSlipsString) {
    const storedBetSlips = JSON.parse(storedBetSlipsString);

    return {
      betSlipMode: storedBetSlips.betSlipMode,
      sportBetSlip: storedBetSlips.betSlips.sportBetSlip,
      lottoBetSlip: storedBetSlips.betSlips.lottoBetSlip,
    };
  } else {
    return {
      betSlipMode: "singles",
      sportBetSlip: emptySportBetSlip,
      lottoBetSlip: emptyLottoBetSlip,
    };
  }
}

/**
 * Fetches bets from local storage and provides them as initial values to redux
 * @returns Either empty bets or bet slips retrieved from local storage
 */
export function getBetsFromStorage() {
  const storedBetsString = localStorage.getItem(
    betSlipConfig.betsLocalStorageKey
  );

  if (storedBetsString) {
    const storedBets = JSON.parse(storedBetsString);

    return {
      sportBets: storedBets.sportBets,
    };
  } else {
    return {
      sportBets: [],
    };
  }
}

export function getDefaultStakeFromStorage() {
  const storedDefaultStake = localStorage.getItem(
    betSlipConfig.defaultStakeLocalStorageKey
  );

  if (storedDefaultStake) {
    const defaultStake = JSON.parse(storedDefaultStake);
    return Number(defaultStake);
  } else {
    return 10;
  }
}
