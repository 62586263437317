import { FC } from "react";
import { Clear } from "@mui/icons-material";

import { FileContainer, DeleteButton } from "./FileComponent.styles";

interface IFileComponent {
  fileName: string;
  deleteFile: () => void;
}

const FileComponent: FC<IFileComponent> = ({ fileName, deleteFile }) => {
  return (
    <FileContainer>
      {fileName}
      <DeleteButton onClick={deleteFile}>
        <Clear sx={{ color: "#6B6B6B" }} />
      </DeleteButton>
    </FileContainer>
  );
};

export default FileComponent;
