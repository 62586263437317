import {
  FixtureItem,
  FixtureMarketItem,
  FixtureSelectionItem,
} from "src/features/sports";
import { SportBet, SportBetInfo, SportBetLeg } from "../models/sport";

import { calculateBetPayout } from "../utils/payoutAndStakeCalc";

/**
 * Converts a fixture into a bet slip item
 * @param fixture - fixture to be added to the bat slip
 * @param marketId - market within fixture to be added to the bet slip
 * @param selectionId - selection within market to be added to the bet slip
 * @returns A bet slip item representation of the fixture to be added
 */
export const sportFixtureMapper = (
  fixture: FixtureItem,
  market: FixtureMarketItem,
  selection: FixtureSelectionItem,
  defaultStake: number
) => {
  const sportBetInfo: SportBetInfo = {
    bonusStretch: {},
    fixtureName: "",
    fixtureStartTime: market.wagerCloseDate,
    marketName: market.name,
    name: selection.name,
    region: fixture.attr.region?.value ?? "",
    selectedForMultiple: true,
    sport: fixture.attr.sportName.value,
    tournament: fixture.attr.tournamentName.value,
    competitors: fixture?.attr?.competitors?.value.map((competitor) => competitor.name),
  };

  const sportBetLegs: SportBetLeg[] = [
    {
      errors: [],
      fixtureDate: market.wagerCloseDate,
      fixtureId: fixture.id,
      info: {
        marketDefId: market.def.id,
        sport: fixture.attr.sportName.ref,
        sportId: fixture.attr.sportName.ref,
        tournamentId: fixture.attr.tournamentName.ref,
      },
      marketId: market.id,
      price: selection.price.dec,
      priceChangeIndicator: 2,
      selection: null,
      selectionId: selection.id,
      sp: market.allowSpBet,
    },
  ];

  const prices = sportBetLegs.map((leg) => leg.price);
  const { payout, price } = calculateBetPayout(defaultStake, prices);

  const sportBet: SportBet = {
    id: fixture.id + "-" + market.id + "-" + selection.id,
    errors: [],
    info: sportBetInfo,
    legs: sportBetLegs,
    live: market.phase.inPlay,
    payout,
    price,
    promos: {},
    provider: fixture.attr.origDataSource.value,
    stake: defaultStake,
  };

  return sportBet;
};
