import { FC, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import { HeaderBox, CellBox } from "./MobileTable.styles";
import RepeatBetButton from "src/features/myBets/components/data-table/RepeatBetButton";
import SubRowsModal from "src/features/myBets/components/modal";

import {
  MyBetsMobileDataRow,
  MyBetsMobileDataSubRow,
  hasKey,
  isMyBets,
} from "src/features/myBets/models/DataRow";
import {
  TransactionsMobileDataRow,
  isTransactions,
} from "src/features/transactions/models/DataRow";

import { formatDate, isAfter } from "src/utils/dateHelper";

interface IMobileTable {
  columns: GridColDef[];
  rows:
    | MyBetsMobileDataRow
    | MyBetsMobileDataSubRow
    | TransactionsMobileDataRow;
}

const MobileTable: FC<IMobileTable> = ({ columns, rows }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [rowIndex, setRowIndex] = useState<number>(0);

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = (index: number) => {
    setRowIndex(index);
    setShowModal(true);
  };

  if (Object.keys(rows) && Object.keys(rows).length === 0) {
    return (
      <Typography color="white" textAlign="center">
        You have no placed bets. Once you place a bet, it will show up here.
      </Typography>
    );
  }

  if (isMyBets(rows)) {
    return (
      <>
        <SubRowsModal
          open={showModal}
          closeModal={closeModal}
          rows={[]}
          mobileRows={rows["subRows"][rowIndex]}
        />
        <Grid container flexDirection="column" overflow="scroll">
          {columns.map((column, index) => {
            if (column.field === "repeatBet") {
              const row = rows[column.field];
              return (
                <Grid
                  item
                  key={column.field + "_" + index}
                  display="flex"
                  flexDirection="row"
                >
                  <HeaderBox sx={{ border: "none" }}></HeaderBox>
                  {row.map((r, index) => {
                    const fixtureDate = new Date(r.fixtureDate);
                    const isExpired = isAfter(new Date(), fixtureDate);

                    return (
                      <CellBox sx={{ border: "none" }} key={index}>
                        <RepeatBetButton
                          key={r + "_" + index}
                          disabled={isExpired}
                          leg={r}
                        />
                      </CellBox>
                    );
                  })}
                </Grid>
              );
            } else if (column.field === "selection") {
              const row = rows[column.field];
              return (
                <Grid
                  item
                  key={column.field + "_" + index}
                  display="flex"
                  flexDirection="row"
                >
                  <HeaderBox>{column.headerName}</HeaderBox>
                  {row.map((r, index) => {
                    const showSubRows = rows["subRows"][index].id.length > 1;

                    return (
                      <>
                        <CellBox
                          key={index}
                          onClick={
                            showSubRows ? () => openModal(index) : undefined
                          }
                        >
                          {showSubRows ? "Multiple Selections" : r}
                        </CellBox>
                      </>
                    );
                  })}
                </Grid>
              );
            } else {
              if (hasKey(rows, column.field)) {
                const row = rows[column.field];

                return (
                  <Grid
                    item
                    key={column.field + "_" + index}
                    display="flex"
                    flexDirection="row"
                  >
                    <HeaderBox>{column.headerName}</HeaderBox>
                    {row.map((r, index) => (
                      <>
                        <CellBox key={r + "_" + index}>
                          {column.headerName === "Bet Date" ||
                          column.headerName === "Fixture Date" ? (
                            <>{formatDate(String(r), "DD/MM/YYYY HH:mm")}</>
                          ) : (
                            String(r)
                          )}
                        </CellBox>
                      </>
                    ))}
                  </Grid>
                );
              } else {
                return <></>;
              }
            }
          })}
        </Grid>
      </>
    );
  } else if (isTransactions(rows)) {
    return (
      <Grid container flexDirection="column">
        {columns.map((column, index) => {
          if (hasKey(rows, column.field)) {
            const row = rows[column.field];

            return (
              <Grid
                item
                key={column.field + "_" + index}
                display="flex"
                flexDirection="row"
              >
                <HeaderBox>{column.headerName}</HeaderBox>
                {row.map((r, index) => (
                  <CellBox key={r + "_" + index}>{String(r)}</CellBox>
                ))}
              </Grid>
            );
          } else {
            return <></>;
          }
        })}
      </Grid>
    );
  } else {
    return (
      <Grid container flexDirection="column">
        {columns.map((column, index) => {
          if (hasKey(rows, column.field)) {
            const row = rows[column.field];

            return (
              <Grid
                item
                key={column.field + "_" + index}
                display="flex"
                flexDirection="row"
              >
                <HeaderBox>{column.headerName}</HeaderBox>
                {row.map((r, index) => (
                  <>
                    <CellBox key={r + "_" + index}>
                      {column.headerName === "Bet Date" ||
                      column.headerName === "Fixture Date" ? (
                        <>{formatDate(String(r), "DD/MM/YYYY HH:mm")}</>
                      ) : (
                        String(r)
                      )}
                    </CellBox>
                  </>
                ))}
              </Grid>
            );
          } else {
            return <></>;
          }
        })}
      </Grid>
    );
  }
};

export default MobileTable;
