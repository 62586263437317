import { LottoBet, LottoBetLeg } from "src/features/betslip/models/lotto";
import { LottoFixtureDefGridBallType } from "src/features/luckyNumbers/Fixture/models/lottoFixture";
import { NumbersExoticMarketType } from "./types";
import { PickSetSelection } from "src/features/betslip/models/lotto/lottoBet";

export const advancedNumbersMapper = (
  stake: number,
  fixtureDefId: string,
  gridNumber: number,
  market: NumbersExoticMarketType,
  numbers: LottoFixtureDefGridBallType[],
  pickSetId: number
): LottoBet => {
  const numberLabels = numbers.map((number) => number.label).join("");

  const pickSetSelections: PickSetSelection[] = [
    {
      pickSetId,
      balls: numbers.map((number) => ({
        color: number.color,
        id: number.id,
        label: number.label,
      })),
      gridNumber,
    },
  ];

  const lottoBetLegs: LottoBetLeg[] = [
    {
      fixtureId: market.fixtureId,
      marketId: market.id,
      marketName: market.name,
      fixtureDefId,
      price: market.price.dec,
      gameMode: "CUSTOM",
      marketDefId: market.def.id,
      game: market.gameType,
      pickSetSelections,
      wagerCloseDate: market.wagerCloseDate,
    },
  ];

  const lottoBet: LottoBet = {
    id: `${market.fixtureId}-${market.id}-${numberLabels}`,
    legs: lottoBetLegs,
    stake,
    payout: market.price.dec * stake,
    key: "",
    errorCode: 0,
    isFixedStake: Boolean(market.fixedStake),
  };

  return lottoBet;
};
