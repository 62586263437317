import { FC } from 'react';
import { useAppSelector } from 'src/hooks/hooks';
import { selectLottoBets } from '../../store/lotto/lottoBetSlipSlice';
import { StyledBox } from './SportBetItems.styles';
import BetItemsEmpty from './BetItemsEmpty';
import LottoBetItem from './item/LottoBetItem';

const LottoBetItems: FC = () => {
  const betItems = useAppSelector(selectLottoBets);

  return (
    <StyledBox>
      {betItems.length === 0 ? (
        <BetItemsEmpty />
      ) : (
        <>
          {betItems.map((item, index) => (
            <LottoBetItem key={index} bet={item} index={index} />
          ))}
        </>
      )}
    </StyledBox>
  );
};

export default LottoBetItems;
