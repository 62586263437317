import {
  CustomExoticMarketSelectionType,
  CustomExoticMarketType,
} from "./types";
import { LottoBet, LottoBetLeg } from "src/features/betslip/models/lotto";

export const customExoticMapper = (
  stake: number,
  fixtureDefId: string,
  market: CustomExoticMarketType,
  selection: CustomExoticMarketSelectionType
): LottoBet => {
  const lottoBetLegs: LottoBetLeg[] = [
    {
      fixtureId: market.fixtureId,
      marketId: market.id,
      marketName: market.name,
      fixtureDefId,
      price: selection.price.dec,
      gameMode: "CUSTOM",
      selectionId: selection.id,
      marketDefId: market.def.id,
      game: "LOTTO",
      selectionDefinitionId: selection.def.id,
      selectionName: selection.name,
      wagerCloseDate: market.wagerCloseDate,
    },
  ];

  const lottoBet: LottoBet = {
    id: `${market.fixtureId}-${market.id}-${selection.id}`,
    legs: lottoBetLegs,
    stake,
    payout: selection.price.dec * stake,
    key: "",
    errorCode: 0,
    isFixedStake: false,
  };

  return lottoBet;
};
