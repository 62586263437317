import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  padding: theme.spacing(0.625, 1.25),
}));

export const SubWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  gap: theme.spacing(1.25),
}));

export const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.text.light,
  fontSize: 12,
  fontWeight: 400,
}));

export const SmallText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.text.light,
  fontSize: 10,
  fontWeight: 200,
}));
