import { Box, styled } from "@mui/material";

export const SidebarWrapper = styled(Box)(({ theme }) => ({
  background: theme.sidebar.background,
  boxShadow: theme.sidebar.boxShadow,
  width: theme.sidebar.width,
  minWidth: theme.sidebar.width,
  color: theme.colors.neutral.white[100],
  position: "relative",
  zIndex: 7,
  height: "100%",
  paddingBottom: "8px",
  paddingTop: theme.sidebar.paddingTop,
}));

export const MenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: ${theme.spacing(1)};
  
      & > .MuiList-root {
        padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
      }
    }
  
      .MuiListSubheader-root {
        text-transform: uppercase;
        font-weight: bold;
        font-size: ${theme.typography.pxToRem(12)};
        color: ${theme.sidebar.menuItemHeadingColor};
        padding: ${theme.spacing(0, 2.5)};
        line-height: 1.4;
      }
  `
);
