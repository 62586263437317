import { FC, useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";

import { StyledButton } from "./ViewDocs.styles";

import { useLazyGetFicaDocsQuery } from "src/services/restApi/queries/fica.service";

import { openInNewTab } from "src/utils/navigationHelpers";

const ViewDocs: FC = () => {
  const [documents, setDocuments] = useState<string[] | undefined>(undefined);

  const [getFicaDocs, response] = useLazyGetFicaDocsQuery();

  useEffect(() => {
    setDocuments(response.data?.urls);
  }, [response]);

  return (
    <>
      <StyledButton onClick={() => getFicaDocs()}>
        {response.isLoading || response.isFetching ? (
          <CircularProgress />
        ) : (
          "View My Documents"
        )}
      </StyledButton>
      {documents ? (
        <>
          {documents.map((document, index) => (
            <Button key={index} onClick={() => openInNewTab(document)}>
              Document {index + 1}
            </Button>
          ))}
        </>
      ) : null}
    </>
  );
};

export default ViewDocs;
