import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { StyledDivider, NavButtonsWrapper } from "./NavButtons.styles";
import NavButton from "./navButton/NavButton";

import { useAppSelector } from "src/hooks/hooks";
import { selectLottoBets } from "../../store/lotto/lottoBetSlipSlice";
import { selectSportBets } from "../../store/sport/sportBetSlipSlice";

import { useAuth } from "src/features/authentication";

import { useGetSportBetsQuery } from "src/services/restApi/queries/myBets.service";

type NavButtonsProps = {
  toggleBetSlip?: (isOpen?: boolean) => void;
};

const NavButtons: FC<NavButtonsProps> = ({ toggleBetSlip }) => {
  const sportBets = useAppSelector(selectSportBets);
  const lottoBets = useAppSelector(selectLottoBets);

  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { data } = useGetSportBetsQuery(
    { period: "today", status: "pending" },
    {
      skip: !isAuthenticated,
    }
  );

  const betSlipBets = pathname.includes("luckyNumbers")
    ? lottoBets.length
    : sportBets.length;
  const myBetsBets = data ? data.count : 0;

  function myBetsClickHandler() {
    if (toggleBetSlip) {
      toggleBetSlip();
    }
    navigate("/account/myBets");
  }

  return (
    <NavButtonsWrapper>
      {isAuthenticated ? (
        <>
          <NavButton
            numberOfBets={myBetsBets}
            text="My Bets"
            onClick={myBetsClickHandler}
          />
          <StyledDivider orientation="vertical" />
        </>
      ) : null}
      <NavButton
        numberOfBets={betSlipBets}
        text="Bet Slip"
        onClick={toggleBetSlip ? toggleBetSlip : () => {}}
      />
    </NavButtonsWrapper>
  );
};

export default NavButtons;
