import * as Sentry from '@sentry/react';

/** Signature of a logging function */
export type LogFn = (message?: any, ...optionalParams: any[]) => void;

/** Basic logger interface */
export interface Logger {
  log: LogFn;
  logLevel: LogLevel;
  warn: LogFn;
  error: LogFn;
}

/** Log levels */
export enum LogLevel {
  LOG = 'log',
  WARN = 'warn',
  ERROR = 'error',
}

const NO_OP: LogFn = () => {};

/** Creates a logger which outputs to the browser console */
export function createLogger(options?: { level?: LogLevel }): Logger {
    const level = options?.level;
  
    const logger: Logger = {
      log: NO_OP,
      warn: NO_OP,
      error: NO_OP,
      logLevel: level || LogLevel.LOG,
    };
  
    if (level !== LogLevel.ERROR) {
      logger.log = (message?: any, ...optionalParams: any[]) => {
        console.log(message, ...optionalParams);
        Sentry.captureMessage(message, 'log');
      };
    }
  
    if (level !== LogLevel.LOG) {
      logger.warn = (message?: any, ...optionalParams: any[]) => {
        console.warn(message, ...optionalParams);
        Sentry.captureMessage(message, 'warning');
      };
    }
  
    logger.error = (message?: any, ...optionalParams: any[]) => {
      console.error(message, ...optionalParams);
      Sentry.captureException(message);
    };
  
    return logger;
  }
  
  const LOG_LEVEL = process.env.NODE_ENV === 'production' ? LogLevel.ERROR : LogLevel.LOG;
  
 /** Use this to log all console messages thoughout the app
  * 
  * @example
  * logger.log('Hello World');
  * logger.warn('Hello World');
  * logger.error('Hello World');
  */
 const logger = createLogger({ level: LOG_LEVEL });

 export default logger;