import { FC } from "react";
import { Check } from "@mui/icons-material";

import { TickWrapper } from "./Tick.styles";

const Tick: FC = () => {
  return (
    <TickWrapper>
      <Check sx={{ color: "black" }} />
    </TickWrapper>
  );
};

export default Tick;
