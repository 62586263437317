import { useCallback } from "react";
import { useAppDispatch } from "src/hooks/hooks";
import {
  removeLottoBet,
  updateLottoBetAmount,
} from "src/features/betslip/store/lotto/lottoBetSlipSlice";

/**
 * Custom hook to manage lottery bet items within a bet slip.
 *
 * Provides functionalities to:
 * - Remove a bet item from the bet slip.
 * - Update the stake amount of a bet item.
 *
 * @returns {Object} An object containing the `removeFromBetSlip` and `updateLottoStake` functions.
 */
export const useLottoBetItem = () => {
  const dispatch = useAppDispatch();

  /**
   * Removes a bet item from the bet slip.
   *
   * @param {string} betId - The unique identifier of the bet item.
   * @param {number} [betIndex] - The index of the bet item in the bet slip (optional). This is for cases where the bet slip can contain items with the same id.
   */
  const removeFromBetSlip = useCallback(
    (betId: string, betIndex?: number) => {
      dispatch(removeLottoBet({ betId, betIndex }));
    },
    [dispatch]
  );

  /**
   * Updates the stake amount of a bet item.
   *
   * @param {string} betId - The unique identifier of the bet item.
   * @param {number} amount - The new stake amount for the bet item.
   */
  const updateLottoStake = useCallback(
    (betId: string, amount: number) => {
      dispatch(updateLottoBetAmount({ betId, amount }));
    },
    [dispatch]
  );

  return { removeFromBetSlip, updateLottoStake };
};
