import { baseApi } from "./baseApi.service";
import { Endpoint, TagId } from "./config/apiTags";

import { DisplayOrders } from "src/features/sports";

const displayOrdersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDisplayOrders: builder.query<DisplayOrders, void>({
      query: () => {
        return {
          url: Endpoint.DISPLAY_ORDERS,
          method: "GET",
        };
      },
      providesTags: (result, error, arg) => [
        { type: Endpoint.DISPLAY_ORDERS, id: TagId.LIST },
      ],
    }),
    getDisplayOrder: builder.query<DisplayOrders, { "sport-name": string }>({
      query: (params) => {
        return {
          url: Endpoint.DISPLAY_ORDER,
          method: "GET",
          params,
        };
      },
      providesTags: (result, error, arg) => [
        { type: Endpoint.DISPLAY_ORDER, id: TagId.ITEM },
      ],
    }),
  }),
});

export const {
  useGetAllDisplayOrdersQuery,
  useLazyGetAllDisplayOrdersQuery,
  useGetDisplayOrderQuery,
  useLazyGetDisplayOrderQuery,
} = displayOrdersApi;
