import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
  selectPeriodFilter,
  selectStatusFilter,
  selectWalletFilter,
  setPeriodFilter,
  setStatusFilter,
  setWalletFilter,
} from "../store/myBetsSlice";

/**
 * Custom hook that interacts with the my bets redux slice
 */
export const useMyBets = () => {
  const dispatch = useAppDispatch();

  /* period filter */
  const currentPeriodFilter = useAppSelector(selectPeriodFilter);

  const changePeriodFilter = (value: string) => {
    dispatch(setPeriodFilter({ value }));
  };

  /* status filter */
  const currentStatusFilter = useAppSelector(selectStatusFilter);

  const changeStatusFilter = (value: string) => {
    dispatch(setStatusFilter({ value }));
  };

  /* wallet filter */
  const currentWalletFilter = useAppSelector(selectWalletFilter);

  const changeWalletFilter = (value: number) => {
    dispatch(setWalletFilter({ value }));
  };

  return {
    currentPeriodFilter,
    changePeriodFilter,
    currentStatusFilter,
    changeStatusFilter,
    currentWalletFilter,
    changeWalletFilter,
  };
};
