import { FC } from "react";
import { Divider, styled } from "@mui/material";

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.colors.secondary.main,
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const SidebarDivider: FC = () => {
  return <StyledDivider />;
};

export default SidebarDivider;
