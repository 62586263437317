import { ChangeEvent, FC, useReducer, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import CheckboxComponent from "src/components/checkbox/CheckBoxComponent";
import {
  LoginWrapper,
  LoginTitle,
  StyledFormControlLabel,
  SubText,
  ActionsWrapper,
} from "./Login.styles";
import {
  PasswordInput,
  PhoneNumberInput,
  CustomInputElement,
} from "src/components/Inputs";
import { PrimaryButton } from "src/components/buttons";

import { useAuth } from "../../hooks/useAuth";

import {
  actionTypes,
  initialState,
  loginFormReducer,
} from "../../reducers/loginFormReducer";

import { getFullNumber } from "../../utils/getFullNumber";
import logger from "src/utils/logger";
import {
  AuthDialogType,
  useAuthDailogStore,
} from "src/store/authentication/authStore";

const Login: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [state, dispatch] = useReducer(loginFormReducer, initialState);
  const setAuthDisplayType = useAuthDailogStore(
    (state) => state.setDisplayType
  );
  const setTempNumber = useAuthDailogStore((state) => state.setTemporaryNumber);
  const navigate = useNavigate();

  const {
    isGuarded,
    loginWithCellphoneAndPassword,
    setIsGuardedHandler,
    resendOtp,
  } = useAuth();

  const passwordChangeHandler = (event: ChangeEvent<CustomInputElement>) => {
    event.preventDefault();

    dispatch({
      type: actionTypes.PASSWORD,
      payload: event.target.value,
    });
  };

  const phoneChangeHandler = (event: ChangeEvent<CustomInputElement>) => {
    event.preventDefault();

    dispatch({
      type: actionTypes.CELLPHONE,
      payload: event.target.value,
    });
  };

  const countryChangeHandler = (value: string) => {
    dispatch({
      type: actionTypes.COUNTRY_CODE,
      payload: value,
    });
  };

  const loginUser = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const fullNumber = getFullNumber(
      state.countryCode.value,
      state.cellphone.value
    );
    setLoading(true);
    try {
      await loginWithCellphoneAndPassword(fullNumber, state.password.value);
      setAuthDisplayType(AuthDialogType.None);
    } catch (err: any) {
      logger.error(err);
      if (err.message?.includes("UserNotConfirmedException")) {
        setAuthDisplayType(AuthDialogType.Verify);
        resendOtp(fullNumber);
        setTempNumber(fullNumber);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const formValid =
    state.cellphone.valid &&
    state.password.valid &&
    state.password.value.length > 0 &&
    state.cellphone.value.length > 0 &&
    state.countryCode.valid;

  const signUpClickHandler = () => {
    if (isGuarded) {
      navigate("/home");
      setIsGuardedHandler(false);
    }
    setAuthDisplayType(AuthDialogType.Register);
  };

  return (
    <Box component="form" onSubmit={loginUser} autoComplete="off" noValidate>
      <LoginWrapper>
        <LoginTitle>Login</LoginTitle>
        <PhoneNumberInput
          inputLabel="Phone Number"
          id="login-phone-input"
          autoComplete="cellphone"
          error={!state.cellphone.valid}
          helperText={state.cellphone.error}
          required
          value={state.cellphone.value}
          onChange={phoneChangeHandler}
          countryChange={countryChangeHandler}
          countryDialCode={state.countryCode.value}
          countryError={!state.countryCode.valid}
          countryHelperText={state.countryCode.error}
        />
        <PasswordInput
          inputLabel="Password"
          id="login-password-input"
          autoComplete="current-password"
          error={!state.password.valid}
          helperText={state.password.error}
          value={state.password.value}
          onChange={passwordChangeHandler}
        />
        <SubText
          fontSize={16}
          onClick={() => setAuthDisplayType(AuthDialogType.ForgotPassword)}
        >
          Forgot your password?
        </SubText>
        <PrimaryButton
          loading={loading}
          fullWidth
          fontSize={16}
          type="submit"
          disabled={!formValid}
        >
          Login
        </PrimaryButton>
        <ActionsWrapper>
          <StyledFormControlLabel
            control={<CheckboxComponent />}
            label="Remember me"
          />
          <SubText fontSize={14} onClick={signUpClickHandler}>
            Sign up
          </SubText>
        </ActionsWrapper>
      </LoginWrapper>
    </Box>
  );
};

export default Login;
