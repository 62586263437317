import { FC, useState } from "react";
import { TextFieldProps } from "@mui/material";

import { InputWrapper, InputLabel } from "./Inputs.styles";
import CustomInput from "./custom-input/CustomInput";
import PasswordVisibleButton from "./components/PasswordVisibleButton";

type PasswordInputProps = { inputLabel: string } & TextFieldProps;

const PasswordInput: FC<PasswordInputProps> = ({ inputLabel, ...props }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const showPasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <InputWrapper>
      <InputLabel>{inputLabel}</InputLabel>
      <CustomInput
        {...props}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <PasswordVisibleButton
              showPassword={showPassword}
              showPasswordHandler={showPasswordHandler}
            />
          ),
        }}
      />
    </InputWrapper>
  );
};

export default PasswordInput;
