import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
  clearLottoBets,
  selectLottoBetSlip,
} from "../store/lotto/lottoBetSlipSlice";

export const useLottoBetSlip = () => {
  const dispatch = useAppDispatch();
  const lottoBetSlip = useAppSelector(selectLottoBetSlip);

  function clearBets() {
    dispatch(clearLottoBets());
  }

  return { lottoBetSlip, clearBets };
};
