import { TextField, TextFieldProps, styled } from "@mui/material";

export const StyledTextField = styled(TextField)<TextFieldProps>(
  ({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      flexGrow: 1,
      overflow: "hidden",
      width: "100%",

      borderRadius: "10px",
      border: "2px solid " + theme.colors.text.light,
      backgroundColor: "inherit",

      color: theme.colors.text.light,
      fontSize: 14,
      fontWeight: 600,

      "&.Mui-error": {
        border: "2px solid " + theme.colors.error.main,
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "& .MuiOutlinedInput-input": {
      borderRadius: 0,
      padding: theme.spacing(1.25),

      "&.Mui-disabled": {
        WebkitTextFillColor: theme.colors.text.light,
      },
    },
  })
);
