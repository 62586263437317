import { FC } from "react";

import {
  Wrapper,
  Title,
  SelectorWrapper,
  Selector,
  SelectorButton,
  BetStakeWrapper,
  BetStakeText,
} from "./Preferences.styles";
import CustomDialog from "src/components/custom-dialog/CustomDialog";
import { CustomInput } from "src/components/Inputs";
import SidebarDivider from "src/layouts/SidebarLayout/Sidebar/SidebarDivider";

import { useSportBetSlipPreferences } from "./Preferences.hooks";

const PreferencesModal: FC = () => {
  const {
    acceptOdds,
    defaultStake,
    preferencesOpen,
    changeAcceptOdds,
    changeDefaultStake,
    closePreferences,
  } = useSportBetSlipPreferences();

  return (
    <CustomDialog open={preferencesOpen} onClose={closePreferences}>
      <Wrapper>
        <Title>My betslip preferences</Title>
        <SelectorWrapper value={acceptOdds} onChange={changeAcceptOdds}>
          <Selector
            control={<SelectorButton />}
            label="Accept any odds changes"
            value="any"
          />
          <Selector
            control={<SelectorButton />}
            label="Accept higher odds only"
            value="higher"
          />
          <Selector
            control={<SelectorButton />}
            label="Do not accept odds changes"
            value="none"
          />
        </SelectorWrapper>
        <SidebarDivider />
        <BetStakeWrapper>
          <BetStakeText>My default stake</BetStakeText>
          <CustomInput
            onChange={changeDefaultStake}
            value={defaultStake}
            sx={{ width: "100px" }}
          />
        </BetStakeWrapper>
      </Wrapper>
    </CustomDialog>
  );
};

export default PreferencesModal;
