import { Box, BoxProps, styled } from "@mui/material";

export const ConfirmWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  gap: theme.spacing(4.25),
  padding: 0,
}));
