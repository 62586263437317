import { FC } from "react";

import { HeaderWrapper, HeaderTypography } from "./GoldenHeader.styles";

type GoldenHeaderProps = {
  title: string;
  height: number;
  fontSize: number;
  gray?: boolean;
  small?: boolean;
};

const GoldenHeader: FC<GoldenHeaderProps> = ({
  title,
  height,
  fontSize,
  gray = false,
  small = false,
}) => {
  return (
    <HeaderWrapper
      sx={{
        height: height,
        borderRadius: height / 2,
        background: gray ? "#7C7C7C" : "",
        width: small ? 'fit-content' : 'inherit'
      }}
    >
      <HeaderTypography
        fontSize={fontSize}
        textTransform={gray ? "none" : "uppercase"}
      >
        {title}
      </HeaderTypography>
    </HeaderWrapper>
  );
};

export default GoldenHeader;
