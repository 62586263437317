export interface TransactionsDataRow {
  id: string;
  type: string;
  currency: string;
  date: string;
  amount: string;
  ref: string;
  accountName: string;
  balance: string;
  legRef: string;
}

export interface TransactionsMobileDataRow {
  id: string[];
  type: string[];
  currency: string[];
  date: string[];
  amount: string[];
  ref: string[];
  accountName: string[];
  balance: string[];
  legRef: string[];
}

export function isTransactions(obj: any): obj is TransactionsMobileDataRow {
  return "legRef" in obj;
}
