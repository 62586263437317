import { Checkbox, CheckboxProps, styled } from "@mui/material";

export const StyledCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  backgroundColor: "inherit",
  borderRadius: "4px",

  color: theme.colors.neutral.white[100],

  height: "24px",
  width: "24px",
}));
