import { FC, Fragment } from "react";
import { Divider } from "@mui/material";

import { FixtureGroupWrapper } from "./FixtureGroup.styles";
import GoldenHeader from "src/components/golden-header/GoldenHeader";
import Fixture from "../fixture/Fixture";

import { FixtureItem } from "../../../models/FixtureItem";

type FixtureGroupProps = {
  title: string;
  fixtures: FixtureItem[];
};

const FixtureGroup: FC<FixtureGroupProps> = ({ title, fixtures }) => {
  return (
    <FixtureGroupWrapper>
      <GoldenHeader title={title} height={20} fontSize={10} />
      {fixtures.map((fixture, index) => (
        <Fragment key={fixture.id}>
          <Fixture fixture={fixture} />
          {index !== fixtures.length - 1 ? (
            <Divider flexItem orientation="horizontal" sx={{ height: "1px" }} />
          ) : null}
        </Fragment>
      ))}
    </FixtureGroupWrapper>
  );
};

export default FixtureGroup;
