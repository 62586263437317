import { useEffect } from "react";

import { useLazyGetSportBetsQuery } from "src/services/restApi/queries/myBets.service";

import { useAuth } from "src/features/authentication";
import { useMyBets } from "../../hooks/useMyBets";

import { MyBetsDataRow, MyBetsMobileDataRow } from "../../models/DataRow";

import { toDataTable } from "../../mappers/toDataTable";
import { toMobileDataTable } from "../../mappers/toMobileDataTable";

export const useDataTable = () => {
  const { isAuthenticated } = useAuth();
  const { currentPeriodFilter, currentStatusFilter, currentWalletFilter } =
    useMyBets();

  const [getBets, { data, isLoading, isFetching, isError }] =
    useLazyGetSportBetsQuery();

  useEffect(() => {
    if (isAuthenticated) {
      getBets({
        period: currentPeriodFilter,
        status: currentStatusFilter,
      });
    }
  }, [getBets, isAuthenticated, currentPeriodFilter, currentStatusFilter]);

  let rows: MyBetsDataRow[] = [];
  let mobileRows: MyBetsMobileDataRow = {} as MyBetsMobileDataRow;
  if (data && data.items) {
    const items = data.items.filter(
      (dataItem) => dataItem.walletId === currentWalletFilter
    );
    rows = toDataTable(items);
    mobileRows = toMobileDataTable(rows);
  }

  return { rows, mobileRows, isLoading: isFetching || isLoading, isError };
};
