import { FC, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";

import {
  DesktopWrapper,
  MobileWrapper,
} from "src/components/component-wrappers";
import Table from "src/components/Table/Table";
import MobileTable from "src/components/MobileTable/MobileTable";

import { columns } from "./columns";

import { useDataTable } from "./DataTable.hooks";

const DataTable: FC = () => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 5,
    page: 0,
  });

  const { rows, mobileRows, isLoading, isError } = useDataTable();

  function paginationModelHandler(newPaginationModel: GridPaginationModel) {
    setPaginationModel(newPaginationModel);
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (rows.length === 0) {
    return (
      <Typography color="white" textAlign="center">
        There are no bets matching your filter criteria.
      </Typography>
    );
  }

  return (
    <>
      <MobileWrapper>
        <MobileTable columns={columns} rows={mobileRows} />
      </MobileWrapper>
      <DesktopWrapper>
        <Table
          columns={columns}
          rows={rows}
          pageSizeOptions={[5, 10, 15, 20]}
          loading={isLoading}
          refetch={() => {}}
          isFetching={isLoading}
          paginationModel={paginationModel}
          setPaginationModel={paginationModelHandler}
          totalRows={rows.length}
          error={isError}
        />
      </DesktopWrapper>
    </>
  );
};

export default DataTable;
