import { FC } from "react";
import { Box } from "@mui/material";

import { RegisterText, ResendText } from "../Register.styles";
import { OtpInput } from "src/components/Inputs";
import Declaration from "../../declaration/Declaration";
import { PrimaryButton } from "src/components/buttons";

import {
  IInitialState,
  IAction,
  actionTypes,
} from "src/features/authentication/reducers/authFormReducer";

import { useAuth } from "src/features/authentication/hooks/useAuth";

import { getMaskedNumber } from "src/features/authentication/utils/getFullNumber";

type VerifyOtpProps = {
  loading: boolean;
  state: IInitialState;
  dispatch: React.Dispatch<IAction>;
  fullNumber?: string;
  otpVerifyClick: () => void;
};

const VerifyOtp: FC<VerifyOtpProps> = ({
  loading,
  state,
  dispatch,
  fullNumber,
  otpVerifyClick,
}) => {
  const { resendOtp } = useAuth();

  const maskedNumber = fullNumber ? getMaskedNumber(fullNumber) : 'your number used to create your account';

  const otpChangeHandler = (newOtp: string) => {
    dispatch({
      type: actionTypes.OTP,
      payload: newOtp,
    });
  };

  const verifyClickHandler = () => {
    if (!state.otp.valid) return;

    otpVerifyClick();
  };

  return (
    <>
      <RegisterText>
        We have sent you a one-time password to your mobile number
      </RegisterText>
      <RegisterText>Please enter the OTP sent to {maskedNumber}</RegisterText>
      <OtpInput
        otp={state.otp.value}
        error={!state.otp.valid}
        helperText={state.otp.error}
        otpChangeHandler={otpChangeHandler}
      />
      <Box display="flex" flexDirection="row">
        <RegisterText>{"Didn't receive the OTP?"}</RegisterText>
        <RegisterText>&nbsp;</RegisterText>
        {fullNumber && (<ResendText onClick={() => resendOtp(fullNumber)} color="secondary">
          Resend OTP
        </ResendText>)}
      </Box>
      <Declaration />
      <br />
      <PrimaryButton
        loading={loading}
        fullWidth
        fontSize={16}
        onClick={verifyClickHandler}
      >
        Verify
      </PrimaryButton>
    </>
  );
};

export default VerifyOtp;
