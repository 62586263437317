import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { selectIsBetSlipOpen, toggleBetSlip } from "../../store/betSlipSlice";

export const useBetSlipHandler = () => {
  const dispatch = useAppDispatch();
  const isBetSlipOpen = useAppSelector(selectIsBetSlipOpen);

  const toggleBetSlipHandler = (isOpen?: boolean) => {
    if (isOpen) {
      dispatch(toggleBetSlip({ isOpen }));
    } else {
      dispatch(toggleBetSlip({ isOpen: !isBetSlipOpen }));
    }
  };

  return { isBetSlipOpen, toggleBetSlipHandler };
};
