import { FC, useEffect } from "react";
import {
  FormControl,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import {
  Container,
  TitleText,
  StyledInput,
  StyledMenuItem,
} from "./FundsSource.styles";

import { useAuth } from "src/features/authentication";
import { useFica } from "../../hooks/useFica";
import { useLazyGetIncomeSourcesQuery } from "src/services/restApi/queries/fica.service";

interface IFundsSource {
  disabled?: boolean;
  selectedSource?: number;
}

const FundsSource: FC<IFundsSource> = ({
  disabled = false,
  selectedSource,
}) => {
  const { isAuthenticated } = useAuth();
  const { fundsSource, updateFundsSource } = useFica();
  const [getSources, { data: sources }] = useLazyGetIncomeSourcesQuery();

  function onChangeHandler(event: SelectChangeEvent<number>) {
    updateFundsSource(Number(event.target.value));
  }

  useEffect(() => {
    if (isAuthenticated) {
      getSources();
    }
  }, [getSources, isAuthenticated]);

  if (!sources) {
    return <Typography>Unable to get income source options.</Typography>;
  }

  return (
    <Container>
      <TitleText>Source of funds</TitleText>
      <FormControl sx={{ width: "100%" }} variant="standard">
        <Select
          disabled={disabled}
          input={<StyledInput />}
          IconComponent={ExpandMore}
          onChange={onChangeHandler}
          value={selectedSource ?? fundsSource}
          variant="filled"
        >
          <StyledMenuItem value={0}>Source of Funds</StyledMenuItem>
          {sources.map((source, index) => (
            <StyledMenuItem key={index + "_" + source.id} value={source.id}>
              {source.value}
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
    </Container>
  );
};

export default FundsSource;
