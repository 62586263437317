import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const BetCountWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.secondary.main,
  borderRadius: "100%",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const BetCountText = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.colors.neutral.black[100],
    fontWeight: 700,
  })
);
