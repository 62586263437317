import {
  Box,
  BoxProps,
  FormControlLabel,
  FormControlLabelProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const LoginWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  gap: theme.spacing(4.25),
  padding: theme.spacing(5),
}));

export const LoginTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 25,
  fontWeight: 700,
  color: theme.colors.neutral.white[100],
}));

export const InputWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  gap: theme.spacing(1),
}));

export const InputLabel = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.colors.text.light,
}));

export const StyledFormControlLabel = styled(
  FormControlLabel
)<FormControlLabelProps>(({ theme }) => ({
  color: theme.colors.neutral.white[100],
  fontSize: 14,
  fontWeight: 400,

  gap: theme.spacing(1),
}));

export const SubText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 700,
  color: theme.colors.text.light,
}));

export const ActionsWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));
