import { useCallback, useState } from "react";

/**
 * Custom hook for managing step state. E.g. Register steps
 * @param numberOfSteps - number of steps
 * @returns The current active step, and functions to mutate the active step
 */
export const useSteps = (numberOfSteps: number) => {
  const [step, setStep] = useState<number>(0);

  const decrementStep = useCallback(() => {
    setStep((prevStep) => (prevStep - 1 < 0 ? 0 : prevStep - 1));
  }, []);

  const incrementStep = useCallback(() => {
    setStep((prevStep) =>
      prevStep + 1 > numberOfSteps - 1 ? prevStep : prevStep + 1
    );
  }, [numberOfSteps]);

  const setStepValue = useCallback((newStep: number) => {
    setStep(newStep);
  }, []);

  return { step, setStepValue, decrementStep, incrementStep };
};
