import { FC } from "react";
import { Box } from "@mui/material";

import { VerifyWrapper, ResendText } from "./VerifyOtp.styles";
import { ForgotTitle, ForgotText } from "../ForgotPassword.styles";
import { OtpInput } from "src/components/Inputs";
import { PrimaryButton } from "src/components/buttons";
import AuthTextButton from "../../auth-text-button/AuthTextButton";

import { useAuth } from "src/features/authentication/hooks/useAuth";
import {
  IInitialState,
  IAction,
  actionTypes,
} from "src/features/authentication/reducers/forgotFormReducer";

import { getMaskedNumber } from "../../../utils/getFullNumber";

type VerifyOtpProps = {
  state: IInitialState;
  dispatch: React.Dispatch<IAction>;
  fullNumber: string;
  verifyClickHandler: () => void;
};

const VerifyOtp: FC<VerifyOtpProps> = ({
  state,
  dispatch,
  fullNumber,
  verifyClickHandler,
}) => {
  const { resendOtp } = useAuth();

  const maskedNumber = getMaskedNumber(fullNumber);

  const otpChangeHandler = (newOtp: string) => {
    dispatch({
      type: actionTypes.OTP,
      payload: newOtp,
    });
  };

  const formValid = state.otp.valid && state.otp.value.trim() !== "";

  return (
    <VerifyWrapper>
      <ForgotTitle>OTP - Verification</ForgotTitle>
      <ForgotText>Enter the OTP sent to {maskedNumber}</ForgotText>
      <OtpInput
        otp={state.otp.value}
        error={!state.otp.valid}
        helperText={state.otp.error}
        otpChangeHandler={otpChangeHandler}
      />
      <Box display="flex" flexDirection="row">
        <ForgotText>{"Didn't receive the OTP?"}</ForgotText>
        <ForgotText>&nbsp;</ForgotText>
        <ResendText onClick={() => resendOtp(fullNumber)} color="secondary">
          Resend OTP
        </ResendText>
      </Box>
      <PrimaryButton
        fullWidth
        fontSize={16}
        onClick={verifyClickHandler}
        sx={{ marginTop: "10px" }}
        disabled={!formValid}
      >
        Verify
      </PrimaryButton>
      <AuthTextButton variant="signup" />
    </VerifyWrapper>
  );
};

export default VerifyOtp;
