import { FC } from "react";
import { SelectChangeEvent } from "@mui/material";

import CustomSelect, { SelectItem } from "src/components/customSelect";

import { useMyBets } from "../../hooks/useMyBets";

const items: SelectItem[] = [
  { label: "cash", value: "1" },
  { label: "bonus", value: "2" },
  { label: "promo", value: "3" },
];

const WalletFilter: FC = () => {
  const { currentWalletFilter, changeWalletFilter } = useMyBets();

  function onChangeHandler(event: SelectChangeEvent<string>) {
    changeWalletFilter(Number(event.target.value));
  }

  return (
    <CustomSelect
      id="wallet-select"
      items={items}
      onChange={onChangeHandler}
      value={String(currentWalletFilter)}
      variant="outlined"
    />
  );
};

export default WalletFilter;
