import {
  Box,
  BoxProps,
  Grid,
  GridProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const StyledTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.colors.text.light,
    fontSize: 12,
    textOverflow: "ellipsis",
  })
);

export const FixtureWrapper = styled(Grid)<GridProps>(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
}));

export const TeamNamesWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "start",
  gap: theme.spacing(0.5),
}));

export const TextWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "start",
  gap: theme.spacing(1),
  paddingLeft: theme.spacing(0.5),
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const SelectionWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(0.2),
}));
