import { FC } from "react";
import { useLocation } from "react-router-dom";

import { MobileWrapper } from "src/components/component-wrappers";
import { SubheaderWrapper } from "./Subheader.styles";
import Breadcrumbs from "src/components/breadcrumbs/BreadCrumbs";
import { NavBar } from "src/features/navBar";
import appConfig from "src/config/appConfig";

const Subheader: FC = () => {
  const { pathname } = useLocation();

  return (
    <>
      {!appConfig.hideNavigationBar.includes(pathname) ? (
        <SubheaderWrapper>
          <MobileWrapper>
            <Breadcrumbs />
          </MobileWrapper>
          <NavBar />
        </SubheaderWrapper>
      ) : null}
    </>
  );
};

export default Subheader;
