import { Box } from "@mui/material";
import { FC, useState } from "react";
import { OtpInput } from "src/components/Inputs";
import { PrimaryButton } from "src/components/buttons";
import { useAuth } from "../../hooks/useAuth";
import { getMaskedNumber } from "../../utils/getFullNumber";
import {
  ForgotText,
  ForgotTitle,
  ResendText,
  VerifyWrapper,
} from "./Unverified.styles";

type UnverifiedProps = {
  fullNumber: string;
};

const Unverified: FC<UnverifiedProps> = ({ fullNumber }) => {
  const { resendOtp } = useAuth();
  const [otp, setOtp] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const maskedNumber = getMaskedNumber(fullNumber);
  const { verifyOtp } = useAuth();
  const otpRegex = /^[0-9]{6}$/;

  const otpChangeHandler = (newOtp: string) => {
    setOtp(newOtp);
    const valid = otpRegex.test(newOtp);
    setError(!valid);
  };

  const verifyClickHandler = () => {
    if (!otpRegex.test(otp)) return;

    // Verify OTP
    try {
      verifyOtp(fullNumber, otp);
    } catch (error) {
      setError(true);
    }
  };

  return (
    <VerifyWrapper>
      <ForgotTitle>OTP - Verification</ForgotTitle>
      <ForgotText>
        The login failed because your account is not verified. Please enter the
        OTP sent to {maskedNumber}
      </ForgotText>
      <OtpInput
        otp={otp}
        error={error}
        helperText={error ? "Invalid OTP" : ""}
        otpChangeHandler={otpChangeHandler}
      />
      <Box display="flex" flexDirection="row">
        <ForgotText>{"Didn't receive the OTP?"}</ForgotText>
        <ForgotText>&nbsp;</ForgotText>
        <ResendText onClick={() => resendOtp(fullNumber)} color="secondary">
          Resend OTP
        </ResendText>
      </Box>
      <PrimaryButton
        fullWidth
        fontSize={16}
        onClick={verifyClickHandler}
        sx={{ marginTop: "10px" }}
      >
        Verify
      </PrimaryButton>
    </VerifyWrapper>
  );
};

export default Unverified;
