import { FC } from "react";

import { BetCountText, BetCountWrapper } from "./BetCount.styles";

interface IBetCount {
  size: number | string;
  fontSize: number | string;
  value: number;
}

const BetCount: FC<IBetCount> = ({ size, fontSize, value }) => {
  return (
    <BetCountWrapper height={size} width={size}>
      <BetCountText fontSize={fontSize}>{value}</BetCountText>
    </BetCountWrapper>
  );
};

export default BetCount;
