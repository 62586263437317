import { ChangeEvent, FC, useEffect, useState } from "react";

import { StyledInput } from "./BetAmountInput.styles";

type BetAmountInputProps = {
  betId: string;
  value: number;
  updateStake: (betId: string, amount: number) => void;
};

const BetAmountInput: FC<BetAmountInputProps> = ({
  betId,
  value,
  updateStake,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState<number>(value);

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const newValue = parseFloat(event.target.value);
    if (newValue) {
      setLocalValue(newValue);
    } else {
      setLocalValue(0);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      updateStake(betId, localValue);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [betId, localValue, updateStake]);

  useEffect(() => {
    if (!isFocused && localValue !== value) {
      setLocalValue(value);
    }
  }, [isFocused, localValue, value]);

  return (
    <StyledInput
      value={localValue}
      onChange={inputChangeHandler}
      inputProps={{
        inputMode: "numeric",
        onBlur: () => setIsFocused(false),
        onFocus: () => setIsFocused(true),
      }}
    />
  );
};

export default BetAmountInput;
