import { Typography, TypographyProps, styled } from "@mui/material";

export const DateText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.text.light,
  fontSize: 10,
  fontWeight: 400,

  marginBottom: theme.spacing(0.5),
}));

export const TitleText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.text.light,
  fontSize: 13,
  fontWeight: 500,
  textTransform: "capitalize",
}));

export const SubtitleText = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.colors.text.light,
    fontSize: 13,
    fontWeight: 400,
  })
);

export const ExpandedText = styled(Typography)<TypographyProps>(
  () => ({
    fontSize: 10,
    fontWeight: 200,
  })
);
