import {
  CustomExoticMarketType,
  NumbersExoticMarketType,
  QuickPicksMarketType,
} from "../../Markets/types";

import { LottoFixtureMarketType } from "../models/lottoFixture";

export const isCustomExoticMarket = (
  market: LottoFixtureMarketType
): market is CustomExoticMarketType => {
  return market.def.data.type === "NWAY";
};

export const isNumbersExoticMarket = (
  market: LottoFixtureMarketType
): market is NumbersExoticMarketType => {
  return market.def.data.type === "ADV_NUMBERS";
};

export const isQuickPicksMarket = (
  market: LottoFixtureMarketType
): market is QuickPicksMarketType => {
  return (
    market.def.data.type !== "ADV_NUMBERS" && market.def.data.type !== "NWAY"
  );
};
