import { FC } from "react";

const BookmakerLicense: FC = () => {
  return (
    <>
      <h1>
        <a
          href="https://new.topbet.co.za/assets/content/All/Hatsy_License_NEW-c74a64dd.pdf"
          style={{ color: "#f66e22" }}
          target="_blank"
          rel="noreferrer"
        >
          BOOKMAKER LICENSE
        </a>
      </h1>
    </>
  );
};

export default BookmakerLicense;
