import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
  styled,
} from "@mui/material";

export const StyledFormControlLabel = styled(
  FormControlLabel
)<FormControlLabelProps>(({ theme }) => ({
  color: theme.colors.text.light,
  fontSize: 14,
  fontWeight: 400,
  gap: theme.spacing(1),

  marginLeft: 0,
  marginRight: 0,
}));

export const StyledSwitch = styled(Switch)<SwitchProps>(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.colors.secondary.main,
        border: "2px solid " + theme.colors.secondary.main,
        opacity: 1,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: theme.colors.secondary.main,
      border: "2px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 11.5,

    backgroundColor: theme.colors.neutral.grey[5],
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
