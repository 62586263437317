import { FC } from "react";

import { SportIcons } from "src/assets/icons";

export type SportIconProps = {
  name: keyof typeof SportIcons;
};

const SportIcon: FC<SportIconProps> = ({ name }) => {
  const Svg = SportIcons[name];

  return <Svg />;
};

export default SportIcon;
