import { List, ListProps, styled } from "@mui/material";

export const StyledList = styled(List)<ListProps>(({ theme }) => ({
  maxHeight: "400px",
  width: "100%",
  overflow: "auto",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(1),
}));
