import { FixtureItem } from "../models/FixtureItem";

export type TournamentItem = {
  region: string;
  tournament: string;
};

export function getUniqueTournaments(fixtures: FixtureItem[]) {
  const tournaments: TournamentItem[] = fixtures.map((fixture) => ({
    region: fixture.attr.region?.value ?? "",
    tournament: fixture.attr.tournamentName.value,
  }));

  const uniqueTournaments: TournamentItem[] = tournaments.filter(
    (tournament, index) => {
      const arrayTournamentIndex = tournaments.findIndex(
        (t) =>
          t.region === tournament.region &&
          t.tournament === tournament.tournament
      );

      return arrayTournamentIndex === index;
    }
  );

  return uniqueTournaments;
}
