import { FC } from "react";
import { SelectChangeEvent } from "@mui/material";

import CustomSelect, { SelectItem } from "src/components/customSelect";

import { useMyBets } from "../../hooks/useMyBets";

const items: SelectItem[] = [
  { label: "today", value: "today" },
  { label: "week", value: "week" },
  { label: "month", value: "month" },
  { label: "year", value: "year" },
];

const PeriodFilter: FC = () => {
  const { currentPeriodFilter, changePeriodFilter } = useMyBets();

  function onChangeHandler(event: SelectChangeEvent<string>) {
    changePeriodFilter(event.target.value);
  }

  return (
    <CustomSelect
      id="period-select"
      items={items}
      onChange={onChangeHandler}
      value={currentPeriodFilter}
      variant="outlined"
    />
  );
};

export default PeriodFilter;
