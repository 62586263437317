import { FC } from "react";

import { StyledDivider, Wrapper, RowBox } from "./BetDetails.styles";
import BetDetail from "./BetDetail";

type BetDetailsProps = {
  betsLength: number;
  totalPayout: number;
  totalStake: number;
};

const BetDetails: FC<BetDetailsProps> = ({
  betsLength,
  totalPayout,
  totalStake,
}) => {
  return (
    <Wrapper>
      <RowBox>
        <BetDetail currency title="Total Stake Amount" value={totalStake} />
        <BetDetail title="Number of Bets" value={betsLength} />
      </RowBox>
      <StyledDivider />
      <BetDetail currency title="Possible Winnings" value={totalPayout} />
    </Wrapper>
  );
};

export default BetDetails;
