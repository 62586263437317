import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "src/store/store";
import { SportsState } from "./sportsSlice.contracts";

const initialState: SportsState = {
  allSports: {
    sportFilter: "Cricket",
    marketFilter: "ee3cfae9-6c3c-4d30-ae75-0df939f79e8f",
  },
  liveSports: {
    sportFilter: "Cricket",
    marketFilter: "ee3cfae9-6c3c-4d30-ae75-0df939f79e8f",
  },
  leagues: {
    allLeagues: [],
    selectedLeagues: [],
    showLeaguesModal: false,
  },
  options: {
    showOptionsModal: false,
    timeOption: "all",
    dateOption: "all",
    oddsOption: [0, 10],
  },
};

const sportsSlice = createSlice({
  name: "sports",
  initialState,
  reducers: {
    //#region All Sports
    setAllMarketFilter(state, action: PayloadAction<{ market: string }>) {
      const { market } = action.payload;
      state.allSports.marketFilter = market;
    },
    setAllSportFilter(state, action: PayloadAction<{ sport: string }>) {
      const { sport } = action.payload;
      state.allSports.marketFilter = "";
      state.allSports.sportFilter = sport;
    },
    //#endregion All Sports
    //#region Live Sports
    setLiveMarketFilter(state, action: PayloadAction<{ market: string }>) {
      const { market } = action.payload;
      state.liveSports.marketFilter = market;
    },
    setLiveSportFilter(state, action: PayloadAction<{ sport: string }>) {
      const { sport } = action.payload;
      state.liveSports.marketFilter = "";
      state.liveSports.sportFilter = sport;
    },
    //#endregion Live Sports
    //#region Leagues
    setAllLeagues(state, action: PayloadAction<{ leagues: string[] }>) {
      const { leagues } = action.payload;
      state.leagues.allLeagues = leagues;
    },
    clearAllLeagues(state) {
      state.leagues.allLeagues = [];
    },
    addSelectedLeague(state, action: PayloadAction<{ league: string }>) {
      const { league } = action.payload;
      state.leagues.selectedLeagues.push(league);
    },
    removeSelectedLeague(state, action: PayloadAction<{ league: string }>) {
      const { league } = action.payload;
      const updatedLeagues = state.leagues.selectedLeagues.filter(
        (l) => l !== league
      );
      state.leagues.selectedLeagues = updatedLeagues;
    },
    clearSelectedleagues(state) {
      state.leagues.selectedLeagues = [];
    },
    setSelectedLeagues(state, action: PayloadAction<{ leagues: string[] }>) {
      const { leagues } = action.payload;
      state.leagues.selectedLeagues = leagues;
    },
    toggleLeaguesModal(state, action: PayloadAction<{ show: boolean }>) {
      const { show } = action.payload;
      state.leagues.showLeaguesModal = show;
    },
    //#endregion Leagues
    //#region Options
    toggleOptionsModal(state, action: PayloadAction<{ show: boolean }>) {
      const { show } = action.payload;
      state.options.showOptionsModal = show;
    },
    setTimeOption(state, action: PayloadAction<{ option: string }>) {
      const { option } = action.payload;
      state.options.timeOption = option;
    },
    setDateOption(state, action: PayloadAction<{ option: string }>) {
      const { option } = action.payload;
      state.options.dateOption = option;
    },
    setOddsOption(state, action: PayloadAction<{ option: number[] }>) {
      const { option } = action.payload;
      state.options.oddsOption = option;
    },
    //#endregion Options
  },
});

export default sportsSlice.reducer;

export const {
  addSelectedLeague,
  removeSelectedLeague,
  setAllLeagues,
  setAllMarketFilter,
  setAllSportFilter,
  setDateOption,
  setLiveMarketFilter,
  setLiveSportFilter,
  setOddsOption,
  setSelectedLeagues,
  setTimeOption,
  toggleLeaguesModal,
  toggleOptionsModal,
} = sportsSlice.actions;

/* All Sports */
export const selectAllMarketFilter = (state: RootState) =>
  state.sports.allSports.marketFilter;
export const selectAllSportFilter = (state: RootState) =>
  state.sports.allSports.sportFilter;

/* Live Sports */
export const selectLiveMarketFilter = (state: RootState) =>
  state.sports.liveSports.marketFilter;
export const selectLiveSportFilter = (state: RootState) =>
  state.sports.liveSports.sportFilter;

/* Leagues */
export const selectAllLeagues = (state: RootState) =>
  state.sports.leagues.allLeagues;
export const selectAllSelectedLeagues = (state: RootState) =>
  state.sports.leagues.selectedLeagues;
export const selectShowLeagesModal = (state: RootState) =>
  state.sports.leagues.showLeaguesModal;

/* Options */
export const selectShowOptionsModal = (state: RootState) =>
  state.sports.options.showOptionsModal;
export const selectTimeOption = (state: RootState) =>
  state.sports.options.timeOption;
export const selectDateOption = (state: RootState) =>
  state.sports.options.dateOption;
export const selectOddsOption = (state: RootState) =>
  state.sports.options.oddsOption;
