import logger from "src/utils/logger";
import { _getCognitoUser } from "./_getCognitoUser";

export const confirmPassword = async (
  cellphone: string,
  otp: string,
  newPassword: string
) => {
  try {
    return await new Promise((resolve, reject) => {
      const cognitoUser = _getCognitoUser(cellphone);
      cognitoUser.confirmPassword(otp, newPassword, {
        onSuccess: function (result) {
          logger.log("call result: " + result);
          resolve(result);
        },
        onFailure: function (err) {
          // alert(err.message || JSON.stringify(err));
          reject(err.message);
        },
      });
    });
  } catch (error: any) {
    console.log("error in the last catch block", error);
    throw new Error(error);
  }
};
