import { Box, BoxProps, styled } from "@mui/material";

import appConfig from "src/config/appConfig";

export const BetSlipWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.primaryAlt.main,

  overflow: "visible",
  width: "100%",
  zIndex: 9,

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",

  [theme.breakpoints.down(appConfig.breakMobileView)]: {
    height: "100%",
  },

  [theme.breakpoints.up(appConfig.breakMobileView)]: {
    height: "90%", // this is to keep the bet slip moving down when multiple are added
  },
}));
