import { FC } from "react";

import CustomDialog from "src/components/custom-dialog/CustomDialog";
import ForgotPassword from "./ForgotPassword";

import { AuthDialogType, useAuthDailogStore } from "src/store/authentication/authStore";
import { useSteps } from "../../hooks/useSteps";

const ForgotPasswordModal: FC = () => {
  const { step, incrementStep, decrementStep } = useSteps(3);
  const authDisplayType = useAuthDailogStore((state) => state.displayType);


  return (
    <CustomDialog
      open={authDisplayType === AuthDialogType.ForgotPassword}
      onBackClick={step === 0 ? undefined : decrementStep}
    >
      <ForgotPassword step={step} incrementStep={incrementStep} />
    </CustomDialog>
  );
};

export default ForgotPasswordModal;
