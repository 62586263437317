import { FC } from "react";
import { Skeleton } from "@mui/material";

import { FixtureGroupWrapper } from "./FixtureGroup.styles";
import FixtureLoader from "../fixture/FixtureLoader";

const FixtureGroupLoader: FC = () => {
  return (
    <FixtureGroupWrapper>
      <Skeleton
        animation="pulse"
        variant="rounded"
        height="20px"
        width="140%"
        sx={(theme) => ({
          bgcolor: theme.colors.neutral.grey[5],
        })}
      />
      <FixtureLoader marketSelections={[1, 2, 3]} />
      <FixtureLoader marketSelections={[4, 5]} />
      <FixtureLoader marketSelections={[6, 7]} />
    </FixtureGroupWrapper>
  );
};

export default FixtureGroupLoader;
