import { FC } from "react";
import { Grid, Skeleton } from "@mui/material";

import {
  FixtureWrapper,
  SelectionWrapper,
  TeamNamesWrapper,
  TextWrapper,
} from "./Fixture.styles";

type FixtureLoaderProps = {
  marketSelections: number[];
};

const FixtureLoader: FC<FixtureLoaderProps> = ({ marketSelections }) => {
  return (
    <FixtureWrapper container>
      <Grid item>
        <TextWrapper>
          <Skeleton
            animation="pulse"
            variant="text"
            width="80px"
            sx={(theme) => ({
              bgcolor: theme.colors.neutral.grey[5],
            })}
          />
          <TeamNamesWrapper>
            <Skeleton
              animation="pulse"
              variant="text"
              width="60px"
              sx={(theme) => ({
                bgcolor: theme.colors.neutral.grey[5],
              })}
            />
            <Skeleton
              animation="pulse"
              variant="text"
              width="48px"
              sx={(theme) => ({
                bgcolor: theme.colors.neutral.grey[5],
              })}
            />
          </TeamNamesWrapper>
        </TextWrapper>
      </Grid>
      <Grid item display="flex" flexDirection="row" gap="8px">
        {marketSelections.map((selection) => (
          <SelectionWrapper
            alignItems="center"
            key={"fixture-loader-selection-" + selection}
          >
            <Skeleton
              animation="pulse"
              variant="text"
              width="16px"
              sx={(theme) => ({
                bgcolor: theme.colors.neutral.grey[5],
              })}
            />
            <Skeleton
              animation="pulse"
              variant="rounded"
              height="48px"
              width="80px"
              sx={(theme) => ({
                bgcolor: theme.colors.neutral.grey[5],
                padding: "10px, 15px, 10px, 15px",
              })}
            />
          </SelectionWrapper>
        ))}
      </Grid>
    </FixtureWrapper>
  );
};

export default FixtureLoader;
