import { FC, useEffect } from "react";
import { CircularProgress, Typography } from "@mui/material";
import NProgress from "nprogress";

import PageWrapper from "../page-wrapper/PageWrapper";
import { StyledBox } from "./SuspenseLoader.styles";

interface SuspenseLoaderProps {
  message?: string;
}

/**
 * Component which renders a loading screen while other components are loading.
 * @param message Optional message to display while loading.
 * @returns JSX
 */
const SuspenseLoader: FC<SuspenseLoaderProps> = ({ message }) => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <PageWrapper helmetTitle="Loading">
      <StyledBox>
        <CircularProgress size={64} disableShrink thickness={3} />
        <Typography variant="h2">{message}</Typography>
      </StyledBox>
    </PageWrapper>
  );
};

export default SuspenseLoader;
