import { FC, ReactElement, Ref, forwardRef } from "react";
import { DialogProps, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { ArrowBackRounded, CloseRounded } from "@mui/icons-material";

import {
  StyledDialog,
  DialogContainer,
  StyledIconButton,
} from "./CustomDialog.styles";

interface TransitionPropsType extends TransitionProps {
  children: ReactElement<any, any>;
}
const Transition = forwardRef(function Transistion(
  props: TransitionPropsType,
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface CustomDialogProps extends DialogProps {
  onBackClick?: () => void;
  onCloseHandler?: () => void;
}

const CustomDialog: FC<CustomDialogProps> = ({
  children,
  onBackClick,
  onCloseHandler,
  ...props
}) => {
  return (
    <StyledDialog
      TransitionComponent={Transition}
      {...props}
      fullWidth
      maxWidth="xl"
    >
      <DialogContainer>
        {onBackClick ? (
          <StyledIconButton pos="left" onClick={onBackClick}>
            <ArrowBackRounded color="info" />
          </StyledIconButton>
        ) : null}
        {onCloseHandler ? (
          <StyledIconButton pos="right" onClick={onCloseHandler}>
            <CloseRounded color="info" />
          </StyledIconButton>
        ) : null}
        {children}
      </DialogContainer>
    </StyledDialog>
  );
};

export default CustomDialog;
