import { FC } from "react";
import { Box, BoxProps, styled } from "@mui/material";

const StyledBox = styled(Box)<BoxProps>(() => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  textAlign: "center",
}));

const VersionNumber: FC = () => {
  return <StyledBox>v1.6.10</StyledBox>;
};

export default VersionNumber;
