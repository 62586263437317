import { FC } from "react";
import { Typography } from "@mui/material";

import CustomDialog from "src/components/custom-dialog/CustomDialog";
import { ModalWrapper, ButtonWrapper } from "../Modals.styles";
import Title from "../Title";
import { StyledList } from "./LeaguesModal.styles";
import LeagueItem from "./item/LeagueItem";
import { SecondaryButton, SubmitButton } from "src/components/buttons";

import { useLeagues, useShowLeaguesModal } from "./LeaguesModal.hooks";

const LeaguesModal: FC = () => {
  const { showLeaguesModal, toggleLeaguesModalHandler } = useShowLeaguesModal();
  const {
    allLeagues,
    clearOptions,
    onClickHandler,
    selectedLeagues,
    updateRedux,
  } = useLeagues();

  function applyClickHandler() {
    updateRedux();
    toggleLeaguesModalHandler(false);
  }

  return (
    <CustomDialog
      open={showLeaguesModal}
      onClose={() => toggleLeaguesModalHandler(false)}
    >
      <ModalWrapper>
        <Title
          title="League Filter"
          onCloseHandler={() => toggleLeaguesModalHandler(false)}
        />
        {allLeagues.length === 0 ? (
          <Typography
            color={(theme) => theme.colors.text.light}
            textAlign="center"
            mt={(theme) => theme.spacing(2)}
          >
            No Leagues available based on your filter settings.
          </Typography>
        ) : (
          <StyledList>
            {allLeagues.map((league, index) => (
              <LeagueItem
                key={league + index}
                league={league}
                selected={selectedLeagues.includes(league)}
                onClickHandler={onClickHandler}
              />
            ))}
          </StyledList>
        )}
        <ButtonWrapper>
          <SecondaryButton fontSize={16} fullWidth onClick={clearOptions}>
            Clear
          </SecondaryButton>
          <SubmitButton fontSize={16} fullWidth onClick={applyClickHandler}>
            Apply
          </SubmitButton>
        </ButtonWrapper>
      </ModalWrapper>
    </CustomDialog>
  );
};

export default LeaguesModal;
