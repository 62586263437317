import { FC } from "react";

const TermsAndConditions: FC = () => {
  return (
    <>
      <h1>
        <a
          href="https://new.topbet.co.za/assets/content/Hatsy (Pty) Ltd Complete Terms and Conditions_WC-553dd17a.pdf"
          style={{ color: "#f66e22" }}
          target="_blank"
          rel="noreferrer"
        >
          TERMS & CONDITIONS
        </a>
      </h1>

      <p
        style={{
          textAlign: "center",
          color: "#edba02",
          fontFamily: "Roboto Condensed, sans-serif",
        }}
      >
        <h1>
          <a
            href="https://www.wcgrb.co.za/wp-content/uploads/2019/08/Bookmakers-Operational-Rules-15-June-2017.pdf"
            style={{ color: "#edba02" }}
            target="_blank"
            rel="noreferrer"
          >
            Bookmaker Operation Rules
          </a>{" "}
          |{" "}
          <a
            href="https://www.wcgrb.co.za/wp-content/uploads/2019/08/Racing_and_Betting_Operational_Rules.pdf"
            style={{ color: "#edba02" }}
            target="_blank"
            rel="noreferrer"
          >
            Racing and Betting Operational Rules
          </a>{" "}
          |{" "}
          <a
            href="https://www.wcgrb.co.za/wp-content/uploads/2019/08/Totalisator-Operational-Rules.pdf"
            style={{ color: "#edba02" }}
            target="_blank"
            rel="noreferrer"
          >
            Totalisator Rules
          </a>{" "}
        </h1>
      </p>
    </>
  );
};

export default TermsAndConditions;
