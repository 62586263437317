import { FC } from "react";

import { StyledButton, StyledTypography } from "./NavButton.styles";
import BetCount from "../../betCount";

interface INavButton {
  numberOfBets: number;
  text: string;
  onClick: () => void;
}

const NavButton: FC<INavButton> = ({ numberOfBets, text, onClick }) => {
  return (
    <StyledButton onClick={onClick} variant="text">
      <StyledTypography>{text}</StyledTypography>
      <BetCount value={numberOfBets} size="15px" fontSize={10} />
    </StyledButton>
  );
};

export default NavButton;
