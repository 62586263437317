import { FC } from "react";
import { ButtonProps } from "@mui/material";

import { StyledButton } from "./ModalButtons.styles";

export const ApplyButton: FC<ButtonProps> = (props) => {
  return (
    <StyledButton fullWidth variant="contained" {...props}>
      Apply
    </StyledButton>
  );
};

export const ClearButton: FC<ButtonProps> = (props) => {
  return (
    <StyledButton fullWidth variant="outlined" {...props}>
      Clear
    </StyledButton>
  );
};
