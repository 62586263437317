import { FC } from "react";
import { Slider } from "@mui/material";

import { Wrapper } from "./OptionSlider.styles";

const minDistance = 0.5;

type OptionSliderProps = {
  label: string;
  currentOption: number[];
  optionChangeHandler: (value: number[]) => void;
};

const OptionSlider: FC<OptionSliderProps> = ({
  label,
  currentOption,
  optionChangeHandler,
}) => {
  function slideChangeHandler(
    event: Event,
    value: number | number[],
    activeThumb: number
  ) {
    if (!Array.isArray(value)) {
      return;
    }

    if (activeThumb === 0) {
      optionChangeHandler([
        Math.min(value[0], currentOption[1] - minDistance),
        currentOption[1],
      ]);
    } else {
      optionChangeHandler([
        currentOption[0],
        Math.max(value[1], currentOption[0] + minDistance),
      ]);
    }
  }

  return (
    <Wrapper>
      {label}
      <Slider
        color="secondary"
        disableSwap
        max={10}
        min={0.1}
        onChange={slideChangeHandler}
        step={0.1}
        value={currentOption}
        valueLabelDisplay="auto"
      />
    </Wrapper>
  );
};

export default OptionSlider;
