import {
  getidentityNumberStart,
  isAdult,
  validateIdentificationNumber,
} from "src/utils/identificationHelper";
import { RegexPatterns } from "../../../utils/constants/regex";

import { FieldTypes } from "../models/fieldTypes";

export interface IInitialState {
  cellphone: FieldTypes;
  firstName: FieldTypes;
  lastName: FieldTypes;
  password: FieldTypes;
  confirmPassword: FieldTypes;
  email: FieldTypes;
  birthDate: FieldTypes;
  identityNumber: FieldTypes;
  passportNumber: FieldTypes;
  countryCode: FieldTypes;
  recaptcha: string;
  otp: FieldTypes;
}

export const initialState: IInitialState = {
  email: {
    value: "",
    valid: true,
    error: "",
    name: "Email",
    regex: RegexPatterns.email,
  },
  firstName: {
    value: "",
    valid: true,
    error: "",
    name: "First Name",
    regex: RegexPatterns.name,
  },
  lastName: {
    value: "",
    valid: true,
    error: "",
    name: "Last Name",
    regex: RegexPatterns.name,
  },
  password: {
    value: "",
    valid: true,
    error: "",
    name: "Password",
    regex: RegexPatterns.password,
  },
  confirmPassword: {
    value: "",
    valid: true,
    error: "",
    name: "Confirm Password",
    regex: RegexPatterns.password,
  },
  cellphone: {
    value: "",
    valid: true,
    error: "",
    name: "Cellphone",
    regex: RegexPatterns.cellphone,
  },
  birthDate: {
    value: "",
    valid: true,
    error: "",
    name: "Birth Date",
    regex: RegexPatterns.birthDate,
  },
  identityNumber: {
    value: "",
    valid: true,
    error: "",
    name: "Identity Number",
    regex: RegexPatterns.idNumber,
  },
  passportNumber: {
    value: "",
    valid: true,
    error: "",
    name: "Passport Number",
    regex: RegexPatterns.passportNumber,
  },
  countryCode: { value: "+27", valid: true, error: "", name: "Country Code" },
  recaptcha: "",
  otp: {
    value: "",
    valid: true,
    error: "",
    name: "OTP",
    regex: RegexPatterns.otp,
  },
};

export enum actionTypes {
  EMAIL = "EMAIL",
  FIRSTNAME = "FIRSTNAME",
  LASTNAME = "LASTNAME",
  PASSWORD = "PASSWORD",
  CONFIRMPASSWORD = "CONFIRMPASSWORD",
  CELLPHONE = "CELLPHONE",
  BIRTHDATE = "BIRTHDATE",
  IDENTITYNUMBER = "IDENTITYNUMBER",
  PASSPORTNUMBER = "PASSPORTNUMBER",
  COUNTRYCODE = "COUNTRYCODE",
  RECAPTCHA = "RECAPTCHA",
  OTP = "OTP",
}

export interface IAction {
  type: actionTypes;
  payload: string;
}

export const authFormReducer = (
  state: typeof initialState,
  action: IAction
) => {
  const payload = action.payload;
  let errorObj = { valid: true, error: "" };
  let confirmErrObj = { valid: true, error: "" };
  if (!payload) {
    errorObj = { valid: false, error: "Field is required" };
  }
  let regex;
  switch (action.type) {
    case actionTypes.EMAIL:
      regex = state.email.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
      }
      return {
        ...state,
        email: { ...state.email, ...errorObj, value: payload },
      };
    case actionTypes.FIRSTNAME:
      regex = state.firstName.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
      }
      return {
        ...state,
        firstName: { ...state.firstName, ...errorObj, value: payload },
      };
    case actionTypes.LASTNAME:
      regex = state.lastName.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
      }
      return {
        ...state,
        lastName: { ...state.lastName, ...errorObj, value: payload },
      };
    case actionTypes.PASSWORD:
      regex = state.password.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
        if (payload.length < 8) {
          errorObj = {
            valid: false,
            error: "Password must be at least 8 characters long",
          };
        }
        if (payload.length > 16) {
          errorObj = {
            valid: false,
            error: "Password must be less than 16 characters long",
          };
        }
      }
      if (payload !== state.confirmPassword.value) {
        confirmErrObj = { valid: false, error: "Passwords do not match" };
      }
      return {
        ...state,
        password: { ...state.password, ...errorObj, value: payload },
        confirmPassword: { ...state.confirmPassword, ...confirmErrObj },
      };
    case actionTypes.CONFIRMPASSWORD:
      regex = state.confirmPassword.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
        if (payload !== state.password.value) {
          errorObj = { valid: false, error: "Passwords do not match" };
        }
      }
      return {
        ...state,
        confirmPassword: {
          ...state.confirmPassword,
          ...errorObj,
          value: payload,
        },
      };
    case actionTypes.CELLPHONE:
      regex = state.cellphone.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
      }
      return {
        ...state,
        cellphone: { ...state.cellphone, ...errorObj, value: payload },
      };
    case actionTypes.BIRTHDATE:
      regex = state.birthDate.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
        if (!isAdult(payload)) {
          errorObj = { valid: false, error: "Must be 18 years or older" };
        }
      }
      return {
        ...state,
        birthDate: { ...state.birthDate, ...errorObj, value: payload },
        identityNumber: { ...state.identityNumber, value: getidentityNumberStart(payload) },
      };
    case actionTypes.IDENTITYNUMBER:
      regex = state.identityNumber.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
        // if there is no birtdate, error
        if (!state.birthDate.value) {
          errorObj = { valid: false, error: "Birth Date is required" };
        }
        if (!validateIdentificationNumber(payload).valid) {
          errorObj = { valid: false, error: "ID number is invalid" };
        }
        // if birthdate and id number don't match, error
        const identityNumberStart = getidentityNumberStart(
          state.birthDate.value
        );
        if (identityNumberStart !== payload.substring(0, 6)) {
          errorObj = {
            valid: false,
            error: "Birth Date and ID Number do not match",
          };
        }
      }
      return {
        ...state,
        identityNumber: {
          ...state.identityNumber,
          ...errorObj,
          value: payload,
        },
      };
    case actionTypes.PASSPORTNUMBER:
      regex = state.passportNumber.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
      }
      return {
        ...state,
        passportNumber: {
          ...state.passportNumber,
          ...errorObj,
          value: payload,
        },
      };
    case actionTypes.COUNTRYCODE:
      return {
        ...state,
        countryCode: { ...state.countryCode, value: payload },
      };
    case actionTypes.RECAPTCHA:
      return { ...state, recaptcha: payload };
    case actionTypes.OTP:
      regex = state.otp.regex;
      if (regex?.pattern) {
        const regexp = new RegExp(regex.pattern);
        if (!regexp.test(payload)) {
          errorObj = {
            valid: false,
            error: regex.message ?? "Field is invalid",
          };
        }
      }
      return { ...state, otp: { ...state.otp, ...errorObj, value: payload } };
  }
};
