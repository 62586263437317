import { FC } from 'react';

import { BetDetailWrapper, Title, Value } from './BetDetail.styles';

import { currencyFormat } from 'src/utils/formatters';

interface IBetDetail {
  currency?: boolean;
  title: string;
  value: number;
}

const BetDetail: FC<IBetDetail> = ({ currency = false, title, value }) => {
  return (
    <BetDetailWrapper>
      <Title>{title}</Title>
      <Value>{currency ? currencyFormat(value, 'R') : value}</Value>
    </BetDetailWrapper>
  );
};

export default BetDetail;
