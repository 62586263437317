import { FC } from "react";
import { Box, IconButton } from "@mui/material";

import { Wrapper, SubWrapper } from "./BetItem.styles";
import BetCount from "../../betCount";
import BetInfo from "./info/BetInfo";
import BetInput from "./input/BetInput";
import { Icon } from "src/components/icons";

import { useSportBetItem } from "./SportBetItem.hooks";

import { SportBet } from "../../../models/sport";

type SportBetItemProps = {
  bet: SportBet;
  index: number;
};

const BetItem: FC<SportBetItemProps> = ({ bet, index }) => {
  const { removeFromBetSlip, updateSportStake } = useSportBetItem();

  return (
    <Wrapper>
      <SubWrapper textAlign={"left"}>
        <Box alignSelf={"end"} paddingBottom={"10px"}>
          <BetCount value={index + 1} size={24} fontSize={11} />
        </Box>
        <BetInfo info={bet.info} />
      </SubWrapper>
      <SubWrapper alignItems="center">
        <BetInput
          id={bet.id}
          price={bet.legs[0].price}
          stake={bet.stake}
          updateStake={updateSportStake}
        />
        <IconButton
          onClick={() => removeFromBetSlip(bet.id)}
          sx={{ alignSelf: "end", paddingBottom: "10px" }}
        >
          <Icon name="TrashLight" />
        </IconButton>
      </SubWrapper>
    </Wrapper>
  );
};

export default BetItem;
