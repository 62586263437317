import { FC } from "react";

import CustomDialog from "src/components/custom-dialog/CustomDialog";
import Register from "./Register";

import { AuthDialogType, useAuthDailogStore } from "src/store/authentication/authStore";
import { useSteps } from "../../hooks/useSteps";

const RegisterModal: FC = () => {
  const {step, decrementStep, incrementStep } = useSteps(3);
  const setAuthDisplayType = useAuthDailogStore(
    (state) => state.setDisplayType
  );
  const modalShown = useAuthDailogStore((state) => state.displayType);
  const onBackClick = () => {
    decrementStep();
  };

  return (
    <CustomDialog
      open={modalShown === "register"}
      onClose={() => setAuthDisplayType(AuthDialogType.None)}
      onBackClick={step === 0 ? undefined : onBackClick}
    >
      <Register step={step} incrementStep={incrementStep} />
    </CustomDialog>
  );
};

export default RegisterModal;
