import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: theme.spacing(1),
}));

export const TitleText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.neutral.white[100],
  fontSize: 16,
  fontWeight: 700,
  lineHeight: "140%",
}));

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),

  width: "100%",
}));

export const UploadContainer = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: 10,
  border: "1px solid " + theme.colors.neutral.white[100],
  backgroundColor: "inherit",

  color: theme.colors.neutral.white[60],
  fontSize: 14,
  fontWeight: 600,

  width: "100%",

  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  paddingTop: theme.spacing(0.625),
  paddingBottom: theme.spacing(0.625),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(1.25),
}));

export const UploadButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: 32,
  border: "2px solid " + theme.colors.secondary.main,
  backgroundColor: theme.colors.secondary.main,

  padding: theme.spacing(1.25, 2.5),

  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2.25),

  color: theme.colors.neutral.black[100],
  fontSize: 12,
  fontWeight: 500,
  textTransform: "capitalize",

  "&:hover": {
    backgroundColor: theme.colors.secondary.main,
    border: "2px solid " + theme.colors.secondary.main,
  },
}));

export const ErrorText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.error.main,
  fontSize: 14,
  fontWeight: 600,

  marginLeft: theme.spacing(2),
}));
