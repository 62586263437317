import { FC } from "react";

import CustomDialog from "src/components/custom-dialog/CustomDialog";
import { ModalWrapper, ButtonWrapper } from "../Modals.styles";
import Title from "../Title";
import OptionSelect from "./select/OptionSelect";
import OptionSlider from "./slider/OptionSlider";
import { ApplyButton, ClearButton } from "../ModalButtons";

import { useOptions, useShowOptionsModal } from "./OptionsModal.hooks";

const timeOptions = [
  { label: "All", value: "all" },
  { label: "30 minutes", value: "30" },
  { label: "1 hour", value: "60" },
  { label: "2 hours", value: "120" },
  { label: "6 hours", value: "360" },
];

const dateOptions = [
  { label: "All", value: "all" },
  { label: "Today", value: "today" },
  { label: "Tomorrow", value: "tomorrow" },
  { label: "This Weekend", value: "this weekend" },
];

const OptionsModal: FC = () => {
  const { showOptionsModal, toggleOptionsModalHandler } = useShowOptionsModal();
  const {
    timeOption,
    dateOption,
    oddsOption,
    clearOptions,
    updateLocalOptions,
    updateRedux,
  } = useOptions();

  function applyClickHandler() {
    updateRedux();
    toggleOptionsModalHandler(false);
  }

  return (
    <CustomDialog
      open={showOptionsModal}
      onClose={() => toggleOptionsModalHandler(false)}
    >
      <ModalWrapper>
        <Title
          title="Filter Options"
          onCloseHandler={() => toggleOptionsModalHandler(false)}
        />
        <OptionSelect
          currentOption={timeOption}
          id="time"
          label="Time"
          options={timeOptions}
          optionChangeHandler={(value: string) =>
            updateLocalOptions({ option: "time", newValue: value })
          }
        />
        <OptionSelect
          currentOption={dateOption}
          id="dates"
          label="Dates"
          options={dateOptions}
          optionChangeHandler={(value: string) =>
            updateLocalOptions({ option: "date", newValue: value })
          }
        />
        <OptionSlider
          label="Odds"
          currentOption={oddsOption}
          optionChangeHandler={(value: number[]) =>
            updateLocalOptions({ option: "odds", newValue: value })
          }
        />
        <ButtonWrapper>
          <ClearButton onClick={clearOptions} />
          <ApplyButton onClick={applyClickHandler} />
        </ButtonWrapper>
      </ModalWrapper>
    </CustomDialog>
  );
};

export default OptionsModal;
