import { Box, BoxProps, styled } from "@mui/material";

export const HeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2.5),
  padding: theme.spacing(1.25),
}));

export const HeaderSubWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1.25),
  gap: theme.spacing(1.25),
}));
