import CustomDialog from "src/components/custom-dialog/CustomDialog";
import Unverified from "./Unverified";
import { FC } from "react";
import { AuthDialogType, useAuthDailogStore } from "src/store/authentication/authStore";
import { Typography } from "@mui/material";


const UnverifiedDialog: FC = () => {
    const authDisplayType = useAuthDailogStore((state) => state.displayType);
    const setAuthDisplayType = useAuthDailogStore((state) => state.setDisplayType);
    const temporaryNumber = useAuthDailogStore((state) => state.temporaryNumber);

    function onCloseHandler() {
        setAuthDisplayType(AuthDialogType.None);
    }

    if (!temporaryNumber) {
        return 
    }
    return (
        <CustomDialog
          open={authDisplayType === AuthDialogType.Verify}
          onClose={onCloseHandler}
          onCloseHandler={onCloseHandler}
        >
          {temporaryNumber ? <Unverified fullNumber={temporaryNumber} /> : <Typography>There has been a problem trying to verify your account. Please try logging in again.</Typography>}
        </CustomDialog>
      );
}

export default UnverifiedDialog;