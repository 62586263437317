import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { selectDefaultStake } from "../betSlipSlice";
import {
  addSportBet,
  clearSportBets,
  removeSportBet,
  selectSportBets,
} from "./sportBetSlipSlice";

import {
  FixtureItem,
  FixtureMarketItem,
  FixtureSelectionItem,
} from "src/features/sports";

import { sportFixtureMapper } from "../../mappers/sportFixtureMapper";

export const useSportBetSlipSlice = () => {
  const dispatch = useAppDispatch();
  const sportBets = useAppSelector(selectSportBets);
  const defaultSportStake = useAppSelector(selectDefaultStake);

  function addBet(
    fixture: FixtureItem,
    market: FixtureMarketItem,
    selection: FixtureSelectionItem
  ) {
    const bet = sportFixtureMapper(
      fixture,
      market,
      selection,
      defaultSportStake
    );
    dispatch(addSportBet({ bet }));
  }

  function clearBets() {
    dispatch(clearSportBets());
  }

  function isBetActive(betId: string) {
    const bet = sportBets.find((sportBet) => sportBet.id === betId);

    return !!bet;
  }

  function removeBet(betId: string) {
    dispatch(removeSportBet({ betIds: [betId] }));
  }

  return { addBet, clearBets, isBetActive, removeBet };
};
