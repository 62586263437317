import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomDialog from 'src/components/custom-dialog/CustomDialog';
import Login from './Login';

import { useAuth } from '../../hooks/useAuth';
import {
  AuthDialogType,
  useAuthDailogStore,
} from 'src/store/authentication/authStore';

const LoginModal: FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isGuarded, setIsGuardedHandler } = useAuth();
  const setAuthDisplayType = useAuthDailogStore(
    (state) => state.setDisplayType
  );
  const authDisplayType = useAuthDailogStore((state) => state.displayType);
  function onCloseHandler() {
    if (isGuarded) {
      navigate('/home');
      setIsGuardedHandler(false);
    }
    setAuthDisplayType(AuthDialogType.None);
  }

  useEffect(() => {
    if (isAuthenticated) {
      setAuthDisplayType(AuthDialogType.None);
    }
  }, [isAuthenticated, setAuthDisplayType]);

  return (
    <CustomDialog
      open={authDisplayType === AuthDialogType.Login}
      onClose={onCloseHandler}
      onCloseHandler={onCloseHandler}
    >
      <Login />
    </CustomDialog>
  );
};

export default LoginModal;
