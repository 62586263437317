// TODO: SHOULD WE CALL THIS PUNTERS API?

/*
IPersonalDetails: Describes personal details of a user.

username: User's username (string)
firstName: User's first name (string)
surname: User's surname (string)
dateOfBirth: User's date of birth (string)
language: User's preferred language (string)
phoneNumber: User's phone number (string)
identificationNumber: User's identification number (string)
INotificationSettings: Defines notification settings for a user.

type: Type of notification (from EPunterNotificationType)
contactMethod: Contact method for notifications (from EUsernameType)
IRegMetadata: Contains registration metadata for a user.

affiliateCode: Affiliate code associated with the user (string)
EPunterNotificationType (Enum): Specifies notification types.

PROMOTIONAL: Denotes a promotional notification.
EUsernameType (Enum): Specifies different contact methods.

EMAIL: Contact method via email.
PHONE_NUMBER: Contact method via phone number.
IPostUser: Describes the structure of the data sent when creating a user.

personalDetails: User's personal details (from IPersonalDetails)
notificationSettings: Array of notification settings (from INotificationSettings)
password: User's password (string)
promoCode (optional): Optional promo code associated with the user (string)
metadata: Additional registration metadata (from IRegMetadata) or null
*/

// Describes personal details of a user
export type IPersonalDetails = {
  username: string; // User's username
  firstName: string; // User's first name
  surname: string; // User's surname
  dateOfBirth: string; // User's date of birth
  language: string; // User's preferred language
  phoneNumber: string; // User's phone number
  identificationNumber: string; // User's identification number
};

// Defines notification settings for a user
export type INotificationSettings = {
  type: EPunterNotificationType; // Type of notification - WHAT ARE THE OTHER NOTIFICATION TYPES
  contactMethod: EUsernameType; // Contact method for notifications
};

// Contains registration metadata for a user
export type IRegMetadata = {
  affiliateCode: string; // Affiliate code associated with the user
};

// Enum specifying notification types
export enum EPunterNotificationType {
  PROMOTIONAL = "PROMOTIONAL", // Denotes a promotional notification
}

// Enum specifying different contact methods
export enum EUsernameType {
  EMAIL = "EMAIL", // Contact method via email
  PHONE_NUMBER = "PHONE_NUMBER", // Contact method via phone number
}

// Describes the structure of the data sent when creating a user
export type IPostUser = {
  personalDetails: IPersonalDetails; // User's personal details
  notificationSettings: INotificationSettings[]; // Array of notification settings
  password: string; // User's password
  promoCode?: string; // Optional promo code associated with the user
  metadata: IRegMetadata | null; // Additional registration metadata or null
};

export type IAccountDetails = {
  limits: {
    FICA_DEPOSIT_LIMIT: number;
    FICA_WITHDRAWAL_LIMIT: number;
  };
  bankDetails: {
    accountNumber: string;
    bank: string;
    branchCode: string;
    type: string;
  };
  notificationSettings: {
    type: string;
    contactMethod: string;
  }[];
  personalDetails: {
    branchId: number;
    dateOfBirth: string;
    defaultCommunicationMethod: string;
    firstName: string;
    identificationNumber: string;
    language: string;
    surname: string;
    username: string;
    phoneNumber: string;
  };
  id: string;
  accountNumber: number;
  reference: string;
  enabled: boolean;
  passwordResetRequired: boolean;
  selfDeactivated: boolean;
  permissions: string[];
  ficaStatus: string;
  language: string;
  status: string;
  ficaSourceOfFunds: {
    id: number;
    declaredAsCorrect: boolean;
  };
  defaultLiabilityProfileId: number;
  hasSecurityPin: boolean;
  watched: boolean;
};

export type IBankingDetails = {
  accountNumber: string;
  bank: string;
  branchCode: string;
  type: string;
};

interface Transaction {
  id: string;
  type: string;
  currency: string;
  date: string;
  amount: number;
  ref: string;
  accountName: string;
  balance: number;
  legRef: string;
}

export interface ITransactions {
  transactions: Transaction[];
  count: number;
}

export enum TransactionType {
  BONUS_COMPLETED = "BONUS_COMPLETED",
  BONUS_FAILED = "BONUS_FAILED",
  DEPOSIT = "DEPOSIT",
  JOURNAL = "JOURNAL",
  WITHDRAWAL = "WITHDRAWAL",
}

export const TransactionTypes = [
  ...Object.values(TransactionType).filter((item) => {
    return isNaN(Number(item));
  }),
] as TransactionType[];

export interface ITransactionQuery {
  "start-date": string;
  "end-date": string;
  start: number;
  limit: number;
  "transaction-type": TransactionType[];
  "show-count": boolean;
}
