import { FC, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { BackButton, ForwardButton } from "src/components/buttons/carouselArrows/CarouselArrows";

function NextArrow(props: any) {
    const { onClick } = props;
    return (
        <ForwardButton onClick={onClick} />
    );
}

function PrevArrow(props: any) {
    const { onClick } = props;
    return (
        <BackButton onClick={onClick} />
    );
}

type FeaturedCarouselProps = {
    settings?: any;
    includeArrows?: boolean;
    children?: React.ReactNode[];
};

const SlickCarousel: FC<FeaturedCarouselProps> = ({ settings, includeArrows, children }) => {

    useEffect(() => {
        // Select the slick-track class and apply display: flex
        // we need to add this because otherwise it adds a extra row to the carousel
        const slickTrackElements = document.getElementsByClassName("slick-track");
        for (let i = 0; i < slickTrackElements.length; i++) {
            (slickTrackElements[i] as HTMLElement).style.display = "flex";
        }
    }, []);

    const slickSettings = {
        ...settings,
        nextArrow: includeArrows ? <NextArrow /> : <></>,
        prevArrow: includeArrows ? <PrevArrow /> : <></>,
    };

    return (
        <div className="slider-container">
            <Slider {...slickSettings}>
                {children}
            </Slider>
        </div>
    );
};

export default SlickCarousel;
