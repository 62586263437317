import { FC } from "react";
import { Step, StepLabel, Stepper } from "@mui/material";

import { StyledStepIcon, StyledConnector } from "./RegisterStepper.styles";

type RegisterStepperProps = {
  activeStep: number;
  steps: number[];
};

const RegisterStepper: FC<RegisterStepperProps> = ({ activeStep, steps }) => {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<StyledConnector />}
    >
      {steps.map((step, index) => (
        <Step key={"step-" + index}>
          <StepLabel StepIconComponent={StyledStepIcon} />
        </Step>
      ))}
    </Stepper>
  );
};

export default RegisterStepper;
