import { FC } from "react";

import { FixturesWrapper } from "./Fixtures.styles";
import FixtureGroupLoader from "./group/FixtureGroupLoader";

const FixturesLoader: FC = () => {
  return (
    <FixturesWrapper>
      <FixtureGroupLoader />
      <FixtureGroupLoader />
    </FixturesWrapper>
  );
};

export default FixturesLoader;
