import { baseApi } from "./baseApi.service";
import { Endpoint, TagId } from "./config/apiTags";

import { MyBetsItem } from "src/features/myBets";

function periodHandler(period: string) {
  const endDate = new Date();
  endDate.setHours(23, 59, 59, 999);
  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);

  if (period === "week") {
    startDate.setDate(startDate.getDate() - 7);
  } else if (period === "month") {
    startDate.setMonth(startDate.getMonth() - 1);
  } else if (period === "year") {
    startDate.setFullYear(startDate.getFullYear() - 1);
  }

  return { startDate, endDate };
}

function statusHandler(status: string) {
  if (status === "pending") {
    return `status=Active`;
  } else if (status === "settled") {
    return `status=WON&status=LOST&status=SCRATCHED&status=CANCELLED&status=CASHED_OUT`;
  } else if (status === "won") {
    return `status=WON&status=CASHED_OUT`;
  } else if (status === "lost") {
    return `status=LOST`;
  }
}

interface MyBetsResponse {
  count: number;
  items: MyBetsItem[];
}

const myBetsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSportBets: builder.query<
      MyBetsResponse,
      { period: string; status: string }
    >({
      query: (query) => {
        const { period, status } = query;

        const { startDate, endDate } = periodHandler(period);
        const urlStatus = statusHandler(status);

        const url = `${
          Endpoint.MY_BETS
        }?start-date=${startDate.toISOString()}&end-date=${endDate.toISOString()}&start=0&limit=1000&${urlStatus}`;

        return {
          url,
          method: "GET",
          headers: { Wallet: "Turfsport" },
        };
      },
      providesTags: [{ type: Endpoint.MY_BETS, id: TagId.LIST }],
    }),
  }),
});

export const { useGetSportBetsQuery, useLazyGetSportBetsQuery } = myBetsApi;
