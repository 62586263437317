import {
  Box,
  BoxProps,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  ListItemTextProps,
  styled,
} from "@mui/material";

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",

  borderRadius: "10px",
  border: "1px solid " + theme.colors.neutral.white[100],
}));

export const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(
  ({ theme }) => ({
    backgroundColor: "inherit",

    "&:hover": {
      backgroundColor: "inherit",
    },
  })
);

export const StyledListItemText = styled(ListItemText)<ListItemTextProps>(
  ({ theme }) => ({
    fontSize: 16,
    forntWeight: 400,
    color: theme.colors.neutral.white[60],
  })
);
