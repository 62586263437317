/**
 * Custom OTP input element
 *
 * Adapted from
 * https://dominicarrojado.com/posts/how-to-create-your-own-otp-input-in-react-and-typescript-with-tests-part-1/
 *
 * Support for different OTP lengths
 * Included validation and error handling
 */
import {
  ChangeEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  useMemo,
  useRef,
} from "react";
import { Box, FormHelperText } from "@mui/material";

import { OtpInputWrapper } from "./Inputs.styles";
import CustomInput, { CustomInputElement } from "./custom-input/CustomInput";

import { splitOtp, RE_DIGIT } from "src/utils/splitOtp";

type OtpInputProps = {
  otp: string;
  otpLength?: number;
  error?: boolean;
  helperText?: string;
  otpChangeHandler: (value: string) => void;
};

const OtpInput: FC<OtpInputProps> = ({
  otp,
  otpLength = 6,
  error,
  helperText,
  otpChangeHandler,
}) => {
  const inputRefs = useRef<any>([]);
  const otpItems = useMemo(() => {
    return splitOtp(otp, otpLength);
  }, [otp, otpLength]);

  const focusNextInput = (index: number) => {
    inputRefs.current[
      index + 1 > otpLength - 1 ? otpLength - 1 : index + 1
    ].focus();
  };

  const focusPrevInput = (index: number) => {
    inputRefs.current[index - 1 < 0 ? 0 : index - 1].focus();
  };

  const onChange = (event: ChangeEvent<CustomInputElement>, index: number) => {
    const value = event.target.value.trim();
    const isDigit = RE_DIGIT.test(value);

    if (!isDigit && value !== "") return;

    const newValue = isDigit ? value : " ";

    if (newValue.length === 1) {
      const newOtp =
        otp.substring(0, index) + newValue + otp.substring(index + 1);
      otpChangeHandler(newOtp);

      if (!isDigit) return;

      focusNextInput(index);
    } else if (newValue.length === 6) {
      otpChangeHandler(newValue);

      event.target.blur();
    }
  };

  const onKeyDown = (
    event: KeyboardEvent<CustomInputElement>,
    index: number
  ) => {
    const key = event.key;
    const target = event.target as HTMLInputElement;

    if (key === "ArrowRight" || key === "ArrowDown") {
      event.preventDefault();
      focusNextInput(index);
      return;
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      event.preventDefault();
      focusPrevInput(index);
    }

    if (key !== "Backspace" || target.value !== "") {
      return;
    }

    focusPrevInput(index);
  };

  const onFocus = (event: FocusEvent<CustomInputElement>) => {
    const target = event.target;

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <Box>
      <OtpInputWrapper>
        {otpItems.map((digit, index) => (
          <CustomInput
            key={"otp-" + index}
            id={"forgot-otp-input-" + index}
            autoComplete="one-time-pin"
            error={error}
            inputMode="numeric"
            inputRef={(ref) => (inputRefs.current[index] = ref)}
            inputProps={{
              onFocus,
              onKeyDown: (e) => onKeyDown(e, index),
              inputMode: 'numeric'
            }}
            onChange={(e) => onChange(e, index)}
            value={digit}
          />
        ))}
      </OtpInputWrapper>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Box>
  );
};

export default OtpInput;
