import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const BetDetailWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.neutral.white[100],
  fontSize: 14,
  fontWeight: 400,
  textAlign: "center",
}));

export const Value = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.neutral.white[100],
  fontSize: 24,
  fontWeight: 700,
  textAlign: "center",
}));
