import { createAsyncThunk } from '@reduxjs/toolkit'

export const resetUser = createAsyncThunk(
    'user/resetUser',
    async (
        _,
        { rejectWithValue }
    ) => {
        return true
        //TODO: ARE WE USING THIS?
        // try {
        //     return true;
        // } 
        // catch (error: any) {
        //     return rejectWithValue(error.message)
        // }
    }
);