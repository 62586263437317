import { Box, BoxProps, styled } from "@mui/material";

export const FixturesWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "start",
  gap: theme.spacing(2),

  backgroundColor: "inherit",
}));
