import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const HeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "140%",
  overflow: "hidden",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  padding: theme.spacing(1.25, 2.5),

  background: theme.colors.gradients.gold,
}));

export const HeaderTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.colors.neutral.black[100],
    fontWeight: "bold",
  })
);
