import {
  LottoBet,
  LottoBetLeg,
  LottoBetLegSelection,
} from "src/features/betslip/models/lotto";
import { LottoFixtureBallType } from "../../Fixture/models/lottoFixture";
import { QuickPicksMarketType } from "../quick-picks/types";

export const myNumbersMapper = (
  stake: number,
  fixtureDefId: string,
  market: QuickPicksMarketType,
  numbers: LottoFixtureBallType[]
): LottoBet => {
  const numberLabels = numbers.map((number) => number.label).join("");

  const selection: LottoBetLegSelection[] = numbers.map((number) => ({
    gridNumber: number.gridNumber,
    label: number.label,
    type: market.def.data.type,
    ...(number.absoluteDrawPosition && {
      absoluteDrawPosition: number.absoluteDrawPosition,
    }),
  }));

  const lottoBetLegs: LottoBetLeg[] = [
    {
      fixtureId: market.fixtureId,
      marketId: market.id,
      marketName: market.name,
      fixtureDefId,
      price: market.price.dec,
      selection,
      gameMode: "NUMBERS",
      marketDefId: market.def.id,
      game: market.gameType,
      wagerCloseDate: market.wagerCloseDate,
    },
  ];

  const lottoBet: LottoBet = {
    id: `${market.fixtureId}-${market.id}-${numberLabels}`,
    legs: lottoBetLegs,
    stake,
    payout: market.price.dec * stake,
    key: "",
    errorCode: 0,
    isFixedStake: Boolean(market.fixedStake),
  };

  return lottoBet;
};
