import { FC, PropsWithChildren } from "react";

import { StyledBox, NavButtonsBox } from "./BetSlipInLine.styles";
import NavButtons from "../navButtons/NavButtons";

const BetSlipInLine: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledBox>
      <NavButtonsBox>
        <NavButtons />
      </NavButtonsBox>
      {children}
    </StyledBox>
  );
};

export default BetSlipInLine;
