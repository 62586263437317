import { FC, PropsWithChildren } from "react";

import { StyledDrawer, NavButtonsBox } from "./BetSlipDrawer.styles";
import NavButtons from "../navButtons/NavButtons";

import { useBetSlipHandler } from "./BetSlipDrawer.hooks";

const BetSlipDrawer: FC<PropsWithChildren> = ({ children }) => {
  const { isBetSlipOpen, toggleBetSlipHandler } = useBetSlipHandler();

  return (
    <StyledDrawer
      anchor="bottom"
      open={isBetSlipOpen}
      onClose={() => toggleBetSlipHandler(false)}
      ModalProps={{
        keepMounted: true,
      }}
      variant="temporary"
    >
      <NavButtonsBox>
        <NavButtons toggleBetSlip={toggleBetSlipHandler} />
      </NavButtonsBox>
      {children}
    </StyledDrawer>
  );
};

export default BetSlipDrawer;
