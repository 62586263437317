import { env } from "src/env";

export enum Endpoint {
  /**
   * f-game-type=SPORT|LOTTO sport type
   * f-field=attr|markets|id fields to show
   * f-tag=retailOnly:~true
   * f-id= the id of the item
   * f-start=0
   * f-in-play=false
   * m-status=OPEN
   * m-status=SUSPENDED
   * m-field-group=all
   *
   */
  FIXTURES = 'online/search/api/fixtures?f-game-type=SPORT&f-field=attr&f-field=markets&f-field=id&f-tag=retailOnly:~true&f-start=0&f-in-play=false&m-status=OPEN&m-status=SUSPENDED&m-field-group=all',
  LIVE_FIXTURES = 'online/search/api/fixtures?f-game-type=SPORT&f-field=attr&f-field=markets&f-field=id&f-tag=retailOnly:~true&f-start=0&f-in-play=true&m-status=OPEN&m-status=SUSPENDED&m-field-group=all',

  /**
   * sport-name= The name of the sport that you woud like to display orders for
   *
   */
  DISPLAY_ORDERS = 'online/api/settings/display-orders?sport-name=Soccer&sport-name=Tennis&sport-name=Rugby&sport-name=Cricket&sport-name=Basketball',
  DISPLAY_ORDER = 'online/api/settings/display-orders?',

  LOTTO = `online/search/api/fixtures?f-game-type=LOTTO&f-start=0&f-field-group=all&f-sort-by=date&f-field=name&f-field=id&f-field=status&f-field=date&f-field=def&f-tag=retailOnly:~true&m-limit=0`,
  LOTTO_INFO = `online/search/api/fixtures?f-game-type=LOTTO&f-status=CLOSED&f-min-date=2024-01-02T14:15:57.629Z&m-status=RESULTED&f-sort-by=date&f-sort-order=desc&f-limit=1&f-field=name&f-field=id&f-field=result&f-field=date`,
  LOTTO_RESULTS = 'online/search/api/fixtures?f-game-type=LOTTO&f-status=CLOSED&m-status=RESULTED&f-sort-by=date&f-sort-order=desc&f-limit=20&f-field=name&f-field=id&f-field=def&f-field=result&f-field=date&f-field=attr&f-start=0',

  VALIDATEBETS = 'online/bet-validator-node/api/validate',
  PLACEBETS = 'online/api/bet-slips?auto-activate=true',

  POST_USER = 'online/api/punters',
  PRAGMATIC_TOKEN = "/online/turfsport/api/pragmatic-play",


  AVIATOR = `online/turfsport/api/spribe`,
  BETGAMES = `online/turfsport/api/bet-games`,
  EVOLUTION = `online/turfsport/api/evolution-direct`,
  EZUGI = `online/turfsport/api/ezugi`,
  HABANERO = `online/turfsport/api/habanero`,
  SPINMATIC = `online/turfsport/api/xpress`,
  TRANSACTIONS = 'online/my-account/api/transactions',
  MY_ACCOUNT = `online/my-account/api/`,
  WALLETS = `online/my-account/api/wallets`,
  MY_BETS = `online/my-account/api/bets`,
  FICA = `online/my-account/api/fica`,
  INCOME_SOURCES = `online/my-account/api/fica/sources`,
  WITHDRAWALS = `online/my-account/api/withdrawal-requests`,
  FIRST_DEPOSIT = `online/my-account/api/first-deposit`,
  SETTINGS = `online/my-account/api/settings`,
  SEARCH_LOTTO = 'online/search/api/fixtures',
  SPLIT_THE_POT = 'online/turfsport/api/split-the-pot/tokens',


  //Deposits
  DEPOSITS = `online/my-account/api/deposits`,
  DEPOSIT_PAYMENT_RESPONSE = `https://online-api-prod.tsretail.co.za/gateway/v1/payment-response`,

}

export const TAG_TYPES: readonly string[] = Object.values(Endpoint);

export enum TagId {
  PARTIAL_LIST = 'PARTIAL-LIST',
  LIST = 'LIST',
  ITEM = 'ITEM',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}
