import { isPage, isPageObj } from "src/router/routes";

import { deCamel } from "src/utils/formatters";

export type TabDataItem = {
  label: string;
  path: string;
};

export const getCurrentTabs = (currentPage: any, currentPath: string) => {
  const currentTabs: TabDataItem[] = [];

  if (isPage(currentPage)) {
    const currentPages = Object.values(currentPage);

    for (const page of currentPages) {
      if (isPageObj(page)) {
        const label = deCamel(page.name);
        const path = page.path;

        if (currentPath === "slots" || currentPath === "splitThePot") {
          // if the current path is slots, exclude paths with "play"
          if (!page.name.includes("play")) {
            currentTabs.push({ label, path });
          }
        } else {
          currentTabs.push({ label, path });
        }
      }
    }
  }

  return currentTabs;
};
