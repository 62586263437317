import { FC } from "react";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";

import { Icon } from "src/components/icons";

import { useProfileWalletSelector } from "./ProfileButtons.hooks";
import { useSidebarContext } from "src/context/SidebarContext";

import { currencyFormat } from "src/utils/formatters";
import AccountInfo from "src/features/wallet/components/header/accountInfo/AccountInfo";
import { DesktopWrapper, MobileWrapper } from "src/components/component-wrappers";

const ProfileButtons: FC = () => {
  const { currentWallet, isLoading } = useProfileWalletSelector();

  const { toggleWalletDrawer } = useSidebarContext();

  return (
    <Box component="span" display="flex" gap={2.5}>
      <IconButton onClick={toggleWalletDrawer}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap={1.2}
        >
          <Icon name="Wallet" />
          {isLoading ? (
            <Skeleton
              animation="pulse"
              variant="text"
              height="12px"
              width="48px"
              sx={(theme) => ({
                bgcolor: theme.colors.secondary.main,
                m: 0,
                opacity: 0.6,
              })}
            />
          ) : (
            <Typography color="secondary" fontSize={10} fontWeight={600}>
              {currencyFormat(
                currentWallet.currencies[0].available,
                currentWallet.currencies[0].symbol
              )}
            </Typography>
          )}
        </Box>
      </IconButton>
      <IconButton onClick={toggleWalletDrawer}>
        <Icon name="Account" />
        {/* We only want to show the account info if they are not on mobile view other spacing is an issue */}
        <DesktopWrapper>
          <AccountInfo />
        </DesktopWrapper>
      </IconButton>
    </Box>
  );
};

export default ProfileButtons;
