import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const ForgotWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  padding: theme.spacing(5),
}));

export const ForgotTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 25,
  fontWeight: 700,
  color: theme.colors.neutral.white[100],
}));

export const ForgotText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.colors.text.light,
}));
