import { ChangeEvent, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
  selectCurrentWallet,
  updateWalletSelection,
} from "../../store/walletSlice";

import { useGetWalletsQuery } from "src/services/restApi/queries/wallets.service";

type WalletChangeEvent = ChangeEvent<HTMLInputElement>;

export const useWalletSelector = () => {
  const dispatch = useAppDispatch();
  const currentWallet = useAppSelector(selectCurrentWallet);

  const { data: wallets, isFetching, isLoading } = useGetWalletsQuery();

  const changeWallet = (event: WalletChangeEvent) => {
    event.preventDefault();

    const newValue = event.target.value;
    const newWallet = wallets!.items.find((wallet) => wallet.type === newValue);
    if (newWallet) {
      dispatch(updateWalletSelection({ wallet: newWallet }));
    }
  };

  useEffect(() => {
    if (!wallets) {
      return;
    }

    const newWallet = wallets.items.find(
      (wallet) => wallet.id === currentWallet.id
    );

    if (newWallet) {
      dispatch(updateWalletSelection({ wallet: newWallet }));
    }
  }, [currentWallet, dispatch, wallets]);

  return {
    currentWallet,
    isLoading: isFetching || isLoading,
    wallets,
    changeWallet,
  };
};
