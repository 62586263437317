import { FC } from "react";
import { Box, Typography, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.secondary.main,
  color: theme.colors.neutral.black[100],
  paddingLeft: "5px",
  paddingRight: "5px",
  borderRadius: "12px",
}));

const NewIcon: FC = () => {
  return (
    <Wrapper className='new-icon-wrapper'>
      <Typography fontSize={8} fontWeight={500} color="black" className="new-icon-text">
        NEW
      </Typography>
    </Wrapper>
  );
};

export default NewIcon;
