import { FC, PropsWithChildren } from "react";
import { useTheme } from "@mui/material";
import { WarningAmberRounded } from "@mui/icons-material";

import {
  Wrapper,
  Title,
  SpecificationWrapper,
  Text,
} from "./PasswordSpecifications.styles";

const Specification: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  return (
    <SpecificationWrapper>
      <WarningAmberRounded
        fontSize="small"
        sx={{ color: theme.colors.text.light }}
      />
      <Text>{children}</Text>
    </SpecificationWrapper>
  );
};

type PasswordSpecificationsProps = {
  background: "black" | "gray";
};

const PasswordSpecifications: FC<PasswordSpecificationsProps> = ({
  background,
}) => {
  const theme = useTheme();

  return (
    <Wrapper
      sx={{
        backgroundColor:
          background === "black"
            ? theme.colors.neutral.black[100]
            : theme.general.background,
      }}
    >
      <Title>Your password must contain at least:</Title>
      <Specification>8 to 16 characters</Specification>
      <Specification>1 uppercase letter e.g. A</Specification>
      <Specification>1 lowercase letter e.g. a</Specification>
      <Specification>1 number e.g. 4</Specification>
      <Specification>1 special character e.g. $</Specification>
    </Wrapper>
  );
};

export default PasswordSpecifications;
