import { toast } from "react-toastify";

import { useAppDispatch } from "src/hooks/hooks";
import {
  addLottoBet,
  clearLottoBets,
  removeLottoBet,
} from "./lottoBetSlipSlice";

import { LottoBet } from "../../models/lotto";

export const useLottoBetSlipSlice = () => {
  const dispatch = useAppDispatch();

  function addBet(bet: LottoBet) {
    dispatch(addLottoBet({ bet }));
    // toast.success("Bet successfully added to bet slip.");
  }

  function clearBets() {
    dispatch(clearLottoBets());
  }

  function removeBet(betId: string) {
    dispatch(removeLottoBet({ betId }));
  }

  return { addBet, clearBets, removeBet };
};
