import { FC } from "react";
import { SelectChangeEvent } from "@mui/material";

import CustomSelect, { SelectItem } from "src/components/customSelect";

import { useMyBets } from "../../hooks/useMyBets";

const items: SelectItem[] = [
  { label: "pending", value: "pending" },
  { label: "settled", value: "settled" },
  { label: "lost", value: "lost" },
  { label: "won", value: "won" },
];

const StatusFilter: FC = () => {
  const { currentStatusFilter, changeStatusFilter } = useMyBets();

  function onChangeHandler(event: SelectChangeEvent<string>) {
    changeStatusFilter(event.target.value);
  }

  return (
    <CustomSelect
      id="status-select"
      items={items}
      onChange={onChangeHandler}
      value={currentStatusFilter}
      variant="outlined"
    />
  );
};

export default StatusFilter;
