import {
  Box,
  BoxProps,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioProps,
  RadioGroup,
  RadioGroupProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

export const Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  gap: theme.spacing(1.25),
  padding: theme.spacing(2.5),
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.neutral.white[100],
  fontSize: 20,
  fontWeight: 700,
  textAlign: "center",
}));

export const SelectorWrapper = styled(RadioGroup)<RadioGroupProps>(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
}));

export const Selector = styled(FormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    color: theme.colors.neutral.white[100],
    fontSize: 16,
    fontWeight: 400,

    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  })
);

export const SelectorButton = styled(Radio)<RadioProps>(({ theme }) => ({
  "&.MuiRadio-root": {
    color: theme.colors.secondary.main,
  },
}));

export const BetStakeWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
}));

export const BetStakeText = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.colors.neutral.white[100],
    fontSize: 14,
    fontWeight: 700,

    flexGrow: 1,
  })
);
