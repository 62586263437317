import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  styled,
} from "@mui/material";

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.general.background,
  overflow: "auto",
  overflowX: "hidden",

  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(2),
}));

export const StyledContainer = styled(Container)<ContainerProps>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
    gap: theme.spacing(1.875),
  })
);
