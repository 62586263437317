import { Box, BoxProps, styled } from "@mui/material";

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },

  borderLeft: "2px solid " + theme.colors.secondary.main,

  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "flex-start",

  position: "fixed",
  bottom: 0,
  right: 0,
  height: `calc(100vh - ${theme.header.height})`,
  width: theme.betSlip.width,
}));

export const NavButtonsBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.primaryAlt.main,

  height: "74px",
  width: "100%",
}));
